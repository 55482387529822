import React, { useState, useCallback } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'


function Custom(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [currentTab, setCurrentTab] = useState(0)
  const [openTab, setOpenTab] = useState('odds')

  const races = null



  function handleFilterClick(e, type, batch) {
    e.preventDefault()
    if (currentTab !== 0) {
      setCurrentTab(0)
    }
    document.querySelectorAll('.filter-trigger')?.forEach(e => e.classList.remove('current'))
    e.currentTarget.classList.add('current')
  }


  function handleOpenTabClick(type) {
    setOpenTab(type)
  }


  return (
    <div ref={io} className={`block-custom block-results-replays-component calendar-compact flex-12 ai-flex-start ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

      <div className="span-12 padd padd-left-off padd-right-off padd-bottom-off c0">

        <div className="flex-12">


          <div className="span-8 racing-info-details flex-12 ac-flex-start">


          </div>


          <div className="span-4 span-12-mobile racing-info-selector flex-12 c1 fs-85 padd-top-half padd-bottom-half ac-flex-start">

            <div className="span-6 filter-wrap text-left ai-flex-start ac-flex-start jc-flex-start padd-half padd-top-off padd-right-off">
              <div className="has-intro btn filter-trigger" onClick={(e) => handleOpenTabClick('odds')}>
                <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100} className="c5 t border-c0 border-top-off border-right-c5">
                  <span>Odds</span>
                </Intro>
              </div>
              {openTab === 'odds' ?
                <div className="sub-items c5 flex" >
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>WIN / PLACE / SHOW</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 2} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>DOUBLE</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 3} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>EXACTA</span>
                    </Intro>
                  </div>
                </div>
                : null}
              <div className="has-intro btn filter-trigger" onClick={(e) => handleOpenTabClick('probables')}>
                <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} className="c5 border-c0 t border-top-off border-right-c5">
                  <span>Probables</span>
                </Intro>
              </div>
              {openTab === 'probables' ?
                <div className="sub-items c5 flex" >
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>WIN / PLACE / SHOW</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 3} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>EXACTA</span>
                    </Intro>
                  </div>
                </div>
                : null}
              <div className="has-intro btn filter-trigger" onClick={(e) => handleOpenTabClick('pools')}>
                <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="c5 border-c0 t border-top-off border-right-c5">
                  <span>Pools</span>
                </Intro>
              </div>
              {openTab === 'pools' ?
                <div className="sub-items c5 flex" >
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>WIN / PLACE / SHOW</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 2} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>EXOTIC</span>
                    </Intro>
                  </div>
                </div>
                : null}
              <div className="has-intro btn filter-trigger" onClick={(e) => handleOpenTabClick('will-pay')}>
                <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className="c5 border-c0 t border-top-off border-right-c5">
                  <span>Will Pay</span>
                </Intro>
              </div>
              {openTab === 'will-pay' ?
                <div className="sub-items c5 flex" >
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>WIN / PLACE / SHOW</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 2} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>DOUBLE</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e, 'results', races)}>
                    <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 3} className="c5 t border-c0 border-top-off border-right-c5">
                      <span>EXACTA</span>
                    </Intro>
                  </div>
                </div>
                : null}
            </div>

            <div className="span-6">

            </div>

          </div>


        </div>

      </div>

    </div>
  )
}

export default Custom