import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import { useQuery, gql } from '@apollo/client'
import Button from '../../Button'
import { useStaticQuery, graphql } from "gatsby"
import Video from '../../Video'
import { Link } from 'gatsby'

function Custom(props) {

  const [io, ioInView] = useInView({ triggerOnce: true })
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Apollo, Pull Events!
  const { loading, error, data: apollo, refetch } = useQuery(APOLLO_QUERY, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore'
  })

  let featuredEvents = []
  if(apollo && !loading)
    featuredEvents = [...apollo?.contentfulOptions?.items?.[0]?.featuredEventsCollection?.items]

  const data = useStaticQuery(graphql`
		query FeaturedEvents {
			contentfulOptions {
        featuredEventsVideo {
          ...blockVideo
        }
			}
		}	
	`)

  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const todayDay = todayDate.getDate()
  const todayLimit = new Date(todayYear, todayMonth, todayDay).toISOString()

  const media = data?.contentfulOptions?.featuredEventsVideo
  const allEventsCombined = []
  const eventsMergedWithDates = []

  // Map Apollo Object to contentful schema
  featuredEvents?.forEach(event => {
    allEventsCombined.push({
      buttons: event?.buttons ? [...event?.buttons]: null,
      eventday : event?.linkedFrom?.eventDayCollection?.items ? [...event?.linkedFrom?.eventDayCollection?.items] : null,
      featuredImage: event?.featuredImage ? event?.featuredImage : null ,
      name: event?.name ? event?.name : null,
      slug: event?.slug ?  event?.slug : null,
      sys: {
        contentType: {
          sys: {
            id: event?.__typename ? event?.__typename : null,
          }
        }
      }
    })
  })

  allEventsCombined?.map(item => {
  
    let futureDatesOnly = item?.eventday?.filter((item) => {
      if (new Date(item.date.replace(/-/g, '\/').replace(/T.+/, '')) >= new Date(todayLimit.replace(/-/g, '\/').replace(/T.+/, '')))
        return item
    })

    if (item?.eventday)
      item.eventday = [...futureDatesOnly]

    item?.eventday?.sort(function (a, b) {
      return new Date(a.date.replace(/-/g, '\/').replace(/T.+/, '')) - new Date(b.date.replace(/-/g, '\/').replace(/T.+/, ''));
    });

    if (item.eventday && item.eventday[0]) {
      eventsMergedWithDates?.push({ ...item, ...item?.eventday?.[0] })
    }

  })

  const eventsMap = eventsMergedWithDates?.map((item, i) => {

    const buttons = item?.buttons?.length > 0 ? item?.buttons?.map((button, i) => {
      return <Button key={i} link={button.value} label={button.key} classes="" />
    }) : null

    let d = new Date(item.date?.replace(/-/g, '\/')?.replace(/T.+/, ''))

    const linkString =
      item?.sys.contentType.sys.id === 'RaceEvent' ?
        `/racing-information/#race?day=${item.date.substring(0, item.date.indexOf('T'))}`
        : item?.sys.contentType.sys.id === 'Promotion' ?
          `/promotion/${item?.slug}/`
          :
          `/event/entertainment/${item?.slug}/`

    const learnMore =
      item?.sys.contentType.sys.id === 'RaceEvent' ?
        <Button link={`/racing-information/#race?day=${item.date.substring(0, item.date.indexOf('T'))}`} label={'Race Details'} classes="learn-more" />
        : item?.sys.contentType.sys.id === 'Promotion' ?
          <Button link={`/promotion/${item?.slug}/`} label={'Learn More'} classes="learn-more" />
          :
          <Button link={`/event/entertainment/${item?.slug}/`} label={'Learn More'} classes="learn-more" />

    return (
      <div key={`featured-${i}`} className="single-item span-12">
        <div className="image-box span-3">
          <Link style={{ overflow: "hidden" }} to={`${linkString}`} className="nostrike img-link">

          <div className='aspect-ratio'>
            {
              item?.featuredImage ?
                <div className='gatsby-image-wrapper fake'>
                  <img 
                    src={item?.featuredImage.thumb}
                    title={item.featuredImage.title}
                    alt={item.featuredImage.description}
                  />
                </div>
                :
                null
            }
          </div>
          </Link>

        </div>
        <div className="content span-9">
          <div className="title">
            <Link to={`${linkString}`}>
              <h4>{item.name}</h4>
            </Link>
          </div>
          {d ?
            <p className="date">{`${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`} </p>
            : null}
          {buttons ? buttons : learnMore}
          {/* <Link to={`/event/${item.sys.contentType.sys.id === 'raceEvent' ? 'racing' : 'entertainment'}/${item.slug}`} className="btn small"><span>Learn More</span></Link> */}
        </div>
      </div>
    )
  })

  return (
    <div ref={io} className={`block-custom featured-event beige-stripes  featured-events  flex-12  ${props.classes}`}>

      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className='heading padd-top padd-right padd-left-half'>
        <h2>EVENTS/TICKETING</h2>
      </div>
      <div className="left-side span-8 span-12-tablet padd-right padd-left-half">

        <div className="padd-top padd-bottom">
          {/* <HTMLVideo hideVolumeButton={true} file={'https://videos.ctfassets.net/rkhnw24d6im5/wTStpkNm5xkswfQLcDB6G/6e4dcad98e824739c797cca0811a593f/santa_anita_website_loop_lifestyle_crop__1080p_.mp4'}/> */}
          {media ?
           <Video {...media}/>
          :
           null
          }
        </div>
      </div>
      <div className="span-4 span-12-tablet padd flex ac-flex-start">
        <h4>Upcoming events</h4>
        <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} className="border-top-off border-left-off border-right-off">
          <div className="container fs-85 flex-12 span-12">
            {eventsMap}
          </div>
          {/* <p className="text-right"><Link to="/events/" className="btn arrow"><span>All events</span></Link></p> */}
        </Intro>
      </div>
    </div>
  )
}

export default Custom

const APOLLO_QUERY = gql`
  query eventsQuery {
    contentfulOptions: optionsCollection(limit: 1) {
      items {
        featuredEventsCollection {
          items {
            ... on Event { 
              linkedFrom {
              eventDayCollection {
                items {
                  date
                }
              }
            }
            __typename
            name
            slug
            buttons
            featuredImage {
              title
              description
              thumb: url(transform: { width: 800, height: 800 })
            }
            }
            ... on RaceEvent {
            linkedFrom {
              eventDayCollection {
                items {
                  date
                }
              }
            }
            __typename
            name
            slug
            buttons
            featuredImage {
              title
              description
              thumb: url(transform: { width: 800, height: 800 })
            }
          }
          }
        }
      }
    }
  }
`