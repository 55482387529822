import React from 'react'
import './style.scss'

import Intro from '../../../../../animations/Intro'
import Weather from '../../../../Weather'
import { Link } from 'gatsby'

function RaceDetailsBox(props) {

  const today = new Date()

  return (
    <div className={`racing-details-box c0`}>

      <Intro visible={true} in={{ border: 300, fade: 0 }} mounted={true} stay={true} className="w-100 border-bottom-off border-right-off">
        <div className='logo has-intro'>
          <Intro visible={true} in={{ border: 300, bg: 300, blink: 0 }} className="c3 t border-top-off border-left-off">
            <div className={`logo logo-gulfstreampark`}></div>
          </Intro>
        </div>
        <div className="logo-aside border-c5 has-intro">
          <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} mounted={true} stay={true} className="c5 t border-top-off border-left-off">
            <div>
              <h6>Racing Information for</h6>
              <h5>GULFSTREAM PARK. <br />{props.type === 'long' ? today.toLocaleDateString(['en-US'], { weekday: 'long', month: 'long', day: '2-digit' }) : null}</h5>
              {props.type == 'long' ? <h6><Link to={'/racing-information/'} className="">Search upcoming dates</Link></h6> : null}
            </div>
          </Intro>
        </div>
        {props.type === 'long' ?
          <div className="logo-aside-arrow border-c5 has-intro">
            <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={350} mounted={true} stay={true} className="c1 t border-top-off border-left-off">
              <Weather />
            </Intro>
          </div>
          :
          null
        }
        {props.type !== 'long' ?
          <div className="expanded-info c0 border-c5 t has-intro">
            <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} mounted={true} stay={true} className="border-top-off border-left-off">
              <p>Use calendar to pick dates and view entries for upcoming races, workouts, changes and results for past races</p>
            </Intro>
          </div>
          :
          <div className="expanded-info c0 border-c5 t has-intro">
            <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} mounted={true} stay={true} className="border-top-off border-left-off">
              <p>Click below to view Stakes, Entries, Results / Replays, Carryovers &amp; Guarantees for today's races.</p>
            </Intro>
          </div>
        }
      </Intro>

    </div>
  )
}

export default RaceDetailsBox