import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../../context/NaviContext'
import Intro from '../../../animations/Intro'

function Hamburger() {
	const naviContext = useContext(NaviContext)

  return (
		<div
			className="master-hamburger"
			onClick={
				() => {
					naviContext.activeToggle()
					naviContext.hamburgerActiveToggle()
				}
			}
			onKeyDown={
				() => {
					naviContext.activeToggle()
					naviContext.hamburgerActiveToggle()
				}
			}
			role="button"
			tabIndex={0}
			aria-label="Navigation hamburger menu toggle"
		>
			<div className="cover"></div>
			<Intro visible={true} in={{ bg: 900, blink: 500 }} delayIn={0} className="master-hamburger-positioner c5 t border-c5">
				<div className="master-hamburger-container">
					<div className={`hamburger-container hamburger hamburger--close1 ${naviContext.isHamburgerActive ? 'open' : null }`}>
						<div className="hamburger__icon">
							<div className="hamburger__line hamburger__line--1"></div>
							<div className="hamburger__line hamburger__line--2"></div>
							<div className="hamburger__line hamburger__line--3"></div>
						</div>
					</div>
				</div>
			</Intro>
		</div>
  )
}

export default Hamburger