import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Select from 'react-select'
import Intro from '../../../../animations/Intro'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })
	
  const [currentContest, setCurrentContest] = useState()

  const selectOptions = props?.custom?.data?.leaderboard.map( (node,i) => {
    return { value: i, label: node.name }
  })

	const leaderboard = props?.custom?.data?.leaderboard.map( (node,i) => {
    const content = node.content.map((nodenode, i) => {
      return (
        <tr key={i}>
          <td>{nodenode.place}</td>
          <td>{nodenode.player}</td>
          <td>{nodenode.score}</td>
          <td>{nodenode.prize}</td>
        </tr>
      )
    })

		if(i === currentContest)
			return <tbody key={`tbody-${i}`}>{content}</tbody>
	})

  return (
		<div ref={io} className={`block-custom padd c5 w-100 ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className="flex-12">
        <div className="span-6 span-12-mobile">
          <h1 >Leaderboard</h1>
        </div>
        <div className="span-6 span-12-mobile select-container flex ai-center">
          <div className="select flex">
            <div className="c1 name uppercase">
              <p>Contest</p>
            </div>
            <Select 
              onChange={event => setCurrentContest(parseInt(event.value))} 
              options={selectOptions} 
              className="react-select"
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  //checked inside list
                  primary: '#2C9BF4',
                  //mouse down
                  primary50: '#000000',
                  //hover
                  primary25: '#383838',
                  //background
                  neutral0: '#000000',
                  //border
                  neutral20: '#ffffff',
                  //border hover
                  neutral30: '#2C9BF4',
                  //arrow hover
                  neutral40: '#2C9BF4',
                  //arrow
                  neutral60: '#ffffff',
                  //text 
                  neutral80: '#ffffff',
             
                },
              })}
            />
          </div>
        </div>
      </div>
      <div className="leaderboard-table">
        <Intro switch={currentContest} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
          <table>
            <thead className="uppercase">
              <tr>
                <th>Place</th>
                <th>Player</th>
                <th>Score</th>
                <th>Prize Value</th>
              </tr>
            </thead>
              {leaderboard}
          </table>
        </Intro>
      </div>
		</div>
  )
}

export default Custom