import React, { useEffect, useState, useContext } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import withLocation from '../HOC/withLocation'

import Intro from '../../animations/Intro'
import CalendarContext from '../../context/CalendarContext'
import NaviContext from '../../context/NaviContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretLeft, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Calendar(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const calendarContext = useContext(CalendarContext)
  const naviContext = useContext(NaviContext)

  const allowPast = props.allowPast ? props.allowPast : false
  const keepDateOnSwitch = props.keepDateOnSwitch ? props.keepDateOnSwitch : false

  const pathname = props.location?.pathname
  const setCalendarView = calendarContext?.setCalendarView
  const [calendarYear, setCalendarYear] = useState()
  const [calendarMonth, setCalendarMonth] = useState()
  const selector = props.selector
  const handleSelectionClick = props.handleSelectionClick
  const filterCalendar = props.filterCalendar
  const currentFilter = props.currentFilter

  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const todayDay = todayDate.getDate()

  useEffect(() => {
    if(calendarContext && calendarContext.calendarView[pathname]) {
      setCalendarYear(calendarContext.calendarView[pathname].year)
      setCalendarMonth(calendarContext.calendarView[pathname].month)
    }
  },[calendarContext, pathname])



  const mode = props.ui === 'full' ?
    {
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      weekDayNames: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      arrowLeft: faArrowLeft,
      arrowRight: faArrowRight,
    } : {
      monthNames: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
      weekDayNames: false,
      arrowLeft: faCaretLeft,
      arrowRight: faCaretRight,
    }

  // Today and iterated (given) day simplified
  const todaySimplified = new Date(todayYear, todayMonth, todayDay).setHours(0, 0, 0, 0)

  // Limit the viewable months
  const minDate = new Date(props.minDate)
  const maxDate = new Date(props.maxDate)
  const allowedPrev = new Date(calendarYear, calendarMonth, 1) > minDate ? true : false
  const allowedNext = new Date(calendarYear, calendarMonth + 1, 1) < maxDate ? true : false

  // provided events, i.e. from API
  const events = props.events?.map((node, i) => {
    return node.date // .replace(/-/g, "/") removed (?)
  })

  // Make sure the events are sorted
  events?.sort(function (a, b) {
    return new Date(a) - new Date(b)
  })

  // console.log('events',events)

  // Prep events so that we got em rounded to full days for comparisons
  const eventsSimplified = events?.map((node, i) => {
    let d = new Date(node.replace(/-/g, '\/').replace(/T.+/, ''))
    return d.setHours(0, 0, 0, 0)
  })

  // Prep upcoming events
  const upcomingEventsSimplified = eventsSimplified?.filter((date) => date >= todaySimplified)
  const pastEventsSimplified = eventsSimplified?.filter((date) => date < todaySimplified)

  // Current Event that has been clicked
  const [selectedEventDaySimplified, setSelectedEventDaySimplified] = useState(null)

  // If we've got events loaded, set default selection on first upcoming event day
  useEffect(() => {
    if (!keepDateOnSwitch || !selectedEventDaySimplified) {
      if (upcomingEventsSimplified?.length && !selectedEventDaySimplified) {
        setSelectedEventDaySimplified(upcomingEventsSimplified[0])
      } else if (pastEventsSimplified?.length && !selectedEventDaySimplified) {
        const last = pastEventsSimplified?.length - 1
        setSelectedEventDaySimplified(pastEventsSimplified[last])
      }
    }
  }, [upcomingEventsSimplified, pastEventsSimplified, selectedEventDaySimplified])

  // If events feed changes, force reselection of closest event
  useEffect(() => {
    if (!keepDateOnSwitch) {
      setSelectedEventDaySimplified(null)
    }
  }, [props.events])

  // Update on date selection change
  useEffect(() => {
    if (selectedEventDaySimplified) {
      selector(old => ({ ...old, dateSelected: new Date(selectedEventDaySimplified) }))
    }
  }, [selectedEventDaySimplified, selector])

  // Update on calendar view month/year change
  useEffect(() => {
    if(calendarYear && (calendarMonth + 1))
      selector(old => ({ ...old, currentViewStart: new Date(calendarYear, calendarMonth, 1), currentViewEnd: new Date(calendarYear, calendarMonth + 1, 1) }))
  }, [calendarMonth, calendarYear, selector])

  // Monitor hash/parameters
  useEffect(() => {
    const href = typeof window !== 'undefined' ? window.location.hash.substr(1) : ''
    const hash = href.split('?')[0]
    const params = href.split('?')[1]
    let timer = null

    // Split params
    if (params && params !== '') {
      var search = params.split('&').reduce(function (res, item) {
        var parts = item.split('=')
        res[parts[0]] = parts[1]
        return res
      }, {})

      // Set Tab
      if (search.day && (hash === 'race' || hash === 'tab' || hash === 'racing-full' || hash === 'picks' || hash === 'select-date' || hash === 'racing-only' || hash === 'events-only' || hash === 'promotions-only')) {
        const paramsDate = new Date(search.day.replace(/-/g, '\/').replace(/T.+/, ''))
        const paramsYear = paramsDate.getFullYear()
        const paramsMonth = paramsDate.getMonth()
        const paramsDateSimplified = paramsDate.setHours(0, 0, 0, 0)
        timer = setTimeout(() => {
          if (hash === 'racing-only' || hash === 'events-only' || hash === 'promotions-only')
            filterCalendar(hash)
          else
            filterCalendar("")
          setTimeout(() => {
            handleSelectionClick(paramsDateSimplified)
            setSelectedEventDaySimplified(paramsDateSimplified)
          }, 820)
          setCalendarView(old => {
            return { ...old, [calendarContext.locationPathname]: { year: paramsYear, month: paramsMonth } }
          })
        }, 520)

        return () => clearTimeout(timer)
      }
    }
    // Set Tab without params
    else {
      if (hash === 'select-date' || hash === 'racing-only' || hash === 'events-only' || hash === 'promotions-only') {
        timer = setTimeout(() => {
          if (hash === 'racing-only' || hash === 'events-only' || hash === 'promotions-only')
            filterCalendar(hash)
          else
            filterCalendar("")
          setTimeout(() => {
            handleSelectionClick(null)
          }, 820)
          setCalendarView(old => {
            return { ...old, [calendarContext.locationPathname]: { year: todayYear, month: todayMonth } }
          })
        }, 520)

        return () => clearTimeout(timer)
      }
    }

    if (hash !== 'racing-only' || hash !== 'events-only' || hash !== 'promotions-only')
      filterCalendar("")

    return () => {
      clearTimeout(timer)
    }
  }, [naviContext?.locationState?.hash, naviContext.locationState?.state?.key])

  // Render calendar including UI, with given month/year with circled events provided and circled current date in red
  function renderCalendar(month, year) {
    const startOfMonth = new Date(year, month).getDay()
    const numOfDays = (new Date(year, month + 1, 0)).getDate()
    const rowSize = 7
    const numOfRows = Math.ceil((startOfMonth + numOfDays) / rowSize)
    const monthNames = mode.monthNames

    let calendarDays = []

    // Do this on previous month UI click
    function previousMonth() {
      setCalendarView(old => {
        let month = old[calendarContext.locationPathname].month
        let year = old[calendarContext.locationPathname].year
        if (month < 1) {
          month = 11
          year = year - 1
        } else {
          month = month - 1
        }
        return { ...old, [calendarContext.locationPathname]: { year: year, month: month } }
      }
      )
    }

    // Do this on next month UI click
    function nextMonth() {
      setCalendarView(old => {
        let month = old[calendarContext.locationPathname].month
        let year = old[calendarContext.locationPathname].year
        if (month > 10) {
          month = 0
          year = year + 1
        } else {
          month = month + 1
        }
        return { ...old, [calendarContext.locationPathname]: { year: year, month: month } }
      }
      )
    }

    // Select event date (tab)
    function eventClickHandle(dateSimplified) {
      handleSelectionClick(dateSimplified)
      setSelectedEventDaySimplified(dateSimplified)
    }


    // Go through rows of calendar
    for (let i = 0; i < numOfRows; i++) {
      // Run through the days, add blanks at the start
      for (let j = 0; j < rowSize && (i * rowSize + j) < numOfDays + startOfMonth; j++) {
        // Blanks
        if (startOfMonth > (i * rowSize + j)) {
          calendarDays.push(<div key={`blank-${i + j}`} className={`calendar-column blank ${'row-' + i}`}></div>)
        } else {
          // The day we are about to render, month and year are provided
          const day = i * rowSize + j + 1 - startOfMonth
          // Prep the current day we are about to render as timestamp to compare against array
          const givenDaySimplified = new Date(year, month, day).setHours(0, 0, 0, 0)
          // Check if it's today
          const today = todaySimplified === givenDaySimplified ? true : false
          // Check if iterated (given) day is on the event list of events
          const eventDay = eventsSimplified?.includes(givenDaySimplified) ? true : false
          // Check if it's past due date
          const past = (todaySimplified > givenDaySimplified) && (allowPast === false) ? true : false
          // Check if current date is the selected event date
          const selected = selectedEventDaySimplified === givenDaySimplified ? true : false

          // Populate render array
          calendarDays.push(

            <div
              key={`day-${day}`}
              className={`calendar-column ${'row-' + i} ${eventDay && !past ? 'handle' : ''} ${past ? 'past' : ''} ${selected ? 'current' : ''}`}
            >

              {mode.weekDayNames ? <div className="day-desc">{mode.weekDayNames[j]}</div> : null}

              <div
                className="day"
                onClick={eventDay && !past ? (e) => eventClickHandle(givenDaySimplified) : null}
                onKeyDown={eventDay && !past ? (e) => eventClickHandle(givenDaySimplified) : null}
              >

                <Intro switch={month} in={{ fade: 150 }} delayIn={day * 15} stay={true} className="calendar-column">
                  {day}
                </Intro>

                {/* <Intro visible={ioInView && past} in={{ fade: 100, draw: 125 }} delayIn={day * 15} stay={false} className="with-svg mark x">
									{day % 3 === 0 ?
									<svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
										<g transform="translate(-819.921 -242.525)">
											<path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
									: null }
									{day % 3 === 1 ?
									<svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
										<g transform="translate(-699.924 -149.882)">
											<path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
									: null }
									{day % 3 === 2 ?
									<svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
										<g transform="translate(-708.549 -430.775)">
											<path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
									: null }
								</Intro> */}

                <Intro visible={ioInView && today} in={{ fade: 150, draw: 200 }} delayIn={125 + day * 15} stay={false} className="with-svg mark underline">
                  <svg xmlns="http://www.w3.org/2000/svg" width="41.03" height="6.779" viewBox="0 0 41.03 6.779">
                    <path d="M20.586,641.919a195.662,195.662,0,0,1,31.577-2.392A173.291,173.291,0,0,0,27.411,641.6a19.475,19.475,0,0,0-7,2.082,196.259,196.259,0,0,1,36.238-1.316" transform="translate(-15.674 -638.524)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="2" />
                  </svg>
                </Intro>

                <Intro visible={ioInView && selected} in={{ fade: 150, draw: 300 }} delayIn={0} stay={false} className="with-svg mark">
                  {day % 4 === 0 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="47.076" height="47.772" viewBox="0 0 47.076 47.772">
                      <g transform="translate(0.901 0.897)">
                        <g transform="translate(45.275 45.973) rotate(180)">
                          <path fill="none" stroke="black" strokeWidth="2" d="M11.3,45.435q6.125,4.931,15.725-2.573c6.267-3.081,13.762-13.2,14.212-17.16C46.673-5.9,7.011-3.509.442,14.638-.051,14.924,0,17.633,0,19.554A21.608,21.608,0,0,0,3.654,32.007c10.784,15.144,33.4,20.733,44.284,1.161" transform="translate(0 0)" />
                        </g>
                      </g>
                    </svg>
                    : null}
                  {day % 4 === 1 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="45.706" height="46.625" viewBox="0 0 45.706 46.625">
                      <path d="M302.7,340.026c-8.3,1.225-16.593,4.768-21.8,11.351s-6.555,16.544-1.75,23.425c4.54,6.5,13.053,8.82,20.946,9.593,5.875.574,12.738.122,16.267-4.61a18.332,18.332,0,0,0,2.7-6.855,35.8,35.8,0,0,0,1.305-9.632c-.345-8.763-6.752-16.507-14.667-20.282s-17.076-4.108-25.771-2.977" transform="translate(-275.43 -338.716)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                    </svg>
                    : null}
                  {day % 4 === 2 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="46.553" height="50.617" viewBox="0 0 46.553 50.617">
                      <path d="M454.246,352.746a23.364,23.364,0,0,0-31.749,20.2,15.709,15.709,0,0,0,2.28,9.883c4.124,6.082,12.605,6.9,19.95,7.125,4.633.142,9.577.206,13.511-2.246,4.343-2.708,6.449-7.864,7.78-12.805,1.5-5.57,2.338-11.617.3-17.014-2.884-7.644-10.9-12.209-18.863-14.051s-16.257-1.592-24.307-2.983" transform="translate(-421.694 -340.118)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                    </svg>
                    : null}
                  {day % 4 === 3 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="47.076" height="47.772" viewBox="0 0 47.076 47.772">
                      <g transform="translate(0.901 0.897)">
                        <g transform="translate(45.275 45.973) rotate(180)">
                          <path fill="none" stroke="black" strokeWidth="2" d="M13.3,45.435q6.125,1.831,17.725-2.573c6.267-3.081,13.762-13.2,14.212-22.16C46.673-7.9,7.011-3.509.442,13.638-.051,14.924,0,17.633,0,19.554A21.608,21.608,0,0,0,3.654,31.007c10.784,15.144,33.4,20.733,41.284,1.161" transform="translate(0 0)" />
                        </g>
                      </g>
                    </svg>
                    : null}
                </Intro>

              </div>

            </div>
          )
        }
      }
    }

    return props.ui !== 'zero' ? (
      <div className={`calendar`}>

        <div className={`ui ${props.ui ? props.ui : 'default'}`}>
          <Intro switch={ioInView + calendarMonth + monthNames[month]} in={{ fadeRight: 200 }} delayIn={200} out={{ fade: 100 }} mounted={true} stay={true} className={`prev ${!allowedPrev ? 'disabled' : ''}`}>
            <div onClick={allowedPrev ? previousMonth : null} onKeyDown={allowedPrev ? previousMonth : null} role="button" aria-label="Previous month" tabIndex={0}>
              <FontAwesomeIcon icon={mode.arrowLeft} />
            </div>
          </Intro>
          <Intro switch={ioInView + calendarMonth + monthNames[month]} in={{ fadeBottom: 300 }} delayIn={0} out={{ fadeTop: 200 }} mounted={true} stay={true} className="month-year">
            {monthNames[month]} {year}
          </Intro>
          <Intro switch={ioInView + calendarMonth + monthNames[month]} in={{ fadeLeft: 200 }} delayIn={200} out={{ fade: 100 }} mounted={true} stay={true} className={`next ${!allowedNext ? 'disabled' : ''}`}>
            <div onClick={allowedNext ? nextMonth : null} onKeyDown={allowedNext ? nextMonth : null} role="button" className="calendar-next-month" aria-label="Next Month" tabIndex={0}>
              <FontAwesomeIcon icon={mode.arrowRight} />
            </div>
          </Intro>
        </div>

        <div className="head">
          <div className="calendar-column">S</div>
          <div className="calendar-column">M</div>
          <div className="calendar-column">T</div>
          <div className="calendar-column">W</div>
          <div className="calendar-column">T</div>
          <div className="calendar-column">F</div>
          <div className="calendar-column">S</div>
        </div>

        <Intro switch={ioInView + calendarMonth} in={{ fade: 500 }} delayIn={200} out={{ fade: 150 }} mounted={true} stay={true} className="dates">
          {calendarDays}
        </Intro>

        {typeof (currentFilter) === 'string' ?
          <div className='filter-container flex-12'>
            <Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={550} mounted={true} stay={true}>
              <div className="checkbox-container">

                <label onClick={() => currentFilter === 'racing-only' ? filterCalendar('') : filterCalendar('racing-only')} className="inline">
                  <input type="checkbox" checked={currentFilter === 'racing-only'} onChange={() => false} />
                  <span className="checkmark">
                    <Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30} className="with-svg mark x" mounted={true} stay={true}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
                        <g transform="translate(-699.924 -149.882)">
                          <path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                          <path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                        </g>
                      </svg>
                    </Intro>
                  </span>
                  <p>Racing</p>
                </label>

                <label onClick={() => currentFilter === 'promotions-only' ? filterCalendar('') : filterCalendar('promotions-only')} className="inline">
                  <input type="checkbox" checked={currentFilter === 'promotions-only'} onChange={() => false} />
                  <span className="checkmark">
                    <Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30} className="with-svg mark x" mounted={true} stay={true}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
                        <g transform="translate(-699.924 -139.882)">
                          <path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                          <path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                        </g>
                      </svg>
                    </Intro>
                  </span>
                  <p>Casino</p>
                </label>

                <label onClick={() => currentFilter === 'events-only' ? filterCalendar('') : filterCalendar('events-only')} className="inline">
                  <input type="checkbox" checked={currentFilter === 'events-only'} onChange={() => false} />
                  <span className="checkmark">
                    <Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30} className="with-svg mark x" mounted={true} stay={true}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
                        <g transform="translate(-699.924 -139.882)">
                          <path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                          <path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
                        </g>
                      </svg>
                    </Intro>
                  </span>
                  <p>Entertainment</p>
                </label>
              </div>
            </Intro>
          </div>
          : null}

      </div>
    ) : false
  }

  return (
    <div ref={io} className={`block-calendar ${props.ui === 'full' ? 'full' : 'compact'} ${props.classes ? props.classes : ''}`}>
      {renderCalendar(calendarMonth, calendarYear)}
    </div>
  )
}

export default withLocation(Calendar)