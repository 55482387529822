import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
// import { Link } from 'gatsby'
import Intro from '../../../../../animations/Intro'

import useEventsDayCollection from '../../../../../utils/useQueryEventsDayCollection'
import validatePdf from '../../../../../utils/validatePdf'

// Function to add or substract months in dates
function addMonths(date, months) {
	var d = date.getDate()
	date.setMonth(date.getMonth() + +months)
	if (date.getDate() !== d) {
		date.setDate(0)
	}
	return date
}
// Function to add or substract weeks in dates
function addWeeks(date, weeks) {
  let d = new Date(date)
  let currentTime = d.getTime()
  let milisecondsInWeek = 7 * 24 * 60 * 60 * 1000
  let newTime = currentTime + (weeks * milisecondsInWeek)
  d.setTime(newTime)

  return d
}

let formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0,
})

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


function Custom(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

  // const [calendarSelector, setCalendarSelector] = useState({dateSelected: null, currentViewStart: null, currentViewEnd: null})

  const data = useEventsDayCollection()


	// Setting up 2 months ahead and 1 back
	const todayDate = new Date()
	const todayYear = todayDate.getFullYear()
	const todayMonth = todayDate.getMonth()
  const pastDateRange = props.pastDateRange ? new Date(props.pastDateRange).setHours(0, 0, 0, 0) : new Date().setHours(0, 0, 0, 0)
  // stakes 2 weeks back
	// const pastDateRange = props.pastDateRange ? new Date(props.pastDateRange).setHours(0, 0, 0, 0) : addWeeks(new Date(), -2).setHours(0, 0, 0, 0)
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 6).setHours(0, 0, 0, 0)

  const raceDays = data.allContentfulEventDay.nodes.filter(day => {
    const raceDay = new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0, 0, 0, 0)

    return (
      day.raceEvents &&
      raceDay >= pastDateRange &&
      raceDay <= futureDateRange
    )
  })
 

	let stakesWithDates = []

	raceDays?.forEach( item => {
		item.raceEvents?.map((node) => {
			if(node.stakes) {
        stakesWithDates.push({stakes: node.stakes, date: item.date, slug: node.slug}) 
			}
		})
	})

  const stakesMap = stakesWithDates?.map((stake, ii) => {
    const d = new Date(stake.date.replace(/-/g, '\/').replace(/T.+/, ''))
    const links = stake.stakes.data?.map((link, i) => {

      if(link.key)
        return (
          <div key={i} className="link-wrap">
            <span>{`${link.key}, ${link.value ? link.value : null}, ${link.value2 ? link.value2 : null} - ${link.value3 ? formatter.format(link.value3) : null}`}</span>
            <div className="download-container">
              {link.nominations.url ?
                <Intro id="portal-download" visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} out={{ fade: 300 }} delayIn={500} className={`c0 padd-left-half padd-right-half t border-c5 border-bottom-off ${i === 0 ? '' : "border-top-off"}  border-right-off`}>
                  <div className="downloads">
                    <a href={validatePdf(link.nominations.url)} className="icon-pdf" target="_blank" rel="noreferrer noopener">Noms</a>
                  </div>
                </Intro>
                : null}
              {link.past_performances.url ?
                <Intro id="portal-download" visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} out={{ fade: 300 }} delayIn={600} className={`c0 padd-left-half padd-right-half t  border-bottom-off ${i === 0 ? '' : "border-top-off"}`}>
                  <div className="downloads">
                    <a href={validatePdf(link.past_performances.url)} className="icon-pdf" target="_blank" rel="noreferrer noopener">PPs</a>
                  </div>
                </Intro>
                : null}
            </div>
          </div>
      )
      else 
        return null   
    })
		if(links?.length)
    return(
      <div key={ii} className="single-item padd-top-half">
        <h5>{`${days[d.getDay()]}, ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`}</h5>
        {links}
      </div>
    )
  })


  return (
		<div ref={io} className="stakes-component padd-half padd-top-off flex-12"> 
      <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={200} className="padd-half c0">
					<div className="stakes-box c3 t">
					  {stakesMap}
					</div>
			</Intro>
		</div>
  )
}

export default Custom
