import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'

import Image from '../../Image'
// import Picks from '../../Picks'
import Wysiwyg from '../../Wysiwyg'
import Calendar from '../../../Calendar'
import BlocksRouter from '../../../Sections/BlocksRouter'
import validatePdf from '../../../../utils/validatePdf'

function ExpertPick(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [open, setOpen] = useState(false)

  const {
    naviOffset,
    person,
    i,
    hidden
  } = props

  const [calendarSelector, setCalendarSelector] = useState({ dateSelected: null })

  const events = person?.handicappingPicks?.data

  const calendarSelectorFunction = useCallback((i) => {
    setCalendarSelector(i)
  }, [])

  // Find matching pick to selected date in calendar
  const matchingPick = events?.find(element => {
    // console.log('d',calendarSelector?.dateSelected)
    let d = new Date(calendarSelector?.dateSelected).setHours(0, 0, 0, 0)
    let dx = new Date(element.date?.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0, 0, 0, 0)
    if (d === dx) {
      return true
    }
    return false
  })

  useEffect(() => {

    if (window?.location?.hash && typeof window !== 'undefined') {

      const href = window?.location.hash.substr(1)
      const params = href.split('?')[1]

      // Split params
      if (params && params !== '') {
        var search = params.split('&').reduce(function (res, item) {
          var parts = item.split('=')
          res[parts[0]] = parts[1]
          return res;
        }, {})
      }


      if (person?.name?.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '') === search?.expert)
        setOpen(true)
    }

  }, [typeof window !== 'undefined' && window?.location?.hash])

  // Function to add or substract months in dates
  function addMonths(date, months) {
    var d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() !== d) {
      date.setDate(0)
    }
    return date
  }

  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const pastDateRange = addMonths(new Date(todayYear, todayMonth, 2), -1).toISOString()
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 3).toISOString()


  function toggleOpen() {
    setOpen(state => !state)
  }


  return (
    <div id={`${person?.name?.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')}`} className={`expert-pick ${open ? 'open' : ''} ${hidden ? 'hide' : ''}`}>
      {person?.name?.toLowerCase() === 'dean keppler' ?
        <div ref={io} className={`span-12 expert-pick-wrap`}>
          <div className={`expert-pick-handle c0 t`} style={{ top: naviOffset }}>
            <a className={`nostrike expert-pic-handle-trigger ${i % 2 === 0 ? 'c1' : 'c0'} padd-half`} href="https://inthemoneypodcast.com/category/blog/santa-anita/" target="_blank" rel="noreferrer noopener">
              <div className="expert-pick-pic">
                {/* <Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} mounted={true} className=""> */}
                <Image key={`person-image-${i}`} image={person.image} classes="aspect-ratio cover borderless bgless filter-greyscale release-aspect" />
                {/* </Intro> */}
              </div>
              <div className="expert-pick-bio padd-left-half">
                <Wysiwyg key={`bio-wysiwyg-${i}`} content={{ ...person?.bio, jsx: <h5 className='title'>{person?.name}</h5> }} />
              </div>
              <div className="expert-pick-expand-cta padd-left-half as-flex-end js-flex-end">
                {open ?
                  <p><span className="btn"><span>Hide Picks</span></span></p>
                  :
                  <p><span className="btn"><span>See Picks</span></span></p>
                }
              </div>
            </a>
          </div>
        </div>
        :
        <div ref={io} className={`span-12 expert-pick-wrap`}>
          <div className={`expert-pick-handle c0 t`} style={{ top: naviOffset }}>
            <div className={`expert-pic-handle-trigger ${i % 2 === 0 ? 'c1' : 'c0'} padd-half`} onClick={() => toggleOpen()} onKeyDown={() => toggleOpen()}>
              <div className="expert-pick-pic">
                {/* <Intro visible={ioInView} in={{ fade: 500 }} mounted={true} delayIn={0} className=""> */}
                <Image key={`person-image-${i}`} image={person.image} classes="aspect-ratio cover borderless bgless filter-greyscale release-aspect" />
                {/* </Intro> */}
              </div>
              <div className="expert-pick-bio padd-left-half padd-right-half">
                {/* <Intro visible={ioInView} in={{ fade: 500 }} mounted={true} delayIn={200} className=""> */}
                <Wysiwyg key={`bio-wysiwyg-${i}`} content={{ ...person?.bio, jsx: <h5 className='title'>{person?.name}</h5> }} />
                {/* </Intro> */}
              </div>
              <div className="expert-pick-expand-cta padd-left-half as-flex-end js-flex-end">
                {open ?
                  <p><span className="btn"><span>Hide Picks</span></span></p>
                  :
                  <p><span className="btn"><span>See Picks</span></span></p>
                }
              </div>
            </div>
            <Intro visible={open} in={{ bg: 1000, fade: 500 }} mounted={true} delayIn={200} className="calendar-wrap fordesktop padd-half c5 t">
              <h6 className='select-date-to-view-picks text-center'>SELECT DATE TO VIEW PICKS</h6>
              <Calendar ui={'week'} handleSelectionClick={() => false} filterCalendar={() => false} allowPast={true} selector={calendarSelectorFunction} minDate={pastDateRange} maxDate={futureDateRange} events={events} />
              {matchingPick?.file?.url ?
                <p style={{ fontSize: '.95em', marginTop: '.75em' }}><a className="btn arrow" href={validatePdf(matchingPick?.file.url)} target="_blank" rel="noreferrer noopener" alt={matchingPick?.file.title} style={{ width: '100%' }}><span style={{ paddingRight: '2em' }}>Picks in PDF ({calendarSelector?.dateSelected?.toLocaleDateString([], { month: '2-digit', day: '2-digit' })})</span></a></p>
                : null}
            </Intro>
          </div>

          <Intro visible={open} in={{ bg: 500, fade: 500 }} mounted={true} delayIn={200} className="expert-pick-picks padd-half c5 t">

            <div className={`expert-pick-bio ${i % 2 === 0 ? 'c1' : 'c0'} formobile padd-left-half padd-right-half`}>
              <Wysiwyg key={`bio-wysiwyg-${i}`} content={{ ...person?.bio }} />
            </div>

            <Intro visible={open} in={{ bg: 1000, fade: 500 }} mounted={true} delayIn={200} className="calendar-wrap formobile padd-half c5 t">
              <h6 className='select-date-to-view-picks text-center'>SELECT DATE TO VIEW PICKS</h6>
              <Calendar ui={'week'} handleSelectionClick={() => false} filterCalendar={() => false} allowPast={true} selector={calendarSelectorFunction} minDate={pastDateRange} maxDate={futureDateRange} events={events} />
              {matchingPick?.file?.url ?
                <p style={{ fontSize: '.95em', marginTop: '.75em' }}><a className="btn arrow" href={validatePdf(matchingPick?.file.url)} target="_blank" rel="noreferrer noopener" alt={matchingPick?.file.title} style={{ width: '100%' }}><span style={{ paddingRight: '2em' }}>Picks in PDF ({calendarSelector?.dateSelected?.toLocaleDateString([], { month: '2-digit', day: '2-digit' })})</span></a></p>
                : null}
            </Intro>
            
              <div className={`block-picks c3 ${props.classes}`}>
                <div className="pick padd c0">
                  {matchingPick?.blog || matchingPick?.content || matchingPick?.file?.url ?
                    <div className="align">
                      <h5>{calendarSelector?.dateSelected?.toLocaleDateString([], { weekday: 'short', day: '2-digit', month: '2-digit' })}</h5>
                      <p>Picks are pre weather and scratches. Good luck!</p>
                      {matchingPick?.file?.url ?
                        <p style={{ fontSize: '.95em', marginTop: '.75em' }}><a className="btn arrow contrast" href={validatePdf(matchingPick?.file.url)} target="_blank" rel="noreferrer noopener" alt={matchingPick?.file.title} style={{ width: '100%' }}><span style={{ paddingRight: '2em' }}>Download picks in PDF for {calendarSelector?.dateSelected?.toLocaleDateString([], { weekday: 'long', day: '2-digit', month: '2-digit' })}</span></a></p>
                      : null}
                      <BlocksRouter blocks={[{ ...matchingPick, __typename: "ContentfulBlockWysiwyg" }]} />
                    </div>
                  :
                    <div className="align">
                      {calendarSelector?.dateSelected ? <h5>{calendarSelector?.dateSelected?.toLocaleDateString([], { weekday: 'short', day: '2-digit', month: '2-digit' })}</h5> : null}
                      <h6 className='no-picks' style={{color: '#000'}}>No picks available from this expert{calendarSelector?.dateSelected ? ' on this date' : ''}.</h6>
                    </div>
                  }
                </div>
              </div>
            {matchingPick?.blog && !props.singleFeaturedExpert ?
              <BlocksRouter blocks={[{ content: matchingPick?.blog, __typename: "ContentfulBlockWysiwyg", classes: "text-left padd story c1" }]} />
              :
              null
            }
            {props.singleFeaturedExpert ?
              <BlocksRouter blocks={[{ content: matchingPick?.blog, __typename: "ContentfulBlockWysiwyg", classes: "text-left c1" }]} />
              :
              null
            }

          </Intro>
        </div>
      }
    </div>
  )
}

export default ExpertPick