import React, { useRef, useEffect, useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import use1stAPI from '../../../../Api'

import Intro from '../../../../animations/Intro'
import BoxInfo from '../../../BoxInfo'
import BoxTabs from '../../../BoxTabs'
import LocalTime from '../LocalTime'
import Timer from '../Timer'
import { useAuth0 } from '../../../../utils/auth'
// import HTMLVideo from '../../../Video/HTML'

import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBacon } from '@fortawesome/free-solid-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useQuery, gql } from '@apollo/client'

function Custom(props) {
  //const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [liveRef, liveRefInView] = useInView({ triggerOnce: false })
  const { isAuthenticated, loginWithRedirect, user } = useAuth0()
  const [fixedFeedOff, setFixedFeedOff] = useState(false)

  const data = useStaticQuery(graphql`
    query LiveRacingMarquee{
      contentfulOptions {
				tickerLiveRacing
      }
    }
  `)

  const marquee = data?.contentfulOptions?.tickerLiveRacing

  function handleLoginClick() {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname
        }
      })
    }
  }

  // Grab Live Box Size
  const liveFeedElementWidth = liveRef?.current?.clientWidth
  const liveFeedElementHeight = liveRef?.current?.clientHeight

  // FED FROM API but base params
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth() + 1
  const todayMonthLeadingZero = todayMonth < 10 ? '0' + todayMonth : '' + todayMonth
  const todayDay = todayDate.getDate()
  const todayDayLeadingZero = todayDay < 10 ? '0' + todayDay : '' + todayDay
  const todayRaceDate = `${todayYear}-${todayMonthLeadingZero}-${todayDayLeadingZero}`
  const [currentRace, setCurrentRace] = useState({
    race_date: todayRaceDate,
    race_number: null,
    highest_race: 1,
    track_code: 'GP',
    event_code: 'GPM',
    mtp: null,
    feed: "https://videos.ctfassets.net/71tzd15sgbj0/3qEvNX63sH1EyGPx91W62k/e5b06a69bd54f4ad6068539c2548072c/drone_racing_loop__720p_.mp4",
    liveParameters: {
      highdef: true,
      mobile: false,
      // width: liveFeedElementWidth ? liveFeedElementWidth : 1280,
      // height: liveFeedElementHeight ? liveFeedElementHeight : 720
    },
  })

  // Apollo, Pull Events!
  const { loading, error, data: apollo, refetch } = useQuery(APOLLO_QUERY, {
    variables: { // variables for query use
      date_gte: todayRaceDate + 'T00:00:00.000Z',
      date_lte: todayRaceDate + 'T23:59:00.000Z'
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore'
  })

  const isRaceCanceled = apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.isRaceCanceled ? apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.isRaceCanceled : null

  const { data: nextUp, loading: nextUpLoading } = use1stAPI('currentRace', currentRace)
  const { data: race, loading: raceLoading } = use1stAPI('race', currentRace)
  const { data: live, loading: liveLoading = true } = use1stAPI('live', currentRace)
  const { data: runners, loading: runnersLoading } = use1stAPI('runners', currentRace)
  const runnersSorted = runners?.runners?.sort((a, b) => (parseInt(a.post_position) > parseInt(b.post_position)) ? 1 : -1)
  const { data: track, loading: trackLoading } = use1stAPI('track', currentRace)
  const { data: changes, loading: changesLoading } = use1stAPI('changes', currentRace)

  const trackTitle = 'Opening Day'
  const trackConditions = track?.track?.track_conditions?.map((node, i) => {
    if (i === 0) {
      return node.course + ': ' + node.condition
    }
    return ' ' + node.course + ': ' + node.condition
  })

  //console.log(nextUp,race,live)

  // Track Date Changes
  useEffect(() => {
    function checkDate() {
      const todayDate = new Date()
      const todayYear = todayDate.getFullYear()
      const todayMonth = todayDate.getMonth() + 1
      const todayMonthLeadingZero = todayMonth < 10 ? '0' + todayMonth : '' + todayMonth
      const todayDay = todayDate.getDate()
      const todayDayLeadingZero = todayDay < 10 ? '0' + todayDay : '' + todayDay
      const localTodayRaceDate = `${todayYear}-${todayMonthLeadingZero}-${todayDayLeadingZero}`
      if (localTodayRaceDate !== currentRace.race_date) {
        setCurrentRace((old) => { return { ...old, race_date: localTodayRaceDate } })
      }
    }
    let interval = setInterval(() => checkDate(), 60000)
    return () => {
      clearInterval(interval)
    }
  }, [currentRace.race_date])

  // Track url changes
  // Monitor replay change so it can switch to them
  const [liveFeedUrl, setLiveFeedUrl] = useState()
  useEffect(() => {
    if (live?.url) {
      let url = new URL(live.url)
      if (url) {
        if (url.searchParams.get('width')) {
          url.searchParams.set('width', liveFeedElementWidth)
        }
        if (url.searchParams.get('height')) {
          url.searchParams.set('height', liveFeedElementHeight)
        }
        if (new URL(live?.url).origin !== new URL(currentRace.feed).origin) {
          setCurrentRace((old) => { return { ...old, feed: url } })
          //console.log('live_url_change_happened',url)
        }
      }
      if (!liveFeedUrl) {
        setLiveFeedUrl(url)
        //console.log('live_url_gained',url)
      }
    }
  }, [live?.url, liveFeedElementWidth, liveFeedElementHeight, currentRace.feed])


  // Track Race # Changes
  useEffect(() => {
    //if(nextUp?.event?.[0].current_race) {
    setCurrentRace((old) => {
      return {
        ...old,
        race_number: nextUp?.event?.[0].current_race ? nextUp?.event?.[0].current_race : 1,
        mtp: nextUp?.event?.[0].mtp,
        highest_race: nextUp?.event?.[0].highest_race
      }
    })
    //}
    //console.log(nextUp)
  }, [nextUp?.event?.[0].current_race])

  useEffect(() => {
    if (!liveRefInView)
      setFixedFeedOff(false)
  }, [liveRefInView])

  return (
    <div ref={io} className={`block-custom block-racing-component calendar-full flex-12 ai-flex-start ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

      <div className="span-8 span-12-mobile flex-12 c0">
        <div className="span-12 span-12-mobile padd-half c3 t step-gradient has-intro">
          <Intro visible={ioInView} in={{ bg: 1000, fade: 500 }} delayIn={200} className="">
            <div ref={liveRef} className="video-feed-wrap">
              <div className={`video-feed ${!liveRefInView && !fixedFeedOff ? 'fixed' : ''}`}>
                {!liveRefInView ?
                  <span onClick={() => setFixedFeedOff(true)} className='close-btn x1 t'>
                    <FontAwesomeIcon icon={faClose} />
                  </span>
                  :
                  null}
                <Intro visible={ioInView} in={{ border: 500, bg: 1000, fade: 500 }} delayIn={1200} className="c5 border-c5 t">
                  <div className={`aspect-ratio`}>
                    {/* <div className={`${currentRace.feed ? 'aspect-ratio' : ''} ${!isAuthenticated ? 'hover-trigger' : ''}`} onClick={() => !isAuthenticated ? handleLoginClick() : null} onKeyDown={() => !isAuthenticated ? handleLoginClick() : null}> 
                    {/*<iframe src={"https://livestream.com/accounts/9869799/events/3519786/player?width=640&amp;height=360&amp;enableInfoAndActivity=true&amp;defaultDrawer=&amp;autoPlay=true&amp;mute=false"} frameBorder="0" allowFullScreen="allowfullscreen"></iframe>*/}
                    <iframe src={"https://vimeo.com/event/4412830/embed"} frameBorder="0" allowFullScreen="allowfullscreen"></iframe>

                    {/* { isAuthenticated && user && currentRace.feed ? // && !liveLoading
											<iframe src={currentRace.feed} frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
										: isAuthenticated && user && !currentRace.feed ?
											<HTMLVideo hideVolumeButton={true} file={'https://videos.ctfassets.net/71tzd15sgbj0/COQ1wveziMMzyWTEHrHFU/394184a6c64e409262186223497d0f36/pegasus_gs_website_loop_text__720p_.mp4'}/>
										: liveLoading ?
											<div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
										:
											<HTMLVideo hideVolumeButton={true} file={'https://videos.ctfassets.net/71tzd15sgbj0/3qEvNX63sH1EyGPx91W62k/e5b06a69bd54f4ad6068539c2548072c/drone_racing_loop__720p_.mp4'}/>
										} */}
                  </div>
                </Intro>
              </div>
            </div>
            <div className="current">
              {marquee ?
                <div id="liveracing-marquee" className="login c5 border-c5 t has-intro">
                  <Intro visible={true} in={{ border: 700, bg: 700, fade: 700 }} delayIn={400} className="active c5 t">
                    <div className="marquee uppercase">
                      <div>
                        <div>{marquee}</div>
                        <div>{marquee}</div>
                        <div>{marquee}</div>
                        <div>{marquee}</div>
                      </div>
                    </div>
                  </Intro>
                </div>
                : null}
              {currentRace?.race_number && currentRace?.highest_race ?
                <div className="current-intro">
                  <Intro visible={ioInView} in={{ border: 800, bg: 500, fade: 500 }} delayIn={1500} className="c0 t">
                    <h6>
                      Current live race - {currentRace.race_number} of {currentRace.highest_race}
                      {currentRace.race_number === currentRace.highest_race ?
                        <>
                          <br />Watch out for next race
                        </>
                        :
                        <>
                          {currentRace.mtp ? <><br />MTP: <Timer t={currentRace.mtp} /></> : null}
                        </>
                      }
                    </h6>
                  </Intro>
                </div>
                : null}
              <div className="current-details">
                <Intro visible={ioInView} in={{ border: 800, bg: 500, fade: 500 }} delayIn={1700} className="c3 t">
                  <h6>GULFSTREAM PARK - {currentRace.race_number ? `RACE ${currentRace.race_number}` : ''} {race?.race?.published_post_time ? `(${race?.race?.published_post_time})` : ''}<br /><LocalTime /></h6>
                </Intro>
              </div>
            </div>
          </Intro>
        </div>
        <div id="portal-tabs-anchors"></div>
      </div>

      <div className="span-4 span-12-mobile padd-half padd-top-off padd-right">
        {/* {trackTitle ?
					<h3>{trackTitle}</h3>
				: null} */}
        <BoxInfo
          date={todayDate}
          venue={'gulfstreampark'}
          title={`<h6>YOU ARE WATCHING</h6><h5>GULFSTREAM PARK</h5>`}
          subtitle={isAuthenticated ? `<h4>R${currentRace.race_number ? currentRace.race_number : ''}</h4>` : `<h4>-</h4>`}
          surface={`<h6>Track conditions - ${trackConditions ? trackConditions : 'N/A'}</h6>`}
        />
        {(nextUp && Object.keys(nextUp).length !== 0) || (race && Object.keys(race).length !== 0) ?
          <BoxTabs isRaceCanceled={isRaceCanceled} race={race} currentRace={currentRace} runners={runnersSorted} changes={changes?.changes?.[parseInt(currentRace?.race_number)]} />
          : isAuthenticated ?
            <div className="technical-difficulties c0 border-c5 t has-intro">
              <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className={`c0 t ${props.onMobile ? 'border-right-off' : ''}`}>
                <h6>There seems to be a problem with getting necessary informations from our track, or it's too early for them to be published.<br />Please try again later.</h6>
              </Intro>
            </div>
            :
            <div className="secondary-login login c0 border-c5 t has-intro hover-trigger">
              <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className={`c0 t ${props.onMobile ? 'border-right-off' : ''}`}>
                <div className="login-btn" onClick={() => handleLoginClick()} onKeyDown={() => handleLoginClick()} role="button" aria-label="Login" tabIndex={0}>
                  {/* <a href="#modal-1st-login"> */}
                  <div className="bg-hover"></div>
                  <div className="icon icon-user fs-85 active-dip"></div>
                  <h4 className="uppercase fs-85 active-dip">{isAuthenticated && user ? `Hi, ${user?.nickname ? user?.nickname : "friend"}` : 'Please Log-in to view detailed race information'}</h4>
                  {/* </a> */}
                </div>
              </Intro>
            </div>
        }
        {/* <pre style={{maxWidth: '29em'}}>
					<code>{JSON.stringify(nextUp, null, 2)}</code>
				</pre> */}
      </div>
    </div>
  )
}

export default Custom


const APOLLO_QUERY = gql`
  query eventsQuery($date_gte: DateTime, $date_lte: DateTime) {
    eventsCurrentView: eventDayCollection (limit: 1000, where: {raceEventsCollection_exists: true, date_gte: $date_gte, date_lte: $date_lte}, order: date_ASC) {
      items {
				date
        raceEventsCollection (limit: 1) {
					items {
            isRaceCanceled
					}
				}
			}
    }
  }
`