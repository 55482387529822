import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import Handle from '../Handle'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'

function Wagering() {

  const data = useStaticQuery(graphql`
  query Carryovers{
    contentfulOptions {
      carryover {
        name
        title
        prize
        note
        number
        color
      }
    }
  }
`)


  // Delay Synchronization	
  const baseDelay = 0
  const stepDelay = 50

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })

  const carryoversMapped = data?.contentfulOptions?.carryover?.map((node, i) => {
    return (
      <div className={`pick-${i} fs-125`} key={`pick-${i}`}>
        <Intro visible={true} in={{ border: 300, bg: 300, fade: 300 }} delayIn={baseDelay + stepDelay * i} className="decor border-c5 border-top-off t">
          <div className="flex-12 flex-no-wrap">
            <div className="title-box span-5 inside-box flex ai-center single-carryover padd-top-half padd-bottom-half">
              <h4>{node.title}</h4>
            </div>
            <div className="no-box span-2 border">
              <div className={`saddle-color-${node.color}  no runner flex jc-center ai-center padd-top-half padd-bottom-half`}>
                <h4>{node.number}</h4>
              </div>
            </div>
            <div className="prize-box flex fs-125 span-5 inside-box link flex ai-center padd-top-half padd-bottom-half">
              <h4 className='h-prize'>{formatter.format(node.prize)}</h4>
              <span className='note'>{node.note}</span>
            </div>
          </div>
        </Intro>
      </div>
    )
  })

  return (
    <div className="wagering-box">
      <div id="sidebar-wagering">
        <div className="link-1 sidebar-only" key="link-1">
          <Link to="/racing/" className='nostrike'>
            <Handle icon={'arrow-right'} title={'Upcoming Races'} asideBox={false} delay={100} />
          </Link>
        </div>
        <div className="link-2 sidebar-only" key="link-2">
          <div className='nostrike'>
            <Handle icon={'arrow-bottom'} title={'Carryovers'} classes="border-top-off" blank={true} asideBox={false} delay={100} />
          </div>
        </div>

        {carryoversMapped ? carryoversMapped : null}

        <div className="link-3 sidebar-only" key="link-3">
          <a target="_blank" rel='noreferrer noopener' href="https://1st.com/bet/gulfstream-park?utm_source=gsp&utm_medium=track_referral&utm_campaign=1st_bet_racing_bet_now&utm_content=1st_bet_racing_bet_now_gsp_button" className='nostrike'>
            <div className="main">
              <div className="row">
                <div className="bg-hover"></div>
                <Intro visible={true} in={{ border: 500, fade: 500 }} delayIn={500} className={`box border-top-off featured flex jc-center`}>
                  <div className="handle active-dip"><h3>Wager on 1/ST Bet</h3></div>
                </Intro>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Wagering