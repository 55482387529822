import React, {useState, useEffect} from 'react'
import './style.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faCloud, faSmog, faSun, faCloudShowersHeavy, faCloudSunRain, faCloudSun, faSnowflake } from '@fortawesome/free-solid-svg-icons'
import { useQuery, gql } from '@apollo/client'
// import use1stAPI from '../../Api'

function Weather() {
	const [weather,setWeather] = useState({temp: '', icon: null})

  // Apollo, Pull Events!
  // const { loading: loadingWeather, error: errorWeather, data: apolloWeather, refetch: refetchWeather } = useQuery(APOLLO_WEATHER, {
	const { data: apolloWeather, refetch: refetchWeather } = useQuery(APOLLO_WEATHER, {

    variables: { // variables for query use
			// pastDateRange: pastDateRange,
			// futureDateRange: futureDateRange,
		},
		skip: false,
    notifyOnNetworkStatusChange: true,
  })

	// Refetch apollo every 15mins
	useEffect(() => {
		function checkApollo() {
			refetchWeather()
		}
		let interval = setInterval(() => checkApollo(), 300000)
		return () => {
			clearInterval(interval)
		}
	},[refetchWeather])


	//const {data: weatherRequest, loading: weatherRequestLoading} = use1stAPI('weather')
	const weatherRequest = apolloWeather?.options?.items?.[0]?.weatherApi?.data

	useEffect(() => {
		if(weatherRequest) {
			const iconMap = {
				'01d': faSun, // clear sky
				'01n': faSun, // clear sky night
				'02d': faCloudSun, // few clouds
				'02n': faCloudSun, // few clouds night
				'03d': faCloud, // scattered clouds
				'03n': faCloud, // scattered clouds night
				'04d': faCloud, // broken clouds
				'04n': faCloud, // broken clouds night
				'09d': faCloudSunRain, // shower rain
				'09n': faCloudSunRain, // shower rain night
				'10d': faCloudShowersHeavy, // rain
				'10n': faCloudShowersHeavy, // rain night
				'11d': faBolt, // thunderstorm
				'11n': faBolt, // thunderstorm night
				'13d': faSnowflake, // snow
				'13n': faSnowflake, // snow night
				'50d': faSmog, // mist
				'50n': faSmog, // mist night
			}
			const current = weatherRequest?.current
			let temp = Math.round(((current?.temp-273.15)*1.8)+32) + '°F '
			let icon = iconMap[current?.weather?.[0].icon]
			setWeather({temp: temp, icon: icon})
		}
	}, [weatherRequest])
	
  return weather.temp ? (
		<div className={`infobox`}>
			<h5>
				{weather?.temp}
				{weather?.icon ?
					<FontAwesomeIcon icon={weather?.icon} />
				: null }
			</h5>
		</div>
  ) : '+'
}

export default Weather

// Query for rehydration
const APOLLO_WEATHER = gql`
  query {
    options: weatherCollection {
      items {
        weatherApi
      }
    }
  }
`