import React, {useState, createContext, useEffect} from 'react'

const ModalContext = createContext(false)

function ModalContextProvider({children, location}) {
  // State for telling if the modal is active or not
	const [isActive, setActive] = useState(false)
	const [dataAttr, setDataAttr] = useState()
  const [trigger, setTrigger] = useState(false)

  const generalPopup = children?.props?.data?.contentfulOptions?.popup
  const popup = children?.props?.data?.contentfulPage?.popups
	
  /* ==========================================================================
    Modal Activity Check
  ========================================================================== */

  useEffect(() => {
		//setActive(true)
		const btn = document.querySelectorAll('[href*="#modal-1st-"]')
		window.setTimeout(() => {
			if(btn.length){
				btn.forEach(item => {
					item.addEventListener('click', event => {
						event.preventDefault()
						setDataAttr(event.currentTarget.attributes.href.nodeValue)
						setActive(true)
					})
				})		
			}
		}, 50)

  if (generalPopup && localStorage.getItem(generalPopup?.id) !== generalPopup?.updatedAt) {
      setDataAttr('#modal-1st-general-custom-box')
      setActive(true)
      localStorage.setItem(generalPopup?.id, generalPopup?.updatedAt);
    }
    else if (popup && localStorage.getItem(popup?.id) !== popup?.updatedAt) {
      setDataAttr('#modal-1st-custom-box')
      setActive(true)
      localStorage.setItem(popup?.id, popup?.updatedAt);
    }
    else if ((localStorage.getItem('subscribe') !== 'true')) {
      const timeout = window.setTimeout(() => {
        setDataAttr('#modal-1st-subscribe')
        setActive(true)
        localStorage.setItem("subscribe", 'true');
      }, 5000)

      return () => clearTimeout(timeout);
    }
  }, [location, trigger])

	return (
		<ModalContext.Provider value={{
      isActive,
			setActive: (trueOrFalse) => setActive(trueOrFalse),
      setTrigger: (trueOrFalse) => setTrigger(trueOrFalse),
			dataAttr
    }}>
      {children}
    </ModalContext.Provider>
	)
}

export default ModalContext
export { ModalContextProvider }