import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import { useStaticQuery, graphql } from "gatsby"
import validatePdf from '../../../../utils/validatePdf'

function Custom(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const data = useStaticQuery(graphql`query overnights {
  allContentfulEventDay(
    filter: {raceEvents: {elemMatch: {entriespdf: {file: {url: {ne: null}}} }}}
    sort: {fields: [date], order: DESC},
    limit: 10
  ) {
    nodes {
      date
      raceEvents {
        entriespdf {
          file {
            url
          }
        }
      }
    }
  }
}`)

  const files = data?.allContentfulEventDay?.nodes?.
    map((item, i) => {
      const date = new Date(item.date.replace(/-/g, '\/').replace(/T.+/, ''))
      const formattedString = `${dayNames[date.getDay()]}, ${monthNames[date.getMonth()]}. ${date.getDate()} Overnight`
      if(item.raceEvents?.[0]?.entriespdf?.file?.url)
      return (
        <div key={i} className='flex single-item '>
          <div className='copy'>
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={325 + 50 * i} className="c0 t border-top-off border-left-off border-right-off">
              <h6>{formattedString}</h6>
              <div className="downloads c0">
                <a href={validatePdf(item.raceEvents?.[0]?.entriespdf?.file?.url)} className="icon-pdf" target="_blank" rel="noreferrer noopener">Download</a>
              </div>
            </Intro>
          </div>
        </div>
      )

      return null
    }).filter(file => file)


  return (
    <div ref={io} className={`block-custom downloads-pack padd ${props.classes}`}>
      <div className='container'>
        <h1>OVERNIGHT</h1>
        <div className='flex-12'>
          {files}
        </div>
      </div>
    </div>
  )
}

export default Custom