import React, {useEffect} from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'
import BlocksRouter from '../../../Sections/BlocksRouter'

import { useStaticQuery, graphql } from "gatsby"


function Custom(props) {

	const [io, ioInView] = useInView({ triggerOnce: true })
  
	const isEspanolSection = props?.directField === "espanol-expert-picks" ? true : false

  const data = useStaticQuery(graphql`query Experts {
  allContentfulPerson(
    limit: 40
    sort: {fields: createdAt}
    filter: {type: {eq: "Expert"}}
  ) {
    nodes {
      name
      title
      featured
      type
      bio {
        raw
      }
      story {
        raw
      }
      image {
        gatsbyImageData(
          quality: 70
          layout: CONSTRAINED
          breakpoints: [300, 500, 750, 1080, 1366, 1920]
          formats: WEBP
        )
        title
        description
      }
      handicappingPicks {
        data
      }
    }
  }
}`)

	let _in = { fade: 500 }

	// Function to add or substract months in dates
	function addMonths(date, months) {
		var d = date.getDate()
		date.setMonth(date.getMonth() + +months)
		if (date.getDate() !== d) {
			date.setDate(0)
		}
		return date
	}

	// Setting up 2 months ahead and 1 back
	const todayDate = new Date()
	const todayYear = todayDate.getFullYear()
	const todayMonth = todayDate.getMonth()
	const pastDateRange = addMonths(new Date(todayYear,todayMonth,2),-1).toISOString()
	const futureDateRange = addMonths(new Date(todayYear,todayMonth,1),3).toISOString()

	const espanolExperts = data?.allContentfulPerson.nodes.filter(node => {
		if(node.title === 'Espanol Handicapping Expert')
			return node
	})

	const experts = data?.allContentfulPerson.nodes.filter(node => {
		if(node.title !== 'Espanol Handicapping Expert')
			return node
	})

	const staticExperts = isEspanolSection ? espanolExperts : experts
	

	// Piecing the thing together
	let tabs = []

	tabs.push({
		tabHandleClasses: 'blank',
		tabHandle: [
			{
				__typename: 'ContentfulBlockWrapper',
				classes: 'flex',
				blocks: [
					{
						__typename: 'ContentfulBlockWysiwyg',
						classes: '',
						content: `<h2 class="span-12">Expert Picks</h2>		`,
					},
					{
						__typename: 'ContentfulBlockWysiwyg',
						classes: '',
						content: `<div class="wagering-menu-link c0 flex padd-left-half">
						<a href="https://1st.com/bet/gulfstream-park?utm_source=gsp&utm_medium=track_referral&utm_campaign=GSP_Site_Other" target="_blank" class="btn colored fs-85"><span>BET NOW</span></a>
						</div>`,
					}
				]
			},

		],
	})

	tabs.push({
		tabHandleClasses: 'blank',
		tabHandle: [
			{
				__typename: 'ContentfulBlockWysiwyg',
				classes: '',
				content: `<h5>YOU CAN FIND THE BEST HANDICAPPING TIPS OF THE DAY
				FROM THESE SOUTH Florida HANDICAPPERS:</h5>`,
			}
		],
	})

	let featuredExperts = staticExperts
	// .filter(function(person) {
	// 	if (person.featured && person.type === 'Expert') {
	// 		return true
	// 	}
	// 	return false
	// })
	.map((person,i) => {

		return {
			tabHandleClasses: 'span-12 span-6-mobile padd-top-half padd-bottom-half flex-12',
			tabHandle: [
				{
					__typename: 'ContentfulBlockWrapper',
					classes: 'span-2 span-12-mobile',
					blocks: [
						{
							__typename: 'ContentfulBlockImage',
							classes: 'aspect-ratio cover ',
							image: person.image,
						},
					]
				},
				{
					__typename: 'ContentfulBlockWrapper',
					classes: 'span-10 span-12-mobile flex-12 expert-copy',
					blocks: [
						{
							__typename: 'ContentfulBlockWysiwyg',
							classes: 'expert-featured span-10 span-12-mobile read-more-target',
							content: person.bio,
						},
						{
							__typename: 'ContentfulBlockWysiwyg',
							classes: 'span-2 span-12-mobile flex jc-flex-end see-btn',
							content: `<p><div class="btn"><span>See Picks</span></div></p>`,
						},
					]
				},

			],
			tabContentClasses: '',
			tabContent: [
				{
					__typename: 'ContentfulBlockWrapper',
					classes: 'bio ',
					blocks: [
						{
							__typename: 'ContentfulBlockWrapper',
							classes: 'intro padd-half padd-left c5',
							blocks: [
								// {
								// 	__typename: 'ContentfulBlockImage',
								// 	classes: 'aspect-ratio cover',
								// 	image: person.image,
								// },
								{
									__typename: 'ContentfulBlockWysiwyg',
									classes: 'text-left',
									content: `<h4>${person.name}</h4>`,
								},
							],
						},

						{
							__typename: 'picks',
							classes: '',
							picks: person?.handicappingPicks,
							pastDateRange: pastDateRange,
							futureDateRange: futureDateRange,
						},
					],
				},
				{
					__typename: 'ContentfulBlockWysiwyg',
					classes: 'text-left padd story',
					content: person.story,
				},
			]
		}
	})

	let espanolExpertsPicksSmall = espanolExperts
	// .filter(function(person) {
	// 	if (person.featured && person.type === 'Expert') {
	// 		return true
	// 	}
	// 	return false
	// })
	.map((person,i) => {

		return {
			tabHandleClasses: 'span-12 span-6-mobile padd-top-half padd-bottom-half flex-12',
			tabHandle: [
				{
					__typename: 'ContentfulBlockWrapper',
					classes: 'span-2 span-12-mobile',
					blocks: [
						{
							__typename: 'ContentfulBlockImage',
							classes: 'aspect-ratio cover ',
							image: person.image,
						},
					]
				},
				{
					__typename: 'ContentfulBlockWrapper',
					classes: 'span-10 span-12-mobile flex-12 expert-copy',
					blocks: [
						{
							__typename: 'ContentfulBlockWysiwyg',
							classes: 'expert-featured span-10 span-12-mobile read-more-target',
							content: person.bio,
						},
						{
							__typename: 'ContentfulBlockWysiwyg',
							classes: 'span-2 span-12-mobile flex jc-flex-end see-btn',
							content: `<p><div class="btn"><span>See Picks</span></div></p>`,
						},
					]
				},

			],
			tabContentClasses: '',
			tabContent: [
				{
					__typename: 'ContentfulBlockWrapper',
					classes: 'bio ',
					blocks: [
						{
							__typename: 'ContentfulBlockWrapper',
							classes: 'intro padd-half padd-left c5',
							blocks: [
								// {
								// 	__typename: 'ContentfulBlockImage',
								// 	classes: 'aspect-ratio cover',
								// 	image: person.image,
								// },
								{
									__typename: 'ContentfulBlockWysiwyg',
									classes: 'text-left',
									content: `<h4>${person.name}</h4>`,
								},
							],
						},

						{
							__typename: 'picks',
							classes: '',
							picks: person?.handicappingPicks,
							pastDateRange: pastDateRange,
							futureDateRange: futureDateRange,
						},
					],
				},
				{
					__typename: 'ContentfulBlockWysiwyg',
					classes: 'text-left padd story',
					content: person.story,
				},
			]
		}
	})

	// let espanolExpertsPicksSmall = espanolExperts
	// .map((person,i) => {
	
	// 	return {
	// 		tabHandleClasses: 'tab-handle-small span-12 span-6-mobile flex-12',
	// 		tabHandle: [
	// 			{
	// 				__typename: 'ContentfulBlockWrapper',
	// 				classes: 'span-12 span-12-mobile flex-12 fs-85 expert-copy',
	// 				blocks: [
	// 					{
	// 						__typename: 'ContentfulBlockWysiwyg',
	// 						classes: 'expert-featured span-10 span-12-mobile',
	// 						content: `<h6>${person.name}</h6>`,
	// 					},
	// 					{
	// 						__typename: 'ContentfulBlockWysiwyg',
	// 						classes: 'span-2 span-12-mobile flex jc-flex-end see-btn',
	// 						content: `<p><div class="btn"><span>See Picks</span></div></p>`,
	// 					},
	// 				]
	// 			},

	// 		],
	// 		tabContentClasses: '',
	// 		tabContent: [
	// 			{
	// 				__typename: 'ContentfulBlockWrapper',
	// 				classes: 'bio ',
	// 				blocks: [
	// 					{
	// 						__typename: 'ContentfulBlockWrapper',
	// 						classes: 'intro padd-half padd-left c5',
	// 						blocks: [
	// 							// {
	// 							// 	__typename: 'ContentfulBlockImage',
	// 							// 	classes: 'aspect-ratio cover',
	// 							// 	image: person.image,
	// 							// },
	// 							{
	// 								__typename: 'ContentfulBlockWysiwyg',
	// 								classes: 'text-left',
	// 								content: `<h4>${person.name}</h4>`,
	// 							},
	// 						],
	// 					},

	// 					{
	// 						__typename: 'picks',
	// 						classes: '',
	// 						picks: person?.handicappingPicks,
	// 						pastDateRange: pastDateRange,
	// 						futureDateRange: futureDateRange,
	// 					},
	// 				],
	// 			},
	// 			{
	// 				__typename: 'ContentfulBlockWysiwyg',
	// 				classes: 'text-left padd story',
	// 				content: person.story,
	// 			},
	// 		]
	// 	}
	// })

	if (featuredExperts) {
		tabs.push(...featuredExperts)
		if( !isEspanolSection && espanolExpertsPicksSmall)
			tabs.push(...espanolExpertsPicksSmall)
	}



	// let experts = staticExperts?.filter(function(person) {
	// 	if (!person.featured && person.type === 'Expert') {
	// 		return true
	// 	}
	// 	return false
	// }).map((person,i) => {

	// 	return {
	// 		tabHandleClasses: '',
	// 		tabHandle: [
	// 			{
	// 				__typename: 'ContentfulBlockWysiwyg',
	// 				classes: 'expert',
	// 				content: `<h4 class="strike"><span>`+person.name+`</span></h4>`,
	// 			}
	// 		],
	// 		tabContentClasses: '',
	// 		tabContent: [
	// 			{
	// 				__typename: 'ContentfulBlockWrapper',
	// 				classes: 'bio padd',
	// 				blocks: [
	// 					{
	// 						__typename: 'ContentfulBlockWrapper',
	// 						classes: 'intro',
	// 						blocks: [
	// 							{
	// 								__typename: 'ContentfulBlockImage',
	// 								classes: 'aspect-ratio cover',
	// 								image: person.image,
	// 							},
	// 							{
	// 								__typename: 'ContentfulBlockWysiwyg',
	// 								classes: 'text-left',
	// 								content: `<h4>${person.name}</h4>`,
	// 							},
	// 						],
	// 					},
	// 					{
	// 						__typename: 'ContentfulBlockWysiwyg',
	// 						classes: 'text-left',
	// 						content: person.bio,
	// 					},
	// 				],
	// 			},
	// 			{
	// 				__typename: 'picks',
	// 				classes: '',
	// 				picks: person?.handicappingPicks,
	// 				pastDateRange: pastDateRange,
	// 				futureDateRange: futureDateRange,
	// 			},
	// 		]
	// 	}
	// })

	// if (experts) {
	// 	tabs.push(...experts)
	// }


	const setup = {
		__typename: 'ContentfulBlockTabs',
		classes: '',
		next: false,
		tabs: tabs,
	}


  return (
		<div ref={io} className={`block-custom flex-12 c1 ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

				<Intro visible={ioInView} in={{ fade: 500 }} delayIn={ 500 } className="expert-picks-intro-wrap">
					<BlocksRouter {...setup} />
				</Intro>
	
		</div>
  )
}

export default Custom