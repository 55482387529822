import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import validatePdf from '../../../../utils/validatePdf'

function Custom(props) {
	const {repeaters: repeater} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

  const files = repeater.length && repeater[0]?.fileUploadRepeater?.data?.length ? 
    repeater[0].fileUploadRepeater.data.map((item, i) => {
      return (
        <div key={i} className='flex single-item '>
          <div className='copy'>
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={325 + 50 * i} className="c0 t border-top-off border-left-off border-right-off">
              <h6>{item.key}</h6>
              <div className="downloads c0">
                <a href={validatePdf(item.file.url)} className="icon-pdf" target="_blank" rel="noreferrer noopener">Download</a>
              </div>
            </Intro>
          </div>
        </div>
      )
    })
  :
    null

  return (
		<div ref={io} className={`block-custom downloads-pack padd ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className='container'>
        <h1>{props.directField ? props.directField : 'Download'}</h1>
        <div className='flex-12'>
			    {files}
        </div>
      </div>
		</div>
  )
}

export default Custom