import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'


function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })

  const content = props.custom.data.map( (item, i) => {
    return (
      <tr>
        <td>{item.benefit}</td>
        <td className='center'>{typeof item.show !== 'string'  ? (item.show ? <span className='dot show'></span> : null) : item.show }</td>
        <td className='center'>{typeof item.place !== 'string' ? (item.place ? <span className='dot place'></span> : null) : item.place }</td>
        <td className='center'>{typeof item.win !== 'string'  ? (item.win ? <span className='dot win'></span> : null): item.win}</td>
        <td className='center'>{typeof item.tripleCrown !== 'string'  ? (item.tripleCrown ? <span className='dot crown'></span> : null) : item.tripleCrown}</td>
      </tr>
    )
  })
  
  return (
		<div ref={io} className={`block-custo tier-benefits flex w-100 ${props.classes}`}>
        <table>
          <tr>
            <th className='benefit'>Benefit</th>
            <th className='center show'>Show</th>
            <th className='center place'>Place</th>
            <th className='center win '>Win</th>
            <th className='center crown'>Triple Crown</th>
          </tr>
          {content}
          <p>*based on play</p>
        </table>
		</div>
  )
}

export default Custom