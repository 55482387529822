import React from 'react'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import BlocksRouter from '../../../Sections/BlocksRouter'
import './style.scss'

function PegasusTickets({ wrappers, classes }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={io} className={`c0 padd ${classes}`}>
      <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 400 }} delayIn={0} mounted={true} stay={true} className=" t border-c5">
        <div className='padd-top-quarter padd-bottom-quarter padd-left-half padd-right-half'>
          <h2 className="text-3xl font-bold underline ">
            CHOOSE YOUR EXPERIENCE
          </h2>
        </div>
      </Intro>
     {/* <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 400 }} delayIn={100} mounted={true} stay={true} className=" t border-c5 border-top-off border-bottom-off">
        <div className='padd-top-quarter padd-bottom-quarter padd-left-half padd-right-half'>
          <h4>
            TICKETS ON SALE NOW
          </h4>
        </div>
  </Intro>*/}
      <div>
        <div className='flex flex-wrap'>
          {wrappers?.map((item, i) => {
            return (
              <Intro key={i} visible={ioInView} in={{ border: 500, bg: 500, fade: 400 }} delayIn={125 + i * 25} mounted={true} stay={true} className={`card t border-c5  ${i > 0 ? 'border-left-off' : ''}`}>
                <div className=''>
                  <BlocksRouter blocks={item.blocks} className="borderless" />
                </div>
              </Intro>
            )
          })}
        </div>
      </div>
      <div className='button flex jc-center ac-center padd-top'>
        <a href="https://www.pegasusworldcup.com/tickets/" target="_blank" rel="noreferrer noopener" className='btn big'><span>GET TICKETS</span></a>
      </div>
    </div>

  )
}

export default PegasusTickets