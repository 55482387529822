import React, { useState, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function OtherPools(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const {
    standing,
    type,
    changes,
    runnersSwitched,
    double,
    exacta,
    quinella,
    trifecta,
    superfecta,
    superhifive,
    pick3,
    pick4,
    pick5,
    pick6,
  } = props

  return (
    <div ref={io} className={`racing-basic-bets-box flex-12`}>
      {double ||
        exacta ||
        quinella ||
        trifecta ||
        superfecta ||
        superhifive ||
        pick3 ||
        pick4 ||
        pick5 ||
        pick6 ?
        <div className="results-tab">
          <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
            {double ?
              <div className="win-p">
                <p>Double</p>
              </div>
              : null}
            {exacta ?
              <div className="win-p">
                <p>Exacta</p>
              </div>
              : null}
            {quinella ?
              <div className="win-p">
                <p>Quinella</p>
              </div>
              : null}
            {trifecta ?
              <div className="win-p">
                <p>Trifecta</p>
              </div>
              : null}
            {superfecta ?
              <div className="win-p">
                <p>Superfecta</p>
              </div>
              : null}
            {superhifive ?
              <div className="win-p">
                <p>superHiFive</p>
              </div>
              : null}
            {pick3 ?
              <div className="win-p">
                <p>Pick 3</p>
              </div>
              : null}
            {pick4 ?
              <div className="win-p">
                <p>Pick 4</p>
              </div>
              : null}
            {pick5 ?
              <div className="win-p">
                <p>Pick 5</p>
              </div>
              : null}
            {pick6 ?
              <div className="win-p">
                <p>Pick 6</p>
              </div>
              : null}

          </Intro>
          <Intro key={`content-results`} switch={ioInView && runnersSwitched} in={{ fadeTop: 500 }} delayIn={100} className={`row no  `}>



            {double ?
              <div className="win-p">
                <p>${double}</p>
              </div>
              : null}
            {exacta ?
              <div className="win-p">
                <p>${exacta}</p>
              </div>
              : null}
            {quinella ?
              <div className="win-p">
                <p>${quinella}</p>
              </div>
              : null}
            {trifecta ?
              <div className="win-p">
                <p>${trifecta}</p>
              </div>
              : null}
            {superfecta ?
              <div className="win-p">
                <p>${superfecta}</p>
              </div>
              : null}
            {superhifive ?
              <div className="win-p">
                <p>${superhifive}</p>
              </div>
              : null}
            {pick3 ?
              <div className="win-p">
                <p>${pick3}</p>
              </div>
              : null}
            {pick4 ?
              <div className="win-p">
                <p>${pick4}</p>
              </div>
              : null}
            {pick5 ?
              <div className="win-p">
                <p>${pick5}</p>
              </div>
              : null}
            {pick6 ?
              <div className="win-p">
                <p>${pick6}</p>
              </div>
              : null}


          </Intro>
          {!runnersSwitched ?
            <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
            :
            null
          }

        </div>
        :
        <div className='padd-half'>
          <h3>No data for selected bet</h3>
        </div>
      }
      &nbsp;
    </div>
  )
}

export default OtherPools
