import React, {useContext} from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import NaviContext from '../../../../context/NaviContext'

function validURL(str) {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
}

function Custom(props) {
	const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map, repeaters: repeater, videos: video} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

	const naviContext = useContext(NaviContext)


	const twoColumns = repeater[0]?.classes?.includes('2-columns') ? true : false

	const links = repeater[0]?.repeater.map((node, i) => {
		return (
			<div key={i} onClick={() => naviContext.setActive(false)} className={`link ${i === 0 ? '' : 'fs-85'} ${twoColumns && i!==0 ? 'span-6' : 'span-12'}`}>
				{ node.value === 'blank' ? 
					<h6 className="padd-top-half colored">{node.key}</h6>
				:
					validURL(node.value) || node.value.startsWith('tel') ?
						i === 0 ?
							<a href={node.value} className="title-link nostrike" target="_blank" rel='noreferrer noopener'>
								<h2 className="colored title">{node.key}</h2>
							</a>
						:
							<a href={node.value} className="navi-link nostrike hover-trigger" target="_blank" rel='noreferrer noopener'>
								{node.key}
								<div className="icon icon-arrow fs-85" />
							</a>
					:
						i === 0 ?
							<Link to={node.value} className="title-link nostrike">
								<h2 className="colored title">{node.key}</h2>
							</Link>
						:
							<Link to={node.value} className="navi-link nostrike hover-trigger" >
								{node.key}
								<div className="icon icon-arrow fs-85" />
							</Link>
				}
			</div>
		)
	})

  return (
		<div ref={io} className={`block-custom ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			<div className="box c5">

				{img[0] ?
					img[0].classes?.includes('image-link') ? 
						<a href={repeater[0]?.repeater[0]?.value} className='nostrike' target="_blank" rel='noreferrer noopener'>
							<div className="img-wrap">
								<div className="aspect-ratio">
									<GatsbyImage image={img[0]?.image.gatsbyImageData} title={img[0]?.image.title}  alt={img[0]?.image.description}  />
								</div>
							</div>
						</a>
					:
					<div className="img-wrap">
						<div className="aspect-ratio">
							<GatsbyImage image={img[0]?.image.gatsbyImageData} title={img[0]?.image.title}  alt={img[0]?.image.description} />
						</div>
					</div>
				:
					null
				}

				{video[0] ?
					<div className="vid-wrap">
						<video loop playsInline disablePictureInPicture autoPlay={true} allow="autoplay" title={"Navigation Video Placeholder"}>
							<source src={video[0].video[0]?.file?.url} type="video/mp4" />
							<track
								default kind="captions"
								srcLang="en"
								src={null}/>     
                <meta itemProp="name" content={"Navigation Video Placeholder"}/>
                <meta itemProp="description" content={"Navigation Video Placeholder"}/>
						</video>
					</div>
				:
					null
				}
				
				<div className="content flex-12">
					{links}
				</div>
			</div>
		</div>
  )
}

export default Custom