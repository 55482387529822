import React, { useState, useCallback, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'

import Calendar from '../../../Calendar'
import Results from '../ResultsReplaysWorkouts/Results'
import Replays from '../ResultsReplaysWorkouts/Replays'
import Changes from '../ResultsReplaysWorkouts/Changes'
import StewardsReview from '../ResultsReplaysWorkouts/StewardsReview'
import Entries from '../ResultsReplaysWorkouts/Entries'
import RaceDetailsBox from '../ResultsReplaysWorkouts/RaceDetailsBox'
//import TrackModel from '../../../TrackModel'
import Workouts from '../ResultsReplaysWorkouts/Workouts'
import Carryovers from '../ResultsReplaysWorkouts/Carryovers'
import Stakes from '../ResultsReplaysWorkouts/Stakes'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
//import { useStaticQuery, graphql } from 'gatsby'
import { useQuery, gql } from '@apollo/client'

// Function to add or substract months in dates
function addMonths(date, months) {
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

// The fun starts here
function Custom(props) {

  // const data = useStaticQuery(graphql`
  // 	query RacingEventsBig {
  // 		allContentfulEventDay {
  // 			nodes {
  // 				raceEvents {
  // 					id
  // 					entriesApi {
  // 						data
  // 					}
  // 					resultsApi {
  // 						data
  // 					}
  // 					changesApi {
  // 						data
  // 					}
  // 					trackApi {
  // 						data
  // 					}
  // 					racesApi {
  // 						data
  // 					}
  // 				}
  // 				date
  // 			}
  // 		}
  // 	}
  // `)


  // Setting up current day
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth() + 1
  const todayMonthLeadingZero = todayMonth < 10 ? '0' + todayMonth : '' + todayMonth
  const todayDay = todayDate.getDate()
  const todayDayLeadingZero = todayDay < 10 ? '0' + todayDay : '' + todayDay
  const todayRaceDate = `${todayYear}-${todayMonthLeadingZero}-${todayDayLeadingZero}`

  // States
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [filteredBatch, setFilteredBatch] = useState({ type: 'entries', batch: entries })
  const [currentTab, setCurrentTab] = useState(0)
  const [distance, setDistance] = useState()
  const [openTab, setOpenTab] = useState()

  const location = useLocation()

  // Setting up 2 months ahead and 1 back
  const pastDateRange = filteredBatch.type === 'entries' ? addMonths(new Date(todayYear, todayMonth, 2), -2).toISOString() : addMonths(new Date(todayYear, todayMonth, 2), -2).toISOString()
  const futureDateRange = filteredBatch.type === 'entries' ? addMonths(new Date(todayYear, todayMonth, 1), 6).toISOString() : addMonths(new Date(todayYear, todayMonth, 1), 6).toISOString()

  // Apollo, Pull Events!
  const { loading, error, data: apollo, refetch } = useQuery(APOLLO_QUERY, {
    variables: { // variables for query use
      date_gte: pastDateRange,
      date_lte: futureDateRange,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore'
  })

  // Refetch apollo every 30s
  useEffect(() => {
    function checkApollo() {
      refetch()
      //console.log('APOLLO request racing info', loading, error, apollo)
    }
    let interval = setInterval(() => checkApollo(), 300000)
    return () => {
      clearInterval(interval)
    }
  }, [refetch])
  //console.log('APOLLO request racing info', loading, error, apollo)

  // Filter static query to have only events that have PUBLISHED races in them
  const racesDays = apollo?.eventsCurrentView?.items ? apollo?.eventsCurrentView?.items?.filter(item => {
    return item?.raceEventsCollection?.items?.[0]
  }) : []
  //const racesDaysOld = data?.allContentfulEventDay?.nodes?.filter(obj => obj.raceEvents)
  const entries = racesDays ? racesDays : [
    {
      "date": todayRaceDate,
    }
  ]

  const [calendarSelector, setCalendarSelector] = useState({ dateSelected: null, currentViewStart: null, currentViewEnd: null })

  useEffect(() => {
    setFilteredBatch((old) => { return { ...old, batch: racesDays } })
  }, [apollo])

  // Monitor hash/parameters
  useEffect(() => {
    const href = location ? location.hash.substr(1) : ''
    const params = href.split('?')[1]
    const triggerButtons = document.querySelectorAll('.filter-trigger')
    let timer = null;
    // Split params
    if (params && params !== '') {
      var search = params.split('&').reduce(function (res, item) {
        var parts = item.split('=')
        res[parts[0]] = parts[1]
        return res;
      }, {})

      if (triggerButtons && triggerButtons[search.tab])
        timer = window.setTimeout(() => triggerButtons[search.tab]?.click(), 1000)


      return () => {
        clearTimeout(timer)
      }

    }

  }, [location])

  // Using callback to avoid rerenders on useEffects for those functions
  const calendarSelectorFunction = useCallback((i) => {
    setCalendarSelector(i)
  }, [])

  // Reset to first tab if date change
  useEffect(() => {
    setCurrentTab(0)
  }, [calendarSelector])

  function handleOpenTabClick(type) {
    setOpenTab(type)
  }

  function handleFilterClick(e, type, batch) {
    e.preventDefault()

    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      const mimic = document.getElementById('master-header-mimic')
      window.scrollTo({
        top: window.pageYOffset - mimic?.offsetHeight + document.querySelector('#racing-info-details')?.getBoundingClientRect().top,
        behavior: 'smooth'
      })
    }
    // Reset to first tab if it's mismatch
    // if(currentTab !== 0) {
    // 	setCurrentTab(0)
    // }
    setFilteredBatch(() => ({ type: type, batch: batch }))
    document.querySelectorAll('.filter-trigger')?.forEach(e => e.classList.remove('current'))
    document.querySelectorAll('.' + e.currentTarget.className.replace(/ /g, "."))?.forEach(e => e.classList.add('current'))
    //e.currentTarget.classList.add('current')
    //setCalendarSelector((old) => ({...old, dateSelected: null}))
  }

  useEffect(() => {
    const header = document?.getElementById('master-header-mimic')
    const sticky = document?.getElementById('sticky-racing-info')
    if (sticky) {
      sticky.style.top = header.offsetHeight - 11 + 'px'
    }
  }, [ioInView])

  const selectedDate = new Date(calendarSelector.dateSelected)

  // The content of selected date obj
  let program = null
  let programAbbreviated = null
  let racesToControl = null
  let owners = null
  let breeders = null

  const selectedEvent = filteredBatch?.batch?.filter(function (node) {

    const givenDaySimplified = new Date(node.date.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0, 0, 0, 0) // removed .replace(/-/g, "/")
    const selectedDaySimplified = new Date(calendarSelector.dateSelected).setHours(0, 0, 0, 0)

    if (givenDaySimplified === selectedDaySimplified) {
      return true
    }
    return false
  })

  const isRaceCanceled = selectedEvent?.[0]?.raceEventsCollection?.items?.[0]?.isRaceCanceled

  const displayedContent = selectedEvent?.map((node, i) => {
    if (filteredBatch.type === 'results') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders
      let results = node.raceEventsCollection?.items?.[0]?.resultsApi?.data?.results
      let runners = node.raceEventsCollection?.items?.[0]?.entriesApi?.data?.[currentTab]
      let changes = node.raceEventsCollection?.items?.[0]?.changesApi?.data?.changes
      let track = node.raceEventsCollection?.items?.[0]?.trackApi?.data?.track
      let races = node.raceEventsCollection?.items?.[0]?.racesApi?.data?.races
      return (
        <>
          <Results key={`res-${i}`} data={{ results: results, runners: runners, changes: changes, track: track, races: races }} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} />
          <Replays key={`rep-${i}`} data={{ results: results, runners: runners, changes: changes, track: track, races: races }} switchFlag={calendarSelector?.dateSelected} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} content={node.races} />
        </>
      )
    } else if (filteredBatch.type === 'stakes') {

      return <Stakes key={i} />

    } else if (filteredBatch.type === 'entries') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders
      //let results = node.raceEventsCollection?.items?.[0]?.resultsApi?.data?.results
      let runners = node.raceEventsCollection?.items?.[0]?.entriesApi?.data?.[currentTab]
      let changes = node.raceEventsCollection?.items?.[0]?.changesApi?.data?.changes
      let track = node.raceEventsCollection?.items?.[0]?.trackApi?.data?.track
      let races = node.raceEventsCollection?.items?.[0]?.racesApi?.data?.races
      let surface = node.raceEventsCollection?.items?.[0]?.racesSurface?.data
      let files = node.raceEventsCollection?.items?.[0]?.entriespdf
      return (
        <Entries key={i} data={{ runners: runners, changes: changes, track: track, races: races, surface: surface, files: files }} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} />
      )
    } else if (filteredBatch.type === 'replays') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders
      return (
        <Replays key={i} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} content={node.races} />
      )
    } else if (filteredBatch.type === 'carryovers') {

      return <Carryovers key={i} />

    } else if (filteredBatch.type === 'scratches & changes') {
      racesToControl = node.races
      let changes = node.raceEventsCollection?.items?.[0]?.changesApi?.data?.changes
      let runners = node.raceEventsCollection?.items?.[0]?.entriesApi?.data
      let track = node.raceEventsCollection?.items?.[0]?.trackApi?.data?.track
      return (
        <Changes key={i} data={{ changes: changes, runners: runners, track: track }} switchFlag={calendarSelector?.dateSelected} selectedDate={selectedDate} />
      )
    } else if (filteredBatch.type === 'workouts') {
      program = node.programWorkouts
      programAbbreviated = node.programAbbreviatedWorkouts
      racesToControl = node.workouts
      owners = node.workouts?.[currentTab].owners
      breeders = node.workouts?.[currentTab].breeders
      return (
        <Workouts key={i} currentTab={currentTab} setCurrentTab={setCurrentTab} workouts={node.workouts} distance={distance} setDistance={setDistance} />
      )
    } else if (filteredBatch.type === 'stewards review') {
      racesToControl = node.races
      let reviews = node?.raceEventsCollection?.items?.[0]?.stewardsReviewCollection?.items
      return (
        <StewardsReview key={i} data={{ reviews: reviews }} switchFlag={calendarSelector?.dateSelected} selectedDate={selectedDate} />
      )
    } else {
      return <h3>No filterer picked</h3>
    }
  })


  return (
    <div ref={io} id="race" className={`block-custom block-results-replays-component calendar-compact flex-12 ai-flex-start ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div id={"racing-full"} className="anchor"></div>
      <div className="span-12 padd padd-left-off padd-right-off padd-bottom-off c0">

        <div className="flex-12">


          <div id="racing-info-details" className={`span-8 racing-info-details flex-12 ac-flex-start`}>

            <div className="span-12 flex-12 v-center">

              {/* { distance ?
							<div className="span-6 padd-half padd-top-off as-center">
								<Intro switch={calendarSelector?.dateSelected && filteredBatch?.type} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="">
									<div  id="portal-track-model" />
									<TrackModel raceNo={currentTab + 1} distance={distance} />
								</Intro>
							</div>
							: null } */}
              <Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} className="racing-results-wrap">
                <div className="calendar-selection-content flex-12">
                  <div className="span-12-tablet padd-half padd-top-off padd-bottom-off next-1-margin--1">
                    <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={200} className="padd-half c0 children-flex">
                      <div className="results-heading-wrap">
                        <h3 className="results-heading">{filteredBatch.type}</h3>
                        <h6>Updates hourly. For real-time updates, please visit <Link to="/racing/">Live Racing</Link> and log in</h6>
                          <div className='legend'>
                            <div className='square-container'> 
                            <span><span className='black square'/> - Live racing </span>
                            <span><span className='grey square' /> - Simulcast Racing</span>
                          </div>
                        </div>
                      </div>
                      <div id='entries-pdf-portal'></div>
                    </Intro>
                  </div>
                  {isRaceCanceled && (filteredBatch.type !== 'stakes' && filteredBatch.type !== 'carryovers') ? 
                    <>
                      <div className='padd-half'>
                        <h4 style={{color: "#f44336"}}>This race day has been cancelled</h4>
                      </div>
                      <div className='canceled'>
                        {displayedContent} 
                      </div>
                    </>
                  :
                  displayedContent
                  }
                </div>
              </Intro>

            </div>

          </div>

          <div className="span-4 span-12-mobile span-12-tablet racing-info-selector flex-12 c1 fs-85 padd-top-half ac-flex-start">

            <div id="sticky-racing-info" className="position-sticky flex-12">

              <div id="portal-race-details" className="span-12 padd-half padd-top-off padd-bottom-off">
                <RaceDetailsBox />
              </div>

              <div className="span-6 filter-wrap text-left ai-flex-start ac-flex-start jc-flex-start padd-half padd-top-off padd-right-off">
                <div className="has-intro btn filter-trigger f-t-stakes" onClick={(e) => handleFilterClick(e, 'stakes', entries)}>
                  <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100} className="c5 t border-c0 border-top-off border-right-c5 border-left-c5">
                    <span>Stakes</span>
                  </Intro>
                </div>
                <div className="has-intro btn filter-trigger f-t-entries current" onClick={(e) => handleFilterClick(e, 'entries', entries)}>
                  <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} className="c5 t border-c0 border-top-off border-right-c5 border-left-c5">
                    <span>Entries</span>
                  </Intro>
                </div>
                <div className="has-intro btn filter-trigger f-t-results" onClick={(e) => handleFilterClick(e, 'results', entries)}>
                  <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                    <span>Results &amp; Replays</span>
                  </Intro>
                </div>
                {/* <div className="has-intro btn filter-trigger f-t-replays" onClick={(e) => handleFilterClick(e,'replays',entries)}>
									<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
										<span>Replays</span>
									</Intro>
								</div> */}
                <div className="has-intro btn filter-trigger f-t-carryovers-guarantees" onClick={(e) => handleFilterClick(e, 'carryovers', entries)}>
                  <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                    <span>Carryovers &amp; Guarantees</span>
                  </Intro>
                </div>
                <div className="has-intro btn filter-trigger f-t-scratches-changes" onClick={(e) => handleFilterClick(e, 'scratches & changes', entries)}>
                  <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={500} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                    <span>Scratches &amp; Changes</span>
                  </Intro>
                </div>
                <div className="has-intro btn filter-trigger f-t-stewards-review" onClick={(e) => handleFilterClick(e, 'stewards review', entries)}>
                  <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={600} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                    <span>Stewards Review</span>
                  </Intro>
                </div>
              </div>

              <div className={`${props.classes === 'only-workouts' ? 'span-12 ' : 'padd-left-off span-6'}  padd-half  padd-top-off as-flex-start`}>
                <Intro switch={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="c3 t border-top-off margin-left--1">
                  <Calendar
                    handleSelectionClick={() => false}
                    filterCalendar={() => false}
                    selector={calendarSelectorFunction}
                    minDate={pastDateRange}
                    maxDate={futureDateRange}
                    events={filteredBatch?.batch}
                    allowPast={true}
                    keepDateOnSwitch={true}
                    ui={filteredBatch.type === 'stakes' || filteredBatch.type === 'carryovers' ? 'zero' : ''}
                    classes={filteredBatch.type === 'stakes' || filteredBatch.type === 'carryovers' ? '' : 'padd-half'}
                  />
                </Intro>
              </div>

              <Link to="/racing/" className="btn wide small-mrg w-100 padd-left-half padd-right-half"><span>Watch Live Racing</span></Link>
              <a href="https://www.xbtv.com/video-on-demand/gulfstream/" target="_blank" rel='noreferrer noopener' className="btn wide small-mrg w-100 padd-left-half padd-right-half"><span>Watch Workouts</span></a>
              <a href="#modal-1st-track-trends" className="btn wide small-mrg w-100 padd-left-half padd-right-half"><span>Track Trends</span></a>
              <a href="https://1st.com/bet/gulfstream-park?utm_source=gsp&utm_medium=track_referral&utm_campaign=GSP_Site_Other" target="_blank" rel='noreferrer noopener' className="btn wide w-100 padd-left-half padd-right-half"><span>Bet Now</span></a>

            </div>

          </div>


        </div>

      </div>

    </div>
  )
}

export default Custom


// Query for rehydration, currently just test
const APOLLO_QUERY = gql`
  query eventsQuery($date_gte: DateTime, $date_lte: DateTime) {
    eventsCurrentView: eventDayCollection (limit: 100, where: {raceEventsCollection_exists: true, date_gte: $date_gte, date_lte: $date_lte}, order: date_ASC) {
      items {
				date
        raceEventsCollection (limit: 1) {
					items {
						trackConditions
						entriesApi
						resultsApi
						changesApi
						trackApi
						racesApi
						racesSurface
            isRaceCanceled
            stewardsReviewCollection {
              items {
                title
                heading
                content {
                  json
                }
              }
            }
            entriespdf {
								title
								url
						}
					}
				}
			}
    }
  }
`