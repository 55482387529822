import React, {useState, useEffect} from 'react'
import './style.scss'

function LocalTime() {

	const d = new Date()
	const [time,setTime] = useState(d)

	useEffect(() => {
		const interval = setInterval(() => setTime(new Date), 1000)
		return () => {
			clearInterval(interval)
		}
	}, [])

  return (
		<span className="local-time">
			LOCAL TIME: {time?.toLocaleTimeString("en-US", {timeZone: "America/New_York"})}
		</span>
  )
}

export default LocalTime