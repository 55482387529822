import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Block from '../../../Sections/BlocksRouter'
import BeatTheExpert  from '../../../Form/Axios/BeatTheExpert'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const {images: img, wysiwygs: wysiwyg} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

  const numberOfRaces = props?.custom?.data?.races

  return (
		<div ref={io} className={`block-custom beat-expert ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			{wysiwyg ? <Block {...wysiwyg[0]} /> : null}
			<BeatTheExpert numberOfRaces={numberOfRaces}/>
      <p className='small-message text-center'>*If you selected more winners than the Expert of the Week, you will be entered into a pool of eligible winners who have also selected more winners than the Expert of the Week. Three eligible winners will be randomly selected to win the polo shirt.</p>
		</div>
  )
}

export default Custom