import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

// import Intro from '../../../../../animations/Intro'
// import RaceDescriptionBox from '../RaceDescriptionBox'
// import RaceStandingsBox from '../RaceStandingsBox'
// import Phones from './app-phones.png'
import Wagering from '../../../../Sidebar/Wagering'

function Custom(props) {
  // const [io, ioInView] = useInView({ triggerOnce: true })

  // Delay Synchronization	
  // const baseDelay = 400
  // const stepDelay = 150

  return (
    <div className="padd-half padd-top-off">
      <div className="carryovers-component c0 padd fs-85 padd-left-off padd-right-off padd-top-off">
        <Wagering />
      </div>
    </div>
  )
}

export default Custom