import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })
	
	let formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
	})

  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const heading = props?.custom?.data?.heading

  const contests = props?.custom?.data?.contests.map( (contest, i) => {

    const date = new Date(contest.date)
    let day = days[date.getDay()];
    let month = months[date.getMonth()];
    let monthDay = date.getDate()

    return (
      <div key={i} className="single-item span-4 span-12-mobile">
        <Intro visible={ioInView} in={{ border: 450, fade: 270 }} delayIn={75 * i} className={`innerborder`}>
          <div key={i} className="image-wrap aspect-ratio">
            <div className="gatsby-image-wrapper">
              <img src={contest.imageURL} alt={contest.title} title={contest.title}/>
            </div>
          </div>
          <div className="content padd padd-left-half uppercase">
            <span className="type fs-85">{contest.type}</span>
            <h4 className="contest-title">{contest.title}</h4>
            <p>
              {`${day} ${month}/${monthDay}`} 
              <br/>
              Estimated Prize Value: {formatter.format(contest.prize)}
            </p>
            <a href={contest.url} className="btn regular btn arrow">
              <span>{contest.button}</span>
            </a>
          </div>
        </Intro>
      </div>
    )
  })


  	return (
		<div ref={io} className={`block-custom ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className="title padd padd-left-half">
        <h1>{heading}</h1>
      </div>
      <div className="flex-12">
        {contests}
      </div>
		</div>
  	)
}

export default Custom