import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Club  from '../../../Form/Axios/Club'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const {images: img, wysiwygs: wysiwyg} = props
	const [io, ioInView] = useInView({ triggerOnce: true })


  return (
		<div ref={io} className={`block-custom ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <h2>Sign up to<br/>receive updates!</h2>
			<Club />
		</div>
  )
}

export default Custom