import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { GatsbyImage } from 'gatsby-plugin-image'
import BlocksRouter from '../../Sections/BlocksRouter'

import Intro from '../../../animations/Intro'
import validatePdf from '../../../utils/validatePdf'

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

function Wysiwyg(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [isOpen, setIsOpen] = useState(false)
  const tooLong = props?.classes?.includes('read-more-target') ? true : false
  const _in = props.in ? props.in : { fade: 500 }

  const content = props.content

  const readMoreClick = () => {
    setIsOpen(old => !old)
  }

  // Rich text stuff
  const Bold = ({ children }) => <span className="bold">{children}</span>
  // This is weird one, trying to prevent printing empty <p> tags everywhere... Thanks, contentful (and Obama)
  const Text = ({ children }) => {
    return children.toString() !== 'false,' ? (
      <p>{children}</p>
    ) : false
  }

  const options = {
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, !!textSegment[0] ? textSegment : null];
      }, []);
    },
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {

        const target = node?.data?.target
        let linksto = target?.description?.search('linksto')
        // If it is lacking linkso phrase, act as normal
        if (target && (linksto === -1)) {
          return (
            <Intro visible={ioInView} in={_in} delayIn={0} mounted={true} stay={true}>
              <GatsbyImage image={target?.gatsbyImageData} title={target?.title} alt={target?.description}  />
            </Intro>
          )
        } else if (target?.description) {
          let link = target?.description?.replace('linksto: ', '')
          return (
            <Intro visible={ioInView} in={_in} delayIn={0} mounted={true} stay={true}>
              <a href={validatePdf(link)} target="_blank" rel='noreferrer noopener' className="asset-link nostrike">
                <GatsbyImage image={target?.gatsbyImageData} title={target?.title} alt={target?.description} />
              </a>
            </Intro>
          )
        } else {
          return false
        }

      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {

        const target = node?.data?.target

        // Button
        if (target?.__typename === 'ContentfulBlockButton' || target?.__typename === 'BlockButton') {
          const classes = target?.size
          const label = target?.label
          const link = target?.link
          // const size = target?.size

          return link && validURL(link) || (link && link.startsWith('#modal-1st')) ? (
            <a href={validatePdf(link)} alt={label} target="_blank" rel='noreferrer noopener' className={`btn ${classes}`}><span>{label}</span></a>
          ) : (
            <Link to={link} className={`btn ${classes}`} alt={label}><span>{label}</span></Link>
          )
          // Video
        } else if (target?.__typename === 'ContentfulBlockVideo') {
          return <BlocksRouter {...target} />
          // Custom
        } else if (target?.__typename === 'ContentfulBlockCustom') {
          return <BlocksRouter {...target} />
          // Image
        } else if (target?.__typename === 'ContentfulBlockImage') {
          return <BlocksRouter {...target} />
        } else {
          return target ? (
            // <pre>
            //   <code>{JSON.stringify(node, null, 2)}</code>
            // </pre>
            false // TO INVESTIGATE
          ) : false
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        const link = node.data.uri
        const label = node.content[0].value

        if (label === 'IFRAME') {
          return <span className="aspect-ratio wysiwyg-iframe"><iframe src={link} title={node?.content[0]?.value}></iframe></span>
        } else if ((link).includes("player.vimeo.com/video")) {
          return <span className="video-embed-wrapper"><iframe title="Video" src={link} frameBorder="0" allowFullScreen></iframe></span>
        } else if ((link).includes("youtube.com/embed")) {
          return <span className="video-embed-wrapper"><iframe title="Video" src={link} frameBorder="0" allowFullScreen></iframe></span>
        } else if (validURL(link) || link.startsWith('mailto:') || link.startsWith('tel:')) {
          return (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {label}
            </a>
          )
        } else {
          return <Link to={link} alt={label}>{label}</Link>
        }
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        // Disabling this one to remove potential problems
        return false
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        // Disabling this one to remove potential problems
        return false
      },
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {

        const target = node?.data?.target

        if (target?.__typename === 'ContentfulBlockButton' || target?.__typename === 'BlockButton') {
          const classes = target?.classes
          const label = target?.label
          const link = target?.link
          const size = target?.size

          return ( link && validURL(link) ) || ( link && link.startsWith('tel') ) || (link && link.startsWith('#modal-1st')) ? (
            <a href={validatePdf(link)} alt={label} target="_blank" rel='noreferrer noopener' className={`btn ${size} ${classes}`}><span>{label}</span></a>
          ) : (
            <Link to={link} className={`btn ${size} ${classes}`} alt={label}><span>{label}</span></Link>
          )
        } else {
          return target ? (
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
          ) : false
        }
      },
    },
  }

  return content ? (
    <div ref={io} className={`block-wysiwyg ${props.classes}`}>
      <Intro visible={ioInView} in={_in} delayIn={200} mounted={true} stay={true}>
        <div className={`wrap ${isOpen ? 'open' : ''} `}>
          {content.jsx ? content.jsx : null}
          {content.raw ? renderRichText(content, options) : null}
          {content.json ? documentToReactComponents(content.json, options) : null}
          {!content.raw && !content.json && !content.jsx ?
            <div className={`wysiwyg`} dangerouslySetInnerHTML={{ __html: content }} />
            : null}

        </div>
        {tooLong ?
          <div onClick={() => readMoreClick()} onKeyDown={() => readMoreClick()} className='read-more-button' role="button" aria-label="Read more" tabIndex={0}>
            <p>{isOpen ? 'LESS' : '...READ MORE'}</p>
          </div>
          :
          null}
      </Intro>
    </div>
  ) : false
}

export default Wysiwyg