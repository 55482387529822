import React, { useState, useCallback, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'
import use1stAPI from '../../../../Api'
import Calendar from '../../../Calendar'
import Results from '../ResultsReplaysWorkouts/Results'
import Replays from '../ResultsReplaysWorkouts/Replays'
import Changes from '../ResultsReplaysWorkouts/Changes'
import StewardsReview from '../ResultsReplaysWorkouts/StewardsReview'
import Entries from '../ResultsReplaysWorkouts/Entries'
import RaceDetailsBox from '../ResultsReplaysWorkouts/RaceDetailsBox'
//import TrackModel from '../../../TrackModel'
// import Workouts from '../ResultsReplaysWorkouts/Workouts'
import Carryovers from '../ResultsReplaysWorkouts/Carryovers'
import Stakes from '../ResultsReplaysWorkouts/Stakes'
import Odds from '../ResultsReplaysWorkouts/Odds'
import Probables from '../ResultsReplaysWorkouts/Probables'
import Pools from '../ResultsReplaysWorkouts/Pools'
import WillPays from '../ResultsReplaysWorkouts/WillPays'

import { createPortal } from 'react-dom'
import { useQuery, gql } from '@apollo/client'

import { useAuth0 } from '../../../../utils/auth'
// import { Link } from 'gatsby'

// // Function to add or substract months in dates
// function addMonths(date, months) {
// 	var d = date.getDate()
// 	date.setMonth(date.getMonth() + +months)
// 	if (date.getDate() !== d) {
// 		date.setDate(0)
// 	}
// 	return date
// }

function Custom(props) {

  // Setting up current day
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth() + 1
  const todayMonthLeadingZero = todayMonth < 10 ? '0' + todayMonth : '' + todayMonth
  const todayDay = todayDate.getDate()
  const todayDayLeadingZero = todayDay < 10 ? '0' + todayDay : '' + todayDay
  const todayRaceDate = `${todayYear}/${todayMonth}/${todayDay}`
  const queryToday = `${todayYear}-${todayMonthLeadingZero}-${todayDayLeadingZero}`
  const entries = [
    {
      "date": todayRaceDate
    }
  ]
  const results = [
    {
      "date": todayRaceDate
    }
  ]
  // const replays = [
  // 	{
  // 		"date": todayRaceDate
  // 	}
  // ]
  const changes = [
    {
      "date": todayRaceDate
    }
  ]

  const [currentRace, setCurrentRace] = useState({
    race_date: queryToday,
    race_number: null,
    track_code: 'GP',
    event_code: 'GPM',
  })

  // States
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [filteredBatch, setFilteredBatch] = useState({ type: 'entries', batch: entries })
  const [currentTab, setCurrentTab] = useState(0)
  const [distance, setDistance] = useState()
  const [openTab, setOpenTab] = useState()
  const { isAuthenticated } = useAuth0()
  const { data: nextUp, loading: nextUpLoading } = use1stAPI('currentRace', currentRace)

  useEffect(() => {
    if (nextUp?.event?.[0].current_race && nextUp?.event?.[0].current_race > 0)
      setCurrentTab(nextUp?.event?.[0].current_race - 1)
  }, [nextUp?.event?.[0].current_race])


  const [calendarSelector, setCalendarSelector] = useState({ dateSelected: null, currentViewStart: null, currentViewEnd: null })

  // Using callback to avoid rerenders on useEffects for those functions
  const calendarSelectorFunction = useCallback((i) => {
    setCalendarSelector(i)
  }, [])

  function handleOpenTabClick(type) {
    setOpenTab(type)
  }

  function handleFilterClick(e, type, batch) {
    e.preventDefault()

    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      const mimic = document.getElementById('master-header-mimic')
      window.scrollTo({
        top: window.pageYOffset - mimic?.offsetHeight + document.querySelector('#racing-info-details')?.getBoundingClientRect().top,
        behavior: 'smooth'
      })
    }
    // Reset to first tab if it's mismatch
    // if(currentTab !== 0) {
    // 	setCurrentTab(0)
    // }
    setFilteredBatch(() => ({ type: type, batch: batch }))
    document.querySelectorAll('.filter-trigger')?.forEach(e => e.classList.remove('current'))
    document.querySelectorAll('.' + e.currentTarget.className.replace(/ /g, "."))?.forEach(e => e.classList.add('current'))
    //e.currentTarget.classList.add('current')
    //setCalendarSelector((old) => ({...old, dateSelected: null}))
  }

  useEffect(() => {
    const header = document?.getElementById('master-header-mimic')
    const sticky = document?.getElementById('sticky-racing-info')
    if (sticky) {
      sticky.style.top = header.offsetHeight - 11 + 'px'
    }
  }, [ioInView])

  const selectedDate = new Date(calendarSelector.dateSelected)

  // Apollo, Pull Events!
  const { loading, error, data: apollo, refetch } = useQuery(APOLLO_QUERY, {
    variables: { // variables for query use
      date_gte: queryToday + 'T00:00:00.000Z',
      date_lte: queryToday + 'T23:59:00.000Z'
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore'
  })

  // console.log('currentTab',currentTab)
  // The content of selected date obj
  let program = null
  let programAbbreviated = null
  let racesToControl = null
  let owners = null
  let breeders = null
  let surface = apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.racesSurface?.data ? apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection.items[0].racesSurface.data : null
  let runners = apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.entriesApi?.data ? apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0].entriesApi?.data : null
  let files = apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.entriespdf ? apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.entriespdf : null
  let isRaceCanceled = apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.isRaceCanceled ? apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.isRaceCanceled : null
  let reviews = apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.stewardsReviewCollection?.items ? apollo?.eventsCurrentView?.items?.[0]?.raceEventsCollection?.items?.[0]?.stewardsReviewCollection?.items : null
  
  const displayedContent = filteredBatch?.batch?.filter(function (node) {

    const givenDaySimplified = new Date(node.date).setHours(0, 0, 0, 0) // removed .replace(/-/g, "/")
    const selectedDaySimplified = new Date(calendarSelector.dateSelected).setHours(0, 0, 0, 0)

    if (givenDaySimplified === selectedDaySimplified) {
      return true
    }
    return false

  }).map((node, i) => {
    if (filteredBatch.type === 'results') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders
      return (
        <>
          <Results key={`res-${i}`} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} results={node.races} replays={false} />
          <Replays key={`rep-${i}`} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} content={node.races} />
        </>
      )
    } else if (filteredBatch.type === 'stakes') {

      return <Stakes key={i} />

    } else if (filteredBatch.type === 'entries') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders
      return (
        <Entries
          key={i}
          data={{ surface: surface, files: files }}
          switchFlag={calendarSelector?.dateSelected + filteredBatch?.type}
          selectedDate={selectedDate}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          distance={distance}
          setDistance={setDistance}
        />
      )
    } else if (filteredBatch.type === 'replays') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders
      return (
        <Replays key={i} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} content={node.races} />
      )
    } else if (filteredBatch.type === 'carryovers') {

      return <Carryovers key={i} />

    } else if (filteredBatch.type === 'scratches & changes') {
      racesToControl = null
      return (
        <Changes data={{ runners: runners }} key={i} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} />
      )
      // } else if (filteredBatch.type === 'workouts') {
      // 	program = node.programWorkouts
      // 	programAbbreviated = node.programAbbreviatedWorkouts
      // 	racesToControl = node.workouts
      // 	owners = node.workouts?.[currentTab].owners
      // 	breeders = node.workouts?.[currentTab].breeders
      // 	return (
      // 		<Workouts key={i} currentTab={currentTab} setCurrentTab={setCurrentTab} workouts={node.workouts} distance={distance} setDistance={setDistance} />
      // 	)
    } else if (filteredBatch.type === 'stewards review') {
      racesToControl = null
      return (
        <StewardsReview key={i} data={{ reviews: reviews }} switchFlag={calendarSelector?.dateSelected} selectedDate={selectedDate} />
      )
    } else if (filteredBatch.type === 'odds win/place/show' || filteredBatch.type === 'other pools') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders

      return (
        <Odds key={i} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} entries={node.races} type={filteredBatch.type} />
      )

    } else if (filteredBatch.type === 'probables win/place/show' || filteredBatch.type === 'probables double' || filteredBatch.type === 'probables exacta' || filteredBatch.type === 'probables quinella') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders

      return (
        <Probables key={i} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} entries={node.races} type={filteredBatch.type} />
      )

      // } else if (filteredBatch.type === 'pools win/place/show' || filteredBatch.type === 'pools double' || filteredBatch.type === 'pools exacta' || filteredBatch.type === 'pools quinella') {
      // 	program = node.program
      // 	programAbbreviated = node.programAbbreviated
      // 	racesToControl = node.races
      // 	owners = node.races?.[currentTab].owners
      // 	breeders = node.races?.[currentTab].breeders

      // 	return (
      // 		<Pools key={i} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} entries={node.races}	type={filteredBatch.type} />
      // 	)

    } else if (filteredBatch.type === 'will pays') {
      program = node.program
      programAbbreviated = node.programAbbreviated
      racesToControl = node.races
      owners = node.races?.[currentTab].owners
      breeders = node.races?.[currentTab].breeders

      return (
        <WillPays key={i} switchFlag={calendarSelector?.dateSelected + filteredBatch?.type} selectedDate={selectedDate} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} entries={node.races} type={filteredBatch.type} />
      )

    } else {
      return <h3>No filterer picked</h3>
    }
  })


  // Portalll

  const portal = typeof document !== "undefined" ? document.getElementById('portal-tabs-anchors') : null
  const portalling = portal ? createPortal(
    <>
      {props.classes !== 'only-workouts' ?
        <div className="filter-wrap text-left">
          <div className="has-intro btn filter-trigger f-t-stakes" onClick={(e) => handleFilterClick(e, 'stakes', entries)} onKeyDown={(e) => handleFilterClick(e, 'stakes', entries)}>
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} className="c5 t border-c0 border-bottom-off border-left-c5">
              <span>Stakes</span>
            </Intro>
          </div>
          <div className="has-intro btn filter-trigger f-t-entries current" onClick={(e) => handleFilterClick(e, 'entries', entries)} onKeyDown={(e) => handleFilterClick(e, 'entries', entries)}>
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="c5 t border-c0 border-bottom-off border-left-c5">
              <span>Entries</span>
            </Intro>
          </div>
          <div className="has-intro btn filter-trigger f-t-results" onClick={(e) => handleFilterClick(e, 'results', results)} onKeyDown={(e) => handleFilterClick(e, 'results', results)}>
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className="c5 border-c0 t border-bottom-off border-left-c5">
              <span>Results &amp; Replays</span>
            </Intro>
          </div>
          <div className="has-intro btn filter-trigger f-t-carryovers-guarantees" onClick={(e) => handleFilterClick(e, 'carryovers', entries)} onKeyDown={(e) => handleFilterClick(e, 'carryovers', entries)}>
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={500} className="c5 border-c0 t border-bottom-off border-left-c5">
              <span>Carryovers &amp; Guarantees</span>
            </Intro>
          </div>
          {/* <div className="has-intro btn filter-trigger f-t-scratches-changes" onClick={(e) => handleFilterClick(e,'scratches & changes',changes)}>
					<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={700} className="c5 border-c0 t border-bottom-off border-left-c5">
						<span>Scratches &amp; Changes</span>
					</Intro>
				</div> */}
        </div>
        :
        null
      }
    </>
    ,
    portal
  ) : null

  return isAuthenticated ? (
    <div ref={io} className={`block-custom block-results-replays-component calendar-compact flex-12 ai-flex-start ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

      <div className="span-12 padd padd-left-off padd-right-off padd-bottom-off c0">

        <div className="flex-12">


          <div id="racing-info-details" className={`span-8 racing-info-details flex-12 ac-flex-start`}>

            <div className="span-12 flex-12 v-center">

              {/* { distance ?
							<div className="span-6 padd-half padd-top-off as-center">
								<Intro switch={calendarSelector?.dateSelected && filteredBatch?.type} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="">
									<div  id="portal-track-model" />
									<TrackModel raceNo={currentTab + 1} distance={distance} />
								</Intro>
							</div>
							: null } */}
              <Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} className="racing-results-wrap">
                <div className="calendar-selection-content flex-12">
                  <div className="span-12-tablet padd-half padd-top-off padd-bottom-off next-1-margin--1">
                  <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={200} className="padd-half c0 children-flex">
                      <div className="results-heading-wrap">
                        <h3 className="results-heading">{filteredBatch.type}</h3>
                          <div className='legend'>
                            <div className='square-container'> 
                            <span><span className='black square'/> - Live racing </span>
                            <span><span className='grey square' /> - Simulcast Racing</span>
                          </div>
                        </div>
                      </div>
                      <div id='entries-pdf-portal'></div>
                    </Intro>
                  </div>
                  {isRaceCanceled && (filteredBatch.type !== 'stakes' && filteredBatch.type !== 'carryovers') ? 
                    <>
                      <div className='padd-half'>
                        <h4 style={{color: "#f44336"}}>This race day has been cancelled</h4>
                      </div>
                      <div className='canceled'>
                        {displayedContent} 
                      </div>
                    </>
                  :
                  displayedContent
                  }
                </div>
              </Intro>

            </div>

          </div>


          {portalling}
          <div className="span-4 span-12-mobile racing-info-selector flex-12 c1 fs-85 padd-top-half ac-flex-start">

            <div id="sticky-racing-info" className="position-sticky flex-12">

              <div id="portal-race-details" className="span-12 padd-half padd-top-off padd-bottom-off">
                <RaceDetailsBox type="long" />
              </div>

              {props.classes !== 'only-workouts' ?
                <div className="span-12 filter-wrap text-left ai-flex-start ac-flex-start jc-flex-start padd-half padd-top-off">
                  <div className="has-intro btn filter-trigger f-t-stakes" onClick={(e) => { handleFilterClick(e, 'stakes', entries); handleOpenTabClick('') }} onKeyDown={(e) => { handleFilterClick(e, 'stakes', entries); handleOpenTabClick('') }}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} className="c5 t border-c0 border-top-off border-right-c5 border-left-c5">
                      <span>Stakes</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger f-t-entries current" onClick={(e) => { handleFilterClick(e, 'entries', entries); handleOpenTabClick('') }} onKeyDown={(e) => { handleFilterClick(e, 'entries', entries); handleOpenTabClick('') }}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="c5 t border-c0 border-top-off border-right-c5 border-left-c5">
                      <span>Entries</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger f-t-results" onClick={(e) => { handleFilterClick(e, 'results', results); handleOpenTabClick('') }} onKeyDown={(e) => { handleFilterClick(e, 'results', results); handleOpenTabClick('') }}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                      <span>Results &amp; Replays</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger f-t-carryovers-guarantees" onClick={(e) => { handleFilterClick(e, 'carryovers', entries); handleOpenTabClick('') }} onKeyDown={(e) => { handleFilterClick(e, 'carryovers', entries); handleOpenTabClick('') }}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={500} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                      <span>Carryovers &amp; Guarantees</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger f-t-scratches-changes" onClick={(e) => { handleFilterClick(e, 'scratches & changes', changes); handleOpenTabClick('') }} onKeyDown={(e) => { handleFilterClick(e, 'scratches & changes', changes); handleOpenTabClick('') }}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={600} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                      <span>Scratches &amp; Changes</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger f-t-stewards-review" onClick={(e) => handleFilterClick(e, 'stewards review', entries)}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={700} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                      <span>Stewards Review</span>
                    </Intro>
                  </div>
                  <div className="has-intro btn filter-trigger f-t-odds" onClick={(e) => handleOpenTabClick('odds')}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={800} className="c5 t border-c0 border-top-off border-left-c5">
                      <span>Odds &amp; Pools</span>
                    </Intro>
                  </div>
                  {openTab === 'odds' ?
                    <div className="sub-items c5 flex" >
                      <div className="has-intro btn filter-trigger f-t-odds-win-place-show" onClick={(e) => handleFilterClick(e, 'odds win/place/show', entries)}>
                        <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100} className="c5 t border-c0 border-bottom-off border-left-c5">
                          <span>WIN / PLACE / SHOW</span>
                        </Intro>
                      </div>
                      <div className="has-intro btn filter-trigger f-t-odds-other-pools" onClick={(e) => handleFilterClick(e, 'other pools', entries)}>
                        <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 2} className="c5 t border-c0 border-bottom-off border-left-c5">
                          <span>Other Pools</span>
                        </Intro>
                      </div>
                    </div>
                    : null}
                  <div className="has-intro btn filter-trigger f-t-probables" onClick={(e) => handleOpenTabClick('probables')}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={900} className="c5 border-c0 t border-top-off border-left-c5">
                      <span>Probables</span>
                    </Intro>
                  </div>
                  {openTab === 'probables' ?
                    <div className="sub-items c5 flex" >
                      <div className="has-intro btn filter-trigger f-t-probables-win-place-show" onClick={(e) => handleFilterClick(e, 'probables win/place/show', entries)}>
                        <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100} className="c5 t border-c0 border-bottom-off border-left-c5">
                          <span>WIN / PLACE / SHOW</span>
                        </Intro>
                      </div>
                      <div className="has-intro btn filter-trigger f-t-probables-double" onClick={(e) => handleFilterClick(e, 'probables double', entries)}>
                        <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 2} className="c5 t border-c0 border-bottom-off border-left-c5">
                          <span>DOUBLE</span>
                        </Intro>
                      </div>
                      <div className="has-intro btn filter-trigger f-t-probables-exacta" onClick={(e) => handleFilterClick(e, 'probables exacta', entries)}>
                        <Intro visible={ioInView} in={{ border: 250, bg: 250, fade: 250 }} delayIn={100 * 3} className="c5 t border-c0 border-bottom-off border-left-c5">
                          <span>EXACTA</span>
                        </Intro>
                      </div>
                      {/* <div className="has-intro btn filter-trigger f-t-probables-quinella" onClick={(e) => handleFilterClick(e,'probables quinella',entries)}>
											<Intro visible={ioInView } in={{ border: 250,bg: 250, fade: 250 }} delayIn={100 * 4} className="c5 t border-c0 border-bottom-off border-left-c5">
												<span>QUINELLA</span>
											</Intro>
										</div> */}
                    </div>
                    : null}
                  <div className="has-intro btn filter-trigger f-t-will-pays" onClick={(e) => { handleFilterClick(e, 'will pays', changes); handleOpenTabClick('') }} onKeyDown={(e) => { handleFilterClick(e, 'will pay', changes); handleOpenTabClick('') }}>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={1000} className="c5 border-c0 t border-top-off border-right-c5 border-left-c5">
                      <span>Will Pay</span>
                    </Intro>
                  </div>
                </div>
                :
                null
              }

              <div className={`${props.classes === 'only-workouts' ? 'span-12 ' : 'padd-left-off span-6'} padd-top-off as-flex-start`}>
                <Intro switch={filteredBatch.type} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="c3 t border-top-off margin-left--1">
                  <Calendar
                    handleSelectionClick={() => false}
                    filterCalendar={() => false}
                    selector={calendarSelectorFunction}
                    minDate={null}
                    maxDate={null}
                    events={filteredBatch?.batch}
                    allowPast={filteredBatch.type === 'entries' || filteredBatch.type === 'scratches & changes' ? false : true}
                    ui={'zero'}
                    classes={''}
                  />
                </Intro>
              </div>

              {owners || breeders ?
                <div className="span-12 padd-half">
                  {owners ? <p>Owners: {owners}</p> : null}
                  {breeders ? <p>Breeders: {breeders}</p> : null}
                </div>
                : null}
              {/* <a href="#modal-1st-track-trends" className="btn wide small-mrg w-100 padd-left-half padd-right-half"><span>Track Trends</span></a> */}
              <a href="https://www.xbtv.com/video-on-demand/gulfstream/" target="_blank" rel='noreferrer noopener' className="btn wide small-mrg w-100 padd-left-half padd-right-half"><span>Watch Workouts</span></a>
              <a href="#modal-1st-track-trends" className="btn wide small-mrg w-100 padd-left-half padd-right-half"><span>Track Trends</span></a>
              <a href="https://1st.com/bet/gulfstream-park?utm_source=gsp&utm_medium=track_referral&utm_campaign=GSP_Site_Other" target="_blank" rel='noreferrer noopener' className="btn wide w-100 padd-left-half padd-right-half"><span>Bet Now</span></a>

            </div>

          </div>


        </div>

      </div>

    </div>
  ) : false
}

export default Custom

// Query for rehydration, currently just test
const APOLLO_QUERY = gql`
  query eventsQuery($date_gte: DateTime, $date_lte: DateTime) {
    eventsCurrentView: eventDayCollection (limit: 1, where: {raceEventsCollection_exists: true, date_gte: $date_gte, date_lte: $date_lte}, order: date_ASC) {
      items {
				date
        raceEventsCollection (limit: 1) {
					items {
						racesSurface
						entriesApi
            isRaceCanceled
            stewardsReviewCollection {
              items {
                title
                heading
                content {
                  json
                }
              }
            }
            entriespdf {
								title
								url
						}
					}
				}
			}
    }
  }
`