import React from 'react'
import './style.scss'

import BlocksRouter from '../../Sections/BlocksRouter'
import { Link } from 'gatsby'
import validatePdf from '../../../utils/validatePdf'

function Wrapper(props) {


	function validURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
		return !!pattern.test(str);
	}

	if(props.link && validURL(props.link)) {
		return (
			<a href={validatePdf(props.link)} target="_blank" rel="noreferrer noopener" className={`block-wrapper ${props.classes}`}>
				{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
				<BlocksRouter {...props} />
			</a>
		)
	} else if (props.link) {
		return (
			<Link to={props.link} className={`block-wrapper ${props.classes}`}>
				{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
				<BlocksRouter {...props} />
			</Link>
		)
	}

  return <div className={`block-wrapper ${props.classes}`}>
		{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
		<BlocksRouter {...props} />
	</div>
}

export default Wrapper