import React from 'react'
import './style.scss'
// import { useInView } from 'react-intersection-observer'

import Block from '../../../Sections/BlocksRouter'
import Picks from '../../../../components/Blocks/Picks'

import { useStaticQuery, graphql } from "gatsby"

function Custom(props) {
	// NEED TO REVAMP THIS WHOLE BLOCK AND PICKS IN GENERAL, CURRENT SETUP IS TOO CUMBERSOME FOR BACKEND EDITORS
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const {wysiwygs: wysiwyg} = props
	// const [io, ioInView] = useInView({ triggerOnce: true })


  const data = useStaticQuery(graphql`query FeaturedExpert {
  allContentfulPerson(
    limit: 40
    sort: {fields: createdAt}
    filter: {type: {eq: "Expert"}}
  ) {
    nodes {
      name
      title
      featured
      type
      story {
        raw
      }
      bio {
        raw
      }
      image {
        gatsbyImageData(
          quality: 70
          layout: CONSTRAINED
          breakpoints: [300, 500, 750, 1080, 1366, 1920]
          formats: WEBP
        )
        title
        description
      }
      handicappingPicks {
        data
      }
    }
  }
}`)


	// Function to add or substract months in dates
	function addMonths(date, months) {
		var d = date.getDate()
		date.setMonth(date.getMonth() + +months)
		if (date.getDate() !== d) {
			date.setDate(0)
		}
		return date
	}

	// Setting up 2 months ahead and 1 back
	const todayDate = new Date()
	const todayYear = todayDate.getFullYear()
	const todayMonth = todayDate.getMonth()
	const pastDateRange = addMonths(new Date(todayYear,todayMonth,2),-1).toISOString()
	const futureDateRange = addMonths(new Date(todayYear,todayMonth,1),3).toISOString()

	const name = props?.directField ? props.directField : "Alfredo Alfonzo"

	const single = data.allContentfulPerson.nodes.filter((item) => item.name === name )
	const person = single ? single[0] : null

	const story = {
		__typename: 'ContentfulBlockWrapper',
		classes: 'bio padd',
		blocks: [
			{
				__typename: 'ContentfulBlockWrapper',
				classes: 'intro',
				blocks: [
					{
						__typename: 'ContentfulBlockImage',
						classes: 'aspect-ratio cover',
						image: person?.image,
					},
					{
						__typename: 'ContentfulBlockWysiwyg',
						classes: 'text-left',
						content: `<h4>${person?.name}</h4>`,
					},
					{
						__typename: 'ContentfulBlockWysiwyg',
						classes: 'hr',
						content: `<hr/>`,
					},
				],
			},
			{
				__typename: 'ContentfulBlockWysiwyg',
				classes: 'text-left',
				content: person?.story,
			},
		],
	}

  return (
		<div className={`block-custom ${props.classes} flex-12`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			<div className="handicapping-col1 span-7 span-12-mobile c3 lighten flex-12">
				{wysiwyg ? <Block blocks={[{...wysiwyg[0], classes: 'padd'}]} /> : null}
				<div className="span-12 flex">
					<Picks 
						picks={person?.handicappingPicks}
						pastDateRange={pastDateRange}
						futureDateRange={futureDateRange}
						classes='flex lighten'
					/>
				</div>
			</div>
			<div className="handicapping-col2 span-5 span-12-mobile c1 padd story-limit-height">
				<Block {...story} />
			</div>
		</div>
  )
}

export default Custom