import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<div ref={io} className={`block-custom flex-12 c5 ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			<Intro visible={ioInView} in={{ fade: 500 }} delayIn={ 500 } className="span-12">
				<div className="aspect-ratio">
					<iframe src="https://video.nest.com/embedded/live/xZ9ZEiccOf?autoplay=1" title="tv" width="480" height="394" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
				</div>
			</Intro>
		</div>
  )
}

export default Custom