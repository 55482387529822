import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import Slider from 'react-slick'
import Wysiwyg from '../../Wysiwyg'

function Custom(props) {
  //const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [currentTab, setCurrentTab] = useState(0)


  const data = props?.custom?.data

  // On Parent Tab Change
  function onTabChange(i) {
    setCurrentTab(i)
  }

  const SliderArrowNext = ({ to, onClick }) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-next-positiner hover-trigger`} aria-label={to}>
        <div className="icon"></div>
      </div>
    )
  }

  const SliderArrowPrev = ({ to, onClick }) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-prev-positiner hover-trigger`} aria-label={to}>
        <div className="icon"></div>
      </div>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    nextArrow: <SliderArrowNext to="next" />,
    prevArrow: <SliderArrowPrev to="prev" />,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          beforeChange: (current, next) => setCurrentTab(next),
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  // Tabs
  const TabPack = data?.map((tab, i) => {
    return (
      <Intro key={i} visible={ioInView} in={{ bg: 500, fade: 500 }} delayIn={100 * i} className="">
        <div className={`image-wrap tab-handle aspect-ratio ${i === currentTab ? 'current' : ''}`} key={i} onClick={() => onTabChange(i)} onKeyDown={() => onTabChange(i)} role="button" aria-label="Tab handle" tabIndex={0}>
          <div className="gatsby-image-wrapper">
            <img src={tab.image} title="Partner Logo" alt="Partner Logo" />
          </div>
        </div>
      </Intro>
    )
  })

  // TabsContent
  const TabContentPack = data?.map((tab, i) => {
    if (i === currentTab)
      return (
        <Wysiwyg key={i} content={tab.content}></Wysiwyg>
      )
  })


  return (
    <div ref={io} className={`block-custom w-100  ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className="tab-pack">
        <div className="tab-handles-pack flex-12">
          <Slider {...settings}>
            {TabPack}
          </Slider>
        </div>
        <Intro switch={ioInView || currentTab} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
          {TabContentPack}
        </Intro>
      </div>
    </div>
  )
}

export default Custom