import React, {useEffect, useState, useContext} from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'

import GoogleMapReact from 'google-map-react'

import mapMarker from './marker.png' 
import mapMarkerFilled from './marker-filled.png' 
import NaviContext from '../../../../context/NaviContext'
import { Link, navigate } from 'gatsby'
import SinglePlace from './SinglePlace'
import close from './SinglePlace/close.png'
import { useLocation } from '@reach/router'

function Custom(props) {
  
	const [io, ioInView] = useInView({ triggerOnce: true })

  const [ currentHover, setCurrentHover ] = useState()
  const [ currentClicked, setCurrentClicked ] = useState()
  const [ currentSelected, setCurrentSelected ] = useState()

  const naviContext = useContext(NaviContext)
  const location = useLocation()


  const closebtn = 
    <Link to={location.pathname.endsWith('/') ? location.pathname : location.pathname+'/'} id="specialclose" className='nostrike'>
      <img src={close} title="Close Button" alt="Close Button" />
    </Link>
  
  useEffect(() => {
    setCurrentSelected(naviContext?.locationState?.hash)
  },[naviContext?.locationState?.hash])
  
	const gMapKey = process.env.GATSBY_GOOGLE_API_KEY

	const defaultProps = {
		center: {
			lat: 25.97812938413917,
			lng: -80.13966106893585,
		},
		zoom: 17,
	}

  const groupsEventsFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('groups-events'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const barsAndLounges = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('bars-and-lounges'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const fineDiningFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('fine-dining'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const casualDiningFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('casual-dining'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const quickBitesFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('quick-bites'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const tracksideFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('trackside'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const fashionFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('fashion'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const specialtyFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('specialty'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const artGalleryFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('art-gallery'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const homeFurnishingsHousewaresFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('home-furnishings-housewares'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const healthBeautyFiltred = props.custom.data.filter( (item, i) => {
    if(item.directory.includes('health-beauty'))
      return item
    return false
  }).map((item,i) => {
    return(
      <div key={i} className='link-container flex span-12'>
        <div className="icon icon-arrow fs-85"/>
        <Link to={'#'+item.slug} id={'link-' + item.slug}>{item.name}</Link>
      </div>
    ) 
  })

  const restaurants = props.custom?.data.map((item, i) => {
    return (
      <Intro key={`restaurants-${i}`} visible={ioInView} in={{ fade: 500 }} delayIn={100 * i} mounted={true} stay={true} className={`span-6 ${currentSelected ? 'hidden' : ''}`}>
        <Link to={`#${item.slug}`} className={`${currentHover === i ? 'hovered' : ''} ${currentClicked === i ? 'clicked' : ''} grid-item nostrike`}>
          <div className="block-img">
            <div className="image-wrap bg">
              <img src={item.image} title={item.name} alt={item.name}/>
            </div>
          </div>
          <div className='navi-link nostrike hover-trigger content-box'>
            <div className='c5 content'>
              <div className='heading fs-85'>
                <h5 className='margin-off'>{item.name}</h5>
                <p className='uppercase margin-off fs-85'>{item.markup}</p>
              </div>
              <div className="icon icon-arrow fs-85"></div>
            </div>
          </div>
        </Link>
      </Intro>
    )
  })

  const singleRestaurant = props.custom?.data.map((item, i) => {
    if(currentSelected === '#'+item.slug)
      return item
    return false
  }).filter(item => item)[0]

  // Compute shadow padding of navi
	useEffect(() => {
		const mimic = document.getElementById('master-header-mimic')
		const map = document.getElementById('sticky-map')
		if(mimic && map) {
			map.style.top = mimic.style.height
      map.style.height = `calc(100vh - ${mimic.style.height} )`
    }
    if(currentSelected)
      mimic.classList.add('t')
    else 
      mimic.classList.remove('t')
	},[currentSelected])

  const addMarker = (google) => {
  
    const gridItems = document.getElementsByClassName('grid-item')
  
    const scaledSize = new google.maps.Size(20, 20)
    const infowindow = new google.maps.InfoWindow()
    const infotitle = new google.maps.InfoWindow({disableAutoPan: true})

    props.custom?.data?.forEach((item,i) => {
      
      let clickFlag = false
      const titleString = `<div class="infobox"><h6>${item.name}</h6></div`
      const markupString = `<div class="info-container"><div class="block-img"><div class="image-wrap aspect-ratio"><img alt="${item.name}" src="${item.image}"/></div></div><div class='infobox'><h5 class="margin-off">${item.name}</h5><div class="flex fs-85 jc-space-between"><h6 class="margin-off">${item.markup}</h6><h6 class="margin-off">${item.price ? item.price : ''}</h6></div></div></div>`
      const icon = {
        url: mapMarker,
        scaledSize: scaledSize
      }

      const iconFilled = {
        url: mapMarkerFilled,
        scaledSize: scaledSize
      }

      const marker = new google.maps.Marker({
        position: { lat: item.lat, lng: item.lng },
        map: google.map,
        icon: icon
      })

      const specialclose = document.getElementById(`specialclose`)
      specialclose?.addEventListener('click', function() {
        infotitle.close() // Close previously opened infotitle
        infowindow.close() // Close previously opened infowindow
        setCurrentHover(null)
        marker.setIcon(icon)
      })

      const locationLink = document.getElementById(`link-${item.slug}`)
      locationLink?.addEventListener('click', function() {
        infowindow.currentlyOpen = i
        setCurrentClicked(i)
        infotitle.close()
        infowindow.close()
        infowindow.setContent(markupString)
        infowindow.open(marker.getMap(), marker)   
        marker.setIcon(iconFilled)
        clickFlag = true
      })

      if('#'+item.slug === currentSelected) {
        infowindow.currentlyOpen = i
        setCurrentClicked(i)
        infotitle.close()
        infowindow.close()
        infowindow.setContent(markupString)
        infowindow.open(marker.getMap(), marker)   
        marker.setIcon(iconFilled)
        clickFlag = true
      }

      // When clicking item boxes
      gridItems[i]?.addEventListener('click', function() {
        infowindow.currentlyOpen = i
        setCurrentClicked(i)
        infotitle.close()
        infowindow.close()
        infowindow.setContent(markupString)
        infowindow.open(marker.getMap(), marker)   
        marker.setIcon(iconFilled)
        clickFlag = true
      })

      // When hovering item boxes
      gridItems[i]?.addEventListener('mouseover', function() {
        infotitle.close() // Close previously opened infotitle
        infowindow.close() // Close previously opened infowindow
        infotitle.setContent(titleString)
        infotitle.open(google.map, marker)
        setCurrentHover(i)
        marker.setIcon(iconFilled)
      })

      // When hovering item boxes
      gridItems[i]?.addEventListener('mouseout', function() {
        if(!clickFlag) {
          infotitle.close() // Close previously opened infotitle
          infowindow.close() // Close previously opened infowindow
          setCurrentHover(null)
          marker.setIcon(icon)
        } else {
          clickFlag = false
        }
			})

      // When clicking markers
      marker.addListener("click", () => {
        infowindow.currentlyOpen = i
        setCurrentClicked(i)
        infotitle.close()
        infowindow.close()
        infowindow.setContent(markupString)
        infowindow.open(marker.getMap(), marker)   
        marker.setIcon(iconFilled)
        gridItems[i]?.scrollIntoView({behavior: "smooth", block: "center"})
        if(currentSelected)
          navigate('#'+item.slug)
        
      })

      // When hovering markers
      marker.addListener("mouseover", () => {
        infotitle.close()
        if(infowindow.currentlyOpen !== i) {
          infotitle.setContent(titleString)
          infotitle.open(marker.getMap(), marker)
        }
        marker.setIcon(iconFilled)
        setCurrentHover(i)
      })

      // When hovering markers
      marker.addListener('mouseout', () => {
        infotitle.close()
        setCurrentHover(null)
        marker.setIcon(icon)
      })

      // When defocusing marker
      google.map.addListener('click', () => {
        infowindow.close() // Close previously opened infowindow
        infowindow.currentlyOpen = null
        infotitle.close() // Close previously opened infotitle
        marker.setIcon(icon)
        setCurrentClicked(null)
        setCurrentHover(null)
      })

      // marker.setMap(google.map)
    })
  }

  return (
    <>
		<div ref={io} className={`block-custom ${!currentSelected ? 'c5' : ''} flex-12 custom-map-with-markers w-100 ${props.classes}`}>
      <Intro visible={ioInView} in={{ fade: 500 }} delayIn={0} mounted={true} stay={true} className={`c5 border-c5 ${currentSelected ? 'hidden' : ''}`}>
       {/* {location.pathname.includes('shopping') ?
        <div className='padd-left-2'>
          <span>Closed Easter Sunday. Individual store hours may vary.</span>
        </div>
       :
       null
       } */}
        <div className='span-12 directory-container flex c5 padd padd-left-off'>
          <div className='heading directory-group ac-flex-start'>
            <h2>Directory</h2>
          </div>
          { fineDiningFiltred.length ? 
            <div className=' directory-group ac-flex-start'>
              <h4>Fine Dining</h4>
              {fineDiningFiltred}
            </div>
          : 
            null
          }

          {casualDiningFiltred.length ? 
            <div className='directory-group ac-flex-start'>
              <h4>Casual Dining</h4>
              {casualDiningFiltred}
            </div>
            :
            null
          }

          {quickBitesFiltred.length ?
            <div className='directory-group ac-flex-start'>
              <h4>Quick Bites</h4>
              {quickBitesFiltred}
            </div>
            :
            null
          }

          {tracksideFiltred.length ?
           <div className=' directory-group ac-flex-start'>
              <h4>Trackside</h4>
              {tracksideFiltred}
            </div>
            :
            null
          }
          {barsAndLounges.length ?
            <div className=' directory-group ac-flex-start'>
              <h4>Bars and Lounges</h4>
              {barsAndLounges}
              {/* <h4>Brunch</h4>
              {brunchFiltred}
              <h4>Happy Hours</h4>
              {happyHoursFiltred} */}
            </div>
            :
            null
          }
          {groupsEventsFiltred.length?
            <div className='directory-group ac-flex-start'>
              <h4>Groups &amp; Events</h4>
              {groupsEventsFiltred}
            </div>
            :
            null
          }

          {fashionFiltred.length?
            <div className='directory-group ac-flex-start'>
              <h4>Fashion</h4>
              {fashionFiltred}
            </div>
            :
            null
          }

          {specialtyFiltred.length?
            <div className='directory-group ac-flex-start'>
              <h4>Specialty</h4>
              {specialtyFiltred}
            </div>
            :
            null
          }

          {artGalleryFiltred.length?
            <div className='directory-group ac-flex-start'>
              <h4>Art Gallery</h4>
              {artGalleryFiltred}
            </div>
            :
            null
          }

          {homeFurnishingsHousewaresFiltred.length?
            <div className='directory-group ac-flex-start'>
              <h4>Home Furnishings <br/> &amp; Housewares</h4>
              {homeFurnishingsHousewaresFiltred}
            </div>
            :
            null
          }

          {healthBeautyFiltred.length?
            <div className='directory-group ac-flex-start'>
              <h4>Health &amp; Beauty</h4>
              {healthBeautyFiltred}
            </div>
            :
            null
          }
        </div>
      </Intro>
      <div className={`span-8 ${currentSelected ? 'c0' : 'c5'} span-12-mobile flex-12 grid-container`}>
        <div className={`close padd-right padd-left ${currentSelected ? '' : 'hidden'}`}>{closebtn}</div>
        {currentSelected ?
          <>
            <SinglePlace place={singleRestaurant} active={currentSelected}/>
          </>
        :
          null
        }
        {restaurants}
      </div>
      <div className={`span-4 span-12-mobile  ${currentSelected ? 'order-2' : ''}`}>
        <div id="sticky-map" className='map'>
          <Intro visible={ioInView} in={{ border: 300, bg: 700, fade: 500 }} delayIn={100} mounted={true} stay={true} className="c5 t border-c5 border-bottom-off">
            <GoogleMapReact
              bootstrapURLKeys={{ key: gMapKey }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={(google) => addMarker(google)}
              options={
                {
                  styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#686868"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "lightness": "-22"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "saturation": "11"
                            },
                            {
                                "lightness": "-51"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "saturation": "3"
                            },
                            {
                                "lightness": "-56"
                            },
                            {
                                "weight": "2.20"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "lightness": "-52"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "weight": "6.13"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "lightness": "-10"
                            },
                            {
                                "gamma": "0.94"
                            },
                            {
                                "weight": "1.24"
                            },
                            {
                                "saturation": "-100"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "lightness": "-16"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": "-41"
                            },
                            {
                                "lightness": "-41"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "weight": "5.46"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "weight": "0.72"
                            },
                            {
                                "lightness": "-16"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "lightness": "-37"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#b7e4f4"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ]
                }
              }
            >
            </GoogleMapReact>
          </Intro>
        </div>
      </div>
    </div>
    {/* <script type="text/javascript" src="https://ten-palms.popmenu.com/s/embeds/ftdwbpml.js"></script> */}
    </>
  )
}

export default Custom