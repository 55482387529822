import React, {useState, createContext, useEffect} from 'react'

const CalendarContext = createContext(false)

function CalendarContextProvider({children, location}) {

	const todayDate = new Date()
	const todayYear = todayDate.getFullYear()
	const todayMonth = todayDate.getMonth()

  const [calendarView, setCalendarView] = useState({[location.pathname]: {year: todayYear, month: todayMonth}})
  
  useEffect(() => {
    // Set up calendar memory cell for current pathname
    if (!calendarView[location.pathname] && location.pathname){
      setCalendarView(old => ({...old, [location.pathname]: {year: todayYear, month: todayMonth}}))
    }
  },[calendarView,todayYear,todayMonth,location.pathname])

	return (
		<CalendarContext.Provider value={{
      locationPathname: location.pathname,
      calendarView,
      setCalendarView: (input) => setCalendarView(input),
    }}>
      {children}
    </CalendarContext.Provider>
	)
}

export default CalendarContext
export { CalendarContextProvider }