import React, { useContext } from 'react'
import './style.scss'
import NaviContext from '../../../context/NaviContext'

import Intro from '../../../animations/Intro'
import { Link } from 'gatsby'
//import ModalContext from '../../../context/ModalContext'
import { useAuth0 } from "../../../utils/auth"
import { useStaticQuery, graphql } from "gatsby"

function BarLogo(props) {

  const data = useStaticQuery(graphql`
    query Marquee{
      contentfulOptions {
				ticker
      }
    }
  `)

  //const modalContext = useContext(ModalContext)
  const naviContext = useContext(NaviContext)

  // const { isAuthenticated, loginWithRedirect, logout, user, getTokenSilently } = useAuth0()
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0()

  //const accessToken = getAccessTokenSilently()
  // const [pageName, setPageName] = useState('Gulfstream')
  const pageName = 'Gulfstream'


  const marquee = data?.contentfulOptions?.ticker

  function handleLoginClick() {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname
        }
      })
    }
  }

  function handleLogoutClick() {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin })
    }
  }

  return (
    <div id="bar-logo"
      onMouseEnter={props.onMobile ? null : () => { naviContext.setActive(false); naviContext.setHamburgerActive(false); }}
      onKeyDown={() => { naviContext.setActive(false); naviContext.setHamburgerActive(false); }} role="button" aria-label="Toggle navigation" tabIndex={0}
    >
      <div id="master-logo" className='logo c3 t has-intro'>
        <Intro visible={true} in={{ border: 300, bg: 300, blink: 0 }} className="c3 t">
          <Link to='/' className="logo logo-gulfstreampark" aria-label="Logo linking to homepage"></Link>
        </Intro>
      </div>
      <div className="logo-aside c5 border-c5 t has-intro">
        <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} className="c5 t">
          <h4 className="uppercase fs-85">{`${pageName} Park`}</h4>
        </Intro>
      </div>
      <div id="master-login" className="login c0 border-c5 t has-intro hover-trigger">
        <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className={`c0 t ${props.onMobile ? 'border-right-off' : ''}`}>
          <div className="login-btn" onClick={() => handleLoginClick()} onKeyDown={() => handleLoginClick()} role="button" aria-label="Login" tabIndex={0}>
            {/* <a href="#modal-1st-login"> */}
            <div className="bg-hover"></div>
            <div className="icon icon-user fs-85 active-dip"></div>
            <h4 className="uppercase fs-85 active-dip">{isAuthenticated && user ? `Hi, ${user?.nickname ? user?.nickname : "friend"}` : 'Login'}</h4>
            {/* </a> */}
          </div>
        </Intro>
      </div>
      {isAuthenticated && user ?
        <div id="master-logout" className="login c0 border-c5 t has-intro hover-trigger">
          <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className={`c0 t border-left-off ${props.onMobile ? 'border-right-off' : ''}`}>
            <div className="login-btn" onClick={() => handleLogoutClick()} onKeyDown={() => handleLogoutClick()} role="button" aria-label="Logout" tabIndex={0}>
              {/* <a href="#modal-1st-login"> */}
              <div className="bg-hover"></div>
              <h4 className="uppercase fs-85 active-dip">Logout</h4>
              {/* </a> */}
            </div>
          </Intro>
        </div>
        : null}
      <div id="master-marquee" className="login c5 border-c5 t has-intro">
        <Intro visible={true} in={{ border: 700, bg: 700, fade: 700 }} delayIn={400} className="active c5 t">
          <div className="marquee uppercase">
            <div>
              <div>{marquee}</div>
              <div>{marquee}</div>
              <div>{marquee}</div>
              <div>{marquee}</div>
            </div>
          </div>
        </Intro>
      </div>
    </div>
  )
}

export default BarLogo