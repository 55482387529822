import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import { Link } from 'gatsby'
import Intro from '../../../../animations/Intro'
import validatePdf from '../../../../utils/validatePdf'

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const {images: img, wysiwygs: wysiwyg, videos: video, repeaters: repeater} = props
	const [io, ioInView] = useInView({ triggerOnce: true })


  const linksMap = props.custom.data.map((linksArr, i) => {

    const links = linksArr.link.map((link, i) => {
      return (
        <div key={i} className='single-link'>
          {validURL(link.value) ?
            <a href={validatePdf(link.value)} target="_blank" rel='noreferrer noopener'>{link.key}</a>
          :
            <Link to={link.value}>  
              {link.key}     
            </Link>
          }
        </div>
      )
    })
  
    return (
      <Intro key={i} visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={100 * i} mounted={true} stay={true} className="single-item border-bottom-off">
        <div className="flex c3 t uppercase h-100 jc-center ac-center">
          {links}
        </div>
      </Intro>
    )
  })

  return (
		<div ref={io} className={`block-custom flex w-100 ${props.classes}`}>
      {linksMap}
		</div>
  )
}

export default Custom