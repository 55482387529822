import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'
import BlocksRouter from '../../../../Sections/BlocksRouter'
import RaceControl from '../RaceControl'

function Custom(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const { selectedDate, data, switchFlag } = props

  return (
    <>
      <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="padd-half padd-top-off padd-bottom-off" style={{ paddingBottom: '1px' }}>
        <RaceControl selectedDate={selectedDate} />
      </Intro>
      <div ref={io} className="scratches-reviews-component">
        <div className="span-12 flex-12">
          <Intro
            switch={switchFlag}
            in={{ fade: 300 }}
            out={{ fade: 300 }}
            delayIn={0}
            delayOut={0}
            mounted={true}
            stay={true}
            className="span-12 span-12-tablet padd-half"
          >
            <div className="heading-intro-with-tabs">
              <div className="changes-wrap">
                {data?.reviews?.length > 0 ?
                  data?.reviews?.map((item, i) => {
                    return (
                      <Intro key={i} visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100 * i} className="scratch-group scratch-group-fw c0 border-c5 t">
                        <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100 * i} className="scratch-group-race c5 border-c0 t border-top-off border-right-c0">
                          <h4>{item?.heading}</h4>
                        </Intro>
                        <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={100} className="scratch c0 border-c5 t border-top-off border-right-off border-left-off">
                          <BlocksRouter blocks={[{ content: item?.content, __typename: 'ContentfulBlockWysiwyg' }]} />
                        </Intro>
                      </Intro>
                    )
                  })
                  :
                  <h5>No Stewards Review reported</h5>
                }
              </div>
            </div>
          </Intro>
        </div>
      </div>
    </>
  )
}

export default Custom