import React, {useContext, useState, useEffect} from 'react'
import './style.scss'
import NaviContext from '../../../../context/NaviContext'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Intro from '../../../../animations/Intro'
import BlocksRouter from '../../../Sections/BlocksRouter'

function SubNavi(props) {

	const naviContext = useContext(NaviContext)

	// General Animation In
	const baseDelayStep = 50
	const [baseDelay, setBoxDelay] = useState(0)
	const baseDelayMobile = 0
	useEffect(() => {
		setBoxDelay(() => {
			return naviContext.isActive ? 0 : 200
		})
	}, [naviContext.isActive])

	const data = useStaticQuery(graphql`
		query {
			allContentfulNaviPages {
				nodes {
					slug
					sections {
						__typename
						id
						name
						classes
						anchor
						blocks {
							...blockCustom
						}
					}
				}
			}
		}
	`)

	const naviPages = data.allContentfulNaviPages?.nodes

	const naviSections = naviPages.map( (node, i) => {

		const slug = node.slug
	
		return node.sections?.map((node, i) => {
	
			const links = node.blocks[0].assets.filter(asset => asset.repeater)[0].repeater
		
			if(!props.onMobile)
				return (
					<Intro visible={naviContext.isActive && slug === props.subnavi} in={{ border: 250, fade: 200 }} delayIn={baseDelay + baseDelayStep * i} key={`navi-${i}`} className={`navi ${node.classes}`}>
						<section id={`navi-section-${i}`} className="navi-section">
							{node.anchor ? <div id={node.anchor} className="anchor"></div> : null}
							<BlocksRouter {...node} />
						</section>
					</Intro>
				)
			else if(node.classes.includes('mobile'))
				return links.map((link,i) => {
					return (
						<Intro visible={naviContext.isActive && slug === props.subnavi} in={{ bg: 500, fade: 500 }} delayIn={baseDelayMobile + baseDelayStep * i} key={`navi-${i}`} className={`navi ${node.classes}`}>
							<Link
								to={link.value}
								onClick={() => {
									naviContext.setActive(false)
									naviContext.setHamburgerActive(false)
								}}
								onKeyDown={() => {
									naviContext.setActive(false)
									naviContext.setHamburgerActive(false)
								}}
							>
								{link.key}
							</Link>
						</Intro>
					)
				})
		})
	})


	return (
		<div id="sub-navi">
			<Intro switch={naviContext.isActive && props.subnavi} in={{ fade: 100 }} delayIn={baseDelay} out={{ fade: 100 }} delayOut={0} mounted={true} stay={true} className="c5 t">
				<div className="grid section-container fs-85">
					{naviSections}
				</div>
			</Intro>
		</div>
	)
}

export default SubNavi