import React, { useEffect, memo } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
// import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Intro from '../../../../../animations/Intro'
import DayBlock from '../../../../DateBlock'

import Wysiwyg from '../../../Wysiwyg'
import Button from '../../../Button'

let formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})


function EventGroup(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const { eventDay, i, todayDate, last, dateSelected, passed, passedCount, defaultImage, length } = props

  // Set navi height variable for sticky dates
  useEffect(() => {
    let naviHeight = document.querySelector('#bar-top').offsetHeight + document.querySelector('#bar-logo').offsetHeight
    let naviDesktopHeight = document.querySelector('#bar-top')?.offsetHeight + document.querySelector('#bar-logo')?.offsetHeight + document.querySelector('.master-navi-animation')?.offsetHeight - 1
    document.documentElement.style.setProperty("--navi-mobile-height", naviHeight + "px");
    document.documentElement.style.setProperty("--navi-desktop-height", naviDesktopHeight + "px");
  }, [])

  // console.log('eventgroup')

  let eventsWithin = []
  let theDay = null
  let theDayToUrl = null

  for (let key in eventDay) {
    if (eventDay.hasOwnProperty(key)) {

      // Sort by priority
      // eventDay[key].sort((a, b) => (a?.priority < b?.priority) ? 1 : -1)

      // Parse the simplified day of the event
      const day = new Date(parseInt(key))

      // Populate array of events
      eventsWithin.push(eventDay[key]?.map((nodenode, ii) => {

        // Event time in case we need it to display at some point
        //const eventTime = new Date(nodenode?.date)
        let selected = day.getTime() == dateSelected?.getTime() ? true : false

        // The date box component
        theDay = <DayBlock day={day} selected={selected} inheritedDelay={i * 25} /*passed={passed}*/ ii={i} />
        // Generate url for the anchors
        theDayToUrl = day?.toMyString()

        const links = nodenode?.stakes?.data?.length > 0 ? nodenode?.stakes?.data?.map((link, i) => {

          return link.key ? (
            <div key={i} className="link-wrap">
              <div className="entry">
                <span>{`${link.key}, ${link.value ? link.value : null}, ${link.value2 ? link.value2 : null} - ${link.value3 ? formatter.format(link.value3) : null}`}</span>
              </div>
            </div>
          ) : (
            null
          )
        }) : null

        const buttons = nodenode?.buttons?.length > 0 ? nodenode?.buttons?.map((button, i) => {
          return <Button key={i} link={button.value} label={button.key} classes="" />
        }) : null


        const learnMore = nodenode?.sys.contentType.sys.id === 'raceEvent' && (nodenode.numberOfRaces || nodenode.firstPost) ?
          <Button link={`/racing-information/#race?day=${theDayToUrl}`} label={'Race Details'} classes="learn-more" />
          : nodenode?.sys.contentType.sys.id === 'promotion' ?
            <Button link={`/promotion/${nodenode?.slug}/`} label={'Learn More'} classes="learn-more" />
            : nodenode?.sys.contentType.sys.id === 'event' ?
            <Button link={`/event/entertainment/${nodenode?.slug}/`} label={'Learn More'} classes="learn-more" />
              : null


        const priority = nodenode?.priority ? nodenode?.priority : 1

        return (
          <div key={`sub-${ii}`} priority={priority} className={`event-block priority-${priority}`}>

            <div className="image-content-box">

              {nodenode?.featuredImage ?
                <div className="image-box">
                  <div className={`${priority === 1 ? 'aspect-ratio' : 'bg'}`}>
                    <GatsbyImage
                      image={nodenode.featuredImage.gatsbyImageData}
                      imgStyle={{ objectFit: 'cover' }}
                      objectPosition='50% 50%'
                      title={nodenode.featuredImage.title}
                      alt={nodenode.featuredImage.description}
                      className="bg img-cover"
                    />
                  </div>
                </div>
                :
                null
                // <div className="image-box">
                // 	<div className={`${priority === 1 ? 'aspect-ratio' : 'bg'}`}>
                //     <GatsbyImage
                //       image={defaultImage.gatsbyImageData}
                //       imgStyle={{objectFit: 'cover'}}
                //       objectPosition='50% 50%'
                //       alt={defaultImage.title ? defaultImage.title : ""}
                //       className="bg img-cover"
                //     />
                // 	</div>
                // </div>
              }

              <div className="content-box">

                {nodenode?.type ?
                  <h6 className="type">{nodenode?.type}</h6>
                  : nodenode?.sys.contentType.sys.id === 'raceEvent' ?
                    <h6 className="type">Racing</h6>
                    :
                    null
                }

                <div className="copy">
                  <div className='content'>
                    <h1>{nodenode.name}</h1>
                    {nodenode?.excerpt ?
                      <Wysiwyg content={nodenode?.excerpt} in={{appear: 0}} />
                      : nodenode?.contentApi ?
                        <Wysiwyg content={nodenode?.contentApi?.html} in={{appear: 0}} />
                        :
                        null
                    }
                  </div>
                  {buttons || learnMore ?
                    <div className="button-box">
                      <div className="button-box-inner">
                      {buttons}{learnMore}
                      </div>
                    </div>
                    : null}
                  {links ?
                    <div className="links-box">
                      <h6 className='stakes-title'>Stakes:</h6>
                      {links}
                    </div>
                    : null}
                </div>


              </div>

            </div>

            {/* <small>
								starts at: {eventTime.toLocaleTimeString(['en-US'], { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit'})}
								<span className="extrasmall">
									({eventTime.toLocaleTimeString(['en-US'], { hour: '2-digit', minute: '2-digit'})} your time)
								</span>
							</small> */}

          </div>
        )

      }))

    }
  }

  //const distance = 0.5
  // const styleTop = passed ? (distance * (passedCount - 1) + 'em') : (distance * (passedCount) + 'em')
  // const stylePaddingBottom = last ? ('calc(' + distance * (passedCount - 1) + 'em' + ' + ' + distance * (passedCount - 1) + 'px' + ')') : '0'

  const priority3 = eventsWithin[0]?.filter(node => node.props.priority === 3)
  const priority2 = eventsWithin[0]?.filter(node => node.props.priority === 2)
  const priority1 = eventsWithin[0]?.filter(node => node.props.priority === 1)

  return (
    <Intro
      key={`eventgroup-${i}`}
      visible={ioInView}
      in={{ fadeRight: 1110 }}
      delayIn={325}
      mounted={true}
      stay={true}
      style={{ zIndex: length - i }}
      className={`events-group ${passed ? 'passed' : ''}`}
    >
      <div ref={io} className={`events-wrap`} >

        <div id={'on-' + theDayToUrl} className="anchor"></div>

        <div className="date">
          {theDay}
        </div>

        <div className="group">
          <div className="group-row group-priority-3">
            {priority3}
          </div>
          <div className="group-row group-priority-2">
            {priority2}
          </div>
          <div className="group-row group-priority-1">
            {priority1}
          </div>

        </div>

      </div>
    </Intro>
  )
}

export default memo(EventGroup)