import React from 'react'
import './style.scss'
// import { useInView } from 'react-intersection-observer'

import Block from '../../../Sections/BlocksRouter'
import Picks from '../../../../components/Blocks/Picks'

import { useStaticQuery, graphql } from "gatsby"


function Custom(props) {

	const {wysiwygs: wysiwyg} = props
	// const [io, ioInView] = useInView({ triggerOnce: true })
  const name = props.directField ? props.directField : null

  const data = useStaticQuery(graphql`query FeaturedExperts {
  allContentfulPerson(
    limit: 40
    sort: {fields: createdAt}
    filter: {type: {eq: "Expert"}}
  ) {
    nodes {
      name
      title
      featured
      type
      bio {
        raw
      }
      image {
        gatsbyImageData(
          quality: 70
          layout: CONSTRAINED
          breakpoints: [300, 500, 750, 1080, 1366, 1920]
          formats: WEBP
        )
        title
        description
      }
      handicappingPicks {
        data
      }
    }
  }
}`)

  const single = data.allContentfulPerson.nodes.filter((item) => item.name === name)
 
	// Function to add or substract months in dates
	function addMonths(date, months) {
		var d = date.getDate()
		date.setMonth(date.getMonth() + +months)
		if (date.getDate() !== d) {
			date.setDate(0)
		}
		return date
	}

	// Setting up 2 months ahead and 1 back
	const todayDate = new Date()
	const todayYear = todayDate.getFullYear()
	const todayMonth = todayDate.getMonth()
	const pastDateRange = addMonths(new Date(todayYear,todayMonth,2),-1).toISOString()
	const futureDateRange = addMonths(new Date(todayYear,todayMonth,1),3).toISOString()

	const person = single ? single[0] : null

  return (
		<div className={`block-custom ${props.classes} flex-12`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			<div className="c3 flex-12">
				{wysiwyg ? <Block blocks={[{...wysiwyg[0], classes: 'padd'}]} /> : null}
				<div className="span-12 flex">
					<Picks 
						picks={person?.handicappingPicks}
						pastDateRange={pastDateRange}
						futureDateRange={futureDateRange}
						classes='flex'
					/>
				</div>
			</div>
		</div>
  )
}

export default Custom