import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../animations/Intro'

function DateBlock(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const { day, inheritedDelay, passed, ii, selected, animate, type } = props

  const animationInitial = passed && !animate ? { appear: 0, border: 0 } : passed && animate ? { fadeLeft: 500, border: 1000 } : { fadeLeft: 500, border: 1000 }
	
	
  return (
    <Intro visible={ioInView} in={animationInitial} delayIn={inheritedDelay + 10} mounted={true} stay={true} className={`${passed ? 'c1' : 'c0'} t ${type === 'full' ? 'border-top-off border-left-off border-bottom-off' : 'border-top-off border-right-off border-bottom-off'}`}>
      <div ref={io} className={`date-box ${type}`}>

        <div className="weekday">
          {day?.toLocaleDateString(['en-US'], {weekday: 'long'})}
        </div>
        <div className="month">
          {type === 'full' ? day?.toLocaleDateString(['en-US'], {month: 'long'}) : day?.toLocaleDateString(['en-US'], {month: 'short'})}
        </div>
        <div className="day">
          {day?.toLocaleDateString(['en-US'], {day: '2-digit'})}
        </div>

        {!animate && passed ?
          <div className="with-svg mark x">
            {ii % 3 === 0 ?
            <svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
              <g transform="translate(-819.921 -242.525)">
                <path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
                <path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
              </g>
            </svg>
            : null }
            {ii % 3 === 1 ?
            <svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
              <g transform="translate(-699.924 -149.882)">
                <path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="2"/>
              </g>
            </svg>
            : null }
            {ii % 3 === 2 ?
            <svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
              <g transform="translate(-708.549 -430.775)">
                <path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
                <path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
              </g>
            </svg>
            : null }
          </div>
        :
          <Intro visible={ioInView && passed} in={{ fade: 100, draw: 125 }} delayIn={inheritedDelay + 500} stay={false} className="with-svg mark x">
            <div className="with-svg mark x">
              {ii % 3 === 0 ?
              <svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
                <g transform="translate(-819.921 -242.525)">
                  <path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
                  <path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
                </g>
              </svg>
              : null }
              {ii % 3 === 1 ?
              <svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
                <g transform="translate(-699.924 -149.882)">
                  <path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="2"/>
                  <path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="2"/>
                </g>
              </svg>
              : null }
              {ii % 3 === 2 ?
              <svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
                <g transform="translate(-708.549 -430.775)">
                  <path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
                  <path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="1"/>
                </g>
              </svg>
              : null }
            </div>
          </Intro>
        }

        <Intro visible={ioInView && selected} in={{ fade: 100, draw: 600 }} delayIn={200} stay={false} className="with-svg mark u">
          <svg xmlns="http://www.w3.org/2000/svg" width="41.03" height="6.779" viewBox="0 0 41.03 6.779">
            <path d="M20.586,641.919a195.662,195.662,0,0,1,31.577-2.392A173.291,173.291,0,0,0,27.411,641.6a19.475,19.475,0,0,0-7,2.082,196.259,196.259,0,0,1,36.238-1.316" transform="translate(-15.674 -638.524)" fill="none" stroke="red" strokeMiterlimit="10" strokeWidth="2"/>
          </svg>
        </Intro>

      </div>
    </Intro>
  )
}

export default DateBlock