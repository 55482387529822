import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Video from '../../Video'
import { Link } from 'gatsby'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const {images: img, wysiwygs: wysiwyg, videos: video, repeaters: repeater} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

  const links = repeater[0]?.repeater
  
  const items = video.map( (item, i) => {
    return (
      <div key={i} className="span-4 span-12-mobile shadow-bottom">
        <Link to={links[i].value}>
          <div className="single-item c5 t">
            <Video {...item}  hover={true} hideVolumeButton={true} classes='border-left-off border-right-off border-top-off border-bottom-off'/>
            <h2 className="title padd padd-left-half padd-bottom-half">{links[i].key}</h2>
          </div>
        </Link>
      </div>

    )
  })
  return (
		<div ref={io} className={`block-custom flex-12 ${props.classes}`}>
      {items}
		</div>
  )
}

export default Custom