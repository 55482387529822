import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import Intro from '../../../../animations/Intro'

function FormBookNow(props) {
	const { register, handleSubmit, reset, formState: { errors } } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const delayIn = 500
	const [io, ioInView] = useInView({ triggerOnce: true })

	const onSubmit = (form, e) => {
    
		e.preventDefault()
		setLoading(true)
  
		// Left is formspree input name, right is ours
		let data = new FormData()
		data.append('Name', form.name)
		data.append('Email', form.email)
		data.append('Phone Numer', form.number)
		data.append('Subject', form.subject)
		data.append('Message', form.message)
		
		const url = `https://formspree.io/f/xrgjapvj`
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('response', response.data)
					if(response.data.ok) {
					 setMessage("Submission sent")
					 reset()
					}
					setLoading(false)
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
    <>
      <form
        name="contact"
        method="post"
        // action="/thanks/"
        className="contact-us"
        onSubmit={handleSubmit(onSubmit)}
        ref={io}
      >
        <div className="form-container">
          <label>
            <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn } className="border-top-off border-left-off border-right-off">
              <input type="text" placeholder="name" name="name" {...register("name",{required: true, maxLength: 80, message: "error message"})} />
            </Intro>
            {errors.name && <p className="small margin-off">your name is required.</p>}
          </label>
          <label>
            <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 250 } className="border-top-off border-left-off border-right-off">
              <input type="text" placeholder="email" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
            </Intro>
            {errors.email && <p className="small margin-off">a correct email is required.</p>}
          </label>
          <label>
            <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 350 } className="border-top-off border-left-off border-right-off">
              <input type="text" placeholder="number" name="number" {...register("number",{required: false, maxLength: 80, message: "error message"})} />
            </Intro>
            {errors.number && <p className="small margin-off">your phone number is required.</p>}
          </label>
          <label>
            <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 450 } className="border-top-off border-left-off border-right-off">
              <input type="text" placeholder="subject" name="subject" {...register("subject",{required: false, maxLength: 80, message: "error message"})} />
            </Intro>
            {errors.subject && <p className="small margin-off">subject is required.</p>}
          </label>
          <label className="textarea-label">
            <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 550 }>		
              <textarea rows="8" placeholder="MESSAGE" name="message" {...register("message")} />
            </Intro>
          </label>
          <Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 650 } >		
            <button className="submit" type="submit">SUBMIT{isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
          </Intro>
          <p className="message small">{message}</p>
        </div>
      
      </form>
      {/* <iframe
        src={"https://cloud.experience.gulfstreampark.com/Contactusgsp"}
        style={{ order: '100', width: '100vw', maxWidth: '100%', margin: '0 auto', border: 'none', height: '500px' }}
        title={"Contact Us"}
      /> */}
    </>
	)
}

export default FormBookNow