import React, { useEffect, useState } from 'react'
import './style.scss'

import Intro from '../../../../../animations/Intro'
import use1stAPI from '../../../../../Api'
import Replay from './Replay'
import { useAuth0 } from '../../../../../utils/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Custom(props) {
  const { selectedDate, currentTab, switchFlag, data } = props
  const { isAuthenticated, loginWithRedirect, user } = useAuth0()

  function handleLoginClick() {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname
        }
      })
    }
  }


  // Setting up dates
  const selectedCDate = new Date(selectedDate)
  const selectedCYear = selectedCDate.getFullYear()
  const selectedCMonth = selectedCDate.getMonth() + 1
  const selectedCMonthLeadingZero = selectedCMonth < 10 ? '0' + selectedCMonth : '' + selectedCMonth
  const selectedCDay = selectedCDate.getDate()
  const selectedCDayLeadingZero = selectedCDay < 10 ? '0' + selectedCDay : '' + selectedCDay
  const selectedCRaceDate = `${selectedCYear}-${selectedCMonthLeadingZero}-${selectedCDayLeadingZero}`

  const [currentRace, setCurrentRace] = useState({
    race_date: selectedCRaceDate,
    race_number: null,
    highest_race: 1,
    track_code: 'GP',
    event_code: 'GPM',
    replayParameters: {
      user: null,
      mobile: false,
      view_type: null,
      secure: true,
    },
    data: data
  })

  const { data: races, loading: racesLoading } = use1stAPI('races', { ...currentRace })
  const racesSorted = races?.races?.slice().sort((a, b) => (a.race_number > b.race_number) ? 1 : -1)


  // Track loading status changes
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!racesLoading) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [racesLoading])

  // Track tab changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_number: currentTab + 1 } })
  }, [currentTab])

  // Data changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, data: data } })
  }, [data])

  // Date changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_date: selectedCRaceDate } })
  }, [selectedCRaceDate])


  const contentEntries = racesSorted?.map((node, i) => {
    const d = new Date(node.post) // removed .replace(/-/g, "/")

    const raceNumber = parseInt(node.race_number)

    if (raceNumber - 1 === currentTab) {

      return (
        <div key={`current-tab-${i}`} className="span-12 flex-12 box-wrap">
          <Intro
            switch={currentTab}
            in={{ fade: 300 }}
            out={{ fade: 300 }}
            delayIn={0}
            delayOut={0}
            mounted={true}
            stay={true}
            className="span-12 span-12-tablet padd-half padd-top-off flex-12"
          >
            {/* <div className="heading-intro-with-tabs">
							<RaceDescriptionBox {...node}
								details={node.description}
								distance={node.distance}
								raceType={node.pb_class}
								postTime={node.published_post_time}
								purseInt={node.purse}
								raceBreed={node.race_breed}
								raceNumber={raceNumber}
								trackConditions={trackConditions}
								ii={raceNumber}
								d={d}
							/>
						</div> */}
            <h4>Replays:</h4>
            <Replay currentRace={currentRace} />
          </Intro>
        </div>
      )
    } else {
      return false
    }
  })

  return (
    <>
      {!loading ?
        <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="c0">
          <div className="results-replays-component">

            {isAuthenticated ?
              contentEntries
              :
              <div className='padd padd-top-half flex ai-center jc-center w-100 fs-125'>
                <div className="secondary-login login c0 border-c5 t has-intro hover-trigger">
                  <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className={`c0 t ${props.onMobile ? 'border-right-off' : ''}`}>
                    <div className="login-btn" onClick={() => handleLoginClick()} onKeyDown={() => handleLoginClick()} role="button" aria-label="Login" tabIndex={0}>
                      {/* <a href="#modal-1st-login"> */}
                      <div className="bg-hover"></div>
                      <div className="icon icon-user fs-85 active-dip"></div>
                      <h4 className="uppercase fs-85 active-dip">{isAuthenticated && user ? `Hi, ${user?.nickname ? user?.nickname : "friend"}` : 'Please Log-in to view replays'}</h4>
                      {/* </a> */}
                    </div>
                  </Intro>
                </div>
              </div>
            }

          </div>
        </Intro>
        :
        <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
      }
    </>
  )
}

export default Custom