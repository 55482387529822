import React from 'react'
import './style.scss'

import Intro from '../../../../animations/Intro'

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
// import Wysiwyg from '../../Wysiwyg'

function Custom(props) {
	
	// Delay Synchronization	
	// const [baseDelay, setBaseDelay] = useState(0)
	// const stepDelay = 50

  const featured = props.custom?.data

	const data = useStaticQuery(graphql`
    query BeatTheExperts {
      allContentfulPerson(limit: 40,  filter: {type: {eq: "Expert"}}) {
        nodes {
          name
          bio {
            raw
          }
          image {
            gatsbyImageData(quality: 70, layout: CONSTRAINED, breakpoints: [300, 500, 750, 1080, 1366, 1920], formats: WEBP)
            title
            description
          }
        }
      }
    }
  `)

  const filtredExperts = data?.allContentfulPerson?.nodes?.filter(expert => {
    return featured.includes(expert.name)
  })

	const expertsMap = filtredExperts.map((expert, i) => {	
		return (
      <div key={`expert-${i}`} className='single-expert padd padd-bottom-off padd-top-half padd-right-half span-5 span-12-mobile'>
        <div className='position-relative'>
          <div className="img placeholder">
            <Intro visible={true} in={{ border: 500, bg: 700, fade: 500 }} delayIn={50 * i} mounted={true} stay={true} className="c5 t border-c5">
					    <div className="aspect-ratio">
                <GatsbyImage
                  image={expert.image.gatsbyImageData}
                  imgStyle={{objectFit: 'cover'}}
                  objectPosition='50% 50%'
                  title={expert.image.title}
                  alt={expert.image.description} 
                  className="bg img-cover"
                />
						  </div>
            </Intro>
					</div>
          <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={500 + (50 * i)} mounted={true} stay={true} className="c0 border-c5 name-box">
            <h5 className="name">{expert.name}</h5>
          </Intro>
        </div>
      </div>
		)
	})
	
	return (
		<div className="list beat-the-expert-list flex-12 padd-bottom">
			{expertsMap}
		</div>
	)
}

export default Custom
