import React, { useEffect, useState } from 'react'
import './style.scss'
// import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import RaceBasicBetsBox from '../RaceBasicBetsBox'
import OtherPoolsBox from '../OtherPoolsBox'
import use1stAPI from '../../../../../Api'
import RaceControl from '../RaceControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Custom(props) {
  // const [io, ioInView] = useInView({ triggerOnce: true })
  const { currentTab, setCurrentTab, switchFlag, selectedDate, type } = props


  // Setting up dates
  const selectedCDate = new Date(selectedDate)
  const selectedCYear = selectedCDate.getFullYear()
  const selectedCMonth = selectedCDate.getMonth() + 1
  const selectedCMonthLeadingZero = selectedCMonth < 10 ? '0' + selectedCMonth : '' + selectedCMonth
  const selectedCDay = selectedCDate.getDate()
  const selectedCDayLeadingZero = selectedCDay < 10 ? '0' + selectedCDay : '' + selectedCDay
  const selectedCRaceDate = `${selectedCYear}-${selectedCMonthLeadingZero}-${selectedCDayLeadingZero}`

  const [currentRace, setCurrentRace] = useState({
    race_date: selectedCRaceDate,
    race_number: null,
    highest_race: 1,
    track_code: 'GP',
    event_code: 'GPM',
  })

  const { data: runners, loading: runnersLoading } = use1stAPI('runners', { ...currentRace })

  const { data: races, loading: racesLoading } = use1stAPI('races', { ...currentRace })
  const racesSorted = races?.races?.slice().sort((a, b) => (a.race_number > b.race_number) ? 1 : -1)

  const { data: changes, loading: changesLoading } = use1stAPI('changes', { ...currentRace })

  const { data: odds, loading: oddsLoading } = use1stAPI('odds', { ...currentRace })

  const { data: pools, loading: poolsLoading } = use1stAPI('pools', { ...currentRace })


  // Win / Place / Show
  const winPools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_WN')?.[0]?.pools?.[0]?.money
  const placePools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_PL')?.[0]?.pools?.[0]?.money
  const showPools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_SH')?.[0]?.pools?.[0]?.money
  const winOdds = odds?.odds?.filter(odd => odd.bet_code === 'BET_CODE_WN')?.[0]?.odds?.[0]?.odds

  // Other Pools
  const doublePools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_DB')?.[0]?.pool_total
  const exactaPools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_EX')?.[0]?.pool_total
  const quinellaPools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_QU')?.[0]?.pool_total
  const trifectaPools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_TR')?.[0]?.pool_total
  const superfectaPools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_SU')?.[0]?.pool_total
  const superhifivePools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_E5')?.[0]?.pool_total
  const pick3Pools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_P3')?.[0]?.pool_total
  const pick4Pools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_P4')?.[0]?.pool_total
  const pick5Pools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_P5')?.[0]?.pool_total
  const pick6Pools = pools?.pools?.filter(pool => pool.bet_code === 'BET_CODE_P6')?.[0]?.pool_total

  const [runnersSwitched, setRunnersSwitched] = useState(false)

  // Track loading status changes
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!runnersLoading && !racesLoading && !changesLoading && !oddsLoading && !poolsLoading) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [runnersLoading, racesLoading, changesLoading, oddsLoading, poolsLoading])

  // Track tab changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_number: currentTab + 1 } })
    setRunnersSwitched(false)
  }, [currentTab])

  // Date changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_date: selectedCRaceDate } })
  }, [selectedCRaceDate])

  // Monitor runners change so it can switch to them without 
  useEffect(() => {
    setRunnersSwitched(true)
  }, [runners])


  const contentEntries = racesSorted?.map((node, i) => {
    const d = new Date(node.post) // removed .replace(/-/g, "/")

    const raceNumber = parseInt(node.race_number)

    if (raceNumber - 1 === currentTab) {

      return (
        <div key={`current-tab-${i}`} className="flex-12 box-wrap">

          {type === 'odds win/place/show' ?
            <RaceBasicBetsBox {...node}
              standing={runners?.runners}
              runnersSwitched={runnersSwitched}
              changes={changes?.changes?.[raceNumber]}
              type="entries"
              win={winPools}
              place={placePools}
              show={showPools}
              odds={winOdds}
            />
            : null}

          {type === 'other pools' ?

            <OtherPoolsBox {...node}
              standing={runners?.runners}
              runnersSwitched={runnersSwitched}
              changes={changes?.changes?.[raceNumber]}
              type="entries"
              double={doublePools}
              exacta={exactaPools}
              quinella={quinellaPools}
              trifecta={trifectaPools}
              superfecta={superfectaPools}
              superhifive={superhifivePools}
              pick3={pick3Pools}
              pick4={pick4Pools}
              pick5={pick5Pools}
              pick6={pick6Pools}

            />
            : null}

        </div>
      )
    } else {
      return false
    }
  })



  return (
    <>
      {!loading ?
        <>
          <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="padd-half padd-top-off padd-bottom-off" style={{ paddingBottom: '1px' }}>
            <RaceControl toControl={races?.races} currentTab={currentTab} setCurrentTab={setCurrentTab} program={null} programAbbreviated={null} selectedDate={selectedDate} />
          </Intro>
          <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="c0">
            <div className="entries-component">

              <div className="span-12 padd-half padd-top-off flex-12">

                <Intro
                  switch={currentTab && contentEntries}
                  in={{ fade: 300 }}
                  out={{ fade: 300 }}
                  delayIn={0}
                  delayOut={0}
                  mounted={true}
                  stay={true}
                  className={`span-${props.replays ? '6' : '12'} span-12-tablet`}
                >
                  {contentEntries}
                </Intro>

                {!contentEntries ?
                  <h6 className='padd-bottom-half'>No racing information is available for the selected date, please come back at a later time.</h6>
                  : null}

              </div>

            </div>
          </Intro>

        </>
        :
        <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
      }
    </>
  )
}

export default Custom