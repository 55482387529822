import { useEffect, useState } from 'react'
import _ from 'lodash'

import { useAuth0 } from "../utils/auth"

function use1stAPI(type, params) {
  const { isAuthenticated, getTokenSilently, user } = useAuth0()
  const [token, setToken] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  //const [timestamp, setTimestamp] = useState(null)
  //console.log('params',params);

  let url = null
  switch (type) {
    case 'weather':
      url = 'https://api.1stbet.com/v1/weather/onecall'
      break;
    case 'race':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.track_code && params?.race_number ? `https://api.1stbet.com/v1/race/racingdata/race/${params?.race_date}/${params?.track_code}/${params?.race_number}` : null
      break;
    case 'races':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.track_code ? `https://api.1stbet.com/v1/race/racingdata/races/${params?.race_date}/${params?.track_code}` : null
      break;
    case 'results':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.event_code ? `https://api.1stbet.com/v1/race/pooldata/results/${params?.race_date}/${params?.event_code}` : null
      break;
    case 'track':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.track_code ? `https://api.1stbet.com/v1/race/racingdata/track/${params?.race_date}/${params?.track_code}` : null
      break;
    case 'runners':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.track_code && params?.race_number ? `https://api.1stbet.com/v1/race/racingdata/runners/${params?.race_date}/${params?.track_code}/${params?.race_number}` : null
      break;
    case 'replay':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.track_code && params?.race_number ? `https://api.1stbet.com/v1/race/video/replaybytrackcode/${params?.race_date}/${params?.track_code}/${params?.race_number}` : null
      break;
    case 'live':
      url = params?.track_code ? `https://api.1stbet.com/v1/race/video/track/${params?.track_code}` : null
      break;
    case 'currentRace':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.event_code ? `https://api.1stbet.com/v1/race/pooldata/events/${params?.race_date}/${params?.event_code}` : null
      break;
    case 'changes':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.track_code ? `https://api.1stbet.com/v1/race/racingdata/changes/${params?.race_date}/${params?.track_code}` : null
      break;
    case 'odds':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.event_code && params?.race_number ? `https://api.1stbet.com/v1/race/pooldata/odds/${params?.race_date}/${params?.event_code}/${params?.race_number}` : null
      break;
    case 'pools':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.event_code && params?.race_number ? `https://api.1stbet.com/v1/race/pooldata/pools/${params?.race_date}/${params?.event_code}/${params?.race_number}` : null
      break;
    case 'probables':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.event_code && params?.race_number ? `https://api.1stbet.com/v1/race/pooldata/probables/${params?.race_date}/${params?.event_code}/${params?.race_number}` : null
      break;
    case 'willpays':
      url = params?.race_date && params?.race_date !== 'NaN-NaN-NaN' && params?.event_code && params?.race_number ? `https://api.1stbet.com/v1/race/pooldata/willpays/${params?.race_date}/${params?.event_code}/${params?.race_number}` : null
      break;
  }



  const urlParams =
    type === 'weather' ? {
      lat: '34.13917732831236',
      lon: '-118.04573971269032',
      exclude: 'minutely,hourly,daily,alerts'
    } :
      type === 'replay' ? {
        ...params?.replayParameters,
        user: user['https://api.1stbet.com/customer_id']
      } :
        type === 'live' ? {
          ...params?.liveParameters
        }
          : null

  useEffect(() => {
    let disposed = false
    if (isAuthenticated) {
      (async function fetchData() {
        try {
          const tokenSilent = await getTokenSilently()
          if (disposed) return
          setToken(tokenSilent)
        } catch (e) {
          console.error(e)
        }
      })()
    }
    return () => {
      disposed = true
    }
  }, [getTokenSilently, isAuthenticated])

  useEffect(() => {
    //console.log(`${params?.data?.[type] ? 'Static:' : 'API:'} ${type}`,url,params?.race_date,params?.track_code,params?.race_number,params?.event_code)
    let disposed = false
    let interval
    let timestamp = null
    const race_date = params?.race_date
    const race_number = params?.race_number
    const isThereStaticData = params?.data?.[type] && params?.data?.[type] !== undefined ? true : false

    // Just fetched flag
    let flag = false

    function runQuery(force) {
      // Current time check
      const currentTime = new Date()
      // Loading state logic
      setLoading(!flag ? true : false)
      flag = true
      //console.log('RunQuery',currentTime > timestamp, type, timestamp)
      //console.log(isAuthenticated,!isThereStaticData && isAuthenticated && url && token && ((currentTime > timestamp) || force && !isThereStaticData))
      if (
        !isThereStaticData && isAuthenticated && url && token && (currentTime > timestamp) // if there is no static data provided and we are logged in
        || !isThereStaticData && isAuthenticated && url && token && force // if it's forced render and no static data provided
        //|| isThereStaticData && !data?.[type] && isAuthenticated && url && token && ((currentTime > timestamp)) // if static data is provided but incomplete
      ) {
        (async function fetchData() {
          try {
            const params = urlParams ? '?' + new URLSearchParams(urlParams) : ''
            let response = null
            response = await fetch(url + params, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })

            if (response.status >= 400 && response.status < 600) {
              setLoading(false)
              throw new Error("Bad response from server")
            } else {
              // Data Refresh
              //console.log(`type 1 ${type}`,race_date,race_number)
              setLoading(await response ? false : true)
              setData(await response.json())
            }

            // If it's weather set timestamp for next quarter hour
            if (type === 'weather') {
              timestamp = new Date(currentTime.getTime() + 900000)
            }
            // If it's race set timestamp for next 2 hours
            if (type === 'race') {
              timestamp = new Date(currentTime.getTime() + 7200000)
            }
            // If it's races set timestamp for next 2 hours
            if (type === 'races') {
              timestamp = new Date(currentTime.getTime() + 7200000)
            }
            // If it's runners set timestamp for next 1 hour
            if (type === 'runners') {
              timestamp = new Date(currentTime.getTime() + 3600000)
            }
            // If it's results set timestamp for next 30s
            if (type === 'results') {
              timestamp = new Date(currentTime.getTime() + 30000)
            }
            // If it's track set timestamp for next 1 hours
            if (type === 'track') {
              timestamp = new Date(currentTime.getTime() + 3600000)
            }
            // If it's replay set timestamp for next 1 minute
            if (type === 'replay') {
              timestamp = new Date(currentTime.getTime() + 60000)
            }
            // If it's live set timestamp for next hour
            if (type === 'live') {
              timestamp = new Date(currentTime.getTime() + 3600000)
            }
            // If it's changes set timestamp for next 1 minute
            if (type === 'changes') {
              timestamp = new Date(currentTime.getTime() + 60000)
            }
            // If it's odds set timestamp for next 1 minute
            if (type === 'odds') {
              timestamp = new Date(currentTime.getTime() + 60000)
            }
            // If it's pools set timestamp for next 1 minute
            if (type === 'pools') {
              timestamp = new Date(currentTime.getTime() + 60000)
            }
            // If it's probables set timestamp for next 1 minute
            if (type === 'probables') {
              timestamp = new Date(currentTime.getTime() + 60000)
            }
            // If it's probables set timestamp for next 1 minute
            if (type === 'willpays') {
              timestamp = new Date(currentTime.getTime() + 60000)
            }
            // For the current race info tracking, put it into self-check
            if (type === 'currentRace') {
              let minutesToNext = parseInt(data?.event?.[0]?.mtp)
              // If there the race is last and mtp is zero, put it on 1hr refresh rate, otherwise use mtp + 1minute
              let timestampNext = data?.event?.[0].highest_race === data?.event?.[0].current_race ? new Date(currentTime.getTime() + 1800000) :
                minutesToNext < 60 ? new Date(currentTime.getTime() + 60000) :
                  new Date(currentTime.getTime() + 1800000)
              timestamp = timestampNext
            }

            if (disposed) return
          } catch (e) {
            console.error(e)
            timestamp = new Date(currentTime.getTime() + 60000)
          }
        })()
      } else if (isThereStaticData) {
        // Data Refresh
        //console.log(`type 3 ${type}`,race_date,race_number)
        setLoading(false)
        setData(old => {
          if (_.isEqual(old?.[type], params?.data?.[type])) {
            //console.log('type 3 - a - skip')
            return old
          } else {
            //console.log('type 3 - b - overwrite')
            return { ...old, [type]: params?.data?.[type] }
          }
        }
        )
      } else if (!isThereStaticData && isAuthenticated && url && token && (currentTime < timestamp)) {
        // Do Nothing
        setLoading(false)
      } else if (!isThereStaticData) {
        // Data Refresh
        //console.log(`type 4 ${type}`,race_date,race_number)
        setLoading(false)
        setData(null)
      }
    }

    interval = setInterval(() => runQuery(false), 10000)

    runQuery(true)

    return () => {
      disposed = true
      clearInterval(interval)
    }
  }, [
    isAuthenticated, url, token,
    type,
    params?.race_date,
    params?.track_code,
    params?.race_number,
    params?.event_code,
    params?.data?.[type]
  ])

  //console.log(`${isThereStaticData ? 'FIRE Static:' : 'FIRE API:'} ${type}`,data?.[type], params?.data?.[type])
  useEffect(() => {
    //console.log(`data changed ${type}`,data)
  }, [data])

  return { data, loading }
}

export default use1stAPI