import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Slider from 'react-slick'
import Wysiwyg from '../../Wysiwyg'
import Intro from '../../../../animations/Intro'
import useEventsDayCollection from '../../../../utils/useQueryEventsDayCollection'
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

// Slider settings
const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
}

// Function to add or substract months in dates
function addMonths(date, months) {
	var d = date.getDate()
	date.setMonth(date.getMonth() + +months)
	if (date.getDate() !== d) {
		date.setDate(0)
	}
	return date
}

function Custom(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const baseDelay = props.baseDelay ? props.baseDelay : 100

  const data = useEventsDayCollection()

	// Setting up 2 months ahead
	const todayDate = new Date()
	const todayYear = todayDate.getFullYear()
	const todayMonth = todayDate.getMonth()
	const todayDay = todayDate.getDate()
	const futureDateRange = addMonths(new Date(todayYear,todayMonth,1),3).toISOString()
	const todayLimit = new Date(todayYear,todayMonth,todayDay).toISOString()

  const currentView = data.allContentfulEventDay.nodes.filter(day => {
		const date = new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).toISOString()
    return (Array.isArray(day.promotions) && date >= todayLimit && date <= futureDateRange) 
  }).filter(day => day.promotions.length)

  currentView.sort(function(a,b){
    return new Date(a.date) - new Date(b.date)
  })

  let promotionsBunch = []
  currentView.forEach(day => {
    promotionsBunch = promotionsBunch.concat(day.promotions)
  })
  
  promotionsBunch = promotionsBunch.filter(promo => promo.type === 'Casino Promotion')

  const map = new Map(promotionsBunch.map(promo => [promo.slug, promo]));
  const uniquePromotions = [...map.values()];


  const promotions = uniquePromotions.map( (promo, i) => {
    return (
      <Intro key={i} visible={ioInView} in={{ border: 500, appear: 0 }} delayIn={baseDelay * i} stay={true} mounted={true} className="casino-promo-tile c0 border-c5 border-top-off border-left-off t">
        <div >
          { promo?.featuredImage ?
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay * i + 500} stay={true} mounted={true} className="c5 t border-c5 border-left-off image">
              <Link to={`/promotion/${promo.slug}/`} className="nostrike">
                <GatsbyImage
                  image={promo?.featuredImage?.gatsbyImageData}
                  imgStyle={{objectFit: 'cover'}}
                  objectPosition='50% 50%'
                  title={promo?.featuredImage?.title}
                  alt={promo?.featuredImage?.description}
                />
              </Link>
            </Intro>
          : null }
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={baseDelay * i + 500} stay={true} mounted={true} className="description c0 border-c5">
            { promo?.type ? <h6>{promo?.type}</h6> : null }
            <Link to={`/promotion/${promo?.slug}/`} className="promo-title nostrike"><h4>{promo?.name}</h4></Link>
            { promo.excerpt ? <Wysiwyg content={promo.excerpt}/> : null }
          </Intro>
        </div>
      </Intro>
    )
  })
 
  return (
		<div ref={io} className={`block-custom ${props.classes}`}>
      <div className="slider">
        {promotions.length ?
          <Slider {...settings}>
            {promotions}
          </Slider>
        :
          <h4 className="text-center padd-half-top w-100">Sorry, no active promotions</h4>
        }
      </div>
      <Link to="/promotions/" className="btn small js-center" alt="View Full Calendar"><span>View Full Calendar</span></Link>
		</div>
  )
}

export default Custom

