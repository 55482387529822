import React, { useState, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Workouts(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const {
    standing: standing,
    type: type,
    changes: changes,
    runnersSwitched: runnersSwitched,
    double: double,
    pick3: pick3,
    pick5: pick5,
    exacta: exacta,
    quinella: quinella,
    trifecta: trifecta,
    superfecta: superfecta,
    superhifive: superhifive,
    pick4: pick4,
    pick6: pick6,
  } = props

  standing?.sort((a, b) => (parseInt(a?.betting_interest_program_number) > parseInt(b?.betting_interest_program_number)) ? 1 : -1)

  const list = standing?.map((node, i) => {

    const {
      tote_number: startingGrid,
      post_position: pp,
      color: color,
      saddle_cloth_color: saddleColor,

    } = node

    return (
      <Intro key={`content-results-${i}`} switch={ioInView && runnersSwitched} in={{ fadeTop: 500 }} delayIn={i * 100} className={`row no no-${startingGrid} scratched-${changes?.changes?.[parseInt(pp)]?.scratched ? 'true' : 'false'}`}>

        <div className={`no color-${color} runner saddle-color-${saddleColor}`}>
          <h4>{startingGrid}</h4>
        </div>

        {double ?
          <div className="win-o">
            <p>{double?.will_pays?.[i]?.will_pay ? double?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {exacta ?
          <div className="win-p">
            <p>{exacta?.will_pays?.[i]?.will_pay ? exacta?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {quinella ?
          <div className="win-p">
            <p>{quinella?.will_pays?.[i]?.will_pay ? quinella?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {trifecta ?
          <div className="win-p">
            <p>{trifecta?.will_pays?.[i]?.will_pay ? trifecta?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {superfecta ?
          <div className="win-p">
            <p>{superfecta?.will_pays?.[i]?.will_pay ? superfecta?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {superhifive ?
          <div className="win-p">
            <p>{superhifive?.will_pays?.[i]?.will_pay ? superhifive?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {pick3 ?
          <div className="win-p">
            <p>{pick3?.will_pays?.[i]?.will_pay ? pick3?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {pick4 ?
          <div className="win-p">
            <p>{pick4?.will_pays?.[i]?.will_pay ? pick4?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {pick5 ?
          <div className="win-p">
            <p>{pick5?.will_pays?.[i]?.will_pay ? pick5?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}

        {pick6 ?
          <div className="win-p">
            <p>{pick6?.will_pays?.[i]?.will_pay ? pick6?.will_pays?.[i]?.will_pay : '-'}</p>
          </div>
          : null}


      </Intro>
    )
  })

  return (
    <div ref={io} className={`racing-basic-bets-box flex-12`}>
      {double || pick3 || pick5 ?
        <div className="results-tab">
          <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
            <div className="no">
              <p>PG</p>
            </div>
            {double ?
              <div className="win-o">
                <p>
                  {double?.base_amount ? double?.base_amount + '$ Double' : ''}<br></br>
                  {double?.early_result ? double?.early_result : ''}
                </p>
              </div>
              : null}
            {exacta ?
              <div className="win-o">
                <p>
                  {exacta?.base_amount ? exacta?.base_amount + '$ Exacta' : ''}<br></br>
                  {exacta?.early_result ? exacta?.early_result : ''}
                </p>
              </div>
              : null}
            {quinella ?
              <div className="win-o">
                <p>
                  {quinella?.base_amount ? quinella?.base_amount + '$ Quinella' : ''}<br></br>
                  {quinella?.early_result ? quinella?.early_result : ''}
                </p>
              </div>
              : null}
            {trifecta ?
              <div className="win-o">
                <p>
                  {trifecta?.base_amount ? trifecta?.base_amount + '$ Trifecta' : ''}<br></br>
                  {trifecta?.early_result ? trifecta?.early_result : ''}
                </p>
              </div>
              : null}
            {superfecta ?
              <div className="win-o">
                <p>
                  {superfecta?.base_amount ? superfecta?.base_amount + '$ Superfecta' : ''}<br></br>
                  {superfecta?.early_result ? superfecta?.early_result : ''}
                </p>
              </div>
              : null}
            {superhifive ?
              <div className="win-o">
                <p>
                  {superhifive?.base_amount ? superhifive?.base_amount + '$ SuperHiFive' : ''}<br></br>
                  {superhifive?.early_result ? superhifive?.early_result : ''}
                </p>
              </div>
              : null}
            {pick3 ?
              <div className="win-p">
                <p>
                  {pick3?.base_amount ? pick3?.base_amount + '$ Pick 3' : ''}<br></br>
                  {pick3?.early_result ? pick3?.early_result : ''}
                </p>
              </div>
              : null}
            {pick4 ?
              <div className="win-p">
                <p>
                  {pick4?.base_amount ? pick4?.base_amount + '$ Pick 4' : ''}<br></br>
                  {pick4?.early_result ? pick4?.early_result : ''}
                </p>
              </div>
              : null}
            {pick5 ?
              <div className="win-p">
                <p>
                  {pick5?.base_amount ? pick5?.base_amount + '$ Pick 5' : ''}<br></br>
                  {pick5?.early_result ? pick5?.early_result : ''}
                </p>
              </div>
              : null}
            {pick6 ?
              <div className="win-p">
                <p>
                  {pick6?.base_amount ? pick6?.base_amount + '$ Pick 6' : ''}<br></br>
                  {pick6?.early_result ? pick6?.early_result : ''}
                </p>
              </div>
              : null}

          </Intro>
          {list}
          {!runnersSwitched ?
            <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
            :
            null
          }
          {/* <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={500} className="row row-foot">
					<div className="no">
						<p>PG</p>
					</div>
					{double ? 
					<div className="win-o">
						<p>
						{double?.base_amount ? double?.base_amount + '$ Double' : ''}<br></br>
						{double?.early_result ? double?.early_result : ''}
						</p>
					</div>
					: null}
					{pick3 ? 
					<div className="win-p">
						<p>
						{pick3?.base_amount ? pick3?.base_amount + '$ Pick 3' : ''}<br></br>
						{pick3?.early_result ? pick3?.early_result : ''}
						</p>
					</div>
					: null}
					{pick5 ? 
					<div className="win-p">
						<p>
						{pick5?.base_amount ? pick5?.base_amount + '$ Pick 5' : ''}<br></br>
						{pick5?.early_result ? pick5?.early_result : ''}
						</p>
					</div>
					: null}
				</Intro> */}
        </div>
        :
        <div className='padd-half'>
          <h3>No data for selected bet</h3>
        </div>
      }
      &nbsp;
    </div>
  )
}

export default Workouts
