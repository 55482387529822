import React, {useEffect, useState} from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import BlocksRouter from '../../Sections/BlocksRouter'
import { useInView } from 'react-intersection-observer'

function Slider(props) {
	const [io, ioInView] = useInView()

	const slides = props.blocks?.map((node, i) => {

		// Just confirm it's slide BOX
		if(node.__typename === 'slide' || node.__typename === 'ContentfulBlockSlider') {

			let slide = []

			// If it has video
			if(node.video || node.file) {
				slide.push(<BlocksRouter key={`video-${i}`} blocks={[{...node, __typename: 'ContentfulBlockVideo'}]} />)
			}

			// If it has image
			if(node.imgSrcSet) {
				slide.push(<BlocksRouter key={`slide-${i}`} blocks={[{...node, __typename: 'ContentfulBlockImage'}]} />)
			}

			// If it has wysiwyg
			if(node.content) {
				slide.push(<BlocksRouter key={`wysiwyg-${i}`} blocks={[{...node, __typename: 'ContentfulBlockWysiwyg'}]} />)
			}

			return slide
		} else {
			// If it's not slider __typename, attempt to use regular blocks
			return <BlocksRouter key={`regular-${i}`} blocks={[node]} />
		}
	})


	
	// Delay Synchronization	
	const [baseDelay, setBaseDelay] = useState(1200)
	
  // Slider settings
  const [slide, setSlide] = useState(slides ? slides[0] : null)
  const [slideNr, setSlideNr] = useState(0)
	const [uiHover, setUiHover] = useState(false)

  const slideRate = props.autoPlaySpeed ? props.autoPlaySpeed : 7500
	const isAutoPlayOff = props.autoPlaySpeed === 0 ? true : false
  let max = slides?.length

	let timer = null


	const thumbs = props.blocks?.map((node, i) => {

		// Just confirm it's slide BOX
		if(node.__typename === 'slide') {

			let thumb = []

			// If it has image
			if(node.thumbSrcSet) {
				thumb.push(<div key={`thumb-${i}`} className={`thumb ${slideNr === i ? 'current' : ''}`} onClick={() => dotClicked(i)} aria-label="Thumbnail" onKeyDown={() => dotClicked(i)} role="button" tabIndex={0}><BlocksRouter blocks={[{...node, __typename: 'image'}]} /></div>)
			} else {
				<p key={`thumb-${i}`}>Missing Thumbnail</p>
			}

			return thumb
		} else { // If it's not slider __typename, attempt to use regular blocks
			// Make sure thumbs are just images
			if(node.__typename === 'ContentfulBlockImage' || node.image) {
				// Stripe them of their classes for uniform look
				node.classes = ''
				return <div key={`regular-thumb-${i}`} className={`thumb ${slideNr === i ? 'current' : ''}`} onClick={() => dotClicked(i)} aria-label="Thumbnail" onKeyDown={() => dotClicked(i)} role="button" tabIndex={0}>
					<BlocksRouter blocks={[{...node, __typename: 'ContentfulBlockImage'}]} />
				</div>
			} else {
				return <p key={`regular-thumb-${i}`}>Missing Thumbnail</p>
			}
		}
	})


  // Slider animation
  useEffect(() => {

		let i = slideNr
		
		// Hover pause
		if(uiHover === true || ioInView === false || isAutoPlayOff) {
			clearTimeout(timer)
		} else if (!props.autoPlayStop) {
			timer = setTimeout(() => {
				i++
				if (max === i) {
					i = 0
				}
				setSlideNr(i)
				setSlide(function(){
					setBaseDelay(0)
					return slides[i]
				})
			}, slideRate)
		}

		// Clear timeout on useEffect kill
		return () => {
			clearTimeout(timer)
		}

  },[slideNr,uiHover,timer,ioInView])


	// Clicking the dots
	function dotClicked(i) {
		setSlideNr(i)
		setSlide(function(){
			setBaseDelay(0)
			return slides[i]
		})
	}


	// UI Dots
	const dots = slides?.map((node, i) => {
		return (
			<Intro key={`dots-${i}`} visible={true} in={{ blink: 0 }} delayIn={baseDelay} className="c3 t"><div className={`${i === slideNr ? 'current' : ''} li`} onClick={() => dotClicked(i)} aria-label="Control Dot" onKeyDown={() => dotClicked(i)} role="button" tabIndex={0}></div></Intro>
		)
	})



  return slides ? (
		<div ref={io} className={`block-slider ${props.classes}`} onMouseEnter={() => setUiHover(true)} onMouseLeave={() => setUiHover(false)} role="button" aria-label="Pause slides play when hovering" tabIndex={0}>
		
			<Intro switch={slideNr} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="">
				{slide}
			</Intro>
		
			{props.thumbs ?
				<div className="block-slider-thumbs">
					{thumbs}
				</div>
			: null }

			{props.prev && slideNr > 0 ?
				<div className="slider-control-prev-positiner hover-trigger" onClick={() => dotClicked(slideNr - 1)} aria-label="Previous Slide" onKeyDown={() => dotClicked(slideNr - 1)} role="button" tabIndex={0}>
					<div className="icon"></div>
					<div className="text">{props.prev !== 'ARROW' ? props.prev : null}</div>
				</div>
			: null }

			{props.next && slideNr < slides?.length - 1 ?
				<div className="slider-control-next-positiner hover-trigger" onClick={() => dotClicked(slideNr + 1)} aria-label="Next Slide" onKeyDown={() => dotClicked(slideNr + 1)} role="button" tabIndex={0}>
					<div className="text">{props.next !== 'ARROW' ? props.next : null}</div>
					<div className="icon"></div>
				</div>
			: null }

			{props.dots ?
				<div className="dots">
					<div>
						{dots}
					</div>
				</div>
			: null }

		</div>
  ) : false
}

export default Slider