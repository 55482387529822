import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../animations/Intro'
import HTMLVideo from '../../Video/HTML'

function Video(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	const delay = props.delay ? props.delay : 200

	const videos = props.video?.map((node,i) => {
		return <HTMLVideo key={i} hover={props.hover} subtitles={props.subtitles?.publicUrl} hideVolumeButton={props.hideVolumeButton} showControls={props.showControls} file={node.file?.url} title={node.title} description={node.description} />
	})
	
  return (
		<div ref={io} className={`block-video ${props.classes}`}>
			<Intro visible={ioInView} in={{ border: 300, bg: 700, fade: 500 }} delayIn={delay} mounted={true} stay={true} className="c5 t border-c5">
				<div className="video">
					{videos}
				</div>
			</Intro>
		</div>
  )
}

export default Video