import React from 'react'
import Intro from '../../animations/Intro'

import './style.scss'

function Accordion(props) {

	function toggleAccordion(i) {
		props.setCurrent(i)
	}

  return (
		<div key={props.i} className="accordion-qa">
			<div className={`accordion-q ${props.i === props.current ? 'current' : ''}`} onClick={() => toggleAccordion(props.i)} onKeyDown={() => toggleAccordion(props.i)} role="button" aria-label="Accordion toggle" tabIndex={0}>
				{props.q}
			</div>
			
			<Intro
				switch={props.i === props.current ? true : false} 
				in={{ bg: 400, fadeBottom: 300 }}
				out={{ fade: 0 }}
				delayIn={0}
				delayOut={0}
				mounted={false}
				stay={false}
				className="c5 t"
			>
				<h5 className="accordion-details-intro">Details</h5>
				<div className="accordion-content-wrap fs-85">
					{props.a}
				</div>
			</Intro>
		</div>
  )
}

export default Accordion