import React, {useEffect, useState} from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
// import Wysiwyg from '../../Wysiwyg'
// import { useQuery, gql } from '@apollo/client'
// import validatePdf from '../../../../utils/validatePdf'
import { useStaticQuery, graphql } from "gatsby"

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })
	const [currentTab, setCurrentTab] = useState(0)
	// const [iframeHeight, setIframeHeight] = useState()

  const { contentfulOptions } = useStaticQuery(graphql`query LeaderboardQuery {
    contentfulOptions {
      leaderStandingsApi {
        data
      }
    }
  }`)

  const dateRange = contentfulOptions?.leaderStandingsApi?.data.dateRange
	// const [showLeaderBoard, setShowLeaderBoard] = useState(false)

	// // Apollo, Pull Events!
	// const { loading, error, data: apollo, refetch } = useQuery(APOLLO_QUERY, {
	// 	variables: { // variables for query use

	// 		},
	// 	notifyOnNetworkStatusChange: true,
	// 	errorPolicy: 'ignore'
	// })

	// const stats = apollo?.blockRepeaterCollection?.items[0]?.fileUploadRepeater?.data?.map((item,i) => {
	// 	return (
	// 		<div key={i} className="uppercase link-item">
	// 			<a className='' href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
	// 				{`${item.key} (PDF)`}
	// 			</a>
	// 		</div>
	// 	)
	// })

	// useEffect(() => {

	// 	function myFunction(e) {
	// 		if(e.keyCode == 76){
	// 			setShowLeaderBoard(!showLeaderBoard)
	// 		}
	// 	}

	// 	document.addEventListener("keydown", (e) => myFunction(e));
	
	// },[showLeaderBoard])

	let formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
	})

	// const copyAbove = props.assets[0] ? <Wysiwyg content={props.assets[0]?.content} classes={props.assets[0]?.classes}/> : null

	// On Parent Tab Change
	function onTabChange(i) {
		setCurrentTab(i)
	}

	// // Tabs
	// const TabPack = props?.custom?.data?.tabs?.map((tab, i) => {
	// 	return (
	// 		<div className={`tab-handle ${i === currentTab ? 'current' : ''}`} key={i} onClick={() => onTabChange(i)} onKeyDown={() => onTabChange(i)} role="button" tabIndex={0}>
	// 			<h4>{tab.tabHandle}</h4>
	// 		</div>
	// 	)
	// })

  const TabPack = Object.keys(contentfulOptions?.leaderStandingsApi?.data)?.map((category, i) => {
    if(category !== 'dateRange')
      return (
        <div key={i} className={`tab-handle ${i === currentTab ? 'current' : ''}`} onClick={() => onTabChange(i)} onKeyDown={() => onTabChange(i)} role="button" tabIndex={0}>
          <h4>{category.replace('Figure', '')}</h4>
        </div>
      )
  })

		// // TabsContent
		// const TabContentPack = props?.custom?.data?.tabs?.map((tab, i) => {
		// 	if (i === currentTab) {
		// 		let content = tab.tabContent.map((node,i) => {
		// 			return(
		// 				<tr key={i}>
		// 						<td>{node.name}</td>
		// 						<td>{node.sts}</td>
		// 						<td>{node.first}</td>
		// 						<td>{node.second}</td>
		// 						<td>{node.third}</td>
		// 						<td>{node.winRatio}</td>
		// 						<td>{node.dolarRatio}</td>
		// 						<td>{formatter.format(node.earnings)}</td>
		// 				</tr>
		// 			)
		// 		})
		// 		return content
		// 	}
		// 	return false
		// })
    const TabContentPack = Object?.keys(contentfulOptions?.leaderStandingsApi?.data)?.map((category, i) => {
      if (i === currentTab) {
        let content = contentfulOptions?.leaderStandingsApi?.data[category].map((node, j) => {
          return (
            <tr key={j}>
              <td>{node.name}</td>
              <td>{node.totalStarts}</td>
              <td>{node.totalFirst}</td>
              <td>{node.totalSecond}</td>
              <td>{node.totalThird}</td>
              <td>{node.totalPercent}</td>
              <td>{node.totalInMoneyPercent}</td>
              <td>{formatter.format(node.totalEarnings)}</td>
            </tr>
          )
        })
        return content
      }
      return false
    })



	
  return (
		<div ref={io} className={`block-custom w-100 c5 flex jc-flex-end padd-top-off padd padd-right-2 padd-half-mobile padd-right-half-mobile ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			<div className="tab-pack tab-pack-leaderboard flex">
				{/* {copyAbove} */}
				<div className='left'>
        <div className="tab-handles-pack flex">
					{TabPack}
				</div>
        <h6>{dateRange}</h6>
				<Intro switch={ioInView || currentTab} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
					<table>	
						<thead>
							<tr className="uppercase">
								<td>Name</td>
								<td>STS</td>
								<td>1st</td>
								<td>2nd</td>
								<td>3rd</td>
								<td>Win%</td>
								<td>$%</td>
								<td>Earnings</td>
							</tr>
						</thead>
						<tbody>
							{TabContentPack}
						</tbody>
					</table>
				</Intro>
					{/* {showLeaderBoard ?  */}
						{/* <iframe
							onLoad={(ele) => {
								setIframeHeight(ele?.target?.scrollHeight)
							}}
							src="https://oldwebsite.santaanita.com/iframe-leaderboard-gsp/"
							frameBorder="0"
							style={{backgroundColor: '#ffffff', minHeight: '653px', height: iframeHeight, marginTop: "1em"}}
						/> */}
					{/* :
						null 
					} */}
				</div>
				<div className='right text-right'>
						{/* {stats} */}
				</div>
				{/* <div className="tab-handles-pack flex">
					{TabPack}
				</div>
				<Intro switch={ioInView || currentTab} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
					<table>	
						<thead>
							<tr className="uppercase">
								<td>Name</td>
								<td>STS</td>
								<td>1st</td>
								<td>2nd</td>
								<td>3rd</td>
								<td>Win%</td>
								<td>$%</td>
								<td>Earnings</td>
							</tr>
						</thead>
						<tbody>
							{TabContentPack}
						</tbody>
					</table>
				</Intro> */}

			</div>
		</div>
  )
}

export default Custom

// Query for rehydration, currently just test
// const APOLLO_QUERY = gql`
// 	query Stats {
// 		# add your query
// 		blockRepeaterCollection (where: {anchor:"stats"}) {
// 			items {
// 				anchor
// 				fileUploadRepeater
// 			}
// 		}
// 	}
// `