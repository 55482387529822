import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Block from '../../../Sections/BlocksRouter'
import FormGetNotes from '../../../Form/Axios/FreeNotes'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const {images: img, wysiwygs: wysiwyg} = props
	// const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<div className={`block-custom ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			{wysiwyg ? <Block {...wysiwyg[0]} /> : null}
			<FormGetNotes img={img ? img : null}/>
		</div>
  )
}

export default Custom