import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../animations/Intro'

function Decor(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<div ref={io} className={`block-decor ${props.classes}`}>
			<Intro visible={ioInView} in={{ bg: 500 }} delayIn={200} mounted={true} stay={true} className="c3 t">
				<div className="decor"></div>
			</Intro>
		</div>
  )
}

export default Decor