import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import useEventsDayCollection from '../../../../utils/useQueryEventsDayCollection'

import DateBlock from '../../../DateBlock'
import validatePdf from '../../../../utils/validatePdf'

// Function to add or substract months in dates
function addMonths(date, months) {
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

let formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})

function Custom(props) {
  //const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })

  const data = useEventsDayCollection()

  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const pastDateRange = addMonths(new Date(todayYear, todayMonth, 1), -1).setHours(0, 0, 0, 0)
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 6).setHours(0, 0, 0, 0)

  // Only Racing Days
  const raceDays = data.allContentfulEventDay.nodes.filter(day => {
    const raceDay = new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0, 0, 0, 0)

    return (
      day.raceEvents &&
      raceDay >= pastDateRange &&
      raceDay <= futureDateRange
    )
  })

  let stakesWithDates = []
  raceDays?.forEach(item => {
    item.raceEvents?.forEach((node) => {

      if (node.stakes) {
        stakesWithDates.push({ stakes: node.stakes, date: item.date, slug: node.slug })
      }
    })
  })


  const eventDays = stakesWithDates.map((node, i) => {
    const day = new Date(node.date.replace(/-/g, '\/').replace(/T.+/, ''))
    const passed = todayDate > day ? true : false


    const events = node?.stakes?.data?.map((stake, i) => {
      if (stake.key)
        return (
          <tr key={i}>
            <td>{stake.key}</td>
            <td>{stake.value ? stake.value : null}</td>
            <td>{stake.value2 ? stake.value2 : null}</td>
            <td>{stake.value3 ? formatter.format(stake.value3) : null}</td>
            {stake.nominations.url ? <td className="black"><a target="_blank" href={validatePdf(stake.nominations.url)}>Nominations</a></td> : null}
            {stake.past_performances.url ? <td className="black"><a target="_blank" href={validatePdf(stake.past_performances.url)}>Past Performances</a></td> : null}
          </tr>
        )
    })

    if (events?.length)
      return (
        <div key={i} className="eventDay flex padd-bottom-half">
          <div className="date">
            <DateBlock day={day} passed={passed} selected={false} animate={true} className={'c1'} />
          </div>
          <div className="events padd-left-half">
            <h5 className="uppercase">Stakes Race</h5>
            <table>
              <tbody>
                {events}
              </tbody>
            </table>
          </div>
        </div>
      )
    else
      return null
  })

  return (
    <div ref={io} className={`block-custom ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      {eventDays}
    </div>
  )
}

export default Custom