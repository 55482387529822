import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Accordion from '../Accordion'

import Intro from '../../animations/Intro'

function BoxTabs(props) {
  //const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [current, setCurrent] = useState(null)

  const { currentRace, race, runners, changes, isRaceCanceled } = props

  // const hello = tabContentHolder?.current ? createPortal(
  // 	<Intro visible={ioInView} in={{ border: 300, bg: 300, blink: 0 }} className="border-top-off border-left-off">
  // 		<button className='modal__close'>&times;</button>
  // 	</Intro>,
  // 	tabContentHolder.current
  // ) : null

  const runnersMap = runners?.map((node, i) => {
    const betwin2 = 2 + 2 * eval(node.mlodd)

    return (

      <div key={i} className={`accordion-tab no no-${node.tote_number} scratched-${changes?.changes?.[parseInt(node?.post_position)]?.scratched ? 'true' : 'false'}`}>
        <Accordion
          current={current}
          setCurrent={setCurrent}
          i={node.betting_interest_program_number}
          q={
            <div className="accordion-handle">
              <div className={`accordion-ordinal has-intro runner saddle-color-${node.saddle_cloth_color}`}>
                <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="border-top-off border-left-off">
                  {node.betting_interest_program_number}
                </Intro>
              </div>
              <div className="accordion-details has-intro">
                <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} className="border-top-off border-right-off border-left-off">
                  <div className="accordion-details-description">
                    {node.mlodd ? <p>$2 Win: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(betwin2)}</p> : null}
                    <h4 className='horse-jockey'>{node.betting_interest_name}</h4>
                    <h6>T: {node.trainer} J: {node.jockey}</h6>
                  </div>
                  <div className="accordion-details-race">
                    <h4>{node.tote_number}</h4>
                    {node.mlodd ? <h6>Ml {node.mlodd}</h6> : null}
                  </div>
                </Intro>
              </div>
            </div>
          }
          a={
            <div className="accordion-content">
              <div className="accordion-content-essence">
                <p>
                  {node.betting_interest_name ?
                    <>
                      {`Name - ${node.betting_interest_name}`}<br />
                    </>
                    : null}
                  {node.jockey ?
                    <>
                      {`Jockey / Weight - ${node.jockey}`} / {node.weight_carried}<br />
                    </>
                    : null}
                  {node.trainer ?
                    <>
                      {`Trainer - ${node.trainer}`}<br />
                    </>
                    : null}
                  {node.owner ?
                    <>
                      {`Owner - ${node.owner}`}<br />
                    </>
                    : null}
                  {node.owner_silks ?
                    <>
                      {`Owner Silks - ${node.owner_silks}`}<br />
                    </>
                    : null}
                  {node.horse_year_of_birth ?
                    <>
                      {`Horse year of birth - ${node.horse_year_of_birth}`}<br />
                    </>
                    : null}
                  {node.gender ?
                    <>
                      {`Gender - ${node.gender}`}<br />
                    </>
                    : null}
                  {node.dam_name ?
                    <>
                      {`Dam Name - ${node.dam_name}`}<br />
                    </>
                    : null}
                  {node.sire_name ?
                    <>
                      {`Sire Name - ${node.sire_name}`}<br />
                    </>
                    : null}
                  {node.saddle_cloth_color ?
                    <>
                      {`Saddle Color - ${node.saddle_cloth_color}`}<br />
                    </>
                    : null}
                </p>
              </div>
            </div>
          }
        />
      </div>
    )
  })


  const tabs =
  {
    handle: <h5>Program</h5>,
    content: <>
        <div className={`intro c5 ${isRaceCanceled ? 'canceled' : ''}`}>
          <div className="race">
          <h3>R{currentRace?.race_number}</h3>
        </div>
        <div className={`details ${isRaceCanceled ? 'canceled' : ''}`}>
          <p>
            Post time: {race?.race?.published_post_time ? race?.race?.published_post_time : 'Unknown Yet'}<br />
            Distance: {race?.race?.distance ? race?.race?.distance : 'Unknown Yet'}
          </p>
        </div>
        {/* <div className="downloads c0">
						<a href="#" className="icon-pdf" target="_blank" rel="noreferrer noopener">Download Program</a>
					</div> */}
      </div>
      <div className="intro-2 c0">
        <span className="fat">Race Type</span>: {race?.race?.pb_class ? race?.race?.pb_class : 'Unknown Yet'}, <span className="fat">Purse:</span> {race?.race?.purse ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(race?.race?.purse) : 'Unknown Yet'}<br />
        <span className="fat">Details</span>: {race?.race?.description ? race?.race?.description : 'Unknown Yet'}
      </div>
      {runnersMap ?
        <div className={`accordion ${isRaceCanceled ? 'canceled' : ''}`}>
          {runnersMap}
        </div>
        : null}
    </>
  }

  const handles =
    <div className={`tab tab-program has-intro`} >
      <Intro visible={ioInView} in={{ border: 500, bg: 300, blink: 0 }} delayIn={300} className={`border-top-off border-left-off t `}>
        {tabs.handle}
      </Intro>
    </div>



  return (
    <div ref={io} className={`box-tabs`}>
      <Intro visible={ioInView} in={{ appear: 0, border: 500 }} delayIn={100} className="border-bottom-off border-right-off">
        <div className="tabs">
          {handles}
        </div>
      </Intro>
      <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={300} className="border-top-off">
        <div className="tab-content-wrap">
          {tabs.content}
        </div>
      </Intro>
      {isRaceCanceled ? <div className='canceled-stamp'>canceled</div> : null}
    </div>
  )
}

export default BoxTabs