import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'
import validatePdf from '../../../../utils/validatePdf'

function Custom(props) {
  //const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })

  const claimsReportRepeater = props.assets
    .find((item) => item.anchor === 'claimsReport')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })

  const conditionBookRepeater = props.assets
    .find((item) => item.anchor === 'conditionBook')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })


  const postPositionStatsRepeater = props.assets
    .find((item) => item.anchor === 'postPositionStats')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })

  const bookkeepingRepeater = props.assets
    .find((item) => item.anchor === 'bookkeeping')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })

  const factsAndFiguresRepeater = props.assets
    .find((item) => item.anchor === 'factsAndFigures')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })

  const stallApplicationRepeater = props.assets
    .find((item) => item.anchor === 'stallApplication')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })

    const stakesSchedulesRepeater = props.assets
    .find((item) => item.anchor === 'stakesSchedules')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })

  const mediaGuideRepeater = props.assets
    .find((item) => item.anchor === 'mediaGuide')?.fileUploadRepeater?.data
    .map((item, i) => {
      return (
        <div key={i} className="link-item fs-85">
          <a href={validatePdf(item.file.url)} target="_blank" rel='noreferrer noopener'>
            {item.key}
          </a>
        </div>
      )
    })



  const racingOffice = props?.custom?.data?.racingOffice?.map(function (node, i) {
    return (
      <div key={i} className="link-item fs-85">
        <a href={`${node.file}`} target="_blank" rel='noreferrer noopener'>
          {node.name}
        </a>
      </div>
    )
  })

  const horsemensBookkeeper = props?.custom?.data?.horsemensBookkeeper?.map(function (node, i) {
    return (
      <div key={i} className="link-item fs-85">
        <a href={`${node.file}`} target="_blank" rel='noreferrer noopener'>
          {node.name}
        </a>
      </div>
    )
  })

  const licenseOffice = props?.custom?.data?.licenseOffice?.map(function (node, i) {
    return (
      <div key={i} className="link-item fs-85">
        <a href={`${node.file}`} target="_blank" rel='noreferrer noopener'>
          {node.name}
        </a>
      </div>
    )
  })

  const gulfstreamParkMainNumber = props?.custom?.data?.gulfstreamParkMainNumber?.map(function (node, i) {
    return (
      <div key={i} className="link-item fs-85">
        <a href={`${node.file}`} target="_blank" rel='noreferrer noopener'>
          {node.name}
        </a>
      </div>
    )
  })

  return (
    <div ref={io} className={`block-custom block-custom-downloads flex-12 ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

      <div className="span-4 span-12-tablet padd-half ">
        <div className="flex-12 ai-center condition-books-title">
          <h3>Contact</h3>
        </div>
        <div className="stall-applications padd-top-half">
          <h4>Racing Office</h4>
          {racingOffice}
        </div>
        <div className="stats padd-top-half">
          <h4>Horsemen's Bookkeeper</h4>
          {horsemensBookkeeper}
        </div>
        <div className="claims-report padd-top-half">
          <h4>License Office</h4>
          {licenseOffice}
        </div>
        <div className="incompass-financial padd-top-half padd-bottom-half">
          <h4>Gulfstream Park Main Number</h4>
          {gulfstreamParkMainNumber}
        </div>
      </div>

      <div className="span-8 span-12-tablet flex-12 c3 padd-half">
        <div className='span-6 span-12-mobile half'>
          <div className="flex-12 ai-center jc-center condition-books-title">
            <h3>Information and Statistics </h3>
          </div>
          <div className="claims-report padd-top-half">
            <h4>Overnight</h4>
            <div className="link-item fs-85">
              <div className="fs-85">
                <Link to='/overnight/' className='nostrike btn'>
                  <span>Open</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="claims-report padd-top-half">
            <h4>Daily Recap</h4>
            <div className="link-item fs-85">
              <div className="fs-85">
                <Link to='/daily-recap/' className='nostrike btn'>
                  <span>Open</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="claims-report padd-top-half">
            <h4>Stakes Schedules</h4>
            {stakesSchedulesRepeater}
          </div>
          <div className="claims-report padd-top-half">
            <h4>Claims Report</h4>
            {claimsReportRepeater}
          </div>
          <div className="claims-report padd-top-half">
            <h4>Condition Book</h4>
            {conditionBookRepeater}
          </div>
          <div className="claims-report padd-top-half">
            <h4>Media Guide</h4>
            {mediaGuideRepeater}
          </div>
          <div className="claims-report padd-top-half">
            <h4>Stakes, Noms, PPs</h4>
            <div className="link-item fs-85">
              <div className="fs-85">
                <Link to='/stakes/' className='nostrike btn'>
                  <span>Open</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="claims-report padd-top-half">
            <h4>Jockey, Trainer, Owner Stats</h4>
            <div className="link-item fs-85">
              <div className="fs-85">
                <Link to='#leader-standings' className='nostrike btn'>
                  <span>Open</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="claims-report padd-top-half">
            <h4>Post Position Stats</h4>
            {postPositionStatsRepeater}
          </div>
          <div className="claims-report padd-top-half">
            <h4>Facts and Figures</h4>
            {factsAndFiguresRepeater}
          </div>
          <div className="claims-report padd-top-half">
            <h4>Photo Requests</h4>
            <p className='margin-off'>Gulfstream Park's track photography is provided by Bob Coglianese Photos. To make an inquiry or to purchase a photo, please call 954-457-6245 or click mailto:acoglianese@mac.com.</p>
          </div>
        </div>
        <div className='span-6 span-12-mobile half'>
          <div className="flex-12 ai-center  condition-books-title">
            <h3>FORMS CENTER</h3>
          </div>
          <div className="claims-report padd-top-half">
            <h4>InCompass Financial </h4>
            <div className="link-item fs-85">
              <div className="fs-85">
                <a href='#modal-1st-iframe' className='nostrike btn'>
                  <span>Open</span>
                </a>
              </div>
            </div>
          </div>
          <div className="claims-report padd-top-half">
            <h4>Bookkeeper</h4>
            {bookkeepingRepeater}
          </div>
          {/* <div className="claims-report padd-top-half">
            <h4>Stall Application</h4>
            <div className="link-item fs-85">
                <Link to='https://rh360.1st.com/' target='_blank'>
                  <span>Online Stall Applications</span>
                </Link>
            </div> 
            {stallApplicationRepeater}
          </div>*/}
          <div className="claims-report padd-top-half">
            <h4>Stall Application</h4>
            {stallApplicationRepeater}
            <div className="link-item fs-85">
                <Link to='https://rh360.1st.com/' target='_blank'>
                  <span>Online Stall Applications</span>
                </Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Custom