import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

		// TabsContent
		const TableContent = props?.custom?.data 

    const trArr = []

	
    for (const [key, value] of Object.entries(TableContent)) {
      trArr.push(
      <tr key={key}>
        <td>{key}</td>
        <td>{value}</td>
      </tr>
      )
 
}
  return (
		<div ref={io} className={`block-custom w-100 fs-125 c0 flex`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			<div className="w-100">
				<Intro switch={ioInView} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
					<table>	
						<thead>
							<tr className="uppercase">
							  <td>Bet Type</td>
							</tr>
						</thead>
						<tbody>
						  {trArr}
						</tbody>
					</table>
				</Intro>
			</div>
		</div>
  )
}

export default Custom