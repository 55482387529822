import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'

function Custom(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
  const [iframeHeight, setIframeHeight] = useState()

  return (
		<Intro visible={ioInView} in={{ bg: 600, fade: 400 }} delayIn={500} mounted={true} stay={true} className={`c0 ${props.classes}  w-100 t`}>
			<div ref={io} className={`w-100`}>

				<iframe
					onLoad={(ele) => {
						setIframeHeight(ele?.target?.scrollHeight)
					}}
					src="https://mcdg8xmk7lvyntmhlr6vs3w15q1m.pub.sfmc-content.com/qzc1g31w2o1"
					frameBorder="0"
					style={{backgroundColor: '#ffffff', minHeight: '800px', height: iframeHeight, width: "100%"}}
				/>

			</div>
		</Intro>
  )
}

export default Custom