import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlocksRouter from '../../../Sections/BlocksRouter'
import Slider from 'react-slick'

function Custom(props) {
  
	const {wrappers: wrapper} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

  const SliderArrowNext = ({to, onClick}) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-next-positiner hover-trigger`} aria-label={to}>
        <div className="text">NEXT</div>
        <div className="icon"></div>
      </div>
    )
  }

  const SliderArrowPrev = ({to, onClick}) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-prev-positiner hover-trigger`} aria-label={to}>
        <div className="icon"></div>
        <div className="text">PREVIOUS</div>
      </div>
    )
  }

  const settings = {
		dots: false,
		infinite: true,
		speed: 500,
    nextArrow: <SliderArrowNext to="next"/>,
    prevArrow: <SliderArrowPrev to="prev"/>,
		slidesToShow: 3,
		slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
	};
 

  const winners = wrapper.map( (winner, i) => {
    let image, wysiwyg = null
    winner.blocks.filter((block, i) => {
      if(block.__typename === 'ContentfulBlockImage')
        image = block.image
    })

    winner.blocks.filter((block, i) => {
      if(block.__typename === 'ContentfulBlockWysiwyg')
        wysiwyg = block
    })

    return (
      <div key={i} className="single-item span-4">
        <div className="image-wrap aspect-ratio">
          <GatsbyImage
            image={image.gatsbyImageData}
            imgStyle={{objectFit: 'cover'}}
            objectPosition='50% 50%'
            title={image.title}
            alt={image.description}
          />
        </div>
        {wysiwyg ? <BlocksRouter {...wysiwyg} /> : null}
      </div>
    )
  })


  return (
		<div ref={io} className={`block-custom ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <Slider {...settings}>
        {winners}
      </Slider>
		</div>
  )
}

export default Custom