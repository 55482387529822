import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import Intro from '../../../../animations/Intro'

function BeatTheExpert(props) {
	const { register, handleSubmit, reset, formState: { errors } } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const delayIn = 500
	const [io, ioInView] = useInView({ triggerOnce: true })

  const numberOfRaces = props.numberOfRaces

	const onSubmit = (form, e) => {
    
		e.preventDefault()
		setLoading(true)
  
		// Left is formspree input name, right is ours
		let data = new FormData()
		data.append('Name', form.name)
		data.append('Email', form.email)

		for(let i = 1; i <= numberOfRaces ; i++) {
			data.append(`Race ${i}`, form[`race-${i}`])
		}
		
		const url = `https://formspree.io/f/xoqrneow`
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('response', response.data)
					if(response.data.ok) {
					 setMessage("Submission sent")
					 reset()
					}
					setLoading(false)
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}


  const raceInput = []
  for(let i = 1; i <= numberOfRaces ; i++) {
    raceInput.push(
      <label key={i} >
        <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 50 * (i+1) } className="border-top-off border-left-off border-right-off">
          <input type="text" placeholder={`RACE ${i} WINNER`} name={`race-${i}`} {...register(`race-${i}`,{required: false, maxLength: 80, message: "error message"})} />
        </Intro>
    	</label>
    )
  }

	return (
		<form
			name="contact"
			method="post"
			action="/thanks/"
			className="beat-the-expert padd-top-half"
			onSubmit={handleSubmit(onSubmit)}
			ref={io}
		>
			<div className="form-container">
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="first &amp; last name" name="name" {...register("name",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your name is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 50 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="email" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
					</Intro>
					{errors.email && <p className="small margin-off">a correct email is required.</p>}
				</label>
	
		    {raceInput ? 
          raceInput 
          :
          null  
        }

				<Intro visible={ioInView} className="padd-top-half" in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 650 } >		
					<button className="submit" type="submit">SUBMIT{isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
				</Intro>
				<p className="message small">{message}</p>
			</div>
		
		</form>
	)
}

export default BeatTheExpert