import React, {useState, useEffect} from 'react'
import './style.scss'

function Timer(props) {

	const {t} = props

	const [x,setX] = useState('')

	useEffect(() => {
		if (t) {
			var timer = t * 60, minutes, seconds, zMinutes, zSeconds
			const timerInterval = setInterval(function () {
				minutes = parseInt(timer / 60, 10)
				seconds = parseInt(timer % 60, 10)

				zMinutes = minutes < 10 ? '0' + minutes : minutes
				zSeconds = seconds < 10 ? '0' + seconds : seconds

				let display = (minutes > 0 || seconds > 0) ? `${zMinutes}:${zSeconds}` : `0`

				setX(display)

				if (--timer < 0) {
					clearInterval(timerInterval)
				}

				return false
			}, 1000)
		

			return () => {
				clearInterval(timerInterval)
			}
		}
	}, [t])

  return (
		<span className="timer">
			{parseInt(t) > 98 ? 'Over 2hrs' : parseInt(t) === 0 ? 'Soon' : x}
		</span>
  )
}

export default Timer