import { graphql, useStaticQuery } from 'gatsby'

const useEventsDayCollection = () => {

  // if the events are not showing change the date or limit #ISSUES
  const data = useStaticQuery(graphql`query EventsBig {
  allContentfulEventDay(
    limit: 500
    filter: {date: {gt: "2024-01-01"}}
    sort: {fields: date}
  ) {
    nodes {
      name
      date
      raceEvents {
        name
        slug
        priority
        numberOfRaces
        firstPost
        featuredImage {
          gatsbyImageData(
            quality: 70
            layout: CONSTRAINED
            breakpoints: [300, 500, 750, 1080, 1366, 1920]
            formats: WEBP
          )
          title
          description
        }
        stakes {
          data
        }
        buttons {
          key
          value
        }
        excerpt {
          raw
        }
        contentApi {
          html
        }
        content {
          raw
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      events {
        name
        type
        slug
        priority
        featuredImage {
          gatsbyImageData(
            quality: 70
            layout: CONSTRAINED
            breakpoints: [300, 500, 750, 1080, 1366, 1920]
            formats: WEBP
          )
          title
          description
        }
        buttons {
          key
          value
        }
        excerpt {
          raw
        }
        content {
          raw
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      promotions {
        name
        slug
        subtitle
        type
        featuredImage {
          gatsbyImageData(
            quality: 70
            layout: CONSTRAINED
            breakpoints: [300, 500, 750, 1080, 1366, 1920]
            formats: WEBP
          )
          title
          description
        }
        excerpt {
          raw
        }
        content {
          raw
        }
        eventday {
          date
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
  }
  eventsUpcoming: allContentfulEventDay {
    nodes {
      name
    }
  }
  contentfulOptions {
    defaultRaceEventImage {
      gatsbyImageData(
        quality: 70
        layout: CONSTRAINED
        breakpoints: [300, 500, 750, 1080, 1366, 1920]
        formats: WEBP
      )
      title
      description
    }
  }
}`)

  return data

}

export default useEventsDayCollection