import React from 'react'
import './style.scss'

import BookMyExperience from './BookMyExperience'
import FeaturedEvents from './FeaturedEvents'
import EventsCalendarBig from './EventsCalendarBig'
import EventsUpcomingCalendar from './EventsUpcomingCalendar'
import ExpertPicks from './ExpertPicks'
import ExpertPicksNew from './ExpertPicksNew'
import HandicappingWithFeaturedExpert from './HandicappingWithFeaturedExpert'
import Xbtv from './Xbtv'
import Racing from './Racing'
// import RacingInformation from './RacingInformation'
import RacingInformationViaAPI from './RacingInformationViaAPI'
import RacingInformationViaAPIFull from './RacingInformationViaAPIFull'
import RacingOddsInformation from './RacingOddsInformation'
import ResultsReplaysWorkouts from './ResultsReplaysWorkouts'
import ContactUs from './ContactUs'
import Downloads from './Downloads'
import LeaderStanding from './LeaderStanding'
import StakesSchedule from './StakesSchedule'
import FeaturedExpertPicksCalendar from './FeaturedExpertPicksCalendar'
import RacingInformationJockeys from './RacingInformationJockeys'
import RacingInformationJockeysReverse from './RacingInformationJockeysReverse'
import HorseCareParallax from './HorseCareParallax'
import HorseCareAboutParallax from './HorseCareAboutParallax'
import UpcomingContests from './UpcomingContests'
import MoreContests from './MoreContests'
import ContestWinnersCustomSlider from './ContestWinnersCustomSlider'
import Leaderboard from './Leaderboard'
import GetFreeNotes from './GetFreeNotes'
import CustomMap from './CustomMap'
import PartnersSlider from './PartnersSlider'
import SilksSimulcastCenter from './SilksSimulcastCenter'
import RacingPromotions from './RacingPromotions'
import Map3D from './Map3D'
import WinLossStatementForm from './WinLossStatementForm'
import DownloadsBunch from './DownloadsBunch'
import PopMenuTest from './PopMenuTest'
import SuitesForm from './SuitesForm'
import Overnights from './Overnights'

import NaviSection from '../CustomNavigation/NaviSection'
import NaviRacingCalendar from '../CustomNavigation/NaviRacingCalendar'
import NaviEventsCalendar from '../CustomNavigation/NaviEventsCalendar'

import ThreeVideos from '../CustomGulfstream/ThreeVideos'
import CasinoPromotionsSlider from '../CustomGulfstream/CasinoPromotionsSlider'
import CustomLinks from '../CustomGulfstream/CustomLinks'
import CasinoHero from '../CustomGulfstream/CasinoHero'
import MapWithMarkers from '../CustomGulfstream/MapWithMarkers'
import MapWithMarkersNew from '../CustomGulfstream/MapWithMarkersNew'
import PromotionsCalendarBig from '../CustomGulfstream/PromotionsCalendarBig'
import BeatTheExpert from '../CustomGulfstream/BeatTheExpert'
import WagerTakeOut from '../CustomGulfstream/WagerTakeOut'
import BeatTheExpertList from '../CustomGulfstream/BeatTheExpertList'
import SlotsSlider from '../CustomGulfstream/SlotsSlider'
import TierBenefits from '../CustomGulfstream/TierBenefits'
import ClubForm from '../CustomGulfstream/ClubForm'
import SubscribeForm from '../CustomGulfstream/SubscribeForm'
import PegasusTickets from '../CustomGulfstream/PegasusTickets'

function CustomBlockRouter(props) {

  // Assets Separation
  //console.log('assets',props.assets)

  // Images
  const images = props.assets?.filter(obj => obj.__typename === 'ContentfulBlockImage')

  // Videos
  const videos = props.assets?.filter(obj => obj.__typename === 'ContentfulBlockVideo')

  // WYSIWYGs
  const wysiwygs = props.assets?.filter(obj => obj.__typename === 'ContentfulBlockWysiwyg')

  // Repeaters
  const repeaters = props.assets?.filter(obj => obj.__typename === 'ContentfulBlockRepeater')

  // Wrappers
  const wrappers = props.assets?.filter(obj => obj.__typename === 'ContentfulBlockWrapper')

  // Empty props.type Warning
  if (props.type === null)
    console.log('EMPTY CUSTOM BLOCK TYPE!')

  let block = null
  if (props.type === 'book-my-experience') return <BookMyExperience {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'featured-events') return <FeaturedEvents {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'events-calendar-big') return <EventsCalendarBig {...props} />
  if (props.type === 'events-upcoming-calendar') return <EventsUpcomingCalendar {...props} images={images} repeaters={repeaters} />
  if (props.type === 'expert-picks') return <ExpertPicks {...props} />
  if (props.type === 'expert-picks-new') return <ExpertPicksNew {...props} />
  if (props.type === 'handicapping-with-jeff-siegel') return <HandicappingWithFeaturedExpert {...props} wysiwygs={wysiwygs} />
  if (props.type === 'xbtv') return <Xbtv {...props} />
  if (props.type === 'racing-main') return <Racing {...props} />
  // if(props.type === 'racing-details-current' ) return <RacingInformation {...props} />
  //if(props.type === 'racing-details' ) return <RacingInformation {...props} />
  if (props.type === 'racing-details') return <RacingInformationViaAPIFull {...props} />
  if (props.type === 'racing-details-api') return <RacingInformationViaAPI {...props} />
  if (props.type === 'racing-odds-details') return <RacingOddsInformation {...props} />
  if (props.type === 'racing-photo-finishes') return <ResultsReplaysWorkouts {...props} mode={'photo-finishes'} />
  if (props.type === 'results-replays') return <ResultsReplaysWorkouts {...props} />
  if (props.type === 'contact-us') return <ContactUs {...props} />
  if (props.type === 'downloads') return <Downloads {...props} />
  if (props.type === 'leader-standing') return <LeaderStanding {...props} />
  if (props.type === 'stakes-schedule') return <StakesSchedule {...props} />
  if (props.type === 'featured-expert-picks-calendar') return <FeaturedExpertPicksCalendar {...props} />
  if (props.type === 'racing-information-jockeys') return <RacingInformationJockeys {...props} />
  if (props.type === 'racing-information-jockeys-reverse') return <RacingInformationJockeysReverse {...props} />
  if (props.type === 'horse-care-parallax') return <HorseCareParallax {...props} videos={videos} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'horse-care-about-parallax') return <HorseCareAboutParallax {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'upcoming-contests') return <UpcomingContests {...props} />
  if (props.type === 'contest-winners-custom-slider') return <ContestWinnersCustomSlider {...props} wrappers={wrappers} />
  if (props.type === 'more-contests') return <MoreContests {...props} />
  if (props.type === 'leaderboard') return <Leaderboard {...props} />
  if (props.type === 'get-free-notes') return <GetFreeNotes {...props} />
  if (props.type === 'custom-map') return <CustomMap {...props} />
  if (props.type === 'partners-slider') return <PartnersSlider {...props} />
  if (props.type === 'silks-simulcast-center') return <SilksSimulcastCenter {...props} repeaters={repeaters} />
  if (props.type === 'racing-promotions') return <RacingPromotions {...props} repeaters={repeaters} />
  if (props.type === '3d-map') return <Map3D {...props} repeaters={repeaters} images={images} />
  if (props.type === 'win-loss-statement-form') return <WinLossStatementForm {...props} repeaters={repeaters} images={images} />
  if (props.type === 'downloads-bunch') return <DownloadsBunch {...props} repeaters={repeaters} images={images} />
  if (props.type === 'suites-form') return <SuitesForm {...props} repeaters={repeaters} images={images} />
  if (props.type === 'overnights') return <Overnights {...props}/>

  if (props.type === 'pop-menu-test') return <PopMenuTest {...props} repeaters={repeaters} images={images} />


  //Navi Custom Pages
  if (props.type === 'navi-section') return <NaviSection {...props} videos={videos} images={images} wysiwygs={wysiwygs} repeaters={repeaters} />
  if (props.type === 'navi-racing-calendar') return <NaviRacingCalendar {...props} videos={videos} images={images} wysiwygs={wysiwygs} repeaters={repeaters} />
  if (props.type === 'navi-events-calendar') return <NaviEventsCalendar {...props} videos={videos} images={images} wysiwygs={wysiwygs} repeaters={repeaters} />


  //Gulfstream
  if (props.type === 'three-videos') return <ThreeVideos {...props} videos={videos} images={images} wysiwygs={wysiwygs} repeaters={repeaters} />
  if (props.type === 'casino-promotions-slider') return <CasinoPromotionsSlider {...props} />
  if (props.type === 'custom-links') return <CustomLinks {...props} repeaters={repeaters} />
  if (props.type === 'casino-hero') return <CasinoHero {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'map-with-markers') return <MapWithMarkers {...props} />
  if (props.type === 'map-with-markers-new') return <MapWithMarkersNew {...props} />
  if (props.type === 'promotions-calendar-big') return <PromotionsCalendarBig {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'beat-the-expert-form') return <BeatTheExpert {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'wager-take-out') return <WagerTakeOut {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'beat-the-expert-list') return <BeatTheExpertList {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'slots-slider') return <SlotsSlider {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'tier-benefits') return <TierBenefits {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'club-form') return <ClubForm {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'subscribe-form') return <SubscribeForm {...props} images={images} wysiwygs={wysiwygs} />
  if (props.type === 'pegasus-tickets') return <PegasusTickets {...props} wrappers={wrappers} />


  return block
}

export default CustomBlockRouter