import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import Intro from '../../../../animations/Intro'

function Suites(props) {
	const { register, handleSubmit, reset, formState: { errors } } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const delayIn = 500
	const [io, ioInView] = useInView({ triggerOnce: true })

  const onSubmit = (form, e) => {
    
		e.preventDefault()
		setLoading(true)
  
		// Left is formspree input name, right is ours
		let data = new FormData()
		data.append('First Name', form.fname)
		data.append('Last Name', form.lname)
		data.append('Email', form.email)
		data.append('Phone', form.phone)
		data.append('Number of Guests', form.guests)
		data.append('Preffered Date', form.date)
		data.append('Description', form.message)
		
		const url = `https://formspree.io/f/xvoljqwa`
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.ok) {
					 setMessage("Submission sent")
					 reset()
					}
					setLoading(false)
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}


	return (
		<>
		{/* <form
			method="POST"
			className="suites-form"
      name="suites-form"
			onSubmit={handleSubmit(onSubmit)}
			ref={io}
		>
			<div className='form-container'>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="First Name" name="fname" {...register("fname",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your first name is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 100 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Last Name" name="lname" {...register("lname",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.lname && <p className="small margin-off">your last name is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 200 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Email address" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
					</Intro>
					{errors.email && <p className="small margin-off">a correct email is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 300 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Phone" name="phone" {...register("phone",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.phone && <p className="small margin-off">your phone number is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 400 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Number of Guests" name="guests" {...register("guests",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.guests && <p className="small margin-off">Number of Guests is required.</p>}
				</label>
        <label>
          <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 500 } className="border-top-off border-left-off border-right-off">		
            <input type="text" onFocus={(e) => e.currentTarget.type = "date"}  placeholder="Preffered Date" name="date"  {...register("date",{required: false, maxLength: 80, message: "error message"})} />
          </Intro>
					{errors.date && <p className="small margin-off">Date is invalid</p>}
			  </label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 600 }>		
						<textarea rows="8" placeholder="Description" name="message" {...register("message")} />
					</Intro>
				</label>
        <Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 700 } >		
			  	<button className="submit" type="submit">Send {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			  </Intro>
			  <p className="message small">{message}</p>
			</div>
		</form> */}

			<iframe
				src="https://mcdg8xmk7lvyntmhlr6vs3w15q1m.pub.sfmc-content.com/dlwsyjevjbd"
				frameBorder="0"
				style={{width: '100vw', maxWidth: '100%', padding: '0', margin: '0 auto', minHeight: '530px'}}
				title="Contact form"
			/>
		</>
	)
}

export default Suites