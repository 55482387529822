import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import Intro from '../../../../animations/Intro'

function FormWinLoss(props) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  const [message, setMessage] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const delayIn = 500
  const [io, ioInView] = useInView({ triggerOnce: true })

  const onSubmit = (form, e) => {

    e.preventDefault()
    setLoading(true)

    // Left is formspree input name, right is ours
    let data = new FormData()
    data.append('Name', form.name)
    data.append('Email', form.email)
    data.append('Account', form.account)
    data.append('Date of Birth', form.date)
    data.append('Comments', form.message)

    const url = `https://formspree.io/f/mayvbkyy`
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    axios.post(url, data, config)
      .then(response => {
        console.log('status', response.data.status)
        console.log('response', response.data)
					if(response.data.ok) {
          setMessage("Submission sent")
          reset()
        }
        setLoading(false)
      }
      )
      .catch(errors => {
        console.log(errors)
      }
      )
  }


  return (
    <>

      <form
			method="POST"
			className="win-loss-statement"
      name="win-loss-statement"
			onSubmit={handleSubmit(onSubmit)}
			ref={io}
		>
			<div className='form-container padd-top-half'>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Name: First, Middle &amp; Last" name="name" {...register("name",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.name && <p className="small margin-off">your name is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 250 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Email address" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
					</Intro>
					{errors.email && <p className="small margin-off">a correct email is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 350 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Account #" name="account" {...register("account",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.account && <p className="small margin-off">your account number is required.</p>}
				</label>
        <label>
          <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 450 } className="border-top-off border-left-off border-right-off">		
            <input type="text" onFocus={(e) => e.currentTarget.type = "date"}  placeholder="Date of Birth" name="date"  {...register("date",{required: true, maxLength: 80, message: "error message"})} />
          </Intro>
					{errors.date && <p className="small margin-off">Date is invalid</p>}
			  </label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 550 }>		
						<textarea rows="8" placeholder="Comments or Additional Requests" name="message" {...register("message")} />
					</Intro>
				</label>
        <Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 650 } >		
			  	<button className="submit" type="submit">Send {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			  </Intro>
			  <p className="message small">{message}</p>
			</div>
		</form>
      {/* <iframe
        src={"https://cloud.experience.gulfstreampark.com/winlosscasino"}
        style={{ order: '100', width: '100vw', maxWidth: '100%', margin: '0 auto', border: 'none', height: '500px', marginTop: '1em' }}
        title={"Win Loss Statement"}
      /> */}
    </>
  )
}

export default FormWinLoss