import React, {useState,useCallback} from 'react'
import './style.scss'

import BlocksRouter from '../../Sections/BlocksRouter'

import Calendar from '../../Calendar'
import validatePdf from '../../../utils/validatePdf'

function Picks(props) {
	const [calendarSelector, setCalendarSelector] = useState({dateSelected: null})

	// Function to add or substract months in dates
	const pastDateRange = props.pastDateRange
	const futureDateRange = props.futureDateRange

	const events = props.picks?.data

	const calendarSelectorFunction = useCallback((i) => {
		setCalendarSelector(i)
	},[])

	// Find matching pick to selected date in calendar
	const matchingPick = props.picks?.data.find(element => {
		// console.log('d',calendarSelector?.dateSelected)
		let d = new Date(calendarSelector?.dateSelected).setHours(0,0,0,0)
		let dx = new Date(element.date?.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0,0,0,0)
		if (d ===dx) {
			return true
		}
		return false
	})
  return (
		<>
		<div id="picks-calendar-view" className={`block-picks c3 ${props.classes}`}>
			<div className="calendar-wrap arrow-right padd">
				<h4 className='select-date-to-view-picks'>SELECT DATE TO VIEW PICKS</h4>
				<Calendar handleSelectionClick={() => false} filterCalendar={() => false} selector={calendarSelectorFunction} minDate={pastDateRange} maxDate={futureDateRange} events={events} />
			</div>
			<div className="pick padd c0">
				<div className="align">
					<h5>{calendarSelector?.dateSelected?.toLocaleDateString([], {weekday: 'short', day: '2-digit', month:'2-digit'})}</h5>
					<p>Picks are pre weather and scratches. Good luck!</p>
					<BlocksRouter blocks={[{...matchingPick, __typename: "ContentfulBlockWysiwyg"}]} />
					{matchingPick?.file?.url ?
						<p className="fs-85"><a className="btn arrow" href={validatePdf(matchingPick?.file.url)} target="_blank" rel="noreferrer noopener" alt={matchingPick?.file.title}><span>Picks PDF</span></a></p>
					: null}
				</div>
			</div>
		</div>
		<BlocksRouter blocks={[{content: matchingPick?.blog, __typename: "ContentfulBlockWysiwyg", classes: "text-left padd story"}]} />
		</>

  )
}

export default Picks