import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import validatePdf from '../../../../utils/validatePdf'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })
	

  const heading = props?.custom?.data?.heading

  const contests = props?.custom?.data?.contests.map( (contest, i) => {

    return (
      <div key={i} className="single-item span-4 span-12-mobile">
        <Intro visible={ioInView} in={{ border: 450, fade: 270 }} delayIn={75 * i} className={`innerborder`}>
          <div key={i} className="image-wrap aspect-ratio">
            <div className="gatsby-image-wrapper">
              <img src={contest.imageURL} title={contest.title} alt={contest.title} />
            </div>
          </div>
          <div className="content padd padd-left-half uppercase">
            <span className="type fs-85">{contest.type}</span>
            <h4 className="contest-title">{contest.title}</h4>
            <p>{contest.excerpt}</p>
            <p>{contest.description}</p>
            
            <a href={validatePdf(contest.url)} className="btn regular">
              <span>{contest.button}</span>
            </a>
          </div>
        </Intro>
      </div>
    )
  })


  	return (
		<div ref={io} className={`block-custom ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className="title padd padd-left-half padd-bottom-half">
        <h1>{heading}</h1>
      </div>
      <div className="flex-12">
        {contests}
      </div>
		</div>
  	)
}

export default Custom