import React, { useEffect, useRef, useState } from 'react'

import './style.scss'
import { useInView } from 'react-intersection-observer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClosedCaptioning } from '@fortawesome/free-solid-svg-icons'

// // Convert time to readable format
// function convertTime(time) {
// 	let minutes = Math.floor(time / 60)
// 	let seconds = Math.floor(time - minutes * 60)
// 	let minuteValue
// 	let secondValue

// 	if (minutes < 10) {
// 		minuteValue = '0' + minutes
// 	} else {
// 		minuteValue = minutes
// 	}

// 	if (seconds < 10) {
// 		secondValue = '0' + seconds
// 	} else {
// 		secondValue = seconds
// 	}

// 	let mediaTime = minuteValue + ':' + secondValue

// 	return mediaTime
// }

// // Prepare time UI
// function setTime(media) {
// 	if(media) {

// 		let current = convertTime(media.currentTime)
// 		let total = convertTime(media.duration)

// 		let timeUi = `${current} / ${total}`

// 		return timeUi

// 	}
// }

function Vimeo(props) {
	const player = useRef(null)
	const [io, ioInView] = useInView({ triggerOnce: false })

	// const [currentTime, setCurrentTime] = useState('00:00')
	// const [isPlaying, setIsPlaying] = useState(false)
	const [isMuted, setIsMuted] = useState(true)
  const [isCaptionsOff, setIsCaptionsOff] = useState(false)
	const hover = props.hover ? props.hover : false
	const hideVolumeButton = props.hideVolumeButton ? props.hideVolumeButton : false

  const hideCaptionsButton = props.showControls || props.alwaysMuted ? true : false
  const alwaysMuted = props.alwaysMuted ? true : false

	// Play when in view
	useEffect(() => {
		ioInView && !hover ? vPlay() : vPause()
	},[ioInView, hover])

	// Play
	function vPlay() {
		player?.current?.play().catch((e)=>{
			console.log('video play error', e)
	 })
		// setIsPlaying(true)
	}

	// Play from beginning
	function vHoverPlay() {
		player?.current?.play().catch((e)=>{
			console.log('video play error', e)
		})
		// player.current.currentTime = 0 
		// setIsPlaying(true)
	}

	// Toggle Reveal
	function vReveal(e) {
		vPlay()
		vMute()
		if(isMuted) {
			player.current.currentTime = 0
		}
	}
	
	// Mute / Unmute
	function vMute() {
		setIsMuted(!isMuted)
	}

  // Show / Hide Captions
  function vCaptions() {
    setIsCaptionsOff(!isCaptionsOff)
    const track = player?.current?.textTracks?.[0]
    track.mode = isCaptionsOff ? "showing" : "hidden"
  }

	// Pause
	function vPause() {
		player?.current?.pause()
		// setIsPlaying(false)
	}

	// Pause and Restart
	function vHoverPause() {
		player?.current?.pause()
		// player.current.currentTime = 0 
		// setIsPlaying(false)
	}
	
	// Time
	// useEffect(() => {
	// 	const timeupdate = (e) => {
	// 		setCurrentTime(setTime(e.target.currentTime))
  //   }
	// 	player?.current?.addEventListener('timeupdate', timeupdate)

	// 	return () => player?.current?.removeEventListener('timeupdate', timeupdate)
	// },[])

	const source = props.file + '#t=0.1'

	return (
		<>
			{props.file ?
				<div ref={io} className='video-html-wrap inview' onClick={(e) => vReveal(e)} onKeyDown={(e) => vReveal(e)} role="button" aria-label="Reveal video" tabIndex={0}>
					{/* <button onClick={vPlay}>play</button> */}
					{/* <button onClick={vPause}>pause</button> */}
					{/* <button onClick={vMute}>sound is {isMuted ? 'off' : 'on'}</button> */}
					{/* {currentTime} | {isPlaying ? 'playing' : 'not playing'} */}
          <div className="video-buttons">
            {props?.subtitles ?
              <div className={`captions-indicator ${hideCaptionsButton ? 'hide' : ''}`} onClick={(e) => { e.stopPropagation(); vCaptions() }} onKeyDown={(e) => { e.stopPropagation(); vCaptions() }} role="button" aria-label="Show captions" tabIndex={0}>
                {isCaptionsOff ?
                  <div className="cc-icon-off animate-in-from-top"><FontAwesomeIcon icon={faClosedCaptioning} /></div>
                  :
                  <div className="cc-icon-on animate-in-from-bottom"><FontAwesomeIcon icon={faClosedCaptioning} /></div>
                }
              </div>
              :
              null
            }
					<div className={`mute-indicator ${hideVolumeButton ? 'hide' : ''}`} onClick={vMute} onKeyDown={vMute} role="button" aria-label="Mute video" tabIndex={0}>
						{
							isMuted ?
							<div className="muted-on animate-in-from-top">
								<svg viewBox="0 0 32 32">
									<g>
										<rect x="24.8" y="10" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.7396 22.9129)" width="2" height="12"/>
										<rect x="19.8" y="15" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.7396 22.9129)" width="12" height="2"/>
									</g>
									<path d="M19.8,2.6l-11.5,8H2.7v10.7h5.6l11.5,8.3V2.6z"/>
								</svg>
							</div> :
							<div className="muted-off animate-in-from-bottom">
								<svg viewBox="0 0 24 32">
									<path d="M19.8,2.6l-11.5,8H2.7v10.7h5.6l11.5,8.3V2.6z"/>
								</svg>
							</div>
						}
					</div>
          </div>
          <video
            controls={props.showControls}
            ref={player}
            muted={isMuted ? true : null}
            onTouchStart={() => hover ? vHoverPlay() : null}
            onMouseEnter={() => hover ? vHoverPlay() : null}
            onMouseLeave={() => hover ? vHoverPause() : null}
            onTouchEnd={() => hover ? vHoverPause() : null}
            loop
            playsInline
            disablePictureInPicture
            allow="autoplay"
            title={props.title}
          >
            <source src={source} type="video/mp4" />
            <track
              label="English"
              kind="captions"
              srcLang="en"
              src={props?.subtitles ? props?.subtitles : null}
              default={true}
            />
            <meta itemProp="name" content={props.title}/>
            <meta itemProp="description" content={props.description}/>
          </video>
        </div>
        : null}
    </>
  )
}

export default Vimeo