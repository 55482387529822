import React, { useContext, useEffect } from 'react'
import '../EVG/css/_core.scss';

import Header from './Header'
// import useKlaviyoFormId from '../utils/useQueryKlaviyoFormId';

import { ParallaxProvider } from 'react-scroll-parallax'

import FsLightbox from 'fslightbox-react'
import FSLightBoxContext from '../context/FSLightBoxContext'

import { Auth0Provider } from "../utils/auth"
import { navigate } from "gatsby"
import { Helmet } from 'react-helmet';
import Preakness from '../preakness';
import Seo from './seo';

if (typeof window !== 'undefined') {

  // Set up pace js
  window.paceOptions = {
    className: 'dss-loader',
    ajax: false, // ajax monitoring - disabled
    restartOnPushState: false, // no restart on push state
    document: false, // document ready state monitoring - disabled
    eventLag: false, // monitoring of event loop lag, signaling js is being executed - disabled
    elements: {
      selectors: ['.hero', '.main-frontpage'] // looks for existance of this element
    }
  }
}

function Layout(props) {
  const lightBoxContext = useContext(FSLightBoxContext)
  console.log(props.path)

  // const data = useKlaviyoFormId().allContentfulPromotion.nodes
  // .filter(node => node.klaviyoId)
  // .map((node, i) => <div key={i} className={`klaviyo-form-${node.klaviyoId}`} />)

  const onRedirectCallback = (appState) => {
    navigate(
      appState && appState.returnTo
        ? appState.returnTo
        : props.location.pathname
    )
  }

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//534001827.collect.igodigital.com/collect.js"
    script.async = true

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  // Conditionally don't show top and bottom nav
  if (props.children.props.location.pathname === '/preakness-stakes/') {
    return <Preakness />
  }

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      client_id={process.env.GATSBY_AUTH0_CLIENT_ID}
      redirect_uri={props.location.origin + '/logging-in/'}
      cacheLocation='localstorage'
      onRedirectCallback={onRedirectCallback}
    >

      <Helmet>
        {/* <script async type="text/javascript" src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=PcWfBY"></script> */}
        <script src="https://offer.fevo.com/js/fevo.js"/>
        <script type="text/javascript" src="//ndn.statistinamics.com/cstnxtm/9E0C2D97-97D9-4AA6-8B0F-E28BF9ED2986.js" async></script>
        <script type="text/javascript" src="https://ten-palms.popmenu.com/s/pm.js"></script>
        <script type="text/javascript">{
          `var _etmc = [];
          _etmc.push(["setOrgId", "534001827"]);
          _etmc.push(["trackPageView"]);`
        }
        </script>

        {typeof window !== 'undefined' && window?.location?.pathname === "/driving-directions" ?
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Gulfstream Park Racing & Casino",
            "image": "",
            "@id": "",
            "url": "https://www.gulfstreampark.com",
            "telephone": "954-454-7000",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "901 S Federal Hwy",
              "addressLocality": "Hallandale Beach",
              "addressRegion": "FL",
              "postalCode": "33009",
              "addressCountry": "US"
            }  
          }`}
          </script>
          : null}

      </Helmet>


      {typeof window !== 'undefined' && props.path.startsWith('/assets') ? 
        // Contentful assets SEO only
        <Seo 
          title={props?.location?.search?.split(".pdf")?.[0]?.split('/')?.reverse()?.[0]} 
          path={props?.location?.pathname + props?.location?.search}
        />
      :
			  <Header {...props.data} />
      }

      <div className={`hidden`}>
        {/* {data} */}
      </div>

      <ParallaxProvider>
        {props.children}
      </ParallaxProvider>

      {lightBoxContext.sources ?
        <FsLightbox
          toggler={lightBoxContext.toggler}
          sources={lightBoxContext.sources}
          thumbs={lightBoxContext.thumbs}
          thumbsIcons={lightBoxContext.thumbsIcons}
          slide={lightBoxContext.slide}
          key={lightBoxContext.sources[0]}
          //type="image"
          disableThumbs={true}
        />
        : null}

    </Auth0Provider>
  )
}

export default Layout