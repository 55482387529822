import React from 'react'

import GoogleMapReact from 'google-map-react'
// import Marker from '../../Map/Marker'

import './style.scss'

function GMap(props) {

	const gMapKey = process.env.GATSBY_GOOGLE_API_KEY

	const defaultProps = {
		center: {
			lat: props.lat,
			lng: props.lng,
		},
		zoom: props.zoom,
	}

	return (
		<GoogleMapReact
			bootstrapURLKeys={{ key: gMapKey }}
			defaultCenter={defaultProps.center}
			defaultZoom={defaultProps.zoom}
			options={
				{
					styles: [
					  {
					    "elementType": "labels",
					    "stylers": [
					      {
					        "visibility": "on"
					      },
					      {
					        "weight": 3
					      }
					    ]
					  },
					  {
					    "elementType": "labels.icon",
					    "stylers": [
					      {
					        "visibility": "off"
					      },
					      {
					        "weight": 2.5
					      }
					    ]
					  },
					  {
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#ffffff"
					      },
					      {
					        "saturation": 35
					      }
					    ]
					  },
					  {
					    "elementType": "labels.text.stroke",
					    "stylers": [
					      {
					        "color": "#000"
					      },
					      {
					        "lightness": 5
					      },
					      {
					        "visibility": "on"
					      },
					      {
					        "weight": 3
					      }
					    ]
					  },
					  {
					    "featureType": "administrative",
					    "elementType": "geometry.fill",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      },
					      {
					        "lightness": 12
					      }
					    ]
					  },
					  {
					    "featureType": "administrative",
					    "elementType": "geometry.stroke",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      },
					      {
					        "lightness": 8
					      },
					      {
					        "weight": 1
					      }
					    ]
					  },
					  {
					    "featureType": "administrative.country",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#2C9BF4"
					      }
					    ]
					  },
					  {
					    "featureType": "administrative.locality",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#2C9BF4"
					      }
					    ]
					  },
					  {
					    "featureType": "administrative.neighborhood",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#2C9BF4"
					      }
					    ]
					  },
					  {
					    "featureType": "landscape",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      },
					      {
					        "lightness": 1
					      },
					      {
					        "weight": 1.5
					      }
					    ]
					  },
					  {
					    "featureType": "poi",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      },
					      {
					        "lightness": 8
					      },
					      {
					        "visibility": "on"
					      }
					    ]
					  },
					  {
					    "featureType": "poi.business",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2C9BF4"
					      },
					      {
					        "lightness": 12
					      },
					      {
					        "visibility": "on"
					      }
					    ]
					  },
					  {
					    "featureType": "road.arterial",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      },
					      {
					        "lightness": 14
					      }
					    ]
					  },
					  {
					    "featureType": "road.arterial",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#ffffff"
					      }
					    ]
					  },
					  {
					    "featureType": "road.arterial",
					    "elementType": "labels.text.stroke",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      }
					    ]
					  },
					  {
					    "featureType": "road.highway",
					    "elementType": "geometry.fill",
					    "stylers": [
					      {
					        "color": "#aaaaaa"
					      },
					      {
					        "lightness": "0"
					      }
					    ]
					  },
					  {
					    "featureType": "road.highway",
					    "elementType": "geometry.stroke",
					    "stylers": [
					      {
					        "visibility": "off"
					      }
					    ]
					  },
					  {
					    "featureType": "road.highway",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#ffffff"
					      }
					    ]
					  },
					  {
					    "featureType": "road.highway",
					    "elementType": "labels.text.stroke",
					    "stylers": [
					      {
					        "color": "#121212"
					      }
					    ]
					  },
					  {
					    "featureType": "road.local",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      },
					      {
					        "lightness": 7
					      }
					    ]
					  },
					  {
					    "featureType": "road.local",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#ffffff"
					      }
					    ]
					  },
					  {
					    "featureType": "transit",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2c2c2c"
					      },
					      {
					        "lightness": 19
					      }
					    ]
					  },
					  {
					    "featureType": "water",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2C9BF4"
					      },
					      {
					        "lightness": 0
					      }
					    ]
					  }
					]
				}
			}
		>
			{/* <Marker
				lat={props.lat}
				lng={props.lng}
				text={'This fucking map mang...'}
			/> */}
		</GoogleMapReact>
	)
}

export default GMap