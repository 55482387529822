import React, { useEffect, useRef, useState } from 'react'
import './style.scss'

import Intro from '../../../../../../animations/Intro'
import use1stAPI from '../../../../../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Custom(props) {
  const { currentRace } = props
  const headRef = useRef()
  const panRef = useRef()


  const { data: replayHEAD, loading: replayHEADLoading } = use1stAPI('replay', { ...currentRace, replayParameters: { ...props.currentRace.replayParameters, view_type: 'VIEW_TYPE_HEAD_ON' } })
  const { data: replayPAN, loading: replayPANLoading } = use1stAPI('replay', { ...currentRace, replayParameters: { ...props.currentRace.replayParameters, view_type: 'VIEW_TYPE_PAN' } })


  const [replayHEADurl, setReplayHEADurl] = useState()
  const [replayPANurl, setReplayPANurl] = useState()


  const headElementWidth = headRef?.current?.clientWidth
  const panElementWidth = panRef?.current?.clientWidth

  // Track loading status changes
  const [loadingHEAD, setLoadingHEAD] = useState(true)
  const [loadingPAN, setLoadingPAN] = useState(true)
  useEffect(() => {
    if (!replayHEADLoading) {
      setLoadingHEAD(false)
    } else {
      setLoadingHEAD(true)
    }
    if (!replayPANLoading) {
      setLoadingPAN(false)
    } else {
      setLoadingPAN(true)
    }
  }, [replayHEADLoading, replayPANLoading])

  // Monitor replay change so it can switch to them
  useEffect(() => {
    if (replayHEAD && !replayHEADurl) {
      let url = new URL(replayHEAD.url)
      if (url) {
        if (url.searchParams.get('width')) {
          url.searchParams.set('width', headElementWidth)
        }
        if (url.searchParams.get('height')) {
          url.searchParams.set('height', Math.floor(headElementWidth * 0.56))
        }
      }
      setReplayHEADurl(url)
    }
  }, [replayHEAD?.url, headElementWidth])

  // Monitor replay change so it can switch to them
  useEffect(() => {
    if (replayPAN && !replayPANurl) {
      let url = new URL(replayPAN.url)
      if (url) {
        if (url.searchParams.get('width')) {
          url.searchParams.set('width', panElementWidth)
        }
        if (url.searchParams.get('height')) {
          url.searchParams.set('height', Math.floor(panElementWidth * 0.56))
        }
      }
      setReplayPANurl(url)
    }
  }, [replayPAN?.url, panElementWidth])


  return (
    <div className="flex-12">
      <div ref={headRef} className="span-6 span-12-mobile">
        <h6 className="replay-type">Head On:</h6>
        {replayHEADurl && !loadingHEAD ?
          <Intro visible={true} in={{ border: 500, bg: 700, fade: 500 }} delayIn={100} className="c5 border-c5 t replays">
            <div className="aspect-ratio c5">
              <iframe src={replayHEADurl} frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
            </div>
          </Intro>
          : loadingHEAD ?
            <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
            : <h6>N/A</h6>}
      </div>
      <div ref={panRef} className="span-6 span-12-mobile">
        <h6 className="replay-type">Pan:</h6>
        {replayPANurl && !loadingPAN ?
          <Intro visible={true} in={{ border: 500, bg: 700, fade: 500 }} delayIn={200} className="c5 border-c5 t replays">
            <div className="aspect-ratio c5">
              <iframe src={replayPANurl} frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
            </div>
          </Intro>
          : loadingPAN ?
            <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
            : <h6>N/A</h6>}
      </div>
    </div>
  )
}

export default Custom