import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../animations/Intro'

import Weather from '../Weather'

function BoxInfo(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

	const { date, venue, title, subtitle, surface } = props

	const today = date ? new Date(date) : new Date()

  return (
		<div ref={io} className={`box-info`}>
			<Intro visible={ioInView} in={{ border: 300, fade: 0 }} className="border-bottom-off border-right-off">
				<div className='logo has-intro'>
					<Intro visible={ioInView} in={{ border: 300, bg: 300, blink: 0 }} className="c3 t border-top-off border-left-off">
						<div className={`logo logo-${venue}`}></div>
					</Intro>
				</div>
				<div className="logo-aside border-c5 has-intro">
					<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} className="c1 t border-top-off border-left-off">
						<div dangerouslySetInnerHTML={{ __html: title }} />
					</Intro>
				</div>
				<div className="logo-aside-arrow border-c5 has-intro">
					<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={350} className="c1 t border-top-off border-left-off">
						<div dangerouslySetInnerHTML={{ __html: subtitle }} className="arrow" />
					</Intro>
				</div>
				<div className="expanded-info c0 border-c5 t has-intro">
					<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className="border-left-off">
						<div className="date">
							<h5 dangerouslySetInnerHTML={{ __html: today.toLocaleDateString(['en-US'], {weekday: 'long', month: 'long', day: '2-digit'}).replace(/ /, "<br />") }} />
						</div>
						<div className="weather">
							<Weather />
						</div>
					</Intro>
				</div>
				<div className="expanded-info border-c5 t has-intro">
					<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={500} className="c5 t border-left-off">
						<div className="date">
							<div dangerouslySetInnerHTML={{ __html: surface }} />
						</div>
					</Intro>
				</div>
			</Intro>
		</div>
  )
}

export default BoxInfo