import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'
import Calendar from '../../../Calendar'
import { Link } from 'gatsby'
import Wysiwyg from '../../Wysiwyg'

import { GatsbyImage } from 'gatsby-plugin-image'



import useEventsDayCollection from '../../../../utils/useQueryEventsDayCollection'

// Function to add or substract months in dates
function addMonths(date, months) {
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

// The fun starts here
function Custom(props) {
  const data = useEventsDayCollection()
  const baseDelay = props.baseDelay ? props.baseDelay : 100

  const [io, ioInView] = useInView({ triggerOnce: true })

  const [calendarSelector, setCalendarSelector] = useState({ dateSelected: null, currentViewStart: null, currentViewEnd: null })
  const [events, setEvents] = useState()


  // Using callback to avoid rerenders on useEffects for those functions
  const calendarSelectorFunction = useCallback((i) => {
    setCalendarSelector(i)
  }, [])

  const selectedDate = new Date(calendarSelector)

  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const todayDay = todayDate.getDate()
  const pastDateRange = addMonths(new Date(todayYear, todayMonth, 2), 0).toISOString()
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 3).toISOString()
  const todayLimit = new Date(todayYear, todayMonth, todayDay).toISOString()

  const dataDateLimit = data.allContentfulEventDay.nodes.filter(day => {
    const date = new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).toISOString()
    return (Array.isArray(day.promotions) && date >= calendarSelector?.currentViewStart?.toISOString() && date < calendarSelector?.currentViewEnd?.toISOString() && date >= todayLimit && date <= futureDateRange)
  }).filter(day => day.promotions.length)


  // Only Casino Promotions
  const currentView = dataDateLimit.map(day => {
    day.promotions = day.promotions.filter(promo => promo.type === 'Casino Promotion')
    return day
  }).filter(day => day.promotions.length)


  currentView.sort(function (a, b) {
    return new Date(a.date) - new Date(b.date)
  })

  // Set navi height variable for sticky dates
  useEffect(() => {
    let naviHeight = document.querySelector('#bar-top').offsetHeight + document.querySelector('#bar-logo').offsetHeight
    let naviDesktopHeight = document.querySelector('#bar-top')?.offsetHeight + document.querySelector('#bar-logo')?.offsetHeight + document.querySelector('.master-navi-animation')?.offsetHeight - 1
    document.documentElement.style.setProperty("--navi-mobile-height", naviHeight + "px");
    document.documentElement.style.setProperty("--navi-desktop-height", naviDesktopHeight + "px");
  }, [])

  useEffect(() => {
    const stickyElement = document.querySelector('.block-calendar')
    const observerTarget = document.querySelector('.observer-flag')

    if (observerTarget && stickyElement) {
      let observer = new IntersectionObserver(function (entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            stickyElement.classList.remove('is-sticky')
          } else {
            stickyElement.classList.add('is-sticky')
          }
        })
      })

      observer.observe(observerTarget)

      return () => {
        observer.unobserve(observerTarget)
      }
    }
  }, [calendarSelector.currentViewStart, calendarSelector.currentViewEnd])

  // Update 
  useEffect(() => {
    setEvents(currentView)
  }, [calendarSelector.currentViewStart, calendarSelector.currentViewEnd])

  // Scroll to top after month change 
  useEffect(() => {
    //console.log('scroll')
    if (document.querySelector('#calendar-top')) {
      window.setTimeout(() => window.scrollTo({
        top: window.pageYOffset + document.querySelector('#calendar-top')?.getBoundingClientRect().top,
        behavior: 'smooth'
      }), 200)
    }
  }, [calendarSelector.currentViewStart, calendarSelector.currentViewEnd])

  // The manual event click on calendar invokes scroll to said part
  function handleSelectionClick(dateSimplified) {
    if (dateSimplified && typeof window !== 'undefined') {
      //const href = 'on-' + (new Date(dateSimplified)).toMyString()
      const href = 'on-' + (new Date(typeof dateSimplified === 'string' ? dateSimplified.replace(/-/g, '\/').replace(/T.+/, '') : dateSimplified)).toMyString()
      const hash = href.split('?')[0].split('=')[0]

      let mimic = document.querySelector('.block-calendar')?.clientHeight + document.querySelector('#bar-logo')?.clientHeight + document.querySelector('#bar-top')?.clientHeight + document.querySelector('.master-navi-animation')?.clientHeight
      let mimicMobile = document.querySelector('#bar-logo')?.clientHeight + document.querySelector('#bar-top')?.clientHeight
      if (document.querySelectorAll('#' + hash).length > 0) {
        let scrollValue = mimic ? window.pageYOffset + document.querySelector('#' + hash)?.getBoundingClientRect().top - mimic : window.pageYOffset + document.querySelector('#' + hash)?.getBoundingClientRect().top - mimicMobile
        document.querySelector('.image.focused')?.classList?.remove('focused')
        document.querySelector(('#' + hash) + '~.image').classList.add('focused')

        window.scrollTo({
          top: scrollValue,
          behavior: 'smooth'
        })
      }
      //else {
      //   // Second chance
      //   setTimeout(() => {
      //     if (document.querySelectorAll('#' + hash).length > 0) {
      //       let scrollValue = mimic ? window.pageYOffset + document.querySelector('#' + hash)?.getBoundingClientRect().top - mimic : window.pageYOffset + document.querySelector('#' + hash)?.getBoundingClientRect().top - mimicMobile
      //       window.setTimeout(() => window.scrollTo({
      //         top: scrollValue,
      //         behavior: 'smooth'
      //       }), 200)
      //     }
      //   }, 1500)
      // }
    }
  }

  let promotionsBunch = []
  currentView.forEach(day => {
    promotionsBunch = promotionsBunch.concat(day.promotions)
  })

  const map = new Map(promotionsBunch.map(promo => [promo.slug, promo]));
  const uniquePromotions = [...map.values()];

  const eventsGrouped = uniquePromotions.map((promo, i) => {
    const theDayToUrl = promo?.eventday?.map((item, i) => {
      return <div key={`link-${i}`} id={`on-${new Date(item.date.replace(/-/g, '\/').replace(/T.+/, '')).toMyString()}`} className="anchor"></div>
    })

    return (
      <Intro key={i} visible={ioInView} in={{ border: 500, appear: 0 }} delayIn={baseDelay * i} stay={false} className="casino-promo-tile span-4 span-12-mobile c0 border-c5 border-top-off border-left-off t">
        <div >
          {theDayToUrl}
          {promo?.featuredImage ?
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay * i + 500} stay={false} className="c5 t border-c5 border-left-off image">
              <Link to={`/promotion/${promo.slug}/`} className="nostrike">
                <GatsbyImage
                  image={promo?.featuredImage?.gatsbyImageData}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  title={promo?.featuredImage?.title}
                  alt={promo?.featuredImage?.description}
                />
              </Link>
            </Intro>
            : null}
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={baseDelay * i + 500} stay={false} className="description c0 border-c5">
            {promo?.type ? <h6>{promo?.type}</h6> : null}
            <Link to={`/promotion/${promo?.slug}/`} className="promo-title nostrike"><h4>{promo?.name}{promo?.subtitle ? <span className="sub">{promo?.subtitle}</span> : null}</h4></Link>
            {promo.excerpt ? <Wysiwyg content={promo.excerpt} /> : null}
          </Intro>
        </div>
      </Intro>
    )
  })

  return (
    <>
      <div className='observer-flag' />
      <div ref={io} className={`block-custom promotions-calendar-big events-calendar-big ${props.classes}`}>
        {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
        <div className='white-placeholder'>
          <div id="calendar-top" className='anchor'></div>
        </div>
        {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

        <div className='calendar-frame'>
          <Calendar handleSelectionClick={handleSelectionClick} filterCalendar={() => false} selector={calendarSelectorFunction} allowPast={false} minDate={pastDateRange} maxDate={futureDateRange} events={events} ui={'full'} classes={'c0'} />
        </div>

        <Intro switch={calendarSelector.currentViewStart} in={{ appear: 0 }} delayIn={10} out={{ fade: 700 }} className="">
          <div className={`events-container flex-12`}>
            {eventsGrouped.length > 0 ?
              eventsGrouped
              :
              <h2 className="text-center padd-half-top w-100">Sorry, no promotions this month</h2>
            }
          </div>
        </Intro>
      </div>
    </>
  )
}

export default Custom