import React, { useState, useCallback } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'

import Calendar from '../../../Calendar'
//import {photoFinishes} from './data-photo-finishes'
import Results from './Results'
import Workouts from './Workouts'
import RaceControl from './RaceControl'

// Function to add or substract months in dates
function addMonths(date, months) {
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

function Custom(props) {
  const { mode } = props
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [filteredBatch, setFilteredBatch] = useState({
    type: mode === 'photo-finishes' ? 'photo-finishes' : 'results',
    batch: null,
  })
  const [currentTab, setCurrentTab] = useState(0)
  const [distance, setDistance] = useState()

  const [calendarSelector, setCalendarSelector] = useState({ dateSelected: null, currentViewStart: null, currentViewEnd: null })


  // Using callback to avoid rerenders on useEffects for those functions
  const calendarSelectorFunction = useCallback((i) => {
    setCalendarSelector(i)
  }, [])

  // function handleFilterClick(e,type,batch) {
  // 	e.preventDefault()
  // 	setFilteredBatch(() => ({type: type, batch: batch}))
  // 	document.querySelectorAll('.filter-trigger')?.forEach(e => e.classList.remove('current'))
  // 	e.currentTarget.classList.add('current')
  // 	//setCalendarSelector((old) => ({...old, dateSelected: null}))
  // }

  const selectedDate = new Date(calendarSelector.dateSelected)

  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const pastDateRange = addMonths(new Date(todayYear, todayMonth, 2), -4).toISOString()
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 1).toISOString()

  // The content of selected date obj
  let program = null
  let racesToControl = null
  const displayedContent = filteredBatch?.batch?.filter(function (node) {

    const givenDaySimplified = new Date(node.date).setHours(0, 0, 0, 0)
    const selectedDaySimplified = new Date(calendarSelector.dateSelected).setHours(0, 0, 0, 0)

    if (givenDaySimplified === selectedDaySimplified) {
      return true
    }
    return false

  }).map((node, i) => {
    if (filteredBatch.type === 'results') {
      program = node.program
      racesToControl = node?.races
      return (
        <Results key={i} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} results={node.races} replays={true} photoFinishes={false} />
      )
    } else if (filteredBatch.type === 'workouts') {
      program = node.programWorkouts
      racesToControl = node?.workouts
      return (
        <Workouts key={i} currentTab={currentTab} setCurrentTab={setCurrentTab} workouts={node.workouts} />
      )
    } else if (filteredBatch.type === 'photo-finishes') {
      program = node.program
      racesToControl = node?.races
      return (
        <Results key={i} currentTab={currentTab} setCurrentTab={setCurrentTab} distance={distance} setDistance={setDistance} results={node.races} replays={false} photoFinishes={true} />
      )
    } else {
      return <h3>Not filtered</h3>
    }
  })


  return (
    <div ref={io} className={`block-custom block-results-replays-component calendar-full flex-12 ai-flex-start ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

      <div className="span-12 c0">

        <Calendar
          handleSelectionClick={() => false}
          filterCalendar={() => false}
          selector={calendarSelectorFunction}
          minDate={pastDateRange}
          maxDate={futureDateRange}
          events={filteredBatch?.batch}
          allowPast={true}
          ui={'zero'}
          classes={'c0 padd-half padd-bottom-off'}
        />

        {/* { mode !== 'photo-finishes' ?
					<div className="filter-wrap">
						<div className="has-intro btn filter-trigger current" onClick={(e) => handleFilterClick(e,'results',races)}>
							<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={0} className="c0 t">
								<span>Results &amp; Replays</span>
							</Intro>
						</div>
						<div className="has-intro btn filter-trigger" onClick={(e) => handleFilterClick(e,'workouts',workouts)}>
							<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100} className="c0 t">
								<span>Workouts</span>
							</Intro>
						</div>
					</div>
				: null } */}

        <Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} className="racing-results-wrap">
          <div className="calendar-selection-content flex-12">
            <div className="span-6 span-12-tablet padd-half">
              <h3 className="results-heading">{filteredBatch.type}</h3>
              <h5>{selectedDate?.toLocaleDateString(['en-US'], { weekday: 'long', month: 'long', day: 'numeric' })}</h5>
              <div className="race-control-download">
                <Intro switch={calendarSelector?.dateSelected && filteredBatch?.type} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="">
                  <RaceControl toControl={racesToControl} currentTab={currentTab} setCurrentTab={setCurrentTab} program={program} />
                </Intro>
              </div>
            </div>
            <Intro switch={calendarSelector?.dateSelected && filteredBatch?.type} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="">
              {displayedContent}
            </Intro>
          </div>
        </Intro>

      </div>

    </div>
  )
}

export default Custom