import React, { useEffect, useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'


import { useStaticQuery, graphql } from "gatsby"
import ExpertPick from '../ExpertPick'


function Custom(props) {

  const [io, ioInView] = useInView({ triggerOnce: true })
  const [expertsHidden, setExpertsHidden] = useState(true)
  const FEATURED_EXPERTS_NUM = 7
  const isEspanolSection = props?.directField === "espanol-expert-picks" ? true : false


  const data = useStaticQuery(graphql`query ExpertsNew {
  allContentfulPerson(
    limit: 40
    sort: {fields: order}
    filter: {type: {eq: "Expert"}}
  ) {
    nodes {
      name
      featured
      type
      title
      bio {
        raw
      }
      image {
        gatsbyImageData(
          quality: 70
          layout: CONSTRAINED
          breakpoints: [300, 500, 750, 1080, 1366, 1920]
          formats: WEBP
        )
        title
        description
      }
      handicappingPicks {
        data
      }
    }
  }
}`)

  let _in = { fade: 500 }

  // Function to add or substract months in dates
  function addMonths(date, months) {
    var d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() !== d) {
      date.setDate(0)
    }
    return date
  }

  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const pastDateRange = addMonths(new Date(todayYear, todayMonth, 2), -1).toISOString()
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 3).toISOString()

  let experts = isEspanolSection ?
    data?.allContentfulPerson?.nodes?.
      filter(person => person?.title === "Espanol Handicapping Expert")
    : data?.allContentfulPerson?.nodes

  // Show all experts after hash trigger / Scroll to selected
  useEffect(() => {

    if (window?.location.hash && typeof window !== 'undefined') {

      const href = window?.location.hash.substr(1)
      const params = href.split('?')[1]

      // Split params
      if (params && params !== '') {
        var search = params.split('&').reduce(function (res, item) {
          var parts = item.split('=')
          res[parts[0]] = parts[1]
          return res;
        }, {})
      }

      const showAllExperts = experts.some((expert, i) => {
        if (i >= FEATURED_EXPERTS_NUM)
          return expert['name'].toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '') === search?.expert
      })

      if (showAllExperts)
        setExpertsHidden(false)
      const timeout = setTimeout(() => {
        let mimic = document.querySelector('#bar-logo')?.clientHeight + document.querySelector('#bar-top')?.clientHeight + document.querySelector('.master-navi-animation')?.clientHeight
        let mimicMobile = document.querySelector('#bar-logo')?.clientHeight + document.querySelector('#bar-top')?.clientHeight
        if (document.querySelectorAll('#' + search?.expert).length > 0) {

          let scrollValue = mimic ? window.pageYOffset + document.querySelector('#' + search?.expert)?.getBoundingClientRect().top - mimic : window.pageYOffset + document.querySelector('#' + search?.expert)?.getBoundingClientRect().top - mimicMobile

          window.scrollTo({
            top: scrollValue,
            behavior: 'smooth'
          })
        }
      }, 300)

      return () => clearTimeout(timeout)

    }
  }, [typeof window !== 'undefined' && window?.location?.hash])




  // Monitor height of the navi for sticky picks
  const [naviOffset, setNaviOffset] = useState('0px')
  useEffect(() => {
    function updateSize() {
      setTimeout(() => {

        const header = document?.getElementById('master-header-mimic')
        const padding = window.getComputedStyle(header, null).getPropertyValue('padding-bottom')

        setNaviOffset(header.offsetHeight - parseFloat(padding) - 2 + 'px')
        const shadowMask = document?.getElementById('shadow-dropper')
        if (shadowMask) {
          shadowMask.style.top = header?.offsetHeight - parseFloat(padding) - 2 + 'px'
        }
      }, 50)
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    // Kill off listener
    return () => window.removeEventListener('resize', updateSize)
  }, [])



  let featuredExperts = experts
    .map((person, i) => {
      if (i < FEATURED_EXPERTS_NUM)
        return (
          <React.Fragment key={i}>
            <ExpertPick naviOffset={naviOffset} person={person} i={i} />
          </React.Fragment>
        )
    }).filter(item => item)


  let smallExperts = experts
    .map((person, i) => {
      if (i >= FEATURED_EXPERTS_NUM)
        return (
          <React.Fragment key={i}>
            <ExpertPick naviOffset={naviOffset} person={person} i={i} hidden={expertsHidden} />
          </React.Fragment>

        )
    }).filter(item => item)



  return (
    <div ref={io} className={`block-custom flex-12 c0 ${props.classes}`}>
      <div className="span-12 padd-half z1000 flex">
        <div className="expert-picks-intro padd-right-half">
          <h2>Expert Picks</h2>
          <h5>YOU CAN FIND THE BEST HANDICAPPING TIPS OF THE DAY FROM THESE SOUTH FLORIDA HANDICAPPERS:</h5>
        </div>
        <div className="wagering-menu-link-new c0 t flex padd-top-half">
          <a href="https://1st.com/bet/?utm_source=sap&utm_medium=track_referral&utm_campaign=1stsap&utm_content=Site_Other" target="_blank" className="btn colored fs-85"><span>BET NOW</span></a>
        </div>
      </div>
      {/* <div onClick={() => setIsExpand(old => !old)} className='btn big'><span>View {isExpand ? 'LESS' : 'MORE'} Experts</span></div> */}
      {/* {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} className="expert-picks-intro-wrap">
        <BlocksRouter {...setup} />
      </Intro> */}
      <div id="shadow-dropper"></div>
      {featuredExperts}
      <div className="separator c5">
        <div className='btn-container padd-half w-100 flex'>
          {experts?.length > FEATURED_EXPERTS_NUM ?
            <a href="#" className='btn js-center' onClick={(e) => { e.preventDefault(); setExpertsHidden(!expertsHidden) }}><span>{expertsHidden ? "More Experts" : "Less Experts"}</span></a>
            : null}
        </div>

      </div>
      {smallExperts}

    </div >
  )
}

export default Custom