import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import useEventsDayCollection from '../../../../utils/useQueryEventsDayCollection'
import Intro from '../../../../animations/Intro'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Wysiwyg from '../../Wysiwyg'

// Function to add or substract months in dates
function addMonths(date, months) {
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

function Custom(props) {
  //const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })
  const baseDelay = props.baseDelay ? props.baseDelay : 100

  const data = useEventsDayCollection()

  // Setting up 2 months ahead
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const todayDay = todayDate.getDate()
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 3).toISOString()
  const todayLimit = new Date(todayYear, todayMonth, todayDay).setHours(0,0,0,0)

  const currentView = data.allContentfulEventDay.nodes.filter(day => {
    return (day.promotions && new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0,0,0,0) >= todayLimit && new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).toISOString() <= futureDateRange)
  })

  currentView.sort(function (a, b) {
    return new Date(a.date) - new Date(b.date)
  })

  let promotionsBunch = []
  currentView.forEach(day => {
    promotionsBunch = promotionsBunch.concat(day.promotions)
  })

  promotionsBunch = promotionsBunch.filter(promo => promo.type === 'Racing Promotion')

  const map = new Map(promotionsBunch.map(promo => [promo.slug, promo]));
  const uniquePromotions = [...map.values()];

  const racingPromosMap = uniquePromotions.map((promo, i) => {
    return (
      <Intro key={i} visible={ioInView} in={{ border: 500, appear: 0 }} delayIn={baseDelay * i} stay={false} className="racing-promo-tile span-3 span-12-mobile c0 border-c5 border-top-off border-left-off t">
        <div >
          {promo?.featuredImage ?
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay * i + 500} stay={false} className="c5 t border-c5 image">
              <Link to={`/promotion/${promo.slug}/`} className="nostrike">
                <GatsbyImage
                  image={promo?.featuredImage?.gatsbyImageData}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  title={promo?.featuredImage?.title}
                  alt={promo?.featuredImage?.description}
                />
              </Link>
            </Intro>
            : null}
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={baseDelay * i + 500} stay={false} className="description c0 border-c5">
            {promo?.type ? <h6>{promo?.type}</h6> : null}
            <Link to={`/promotion/${promo?.slug}/`} className="promo-title nostrike"><h4>{promo?.name}{promo?.subtitle ? <span className="sub">{promo?.subtitle}</span> : null}</h4></Link>
            {promo.excerpt ? <Wysiwyg content={promo.excerpt} /> : null}
          </Intro>
        </div>
      </Intro>
    )
  })

  return (
    <div ref={io} className={`block-custom ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className='flex-12'>
        {racingPromosMap}
      </div>
    </div>
  )
}

export default Custom