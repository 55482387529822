import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'

function ResultsExotics(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const exoticPayouts = props.exoticPayouts

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  const exacta = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_EX')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Exacta</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const quinella = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_QU')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Quinella</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const trifecta = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_TR')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Trifecta</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const superfecta = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_SU')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Superfecta</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const superHiFive = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_E5')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Super Hi Five</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const dailyDouble = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_DB')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Daily Double</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const pick3 = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_P3')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Pick 3</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const pick4 = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_P4')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Pick 4</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const pick5 = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_P5')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Pick 5</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })

  const pick6 = exoticPayouts.map((item, i) => {
    if (item.bet_code === 'BET_CODE_P6')
      return (
        <Intro key={i} visible={ioInView} in={{ fadeTop: 500 }} delayIn={300} className="row">
          <div className="wager-type">
            <p>{item?.base_amount ? formatter.format(item?.base_amount) : ''}{item?.is_carryover ? ' Carryover' : ''} Pick 6</p>
          </div>
          <div className="wager-numbers">
            <p>{item?.runners === 'RF' ? "Refunded" : item?.runners === 'CA' ? 'Carryover' : item?.runners}</p>
          </div>
          <div className="payoff">
            <p>{item?.payout ? formatter.format(item?.payout) : '-'}</p>
          </div>
        </Intro>
      )
  })



  return (
    <div ref={io} className={`results-standings-box flex-12`}>

      <div className="results-tab">
        <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
          <div className="wager-type">
            <p>Wager Type</p>
          </div>
          <div className="wager-numbers">
            <p>Wager Numbers</p>
          </div>
          <div className="payoff">
            <p>Payoff</p>
          </div>
        </Intro>

        {exacta ? exacta : null}
        {quinella ? quinella : null}
        {trifecta ? trifecta : null}
        {superfecta ? superfecta : null}
        {superHiFive ? superHiFive : null}
        {dailyDouble ? dailyDouble : null}
        {pick3 ? pick3 : null}
        {pick4 ? pick4 : null}
        {pick5 ? pick5 : null}
        {pick6 ? pick6 : null}



        <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={500} className="row row-foot">
          <div className="wager-type">
            <p>Wager Type</p>
          </div>
          <div className="wager-numbers">
            <p>Wager Numbers</p>
          </div>
          <div className="payoff">
            <p>Payoff</p>
          </div>
        </Intro>
      </div>
      &nbsp;
    </div>
  )
}

export default ResultsExotics