import React, { useState, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Workouts(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const {
    standing: standing,
    type: type,
    changes: changes,
    runnersSwitched: runnersSwitched,
    odds: odds,
    win: win,
    place: place,
    show: show
  } = props

  standing?.sort((a, b) => (parseInt(a?.betting_interest_program_number) > parseInt(b?.betting_interest_program_number)) ? 1 : -1)

  const list = standing?.map((node, i) => {

    const {
      tote_number: startingGrid,
      color: color,
      saddle_cloth_color: saddleColor,
      post_position: pp,

    } = node

    return (
      <Intro key={`content-results-${i}`} switch={ioInView && runnersSwitched} in={{ fadeTop: 500 }} delayIn={i * 100} className={`row no no-${startingGrid} scratched-${changes?.changes?.[parseInt(pp)]?.scratched ? 'true' : 'false'}`}>

        <div className={`no color-${color} runner saddle-color-${saddleColor}`}>
          <h4>{startingGrid}</h4>
        </div>

        {odds ?
          <div className="win-o">
            <p>{odds?.[i] ? odds[i] : '-'}</p>
          </div>
          : null}

        <div className="win-p">
          <p>{win?.[i] ? win[i] : '-'}</p>
        </div>

        <div className="place-p">
          <p>{place?.[i] ? place[i] : '-'}</p>
        </div>

        <div className="show-p">
          <p>{show?.[i] ? show[i] : '-'}</p>
        </div>

      </Intro>
    )
  })

  return (
    <div ref={io} className={`racing-basic-bets-box flex-12`}>
      {odds || win || place || show ?
        <div className="results-tab">
          <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
            <div className="no">
              <p>PG</p>
            </div>
            {odds ?
              <div className="win-o">
                <p>Win Odds</p>
              </div>
              : null}
            <div className="win-p">
              <p>Win</p>
            </div>
            <div className="place-p">
              <p>Place</p>
            </div>
            <div className="show-p">
              <p>Show</p>
            </div>
          </Intro>
          {list}
          {!runnersSwitched ?
            <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
            :
            null
          }
          <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={500} className="row row-foot">
            <div className="no">
              <p>PG</p>
            </div>
            {odds ?
              <div className="win-o">
                <p>Win Odds</p>
              </div>
              : null}
            <div className="win-p">
              <p>Win</p>
            </div>
            <div className="place-p">
              <p>Place</p>
            </div>
            <div className="show-p">
              <p>Show</p>
            </div>
          </Intro>
        </div>
        :
        <div className='padd-half'>
          <h3>No data for selected bet</h3>
        </div>
      }
      &nbsp;
    </div>
  )
}

export default Workouts
