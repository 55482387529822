const validatePdf = url => {

  let newUrl = ""
  if(url?.startsWith("https:////assets.ctfassets.net/"))
    newUrl = url.replace("https:////assets.ctfassets.net/","")
  else if(url?.startsWith("https://assets.ctfassets.net/"))
    newUrl = url.replace("https://assets.ctfassets.net/","") 
  else if(url?.startsWith("https:////downloads.ctfassets.net/"))
    newUrl = url.replace("https:////downloads.ctfassets.net/","")
  else if(url?.startsWith("https://downloads.ctfassets.net/"))
    newUrl = url.replace("https://downloads.ctfassets.net/","")
  else if(url?.startsWith("//assets.ctfassets.net/"))
    newUrl = url.replace("//assets.ctfassets.net/","")
  else 
    return url

  if( ( newUrl?.startsWith("rkhnw24d6im5") || newUrl?.startsWith("71tzd15sgbj0") || newUrl?.startsWith("wujhxfu8hx6p") ) && newUrl.endsWith(".pdf"))
    return `/assets/?url=${newUrl}`
  else 
    return url
}

export default validatePdf