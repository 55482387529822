import React, {useState} from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Slider from 'react-slick'
import Wysiwyg from '../../Wysiwyg'
import Intro from '../../../../animations/Intro'

const SliderArrowNext = ({to, onClick}) => {
  return (
    <div role="button" onClick={onClick} className={`slider-control-next-positiner hover-trigger`} aria-label={to}>
      <div className="icon"></div>
    </div>
  )
}

const SliderArrowPrev = ({to, onClick}) => {
  return (
    <div role="button" onClick={onClick} className={`slider-control-prev-positiner hover-trigger`} aria-label={to}>
      <div className="icon"></div>
    </div>
  )
}

// Slider settings
const settings = {
  dots: false,
  className: "center",
  infinite: true,
  arrows: true,
  nextArrow: <SliderArrowNext to="next"/>,
  prevArrow: <SliderArrowPrev to="prev"/>,
  speed: 500,
  slidesToShow: 3,
  draggable: false,
  centerMode: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
}

function Custom(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
  const [currentTab, setCurrentTab] = useState('new')
  const [currentGame, setCurrentGame] = useState(0)


	function onGameChange(i) {
		setCurrentGame(i)
	}

  function onTabChange(tabName) {
		if(tabName !== currentTab) {
      setCurrentTab(tabName)
      setCurrentGame(0)
    }
	}

  const newSlots = props.custom.data.new.map( (game,i) => {
    return (
      <Intro key={i} visible={ioInView} in={{ bg: 500, fade: 500 }} delayIn={100 * i} className={`single-game ${i === currentGame ? 'current' : ''}`}>
      <div className={`image-wrap aspect-ratio c5 `}>
        <div className="gatsby-image-wrapper">
          <img src={game.image} alt="New slots game" />
        </div>
      </div>
    </Intro>
    )
  })

  const newSlotsContent = props.custom.data.new.map( (game,i) => {
    if( i === currentGame)
    return (     
       <div className='padd-top-half'>
        <Wysiwyg key={i} content={game.content}></Wysiwyg>
      </div>
    )
  })

  const classicSlots = props.custom.data.classic.map((game,i) => {
    return (
      <Intro key={i} visible={ioInView} in={{ bg: 500, fade: 500 }} delayIn={100 * i} className={`single-game ${i === currentGame ? 'current' : ''}`}>
      <div className={`image-wrap aspect-ratio c5 `}>
        <div className="gatsby-image-wrapper">
          <img src={game.image} alt="Classic slots game" />
        </div>
      </div>
    </Intro>
    )
  })

  const classicSlotsContent = props.custom.data.classic.map((game,i) => {
    if( i === currentGame)
    return (
      <div className='padd-top-half'>
        <Wysiwyg key={i} content={game.content}></Wysiwyg>
      </div>
    )
  })

 
  return (
		<div ref={io} className={`block-custom slots-slider c5 t w-100 ${props.classes}`}>
      <div className='padd-top-half padd-bottom-half flex'>
        <div className={`tab-handle ${currentTab === 'new' ? 'current' : ''}`} onClick={() => onTabChange('new')} onKeyDown={() => onTabChange('new')} role="button" tabIndex={0}><h6>NEW &amp; INNOVATIVE</h6></div>
        <div className={`tab-handle padd-left-half ${currentTab === 'classic' ? 'current' : ''}`} onClick={() => onTabChange('classic')} onKeyDown={() => onTabChange('classic')} role="button" tabIndex={0}><h6>PLAY THE CLASSICS</h6></div>
      </div>
      <div className="slider">
        {currentTab === 'new' ? 
        <>
          <Slider {...settings} speed={newSlots.length <= 3 ? 0 : 500} afterChange={index => onGameChange(index)}>
            {newSlots}
          </Slider>
          {newSlotsContent}
        </>
        :
        null
        }
        {currentTab === 'classic' ? 
        <>
          <Slider {...settings} speed={classicSlots.length <= 3 ? 0 : 500}  afterChange={index => onGameChange(index)}>
            {classicSlots}
          </Slider>
          {classicSlotsContent}
        </>
        :
        null
        }
      </div>
		</div>
  )
}

export default Custom

