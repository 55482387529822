import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import Intro from '../../../../animations/Intro'

function Club(props) {
	const { register, handleSubmit, reset, formState: { errors } } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const delayIn = 500
	const [io, ioInView] = useInView({ triggerOnce: true })

  // const numberOfRaces = props.numberOfRaces

	const onSubmit = (form, e) => {
		e.preventDefault()
		console.log("form",form)
		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('First Name', form.fname)
		data.append('Last Name', form.lname)
		data.append('Email', form.email)
		data.append('Birthday', form.birthday)
		data.append('Zip Code', form.zip)
		data.append('Phone Number', form.phone)
		data.append('Player Account Number', form.accountNumber)
		data.append('Interest', form.interest)
		data.append('Time', form.time)
		
		const url = `https://formspree.io/f/mdoblerq`
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.ok) {
					 setMessage('Submission sent')
					 reset()
					}
					setLoading(false)
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}



	return (
		<form
			name="contact"
			method="post"
			action="/thanks/"
			className="contact-club padd-top-half"
			onSubmit={handleSubmit(onSubmit)}
			ref={io}
		>
			<div className="form-container flex-12">
				<label className='span-12'>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="First Name" name="fname" {...register("fname",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your name is required.</p>}
				</label>
				<label className='span-12'>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 50} className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Last Name" name="lname" {...register("lname",{required: false, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your last name is required.</p>}
				</label>
				<label className='span-12'>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 150 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="email" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
					</Intro>
					{errors.email && <p className="small margin-off">a correct email is required.</p>}
				</label>
				<label className='span-8'>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 200} className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Birthday" name="birthday" {...register("birthday",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your birthday is required.</p>}
				</label>
				<label className='span-4'>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 250} className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Zip Code" name="zip" {...register("zip",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your zip is required.</p>}
				</label>
				<label className='span-12'>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 300} className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Phone" name="phone" {...register("phone",{required: false, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your phone number is required.</p>}
				</label>
				<label className='span-12'>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 350} className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Player Account Number" name="accountNumber" {...register("accountNumber",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your account number is required.</p>}
				</label>
				<div>
				<Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 400 } >	
					<div className="checkbox-container">
						<label className="inline">
							<input type="checkbox"  value="Casino" {...register("interest",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
										<g transform="translate(-699.924 -139.882)">
											<path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Casino</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Weekly" {...register("time",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
										<g transform="translate(-819.921 -242.525)">
											<path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Weekly</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Racing" {...register("interest",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
										<g transform="translate(-708.549 -430.775)">
											<path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Racing</p>
						</label>
						<label className="inline" >
							<input type="checkbox"  value="Monthly" {...register("time",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark" >
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
										<g transform="translate(-699.924 -139.882)">
											<path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Monthly</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Events" {...register("interest",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark">
									<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
										<svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
											<g transform="translate(-708.549 -430.775)">
												<path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
												<path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											</g>
										</svg>
									</Intro>
								</span>
							<p>Events</p>
						</label>

					</div>
				</Intro>
			</div>


				<Intro visible={ioInView} className="padd-top-half" in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 450 } >		
					<button className="submit" type="submit">SUBMIT YOUR REQUEST{isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
				</Intro>
				<p className="message small">{message}</p>
			</div>
		
		</form>
	)
}

export default Club