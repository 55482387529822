import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { Parallax } from 'react-scroll-parallax'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlocksRouter from '../../../Sections/BlocksRouter'
import Intro from '../../../../animations/Intro'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const {images: img, wysiwygs: wysiwyg} = props
	const [io, ioInView] = useInView({ triggerOnce: true })
	
  	return (
		<div ref={io} className={`block-custom flex-12 ${props.classes}`}>
      <div className="block-wrapper flex span-7 span-12-mobile">
        {wysiwyg ? <BlocksRouter {...wysiwyg[0]} /> : null}
        <div className="img-1 green-bar js-flex-end w-70">
          <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ 200 } className="">
            <GatsbyImage
              image={img[0]?.image?.gatsbyImageData}
              imgStyle={{objectFit: 'contain'}}
              objectPosition='50% 50%'
              title={img[0]?.image?.title}
              alt={img[0]?.image?.description}
            />
          </Intro>
        </div>
      </div>
      <div className="block-wrapper second-img span-5 span-12-mobile padd-top-2">
        <div className="image-wrap">
          <Parallax className="sticker-parallax" y={[-10, 10]} tagOuter="figure">
              <div className="img-2">
                <Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ 400 } className="">
                  <GatsbyImage
                    image={img[1]?.image?.gatsbyImageData}
                    imgStyle={{objectFit: 'cover'}}
                    objectPosition='50% 50%'
                    title={img[1]?.image?.title}
                    alt={img[1]?.image?.description}
                  />
                </Intro>
              </div>
            </Parallax>
          </div>   
        </div>
		</div>
  	)
}

export default Custom