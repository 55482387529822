import React, { useState, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Workouts(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const {
    standing: standing,
    type: type,
    changes: changes,
    runnersSwitched: runnersSwitched,
    data: data,
  } = props

  standing?.sort((a, b) => (parseInt(a?.betting_interest_program_number) > parseInt(b?.betting_interest_program_number)) ? 1 : -1)

  const tabHeadingSize = data?.[0]?.odds ? data?.[0]?.odds.length :
    data?.[0]?.probables ? data?.[0]?.probables.length :
      data?.[0]?.money ? data?.[0]?.money.length :
        0

  const tabHeading = standing?.map((node, i) => {
    const {
      tote_number: startingGrid,
      color: color,
      saddle_cloth_color: saddleColor,
      post_position: pp,

    } = node
    return (
      <div key={i} className={`no-top no-header scratched-${changes?.changes?.[parseInt(pp)]?.scratched ? 'true' : 'false'}`}>
        <div className={`no color-${color} runner saddle-color-${saddleColor}`}>
          <h4>{startingGrid}</h4>
        </div>
      </div>
    )
  }).filter((item, i) => i < tabHeadingSize)


  const list = standing?.map((node, i) => {
    const {
      tote_number: startingGrid,
      color: color,
      saddle_cloth_color: saddleColor,
      post_position: pp,
    } = node

    const singleHorseOdds = data?.[i]?.odds?.map((item, i) => {
      return (
        <div key={i} className={`scratched-${item === 'SC' ? 'true' : 'false'} no-top`}>
          <p>{item}</p>
        </div>
      )
    })

    const singleHorseProbables = data?.[i]?.probables?.map((item, i) => {
      return (
        <div key={i} className={`scratched-${item === 'SC' ? 'true' : 'false'} no-top`}>
          <p>{item}</p>
        </div>
      )
    })

    const singleHorsePools = data?.[i]?.money?.map((item, i) => {
      return (
        <div key={i} className={`scratched-${item === 'SC' ? 'true' : 'false'} no-top`}>
          <p>{item}</p>
        </div>
      )
    })

    return (
      <Intro key={`content-results-${i}`} switch={ioInView && runnersSwitched} in={{ fadeTop: 500 }} delayIn={i * 100} className={`row no no-${startingGrid} scratched-${changes?.changes?.[parseInt(pp)]?.scratched ? 'true' : 'false'}`}>
        <div className={`no color-${color} runner saddle-color-${saddleColor}`}>
          <h4>{startingGrid}</h4>
        </div>
        {singleHorseOdds ? singleHorseOdds : null}
        {singleHorseProbables ? singleHorseProbables : null}
        {singleHorsePools ? singleHorsePools : null}
      </Intro>
    )
  })

  return (
    <div ref={io} className={`racing-exotic-bets-box flex-12`}>
      {data ?
        <div className="results-tab">
          <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
            <div className="with">
              <p>With</p>
            </div>
            {tabHeading}
          </Intro>
          {list}
          {!runnersSwitched ?
            <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
            :
            null
          }
        </div>
        :
        <div className='padd-half'>
          <h3>No data for selected bet</h3>
        </div>
      }
      &nbsp;
    </div>
  )
}

export default Workouts
