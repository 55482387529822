import React, { memo } from 'react'
import './style.scss'
import { createPortal } from 'react-dom'

import Wysiwyg from '../../Blocks/Wysiwyg'
import Image from '../../Blocks/Image'
import Decor from '../../Blocks/Decor'
import Video from '../../Blocks/Video'
import Tabs from '../../Blocks/Tabs'
import Wrapper from '../../Blocks/Wrapper'
import Button from '../../Blocks/Button'
import ToSection from '../../Blocks/ToSection'
import Slider from '../../Blocks/Slider'
import Timeline from '../../Blocks/Timeline'
import Lightbox from '../../Blocks/Lightbox'
import Picks from '../../Blocks/Picks'
import Custom from '../../Blocks/Custom'
// import CustomJson from '../../Blocks/Json'

function BlocksRouter(props) {
	const selectorAnchor = props.anchor
	function route(node,i) {

		// OLD Wysiwyg BOX
		if(node.__typename === 'wysiwyg') {
			return (
				<Wysiwyg key={`wysiwyg-${i}`} {...node} />
			)
		// Wysiwyg BOX
		} else if (node.__typename === 'ContentfulBlockWysiwyg' || node.__typename === 'BlockWysiwyg') {

			const portal = typeof document !== "undefined" ? document.getElementById(node.portal) : null
			const portalling = portal ? createPortal(
				<Wysiwyg key={`wysiwyg-${i}`} {...node} />
				,
				portal
			) : null
			
			return portalling ? portalling : (
				<Wysiwyg key={`wysiwyg-${i}`} {...node} />
			)
		// OLD Image BOX
		} else if (node.__typename === 'image') {
			return (
				<Image key={`image-${i}`} {...node} />
			)
		// Image BOX
		} else if (node.__typename === 'ContentfulBlockImage') {
			return (
				<Image key={`image-${i}`} {...node} />
			)
		// OLD Decor BOX
		} else if (node.__typename === 'decor') {
			return (
				<Decor key={`decor-${i}`} {...node} />
			)
		// Decor BOX
		} else if (node.__typename === 'ContentfulBlockDecor') {
			return (
				<Decor key={`decor-${i}`} {...node} />
			)
		// OLD ToSection BOX
		} else if (node.__typename === 'tosection') {
			return (
				<ToSection key={`tosection-${i}`} {...node} />
			)
		// ToSection BOX
		} else if (node.__typename === 'ContentfulBlockToSection') {
			return (
				<ToSection key={`tosection-${i}`} {...node} />
			)
		// OLD Video BOX
		} else if (node.__typename === 'video') {
			return (
				<Video key={`video-${i}`} {...node} />
			)
		// Video BOX
		} else if (node.__typename === 'ContentfulBlockVideo' || node.__typename === 'BlockVideo') {
			return (
				<Video key={`video-${i}`} {...node} />
			)
		// OLD Tabs BOX
		} else if (node.__typename === 'tabs') {
			//console.log('goin through tabs', props)
			return (
				<Tabs key={`tabs-${i}`} {...node} selectorAnchor={selectorAnchor}  />
			)
		// Tabs BOX
		} else if (node.__typename === 'ContentfulBlockTabs') {
			return (
				<Tabs key={`tabs-${i}`} {...node} selectorAnchor={selectorAnchor} />
			)
		// OLD Wrapper BOX
		} else if (node.__typename === 'wrapper') {
			return (
				<Wrapper key={`wrapper-${i}`} {...node} />
			)
		// Wrapper BOX
		} else if (node.__typename === 'ContentfulBlockWrapper') {
			// Wrapper Routing
			if (node.type === 'Timeline') {
				return (
					<Timeline key={`timeline-${i}`} {...node} />
				)
			} else if (node.type === 'Lightbox') {
				return (
					<Lightbox key={`lightbox-${i}`} {...node} />
				)
			} else {
				return (
					<Wrapper key={`wrapper-${i}`} {...node} />
				)
			}
		// Button BOX
		} else if (node.__typename === 'ContentfulBlockButton') {
			return (
				<Button key={`button-${i}`} {...node} />
			)
		// OLD Slider BOX
		} else if (node.__typename === 'slider') {
			return (
				<Slider key={`slider-${i}`} {...node} />
			)
		// Slider BOX
		} else if (node.__typename === 'ContentfulBlockSlider') {
			return (
				<Slider key={`slider-${i}`} {...node} />
			)
		// OLD Timeline BOX
		} else if (node.__typename === 'timeline') {
			return (
				<Timeline key={`timeline-${i}`} {...node} />
			)
		// Lightbox BOX
		} else if (node.__typename === 'lightbox') {
			return (
				<Lightbox key={`lightbox-${i}`} {...node} />
			)
		// Picks BOX
		} else if (node.__typename === 'picks') {
			return (
				<Picks key={`picks-${i}`} {...node} />
			)
		// OLD Custom BOX
		} else if (node.__typename === 'custom') {
			return (
				<Custom key={`custom-${i}`} {...node} />
			)
		// Custom BOX
		} else if (node.__typename === 'ContentfulBlockCustom') {
			return (
				<Custom key={`custom-${i}`} {...node} />
			)
		// JSON BOX
		}
    //  else if (node.__typename === 'ContentfulBlockDirectInsert') {
		// 	return (
		// 		<CustomJson key={`custom-json-${i}`} {...node} selectorAnchor={selectorAnchor} />
		// 	)
		// }
	}
	
	// Bunch of blocks, that have children
	const blocks = props.blocks?.map((node, i) => { return route(node,i) })
	
	// Last node, has no children
	const block = !props.blocks ? route(props,0) : null

	// Run deeper blocks or if it's slider wrapper, let it go it's own way
  return blocks && props.__typename !== 'slider' && props.__typename !== 'ContentfulBlockSlider' ? blocks : block ? block : null
}

export default memo(BlocksRouter)