import React, { useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
// import { Link } from 'gatsby'
import Intro from '../../../../../animations/Intro'
import Slider from 'react-slick'
import Seo from '../../../../seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import validatePdf from '../../../../../utils/validatePdf'

function Custom(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

  const item = props.place

  const SliderArrowNext = ({to, onClick}) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-next-positiner hover-trigger`} aria-label={to}>
        {/* <div className="text">NEXT</div> */}
        <div className="icon"></div>
      </div>
    )
  }

  const SliderArrowPrev = ({to, onClick}) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-prev-positiner hover-trigger`} aria-label={to}>
        <div className="icon"></div>
        {/* <div className="text">PREVIOUS</div> */}
      </div>
    )
  }

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
    nextArrow: <SliderArrowNext to="next"/>,
    prevArrow: <SliderArrowPrev to="prev"/>,
		slidesToShow: 1,
		slidesToScroll: 1
	}

  const slides = item?.gallery?.map( (node,i) => {
    return (
      <div key={i} className={`image-wrap aspect-ratio`}>
        <GatsbyImage
          image={node?.gatsbyImageData}
          imgStyle={{objectFit: 'cover'}}
          objectPosition='50% 50%'
          title={node?.title}
          alt={node?.description}
        />
      </div>
    )
  })

  useEffect(() => {
		const script = document.createElement('script');
	
		script.src = "https://ten-palms.popmenu.com/s/embeds/ftdwbpml.js"
		script.async = true
    const target = document.querySelector('#append')
		target?.appendChild(script)

		return () => {
			target?.removeChild(script)
		}
	}, [props.active === '#ten-palms']);

  // const [loaded,setLoaded] = useState(false)
  // useEffect(() => {
  //   const scriptTag = document.createElement('script')
  //   scriptTag.src = "https://ten-palms.popmenu.com/s/embeds/ftdwbpml.js"
  //   scriptTag.addEventListener('load', ()=>setLoaded(true))
  //   document.body.appendChild(scriptTag)
  // },[])

  
  return (
    <>
      <Seo title={item?.name ? item?.name : null} image={item?.featuredImage ? item.featuredImage.file.url : null} path={props.location?.pathname ? props.location?.pathname : ''} />

      <div ref={io} className={`block-custom single-map-place flex-12 `}>
        {/* <div id={item.slug} className="anchor"></div> */}

        <Intro visible={ioInView} in={{ fadeLeft: 500 }} delayIn={100} mounted={true} stay={true} className="">
          <div className={`padd-top-off padd span-12`}>
            {props.closebtn}
            <div className="block-img">
                { !item?.gallery ?
                <Intro visible={ioInView} in={{ border: 400, bg: 400, fade: 300 }} delayIn={0} mounted={true} stay={true} className="c5 t border-c5">
                  <div className="image-wrap aspect-ratio">
                    {/* <img src={item?.image} title={item?.name} alt={item?.name} /> */}
                    <GatsbyImage
                      image={item?.featuredImage?.gatsbyImageData}
                      imgStyle={{objectFit: 'cover'}}
                      objectPosition='50% 50%'
                      title={item?.title}
                      alt={item?.description}
                    />
                  </div>
                </Intro>
                :
                <Intro visible={ioInView} in={{ border: 400, bg: 400, fade: 300 }} delayIn={0} mounted={true} stay={true} className="c5 t border-c5">
                  <div className="slider-gallery slider with-aspect">
                    <Slider {...settings}>
                      {slides}
                    </Slider>
                  </div>
                </Intro>
                }
            </div>
            <div className='info padd-top-half'>
              <h3>{item?.name}</h3>
              <div className='flex'>
                <h6>{item?.markup} </h6>
                <span className='padd-left'>{item?.price}</span>
              </div>
              <div className='uppercase'>
                {item?.hours ?
                    <div className='flex hours'>
                      <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={500} mounted={true} stay={true} className="c5 t border-c5">
                        <div className='blurb'>
                          Hours:
                        </div>
                      </Intro>
                      <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={600} mounted={true} stay={true} className="c0">
                        <div className="blurb">
                          <div dangerouslySetInnerHTML={{ __html: item?.hours }} />
                        </div>
                      </Intro>
                    </div>
                : null}
                {item?.phone ?
                  <div className='flex phone'>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={600} mounted={true} stay={true} className="c5 t border-c5 border-top-c0">
                      <div className='blurb'>
                        Phone:
                      </div>
                    </Intro>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={600} mounted={true} stay={true} className="c0">
                      <div className="blurb">
                        {item?.phone}
                      </div>
                    </Intro>
                  </div>
                : null}
                {item?.manager ?
                  <div className='flex restaurant'>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={600} mounted={true} stay={true} className="c5 t border-c5 border-top-c0">
                      <div className='blurb'>
                        Restaurant Manager:
                      </div>
                    </Intro>
                    <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={600} mounted={true} stay={true} className="c0">
                      <div className="blurb">
                        {item.manager}
                      </div>
                    </Intro>
                  </div>
                : null}
              </div>
            </div>
            <div className='desc flex jc-flex-end text-right'>
              {item?.description?
                <div>
                    <div dangerouslySetInnerHTML={{ __html: item?.description?.childMarkdownRemark?.html }}/>
                    {item.slug === "gulfstream-park-gift-shop" ? 
                      <a href='https://shop.1st.com' target='_blank' class='btn margin-top'><span>SHOP ONLINE</span></a>
                    :
                      null
                    }
                </div>
              :null}

              {item?.btn ?
                <p
                  dangerouslySetInnerHTML={{ __html: item.btn }}
                />
              :null}
            </div>     
            <div className='buttons flex jc-flex-end padd-top-half'>
              {item?.menu ? <a href={validatePdf(item.menu)} className="btn regular"><span>Menu</span></a> : null }
              {item?.reserve ? <a href={validatePdf(item.reserve)} target="_blank" rel='noopener noreferrer' className="btn regular"><span>Reserve</span></a> : null }
            </div>
            {props?.active === '#ten-palms' ?
              <div id="append"></div>
            : null}
            {/* <div id="pm-widget-ftdwbpml"></div> */}
          </div>
        </Intro>
      </div>
    </>
  )
}

export default Custom