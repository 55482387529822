import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import Wysiwyg from '../../Wysiwyg'
import Slider from 'react-slick'
import validatePdf from '../../../../utils/validatePdf'

function Custom(props) {
  //const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [currentTab, setCurrentTab] = useState(0)


  const SliderArrowNext = ({ to, onClick }) => {
    return (
      <div role="button" style={{ opacity: onClick === null ? "0.6" : "1" }} onClick={onClick} onKeyDown={onClick} className="icon decoration slider-control next icon-arrow-right flex" aria-label={to}></div>
    )
  }

  const SliderArrowPrev = ({ to, onClick }) => {
    return (
      <div role="button" style={{ opacity: onClick === null ? "0.6" : "1" }} onClick={onClick} onKeyDown={onClick} className="icon decoration slider-control prev icon-arrow-right flex" aria-label={to}></div>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    nextArrow: <SliderArrowNext to="next" />,
    prevArrow: <SliderArrowPrev to="prev" />,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 3,
  }

  // On Parent Tab Change
  function onTabChange(i) {
    setCurrentTab(i)
  }

  // Tabs
  const TabPack = props?.custom?.data?.jockeys?.map((handle, i) => {
    return (
      <div key={i} className={`tab-handle ${i === currentTab ? 'current' : ''}`} onClick={() => onTabChange(i)} onKeyDown={() => onTabChange(i)} role="button" aria-label="Tab handle" tabIndex={0}>
        <h6 className="handle-heading">{handle.name}</h6>
      </div>
    )
  })

  // TabsContent
  const TabContentCopy = props?.custom?.data?.jockeys?.map((jockey, i) => {
    if (i === currentTab) {
      const links = jockey?.links?.map((link, i) => {
        return (
          <div key={i} className="link flex uppercase padd-right-half c3 t">
            <a href={validatePdf(link.value)}>{link.key}</a>
          </div>
        )
      })
      return (
        <div key={i} className="single-item">
          <h3 className="name">{jockey.name}</h3>
          <div className="flex">
            {links}
          </div>
          <div className="tiny-hr-before"><Wysiwyg content={jockey.description} /></div>
        </div>
      )
    }
    return false
  })

  const TabContentImage = props?.custom?.data?.jockeys?.map((jockey, i) => {
    if (i === currentTab) {
      return (
        <div key={i} className="image-wrap bg">
          <div className="gatsby-image-wrapper">
            <img src={jockey.imageURL} title={jockey.name} alt={jockey.name} />
          </div>
        </div>
      )
    }
    return false
  })

  return (
    <div ref={io} className={`block-custom w-100 flex${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className="tab-pack flex-12">
        <div className="span-6 span-12-mobile flex fd-column">
          <div className="top padd padd-half-mobile padd-bottom-off">
            <h1>{props.custom.data.heading}</h1>
            <h4>{props.custom.data.subheading}</h4>
            <div className="tab-handles-pack flex padd-top-half padd-bottom-half">
              <div className="slider">
                <Slider {...settings}>
                  {TabPack}
                </Slider>
              </div>
            </div>
          </div>
          <Intro switch={ioInView && currentTab} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents copy padd padd-top-half">
            {TabContentCopy}
          </Intro>
          {/* <div className="icon decoration icon-arrow-right flex"></div> */}
        </div>
        <div className="span-6 jockey-img span-12-mobile span-12-tablet">
          <Intro switch={ioInView && currentTab} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
            {TabContentImage}
          </Intro>
        </div>
      </div>
    </div>
  )
}

export default Custom