import React from 'react'
import './style.scss'
import validatePdf from '../../../utils/validatePdf';
import { Link } from 'gatsby'

function Button(props) {

	function validURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
		return !!pattern.test(str);
	}

	if(!props.link) {
		return (
			<div className={`btn ${props.classes}`}>
				<span>{props.label}</span>
			</div>
		)
	}

	if(props.link && validURL(props.link)) {
		return (
			<a href={validatePdf(props.link)} target="_blank" rel="noreferrer noopener" className={`btn ${props.classes}`}>
				<span>{props.label}</span>
			</a>
		)
	}

  if ((props.link.search("data-fevo-offer-id") > -1 || props.link.search("data-fevo-sub-offer-id") > -1)) {
    
    let str = props.link.replaceAll("'", "\"")
    
    const mappedObject = {};
    const matchOfferId = str.match(/data-fevo-offer-id="([^"]+)"/);
    const matchSubOfferId = str.match(/data-fevo-sub-offer-id="([^"]+)"/);

    if (matchOfferId) {
      mappedObject['data-fevo-offer-id'] = matchOfferId[1];
    }

    if (matchSubOfferId) {
      mappedObject['data-fevo-sub-offer-id'] = matchSubOfferId[1];
    }

    return (
      <a data-fevo-offer-id={mappedObject["data-fevo-offer-id"]} data-fevo-sub-offer-id={mappedObject["data-fevo-sub-offer-id"]} className={`btn ${props.classes}`}>
        <span>{props.label}</span>
      </a>
    )
  }

	return (
		<Link to={props.link} className={`btn ${props.classes}`} state={props.state}>
			<span>{props.label}</span>
		</Link>
	)
}

export default Button