import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlocksRouter from '../../../Sections/BlocksRouter'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
  const {images: img,wrappers: wrapper, wysiwygs: wysiwyg, videos: video, repeaters: repeater} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<div ref={io} className={`block-custom casino-hero flex w-100 ${props.classes}`}>
			<div className="block-img">
				<GatsbyImage
					image={img[0]?.image?.gatsbyImageData}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
          title={img[0]?.image?.title}
					alt={img[0]?.image?.description}
				/>
				<div className="hero-text c5 t">
					{wysiwyg ? <BlocksRouter {...wysiwyg[0]} /> : null}
				</div>
			</div>
			<div className="copy-container">
				<Intro visible={ioInView} in={{ fade: 100, draw: 800 }} delayIn={300} stay={false} className="with-svg mark x">
					<svg xmlns="http://www.w3.org/2000/svg" width="239.777" height="173.246" viewBox="0 0 239.777 173.246">
						<path d="M1048,4880.709s-2.109,116.362,90.14,137.737,141.143,20.449,141.143,20.449l-36.246-20.449,42.9,20.449-42.9,14.578,28.514-17.708" transform="translate(-1047.488 -4880.7)" fill="none" stroke="#000" strokeWidth="1"/>
					</svg>
				</Intro>
				{wysiwyg ? <BlocksRouter {...wysiwyg[1]} > </BlocksRouter> : null}
			</div>
		</div>
  )
}

export default Custom