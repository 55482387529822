import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'

function Handle(props) {
	
  return (
		<div className={`accordion-handle-wrapper  ${props.blank ? 'blank' : 'hover-trigger'}`} onClick={props.onClickPassage} onKeyDown={props.onClickPassage} tabIndex="0" role="button" aria-label="Accordion handle" aria-pressed="false">
			<div className={`aside color ${props.asideBox ? '' : 'hide'}`}>
				<Intro visible={true} in={{ border: 300, bg: 300, blink: 0 }} delayIn={props.delay} className="decor c3 t"></Intro>
			</div>
			<div className="main">
				<div className="row">
					<div className={`bg-hover ${props.blank ? 'hide' : ''}`}></div>
					<Intro visible={true} in={{ border: 500, fade: 500 }} delayIn={props.delay} className={`${props.asideBox ? '' : 'aside-padding'} ${props.classes} box c0 t`}>
						<div className="handle active-dip"><div className={`icon icon-${props.icon}`}></div><h3>{props.title}</h3></div>
					</Intro>
				</div>
			</div>
		</div>
	)
}

export default Handle