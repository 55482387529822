import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Intro from '../../../animations/Intro'

function Image(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	let _in = props._in ? props._in : { border: 0, bg: 0, fade: 500 }

	let inheritedDelay = props.inheritedDelay ? props.inheritedDelay : 0
	let delay = props.delay ? props.delay : 200

	// Look if the classes specify if the image is supposed to be borderless
	let borderless = props.classes?.search('borderless')
	// If it is lacking borderless class, add border animation
	if (borderless === -1) {
		_in.border = 500
	}
	// Look if the classes specify if the image is supposed to be bgless
	let bgless = props.classes?.search('bgless')
	// If it is lacking bgless class, add border animation
	if (bgless === -1) {
		_in.bg = 500
	}

	const image = getImage(props.image)


  return (
		<div ref={io} className={`block-img ${props.classes}`}>
			{props.imgSrcSet ?
				<Intro visible={ioInView} in={_in} delayIn={delay + inheritedDelay} mounted={true} stay={true} className={`c5 t innerborder ${borderless > -1 ? '' : 'border-c5'}`}>
					<img
						className="img"
						src={props.imgSrc}
						srcSet={props.imgSrcSet}
            title={props.image?.title}
						alt={props.image?.description}
					/>
				</Intro>
			: props.image?.thumb ?
				<Intro visible={ioInView} in={_in} delayIn={delay + inheritedDelay} mounted={true} stay={true} className={`c5 t innerborder ${borderless > -1 ? '' : 'border-c5'}`}>
					<img
						src={props.image?.thumb}
						srcSet={`${props.image?.thumb} 400w, ${props.image?.sd} 640w, ${props.image?.hdready} 800w, ${props.image?.hd} 1024w, ${props.image?.fourk} 1920w`}
						alt={props.image?.description}
            title={props.image?.title}
						className="bg img-cover"
					/>
				</Intro>
			:
				<Intro visible={ioInView} in={_in} delayIn={delay + inheritedDelay} mounted={true} stay={true} className={`c5 t innerborder ${borderless > -1 ? '' : 'border-c5'}`}>
					<GatsbyImage image={image} title={props.image?.title} alt={props.image?.description ? props.image?.description : ""}/>
				</Intro>
			}
		</div>
  )
}

export default Image