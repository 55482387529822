import React, { useState, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Workouts(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [virtualStableLink, setVirtualStableLink] = useState([])
  const {
    standing: standing,
    type: type,
    changes: changes,
    runnersSwitched: runnersSwitched,
  } = props

  function handleAddVirtualStable(e) {
    const checkboxes = typeof document !== "undefined" ? [...document.querySelectorAll('.virtual-stable input:checked')] : null
    const selections = checkboxes?.map((node, i) => {
      return node.value
    })
    const virtualStableLinkInsert = 'http://www.equibase.com/virtualstable/horse.cfm?SID=1&hl=' + encodeURIComponent(selections.join(',')) + '&add=Y'
    setVirtualStableLink(virtualStableLinkInsert)
    return false
  }

  const list = standing?.map((node, i) => {

    const {
      tote_number: startingGrid,
      post_position: pp,
      bet: bet,
      betting_interest_name: horse,
      jockey: jockey,
      owner: owner,
      owner_silks: owner_silks,
      jockeyLink: jockeyLink,
      weight_carried: jockeyweight,
      trainer: trainer,
      trainerLink: trainerLink,
      virtualStable: virtualStableID,
      color: color,
      saddle_cloth_color: saddleColor,
      gender: gender,
      horse_year_of_birth: yob,
      medication: med,
      claiming_price: claim,
      mlodd: ml,
      sire: sire,
      dam: dam,
      damsire: damsire,
    } = node

    return (
      <Intro key={`content-results-${i}`} switch={ioInView && runnersSwitched} in={{ fadeTop: 500 }} delayIn={i * 100} className={`row no no-${startingGrid} scratched-${changes?.changes?.[parseInt(pp)]?.scratched ? 'true' : 'false'}`}>
        <div className={`no color-${color} runner saddle-color-${saddleColor}`}>
          <h4>{startingGrid}</h4>
        </div>
        <div className={`pp`}>
          <h4>{pp}</h4>
        </div>
        <div className="horse-jockey">
          <h5>{horse}</h5>
          <div className="details">
            <div className="details-l">
              <p>
                {jockeyLink && jockey ?
                  <>
                    Jockey: <a href={jockeyLink}>
                      {jockey}
                    </a>
                  </>
                  : jockey && changes?.changes?.[i + 1]?.jockey ?
                    <>
                      Jockey: <span className="jockey-scratched">{jockey}</span> <span>{changes?.changes?.[i + 1]?.jockey}</span>
                    </>
                    : jockey ?
                      'Jockey: ' + jockey
                      : null}
                {jockeyweight ? ' - ' + jockeyweight + 'lbs' : ''}
                {trainerLink && trainer ?
                  <>
                    , Trainer: <a href={trainerLink}>
                      {trainer}
                    </a>
                  </>
                  : trainer ?
                    ', Trainer: ' + trainer
                    : null}
                {owner ?
                  ', Owner: ' + owner
                  : null}
              </p>
            </div>
            <div className="details-r fs-85">
              <p>
                {sire ? 'S: ' + sire : ''}
                {dam ? ', D: ' + dam : ''}
                {damsire ? ', DS: ' + damsire : ''}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="virtual-stable">
					<div className="checkbox-container">
						<label className="inline">
							<input type="checkbox" value={virtualStableID} onChange={(e) => handleAddVirtualStable(e)} />
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 1250 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
										<g transform="translate(-708.549 -430.775)">
											<path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
						</label>
					</div>
				</div> */}
        <div className="gender">
          <h6>{gender}</h6>
        </div>
        <div className="yob">
          <h6>{yob}</h6>
        </div>
        <div className="med">
          <h6>{med ? med : 'N/A'}</h6>
        </div>
        <div className="claim">
          <h6>{claim ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(claim) : 'N/A'}</h6>
        </div>
        <div className="ml">
          <h6>{ml}</h6>
        </div>
      </Intro>
    )
  })

  return (
    <div ref={io} className={`racing-standings-box flex-12`}>

      <div className="results-tab">
        <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
          <div className="no">
            <p>PG</p>
          </div>
          <div className="pp">
            <p>PP</p>
          </div>
          <div className="horse-jockey">
            <p>Horse / Jockey</p>
          </div>
          {/* <div className="virtual-stable">
						<p>Virtual Stable</p>
					</div> */}
          <div className="gender">
            <p>Gender</p>
          </div>
          <div className="yob">
            <p>YoB</p>
          </div>
          <div className="med">
            <p>MED</p>
          </div>
          <div className="claim">
            <p>Claim&nbsp;$</p>
          </div>
          <div className="ml">
            <p>M/L</p>
          </div>
          {type === 'results' ?
            <>
              <div className="win-prize">
                <p>Win&nbsp;$</p>
              </div>
              <div className="place-prize">
                <p>Place&nbsp;$</p>
              </div>
              <div className="show-prize">
                <p>Show&nbsp;$</p>
              </div>
            </>
            :
            null
          }
        </Intro>
        {list}
        {!runnersSwitched ?
          <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
          :
          null
        }
        <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={500} className="row row-foot">
          <div className="no">
            <p>PG</p>
          </div>
          <div className="pp">
            <p>PP</p>
          </div>
          <div className="horse-jockey">
            <p>Horse / Jockey</p>
          </div>
          {/* <div className="virtual-stable">
						<p><a className="btn" target="_blank" href={virtualStableLink}><span>Add</span></a></p>
					</div> */}
          <div className="gender">
            <p>Gender</p>
          </div>
          <div className="yob">
            <p>YoB</p>
          </div>
          <div className="med">
            <p>MED</p>
          </div>
          <div className="claim">
            <p>Claim&nbsp;$</p>
          </div>
          <div className="ml">
            <p>M/L</p>
          </div>
          {type === 'results' ?
            <>
              <div className="win-prize">
                <p>Win&nbsp;$</p>
              </div>
              <div className="place-prize">
                <p>Place&nbsp;$</p>
              </div>
              <div className="show-prize">
                <p>Show&nbsp;$</p>
              </div>
            </>
            :
            null
          }
        </Intro>
      </div>
      &nbsp;
    </div>
  )
}

export default Workouts
