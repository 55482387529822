import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import Image from '../../Image'

function Custom(props) {
	const {images: img, repeaters: repeater} = props
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [ open, setOpen ] = useState(0)

  const openTab = (i) => {
    setOpen(i)
  }

  const mapItems = repeater.map( (tab, i) => {

    const tabHandles = tab.repeater.map((item, i) => {
      if(item.key === "_blank")
        return (
        <Intro key={i} visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={325} className={`c0 t border-c5 padd-left-half padd-right-half`}>
            <h3>{item.value}</h3>
            <div className={`icon icon-arrow`}></div>
          </Intro>
        )
    })

    const tabContents = tab.repeater.map((item, i) => {
      if(item.key !== "_blank")
      return (
        <Intro key={i} visible={ioInView} in={{  bg: 250, fade: 250 }} delayIn={100 + 50 * i} className="c0 single-item t border-c5 padd-right-half uppercase">
            <div className={`icon ${item.key}`}></div>
            <h6>{item.value}</h6>
        </Intro>
      )
    })

    return (
      <div key={i} className='tab'>
        <div className={`tab-handle ${open === i ? 'current' : ''}`} onClick={() => openTab(i)}> 
          {tabHandles}
        </div>
        <Intro switch={open === i} in={{  bg: 100, fade: 100 }} delayIn={0} className="c0 t border-c5 padd-left-half padd-right-half">
          {tabContents}
        </Intro>
      </div>
    )
  })

  const imagesMap = img.map( (image, i) => {
    return(
    <div key={i} className={`map-layer layer-${i} active-${open} ${open === i ? 'active': ''}`}>
      <Image image={image.image} classes={image.classes}/>
    </div>
    )
  })

  return (
		<div ref={io} className={`block-custom w-100 map-3d ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className='flex-12'>
        <div className='span-5 span-12-tablet padd'>
          <h2>Clubhouse<br/> Directory</h2>
          {mapItems}
        </div>
        <div className='span-7 span-12-tablet layers-container'>
          {imagesMap}
        </div>
      </div>
		</div>
  )
}

export default Custom