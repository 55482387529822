import React, { useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../animations/Intro'
import Button from '../components/Blocks/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'
import { useQuery, gql } from '@apollo/client'

function Preakness(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  // Apollo, Pull Events!
  const { loading, error, data: apollo, refetch } = useQuery(APOLLO_QUERY, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore'
  })

  function unEscapeFunction(htmlStr) {
    htmlStr = htmlStr?.replace(/&lt;/g , "<");	 
    htmlStr = htmlStr?.replace(/&gt;/g , ">");     
    htmlStr = htmlStr?.replace(/&quot;/g , "\"");  
    htmlStr = htmlStr?.replace(/&#39;/g , "\'");   
    htmlStr = htmlStr?.replace(/&amp;/g , "&");
    htmlStr = htmlStr?.replace(/&#039;/g , "'");

    return htmlStr;
  }

  // Refetch apollo every 30s
  useEffect(() => {
    function checkApollo() {
      console.log('useEffect')

      refetch()
      // console.log('APOLLO request racing info', loading, error, apollo)
    }
    let interval = setInterval(() => checkApollo(), 30000)
    return () => {
      clearInterval(interval)
    }
  }, [refetch])

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })

  const { marylandEntriesApi, marylandOddsApi, marylandPoolsApi, marylandScratchesApi } = apollo?.options?.items?.[0] ? apollo?.options?.items?.[0] : []

  const entriesMap = marylandEntriesApi?.data?.map((node, i) => {
    const {
      tote_number: startingGrid,
      post_position: pp,
      bet: bet,
      betting_interest_name: horse,
      jockey: jockey,
      owner: owner,
      owner_silks: owner_silks,
      jockeyLink: jockeyLink,
      weight_carried: jockeyweight,
      trainer: trainer,
      trainerLink: trainerLink,
      virtualStable: virtualStableID,
      color: color,
      saddle_cloth_color: saddleColor,
      gender: gender,
      horse_year_of_birth: yob,
      medication: med,
      claiming_price: claim,
      mlodd: ml,
      sire: sire,
      dam: dam,
      damsire: damsire,
    } = node

    return (
      <Intro key={`content-results-${i}`} switch={ioInView} in={{ fadeTop: 500 }} delayIn={i * 100} className={`row no no-${startingGrid} scratched-${marylandScratchesApi?.data?.changes?.[parseInt(pp)]?.scratched || horse === "Wild On Ice" ? 'true' : 'false'}`}>
        <div className={`no color-${color} runner saddle-color-${saddleColor}-off`} >
          <h4>{startingGrid}</h4>
        </div>
        <div className="horse-jockey">
          <h5>{horse}</h5>
        </div>
        <div className="details">
          <p>Trainer: {unEscapeFunction(trainer)} <br />
            Owner: {unEscapeFunction(owner)}</p>
        </div>
        <div className="ml">
          <h6>{marylandOddsApi?.data?.odds?.[0]?.odds[startingGrid - 1] ? marylandOddsApi?.data?.odds?.[0]?.odds[startingGrid - 1] : '-'}</h6>
        </div>
        <div className="ml">
          <h6>{marylandPoolsApi?.data?.pools?.[0]?.money[startingGrid - 1] ? formatter.format(parseFloat(marylandPoolsApi?.data?.pools?.[0]?.money[startingGrid - 1]?.replace(/,/g, ''))) : '-'}</h6>
        </div>
        <div className="button c0 t">
          <Button link={`https://1st.com/bet/?utm_source=MJC&utm_medium=referral&utm_campaign=MJCFW`} label={'Bet Now'} classes="btn small w-100" />
        </div>
      </Intro>
    )
  })

  return (
    <div className={`block-custom`}>
      <div ref={io} id="preakness-racing-information" className={`racing-standings-box flex-12`}>
        <div className="results-tab">
          <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
            <div className="no no-container">
              <p>Number</p>
            </div>
            <div className="horse-jockey">
              <p>Horse</p>
            </div>
            <div className="details">
              <p>Details</p>
            </div>
            <div className="ml">
              <p>Current Odds</p>
            </div>
            <div className="ml">
              <p>Current Pools</p>
            </div>
            <div className="button">
            </div>
          </Intro>

          {entriesMap}

          {loading ?
            <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
            :
            null
          }

        </div>
        &nbsp;
      </div>
    </div>
  )
}

export default Preakness

const APOLLO_QUERY = gql`
  query eventsQuery {
    options: optionsCollection(limit: 1) {
      items {
        marylandEntriesApi
        marylandOddsApi
        marylandPoolsApi
        marylandScratchesApi
      }
    }
  }
`