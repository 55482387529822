import React, { useEffect, useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import RaceDescriptionBox from '../RaceDescriptionBox'
import ResultsStandingsBox from '../ResultsStandingsBox'
import use1stAPI from '../../../../../Api'
import RaceControl from '../RaceControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'
import ResultsExotics from '../ResultsExotics'

function isEmptyObject(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

function Custom(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const { currentTab, setCurrentTab, selectedDate, switchFlag, data } = props

  // Setting up dates
  const selectedCDate = new Date(selectedDate)
  const selectedCYear = selectedCDate.getFullYear()
  const selectedCMonth = selectedCDate.getMonth() + 1
  const selectedCMonthLeadingZero = selectedCMonth < 10 ? '0' + selectedCMonth : '' + selectedCMonth
  const selectedCDay = selectedCDate.getDate()
  const selectedCDayLeadingZero = selectedCDay < 10 ? '0' + selectedCDay : '' + selectedCDay
  const selectedCRaceDate = `${selectedCYear}-${selectedCMonthLeadingZero}-${selectedCDayLeadingZero}`

  const [currentRace, setCurrentRace] = useState({
    race_date: selectedCRaceDate,
    race_number: null,
    highest_race: 1,
    track_code: 'GP',
    event_code: 'GPM',
    data: data
  })

  const { data: races, loading: racesLoading } = use1stAPI('races', { ...currentRace })
  const racesSorted = races?.races?.slice().sort((a, b) => (a.race_number > b.race_number) ? 1 : -1)

  const { data: runners, loading: runnersLoading } = use1stAPI('runners', { ...currentRace })
  const runnersSorted = runners?.runners?.slice().sort((a, b) => (parseInt(a.tote_number) > parseInt(b.tote_number)) ? 1 : -1)

  const { data: results, loading: resultsLoading } = use1stAPI('results', { ...currentRace })
  const resultsSorted = results?.results?.slice().sort((a, b) => (parseInt(a.race_number) > parseInt(b.race_number)) ? 1 : -1)

  const { data: track, loading: trackLoading } = use1stAPI('track', { ...currentRace })

  const { data: changes, loading: changesLoading } = use1stAPI('changes', { ...currentRace })

  const trackConditions = track?.track?.track_conditions?.map((node, i) => {
    if (!isEmptyObject(node)) {
      if (i === 0)
        return node.course + ': ' + node.condition
      return ', ' + node.course + ': ' + node.condition
    }
    else
      return false
  }).filter(obj => obj)

  const [runnersSwitched, setRunnersSwitched] = useState(false)

  // Track loading status changes
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!runnersLoading && !racesLoading && !resultsLoading && !trackLoading && !changesLoading) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [runnersLoading, racesLoading, resultsLoading, trackLoading, changesLoading])

  // Track tab changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_number: currentTab + 1 } })
    setRunnersSwitched(false)
  }, [currentTab])

  // Data changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, data: data } })
  }, [data])

  // Date changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_date: selectedCRaceDate } })
  }, [selectedCRaceDate])

  // Monitor runners change so it can switch to them without 
  useEffect(() => {
    setRunnersSwitched(true)
  }, [runners])

  let standingsAvailable = false

  const contentResults = racesSorted?.map((node, i) => {
    const d = new Date(node.post)

    if (i === currentTab) {

      const standingSorted = resultsSorted?.[i]?.finish_order?.slice().sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? 1 : -1)
      standingsAvailable = standingSorted ? true : false

      const exotic_payouts = resultsSorted?.[i]?.exotic_payouts

      return (
        <div key={`current-tab-${i}`} className="flex-12 box-wrap">
          <RaceDescriptionBox {...node}
            details={node.description}
            distance={node.distance}
            raceType={node.pb_class}
            postTime={node.published_post_time}
            purseInt={node.purse}
            raceBreed={node.race_breed}
            raceNumber={node.race_number}
            trackConditions={trackConditions}
            ii={i}
            d={d}
          />
          {standingSorted ?
            <ResultsStandingsBox {...node}
              standing={standingSorted}
              runners={runnersSorted}
              runnersSwitched={runnersSwitched}
              changes={changes?.changes?.[i + 1]}
              type="results"
            />
            :
            <h6 className='padd-bottom-half'>No racing information is available for the selected date, please come back at a later time.</h6>
          }
          {exotic_payouts ?
            <ResultsExotics exoticPayouts={exotic_payouts} />
            : null}
        </div>
      )
    } else {
      return false
    }
  })


  const contentReplays = results?.results?.map((node, i) => {

    if (i === currentTab) {
      return (
        <Intro key={`content-replays-${i}`} visible={ioInView} in={{ border: 500, bg: 700, fade: 500 }} delayIn={200} className="c5 border-c5 t replays">
          {node.replay ?
            <div className="aspect-ratio">
              <iframe src={node.replay} frameBorder="0" allowFullScreen title="Race Replay"></iframe>
            </div>
            : null}
        </Intro>
      )
    } else {
      return false
    }

  })


  const contentPhotoFinishes = results?.results?.map((node, i) => {

    if (i === currentTab) {
      return (
        <Intro key={`content-photo-finishes-${i}`} visible={ioInView} in={{ border: 500, bg: 700, fade: 500 }} delayIn={200} className="c5 border-c5 t replays">
          {node.photoFinish ?
            <div className="aspect-ratio">
              <iframe src={node.photoFinish} frameBorder="0" allowFullScreen title="Photo Finishes"></iframe>
            </div>
            : null}
        </Intro>
      )
    } else {
      return false
    }

  })


  // const contentStatistics = results?.results?.map((node,i) => {

  // 	if (i === currentTab) {
  // 		return (
  // 			<Intro key={`content-statistics-${i}`} visible={ioInView} in={{ fadeBottom: 500 }} delayIn={500} className="statistics">
  // 				<div className="statistics-inner">
  // 					<p>
  // 						{ node.times ?
  // 							<>
  // 								Times:<br/>
  // 								{node.times?.in5ths ? `Times in 5ths: ${node.times?.in5ths}` : null}<br/>
  // 								{node.times?.in100ths ? `Times in 100ths: ${node.times?.in100ths}` : null}<br/>
  // 							</>
  // 						: null }
  // 						{ node.winner ?
  // 							<>
  // 								Winner information:<br/>
  // 								{ node.winner.trainer ? `Trainer: ${node.winner?.trainer}` : null }
  // 								{ node.winner.trainer && node.winner.owner ? ' | ' : null }
  // 								{ node.winner.owner ? `Owner: ${node.winner?.owner}` : null }
  // 								<br/>
  // 								{ node.winner.sire ? `Sire: ${node.winner?.sire}` : null }
  // 								{ node.winner.sire && node.winner.breeder ? ' | ' : null }
  // 								{ node.winner.breeder ? `Breeder: ${node.winner?.breeder}` : null }
  // 								{ node.winner.unplaced ? 
  // 									<>
  // 										<br/>
  // 										Unplaced horses listed in order of finish: {node.winner?.unplaced}
  // 									</>
  // 								: null }
  // 								{ node.winner.alsoran ? 
  // 									<>
  // 										<br/>
  // 										Also ran: {node.winner?.alsoran}
  // 									</>
  // 								: null }
  // 								{ node.winner.payoffs ? 
  // 									<>
  // 										<br/>
  // 										Pay offs: {node.winner?.payoffs}
  // 									</>
  // 								: null }
  // 							</>
  // 						: null }
  // 						{	node.fullResultChart ? 

  // 								<a className="btn big text-center" alt="View Full Result Chart" href={node.fullResultChart}><span>View Full Result Chart</span></a>

  // 						: null }
  // 					</p>
  // 				</div>
  // 			</Intro>
  // 		)
  // 	} else {
  // 		return false
  // 	}

  // })

  let resultsSortedWithDescription = racesSorted?.map((item, i) => {
    if(resultsSorted?.[i])
      return {...resultsSorted?.[i], description: item?.description}
  }).filter(item => item)

  return (
    <>
      {!loading ?
        <>
          <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="padd-half padd-top-off padd-bottom-off" style={{ paddingBottom: '1px' }}>
            <RaceControl toControl={resultsSortedWithDescription?.length ? resultsSortedWithDescription : resultsSorted} currentTab={currentTab} setCurrentTab={setCurrentTab} program={null} programAbbreviated={null} selectedDate={selectedDate} />
          </Intro>
          <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="c0">
            <div ref={io} className="results-replays-component">

              <div className="span-12 flex-12">

                <Intro
                  switch={currentTab && contentResults}
                  in={{ fade: 300 }}
                  out={{ fade: 300 }}
                  delayIn={0}
                  delayOut={0}
                  mounted={true}
                  stay={true}
                  className={`span-${props.replays || props.photoFinishes ? '6' : '12'} span-12-tablet padd-half padd-bottom-off padd-top-off`}
                >
                  {contentResults}
                  {/* {contentStatistics} */}
                </Intro>

                {!contentResults ?
                  <h6 className='padd-bottom-off padd-left-half text-center'>No racing information is available for the selected date, please come back at a later time.</h6>
                  : null}

                {props.replays ?
                  <Intro
                    switch={currentTab && contentReplays}
                    in={{ fade: 300 }}
                    out={{ fade: 300 }}
                    delayIn={0}
                    delayOut={0}
                    mounted={true}
                    stay={true}
                    className="span-6 span-12-tablet padd-half padd-bottom-off padd-top-off"
                  >
                    <h3 className="heading-intro">
                      Race {currentTab + 1} replay
                    </h3>
                    {contentReplays}
                  </Intro>
                  : null}

                {props.photoFinishes ?
                  <Intro
                    switch={currentTab && contentPhotoFinishes}
                    in={{ fade: 300 }}
                    out={{ fade: 300 }}
                    delayIn={0}
                    delayOut={0}
                    mounted={true}
                    stay={true}
                    className="span-6 span-12-tablet padd-half padd-top-off"
                  >
                    <h3 className="heading-intro">
                      Photo Finish {currentTab + 1}
                    </h3>
                    {contentPhotoFinishes}
                  </Intro>
                  : null}

              </div>

            </div>
          </Intro>
        </>
        :
        <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
      }
    </>
  )
}

export default Custom