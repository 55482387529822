import React, { useContext} from 'react'
import './style.scss'
import NaviContext from '../../../context/NaviContext'

import Intro from '../../../animations/Intro'
import Hamburger from '../Hamburger'

function BarTop(props) {
	// const [hey,setHey] = useState(true)
	const naviContext = useContext(NaviContext)

	// General Animation Duration
	const baseDelay = 500
	const baseDelayStep = 150

  return (
		<div id="bar-top" className={`bar c0`} 
			// onClick={() => setHey((old) => !old)}
			// onMouseEnter={() => { naviContext.setActive(false); naviContext.setHamburgerActive(false)} }
			// onKeyDown={() => { naviContext.setActive(false); naviContext.setHamburgerActive(false)} }
			// role="button"
			// tabIndex={0}
			// aria-label=""
		>
			{ props.onMobile ?
				<Hamburger baseDuration={500} />
			: null }
			<div className="icons">
        <Intro visible={true} in={{ blink: 0 }} delayIn={baseDelay + baseDelayStep * 1}><a href="https://1st.com" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-1st active-dip"></div></a></Intro>
				<Intro visible={true} in={{ blink: 0 }} delayIn={baseDelay + baseDelayStep * 2}><a href="https://www.santaanita.com/" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-santaanita active-dip"></div></a></Intro>
				<Intro visible={true} in={{ blink: 0 }} delayIn={baseDelay + baseDelayStep * 3}><a href="https://www.laurelpark.com/" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-maryland active-dip"></div></a></Intro>
				<Intro visible={true} in={{ blink: 0 }} delayIn={baseDelay + baseDelayStep * 4}><a href="https://goldengatefields.com/" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-goldengate active-dip"></div></a></Intro>
      </div>
			{/* <div className="search-wrap fs-85">
				<Intro visible={hey} in={{ blink: 0 }} delayIn={baseDelay + baseDelayStep * 3} className="hover-trigger"><div className="icon icon-search last active-dip"></div></Intro>
			</div> */}
		</div>
  )
}

export default BarTop