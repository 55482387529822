import React, {useContext} from 'react'
import './style.scss'

import NaviContext from '../../context/NaviContext'

import BarTop from './BarTop'
import BarLogo from './BarLogo'
import BarNavi from './BarNavi'
import BarCarousel from './BarCarousel'

function Header() {
	const naviContext = useContext(NaviContext)
	const onMobile = naviContext.onMobile

  return (
		<>
			<header id='master-header' className={''}>
				<div 
					className={`canceller ${naviContext.isActive ? 'active' : ''}`}
					onMouseEnter={() => {
						naviContext.setActive(false)
						naviContext.setHamburgerActive(false)
						//console.log('enter')
					}}
					onClick={() => {
						naviContext.setActive(false)
						naviContext.setHamburgerActive(false)
						//console.log('click')
					}}
					onKeyDown={() => {
						naviContext.setActive(false)
						naviContext.setHamburgerActive(false)
						//console.log('key')
					}}
					role="button"
					tabIndex={0}
					aria-label='Close Navigation'
				></div>
				<BarTop onMobile={onMobile} />
				<div id="navi-wrapper">
					<BarLogo onMobile={onMobile} />
					<BarNavi onMobile={onMobile} />
				</div>
				{!onMobile ?
					<BarCarousel />
				: null }
			</header>
			<div id="master-header-mimic"></div>
			{onMobile ?
				<BarCarousel />
			: null }
		</>
  )
}

export default Header