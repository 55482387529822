import React, { useState, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'

function RaceDescriptionBox(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const {
    ii: iteration,
    surface: surface,
    d: dateSelected,
    distance: distance,
    purseInt: purseAmount,
    postTime: postTime,
    raceBreed: raceBreed,
    raceNumber: raceNumber,
    enhancements: enhancements,
    raceType: raceType,
    details: details,
    wagermenu: wagermenu,
    trackConditions: trackConditions,
  } = props

  let surfaceDecode = null

  switch (surface) {
    case 'A':
      surfaceDecode = 'ALL WEATHER'
      break;
    case 'D':
      surfaceDecode = 'DIRT'
      break;
    case 'T':
      surfaceDecode = 'TURF'
      break;
    default:
      surfaceDecode = surface
      break;
  }

  return (
    <div ref={io} className={`racing-description-box flex-12`}>

      <div className="row-1">
        {/* <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={0} mounted={true} stay={true} className="c5 box border-c5 t">
					<h3>R{iteration + 1}</h3>
				</Intro> */}
        <div className="row-1-inside">
          <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100} mounted={true} stay={true} className="box flex-column c5 border-c5 t border-right-c0">
            {postTime ?
              <h6>R{raceNumber} - Post Time: {postTime}</h6>
              : null}
            <h6>Track Conditions: {trackConditions && trackConditions.length ? trackConditions : 'N/A'}</h6>
          </Intro>
        </div>
        {distance ?
          <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} mounted={true} stay={true} className="box c5 border-c5 t border-right-c0">
            <h6>Distance:<br />{distance}</h6>
          </Intro>
          : null}

        {surfaceDecode ?
          <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={300} mounted={true} stay={true} className="box c5 border-c5 t">
            <h6>{surfaceDecode}</h6>
          </Intro>
          : null}
      </div>

      <div className="row-2 fs-85">
        {purseAmount || enhancements || raceType || details ?
          <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} mounted={true} stay={true} className="box c0 border-c5 t">
            <div className="col">
              <p>
                {raceType ?
                  <>
                    <span className="fat">Race Type:</span> {raceType}
                  </>
                  : null}
                {raceBreed ?
                  <>
                    <span className="fat">, Race Breed:</span> {raceBreed}
                  </>
                  : null}
                {purseAmount ?
                  <>
                    <br /><span className="fat">Purse:</span> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(purseAmount)}
                  </>
                  : null}
                {enhancements ?
                  <>
                    <br /><span className="fat">Enhancements:</span> {enhancements}
                  </>
                  : null}
              </p>
            </div>
            <div className="col">
              <p>
                {details ?
                  <>
                    <span className="fat">Details:</span> {details}
                  </>
                  : null}
              </p>
            </div>
          </Intro>
          : null}
      </div>

      <div className="row-3 fs-85">
        {wagermenu ?
          <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} mounted={true} stay={true} className="c5 box border-c5 t">
            <p>
              {wagermenu ?
                <>
                  {wagermenu}
                </>
                : null}
            </p>
          </Intro>
          : null}
      </div>

    </div>
  )
}

export default RaceDescriptionBox