import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import validatePdf from '../../../../utils/validatePdf'

function Custom(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const { repeaters: repeater } = props

  const links = repeater[0]?.repeater.map((node, i) => {
    return (
      <div key={`silks-${i}`} className='link span-12'>
        <a className="navi-link nostrike hover-trigger" href={validatePdf(node.value)} target="_blank" rel='noreferrer noopener'>
          {node.key}
          <div className="icon icon-arrow fs-85"></div>
        </a>
      </div>
    )
  })

  return (
    <div ref={io} className={`block-custom simulcast-center flex-12 c3 t ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      {links}
    </div>
  )
}

export default Custom