import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'

import Intro from '../../../../animations/Intro'
import Calendar from '../../../Calendar'
import EventGroup from './EventGroup'
//import CalendarContext from '../../../../context/CalendarContext'

import useEventsDayCollection from '../../../../utils/useQueryEventsDayCollection'

// Function to add or substract months in dates
function addMonths(date, months) {
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

// Convert date to url format
Date.prototype.toMyString = function () {

  //If month/day is single digit value add perfix as 0
  function AddZero(obj) {
    obj = obj + ''
    if (obj.length === 1)
      obj = "0" + obj
    return obj
  }

  var output = ''
  output += this.getFullYear()
  output += '-' + AddZero(this.getMonth() + 1)
  output += '-' + AddZero(this.getDate())

  return output
}

// The fun starts here
function EventsCalendarBig(props) {

  // console.log('eventscalendarbig')

  const data = useEventsDayCollection()

  // const calendarContext = useContext(CalendarContext)
  // const pathname = props.location?.pathname
  // const calendarYear = calendarContext && calendarContext.calendarView[pathname] ? calendarContext.calendarView[pathname].year : null
  // const calendarMonth = calendarContext && calendarContext.calendarView[pathname] ? calendarContext.calendarView[pathname].month : null
  const [calendarSelector, setCalendarSelector] = useState({
    dateSelected: null,
    currentViewStart: null,
    currentViewEnd: null
  })
  const [events, setEvents] = useState()
  const [scrollCount, setScrollCount] = useState(0)
  const [currentFilter, setCurrentFilter] = useState("")

  // Using callback to avoid rerenders on useEffects for those functions
  const calendarSelectorFunction = useCallback((i) => {
    setCalendarSelector(i)
  }, [])

  //const selectedDate = new Date(calendarSelector)

  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  // console.log('dateRange',pastDateRange,futureDateRange)
  // console.log('dateRange',calendarSelector?.currentViewStart,calendarSelector?.currentViewEnd)
  const todayMonthLeadingZero = todayMonth < 9 ? '0' + (todayMonth + 1) : '' + (todayMonth + 1)
  const todayDay = todayDate.getDate()
  const todayDayLeadingZero = todayDay < 10 ? '0' + todayDay : '' + todayDay
  const pastDateRange = addMonths(new Date(todayYear, todayMonth, 2), 0).toISOString()
  const futureDateRange = addMonths(new Date(todayYear, todayMonth, 1), 6).toISOString()
  const todayLimit = `${todayYear}-${todayMonthLeadingZero}-${todayDayLeadingZero}`


  const defaultImage = data?.contentfulOptions?.defaultRaceEventImage

  function stripDate(d) {
    //const x = d.replace(/-/g, '\/').replace(/T.+/, '')
    const dateToStrip = new Date(d)
    const year = dateToStrip.getFullYear()
    const month = dateToStrip.getMonth() + 1
    const monthLeadingZero = month < 10 ? '0' + month : '' + month
    const day = dateToStrip.getDate()
    const dayLeadingZero = day < 10 ? '0' + day : '' + day
    return `${year}-${monthLeadingZero}-${dayLeadingZero}`
  }

  // const currentView = data.allContentfulEventDay.nodes.filter(day => {
  // 	const givenDay = stripDate(day.date)
  // 	const startDay = stripDate(calendarSelector?.currentViewStart)
  // 	const endDay = stripDate(calendarSelector?.currentViewEnd)
  // 	const futureMax = stripDate(futureDateRange)
  // 	const today = stripDate(todayLimit)
  // 	//console.log('xxx',day.date,givenDay,day.date === givenDay)
  //   return (givenDay >= startDay && givenDay <= endDay && givenDay >= today && givenDay <= futureMax) 
  // })
  let currentView = data.allContentfulEventDay.nodes.filter(day => {
    const startDay = stripDate(calendarSelector?.currentViewStart)
    const endDay = stripDate(calendarSelector?.currentViewEnd)
    //const today = stripDate(todayLimit)
    return (day.date >= startDay && day.date < endDay && day.date >= todayLimit && day.date <= futureDateRange)
  })

  currentView.sort(function (a, b) {
    return new Date(a.date) - new Date(b.date)
  })
  if (currentFilter.length) {
    currentView = currentView
      .map(({ name, date, events, raceEvents, promotions }) => {
        if (currentFilter === 'racing-only') {
          promotions = promotions?.filter(item => {
            return item.type === 'Racing Promotion'
          })
          return { name, date, raceEvents, promotions }
        }
        if (currentFilter === 'events-only'){
          promotions = promotions?.filter(item => {
            return item.type === 'Shopping'
          })
          return { name, date, events, promotions }
        }
        if (currentFilter === 'promotions-only') {
          promotions = promotions?.filter(item => {
            return item.type === 'Casino Promotion'
          })
          return { name, date, promotions }
        }
      })
      .filter(day => day?.raceEvents?.length || day?.events?.length || day?.promotions?.length)
  }


  useEffect(() => {
    const stickyElement = document.querySelector('.block-calendar')
    const observerTarget = document.querySelector('.observer-flag')

    if (observerTarget && stickyElement) {
      let observer = new IntersectionObserver(function (entries) {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            stickyElement.classList.remove('is-docked')
          } else {
            stickyElement.classList.add('is-docked')
          }
        })
      })

      observer.observe(observerTarget)

      return () => {
        observer.unobserve(observerTarget)
      }
    }
  }, [calendarSelector.currentViewStart, calendarSelector.currentViewEnd])

  // Update 
  useEffect(() => {
    setEvents(currentView)
  }, [calendarSelector.currentViewStart, calendarSelector.currentViewEnd, currentFilter])

  // Scroll to top after month change 
  useEffect(() => {

    setScrollCount(i => i + 1)

    const timer = setTimeout(() => {
      if(scrollCount > 1)
        handleSelectionClick()
    }, 800)
    return () => clearTimeout(timer)
  }, [calendarSelector.currentViewStart, calendarSelector.currentViewEnd, handleSelectionClick])

  // The manual event click on calendar invokes scroll to said part
  const handleSelectionClick = useCallback((dateSimplified) => {
    //console.log('dateSimplified',dateSimplified)
    if (typeof window !== 'undefined') {
      const firstChild = document?.querySelector("#events-group-calendar .events-group:first-child")
      const eventsGroupButNotPassed = document?.querySelector("#events-group-calendar .events-group:not(.passed)")
      const elementTracked =  eventsGroupButNotPassed ? eventsGroupButNotPassed : firstChild ? firstChild : null
      const dateSimplifiedAssumed = dateSimplified || elementTracked?.querySelector(".anchor")?.id?.substring(3)
      const dateSimplifiedManaged = new Date(typeof dateSimplifiedAssumed === 'string' ? dateSimplifiedAssumed.replace(/-/g, '\/').replace(/T.+/, '') : dateSimplifiedAssumed)

      const href = 'on-' + dateSimplifiedManaged.toMyString()
      const hash = href.split('?')[0].split('=')[0]
      let mimic = document.querySelector('.block-calendar')?.clientHeight + document.querySelector('#bar-logo')?.clientHeight + document.querySelector('#bar-top')?.clientHeight + document.querySelector('.master-navi-animation')?.clientHeight
      let mimicMobile = document.querySelector('#bar-logo')?.clientHeight + document.querySelector('#bar-top')?.clientHeight
      if (document.querySelectorAll('#' + hash).length > 0) {
        let scrollValue = mimic ? window.pageYOffset + document.querySelector('#' + hash)?.getBoundingClientRect().top - mimic : window.pageYOffset + document.querySelector('#' + hash)?.getBoundingClientRect().top - mimicMobile
        window.scrollTo({
          top: scrollValue,
          behavior: 'smooth'
        })
      }
    }

  })

  const filterCalendar = useCallback((value) => {
    setCurrentFilter(value)
  })

  const forceNextMonth = () => {
    const button = document.querySelector('div.calendar-next-month')
    if (button)
      button.click()
  }

  // Prep events so that we got em rounded to full days for comparisons
  const eventsGroupedByDay = []
  const eventsViewedBunch = []


  // Merge Race Events with Events
  currentView.forEach(day => {
    if (!day.events)
      day.events = []
    if (!day.raceEvents)
      day.raceEvents = []
    if (!day.promotions)
      day.promotions = []

    let newArr = [].concat(day.events, day.raceEvents, day.promotions)

    const new_obj = { ...day, eventsCollection: { items: newArr } }
    eventsViewedBunch.push(new_obj)
  })

  eventsViewedBunch?.forEach(day => {
    const events = day.eventsCollection?.items.map((nodenode, i) => {

      let flag = true
      let d = new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).valueOf()

      // If there is already this event day in array, add the event to that day
      eventsGroupedByDay?.forEach(element => {
        if (element[d]) {
          element[d].push(nodenode)
          flag = false
        }
      })

      // Otherwise just add a new day
      if (flag) {
        eventsGroupedByDay.push(
          { [d]: [nodenode] }
        )
      }
    })
    return events
  })


  // Organize Event Blocks
  let passedCount = 0
  const eventsMap = eventsGroupedByDay?.map((eventDay, i) => {
    const last = eventsGroupedByDay.length === i + 1 ? true : false

    const day = new Date(parseInt(Object.keys(eventDay)[0]))
    // Find day later date so that we can compare it for setting events that passed (aka keep the day of event running till end of the day)
    let dayAfter = new Date(parseInt(Object.keys(eventDay)[0]))
    dayAfter = dayAfter.setDate(day.getDate() + 1)
    // Set event passed flag
    let passed = todayDate > dayAfter ? true : false
    // Count passed items
    if (passed) {
      ++passedCount
    }

    return (
      <EventGroup key={`eventgroup-${i}`} defaultImage={defaultImage} eventDay={eventDay} passed={passed} length={eventsGroupedByDay?.length} passedCount={passedCount} dateSelected={calendarSelector.dateSelected} todayDate={todayDate} last={last} i={i} />
    )
  })

  return (
    <>
      <div className='observer-flag' />
      <div className={`block-custom events-calendar-big ${props.classes}`}>
        {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
        <div className='white-placeholder'>
          <div id="calendar-top" className='anchor'></div>
        </div>

        <div className='calendar-frame'>
          <Calendar handleSelectionClick={handleSelectionClick} currentFilter={currentFilter} filterCalendar={filterCalendar} selector={calendarSelectorFunction} allowPast={false} minDate={pastDateRange} maxDate={futureDateRange} events={events} ui={'full'} classes={'c0'} />
        </div>

        <Intro id="events-group-calendar" switch={calendarSelector.currentViewStart} in={{ fade: 1000 }} mounted={false} stay={true} delayIn={500} out={{ fadeLeft: 500 }} delayOut={200} className="events-group">
          <div className={`events-container `}>
            {eventsMap.length > 0 ?
              eventsMap
              :
              <h2 className="text-center padd-top-half">{"Sorry, no events this month"}</h2>}
          </div>

          <div className='flex jc-center padd-half padd-top-off'>
            <a role='button' onClick={() => forceNextMonth()} className={`btn btn-small ${eventsMap.length <= 0 ? 'padd-bottom-half' : ''}`}><span>NEXT MONTH</span></a>
          </div>
        </Intro>
      </div>
    </>
  )
}

export default EventsCalendarBig