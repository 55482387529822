import React, {useContext, useEffect, useState} from 'react'
import './style.scss'
import NaviContext from '../../../context/NaviContext'

import Intro from '../../../animations/Intro'
import SubNavi from './SubNavi'
import { Link } from 'gatsby'
import { useAuth0 } from "../../../utils/auth"

function BarNavi(props) {
	const naviContext = useContext(NaviContext)
	const [selection, setSelection] = useState()
	const [touchable, setTouchable] = useState(false)

	// General Animation In
	const baseDelay =  props.onMobile ? 100 : 1500
	const baseDelayStep = 75

	const introAnimation = props.onMobile ? { bg: 500, fade: 500 } : { blink: 0, done: 0 }
	const { user } = useAuth0()

	// Compute shadow padding of navi
	useEffect(() => {

		// Checking window resize, dropping value into state
		function updateSize() {
			const mimic = document.getElementById('master-header-mimic')
			const masterNavi = naviContext.onMobile ? 0 : document.getElementById('master-navi') ? document.getElementById('master-navi').offsetHeight : 0
			const barLogo = document.getElementById('bar-logo') ? document.getElementById('bar-logo').offsetHeight : 0
			const barTop = document.getElementById('bar-top') ? document.getElementById('bar-top').offsetHeight : 0
			const height = 
				masterNavi + 
				barLogo + 
				barTop + 'px'
			if (mimic) {
				mimic.style.height = height
			}
		}
		window.addEventListener('resize', updateSize)
		updateSize()

		// Kill off listener
		return () => window.removeEventListener('resize', updateSize)
	},[naviContext.onMobile, naviContext.windowWidth, user])

	// Detect if device is touchable
	useEffect(() => {
		if ("ontouchstart" in document.documentElement)
			setTouchable(true)
		else
			setTouchable(false)
	},[naviContext.windowWidth])

	function handleClick(type) {
		if((!props.onMobile && naviContext.isActive && selection === type) || (props.onMobile && naviContext.isHamburgerActive && naviContext.isActive && selection === type )) {
			naviContext.setActive(true)
		} else {
			naviContext.setActive(true)
			setSelection(type)
		}
	}

	function naviLinkClick(e, mobile, disable) {
		if((mobile || touchable) && !disable) {
			e.preventDefault()
		}
		else {
			naviContext.setActive(false); 
			naviContext.setHamburgerActive(false);
		}
	}

	return (
		<div id="bar-navi">
			<Intro visible={ !props.onMobile ? true : naviContext.isHamburgerActive ? true : false } 
				in={{ border: baseDelay, appear: 0 }}
				delayIn={0}
				className={`c0 master-navi-animation ${props.onMobile ? 'onmobile border-c0  border-right-off' : ''}`}
				mounted={true}
			>
				<div id="master-navi" className='logo c0 t'>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/racing-information/" className="nostrike" onClick={(e) => { naviLinkClick(e, props.onMobile) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'live-racing' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('live-racing')}
								onClick={() => props.onMobile ? handleClick('live-racing') : null}
								onKeyDown={() => handleClick('live-racing')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>
								<div className="bg-hover"></div>
								<div className="icon icon-racing fs-85"></div>
								<h4>Racing</h4>
							</div>
						</Link>
						{ props.onMobile && (selection === 'live-racing') && naviContext.isActive?
							<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay + baseDelayStep} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/wagering/" className="nostrike"  onClick={(e) => { naviLinkClick(e, props.onMobile) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'wagering' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('wagering')}
								onClick={() => props.onMobile ? handleClick('wagering') : null}
								onKeyDown={() => handleClick('wagering')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>
								<div className="bg-hover"></div>
								<div className="icon icon-wagering fs-85"></div>
								<h4>Wagering</h4>
							</div>
						</Link>
						{ props.onMobile && (selection === 'wagering') && naviContext.isActive ?
							<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay + baseDelayStep * 2} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/casino/" className="nostrike" onClick={(e) => { naviLinkClick(e, props.onMobile) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'casino' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('casino')}
								onClick={() => props.onMobile ? handleClick('casino') : null}
								onKeyDown={() => handleClick('casino')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>	
								<div className="bg-hover"></div>
								<div className="icon icon-casino fs-85"></div>
								<h4>Casino</h4>							
							</div>
						</Link>
						{ props.onMobile && (selection === 'casino') && naviContext.isActive ?
							<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay + baseDelayStep * 3} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/events/" className="nostrike"  onClick={(e) => { naviLinkClick(e, props.onMobile) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'events' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('events')}
								onClick={() => props.onMobile ? handleClick('events') : null}
								onKeyDown={() => handleClick('events')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>
								<div className="bg-hover"></div>
								<div className="icon icon-events fs-85"></div>
								<h4>Events</h4>			
							</div>
						</Link>
						{ props.onMobile && (selection === 'events') && naviContext.isActive ?
							<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay + baseDelayStep * 4} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/dining/" className="nostrike" onClick={(e) => { naviLinkClick(e, props.onMobile, true) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile, true) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'dining' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('')}
								onClick={() => props.onMobile ? handleClick('') : null}
								onKeyDown={() => handleClick('')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>
								<div className="bg-hover"></div>
								<div className="icon icon-dining fs-85"></div>
								<h4>Dining</h4>				
							</div>
						</Link>
						{ props.onMobile && (selection === 'dining') && naviContext.isActive ?
							<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay + baseDelayStep * 5} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/shopping/" className="nostrike" onClick={(e) => { naviLinkClick(e, props.onMobile, true) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile, true) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'shopping' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('')}
								onClick={() => props.onMobile ? handleClick('') : null}
								onKeyDown={() => handleClick('')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>
								<div className="bg-hover"></div>
								<div className="icon icon-santa-anita-hoy fs-85"></div>
								<h4>Shopping</h4>							
							</div>
						</Link>
						{ props.onMobile && (selection === 'shopping') && naviContext.isActive ?
							<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay + baseDelayStep * 6} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/discover/" className="nostrike" onClick={(e) => { naviLinkClick(e, props.onMobile) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'discover' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('discover')}
								onClick={() => props.onMobile ? handleClick('discover') : null}
								onKeyDown={() => handleClick('discover')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>
								<div className="bg-hover"></div>
								<div className="icon icon-discover fs-85"></div>
								<h4>Discover</h4>						
							</div>
						</Link>
						{ props.onMobile && (selection === 'discover') && naviContext.isActive ?
							<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
					<Intro visible={true} in={introAnimation} delayIn={baseDelay + baseDelayStep * 7} className={`${props.onMobile ? 'c5 t' : ''}`}>
						<Link to="/en-espanol/" className="nostrike"  onClick={(e) => { naviLinkClick(e, props.onMobile) }} onKeyDown={(e) => { naviLinkClick(e, props.onMobile) }}>
							<div className={`navi-item hover-trigger active-dip ${naviContext.isActive && selection === 'en-espanol' ? 'current' : ''}`}
								onMouseEnter={() => props.onMobile ? null : handleClick('en-espanol')}
								onClick={() => props.onMobile ? handleClick('en-espanol') : null}
								onKeyDown={() => handleClick('en-espanol')}
								aria-label="Navi Link" 
								role="button"
								tabIndex={0}
							>
								<div className="bg-hover"></div>
								<div className="icon icon-news fs-85"></div>
								<h4>En EspaÑol</h4>
							</div>
						</Link>
						{ props.onMobile  && (selection === 'en-espanol') && naviContext.isActive ?
								<SubNavi subnavi={selection} onMobile={props.onMobile} />
						: null }
					</Intro>
				</div>
			</Intro>
			{ !props.onMobile ?
				<SubNavi subnavi={selection} onMobile={props.onMobile} />
			: null }
		</div>
	)
}

export default BarNavi