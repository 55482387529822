import React, {useEffect, useState} from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import BlocksRouter from '../../Sections/BlocksRouter'
import { useInView } from 'react-intersection-observer'
import { useLocation } from '@reach/router'

function Tabs(props) {
	const [currentTab, setCurrentTab] = useState(0)
	const [io, ioInView] = useInView({ triggerOnce: true })
	const location = useLocation()

	const tabz = props.tabs ? props.tabs : null

	// Make Unique ID
	function makeid(length) {
    var result           = ''
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
		}
		return result
	}
	const [id, setId] = useState(makeid(10))

	// Look if the classes specify if the block is supposed to be wrapped
	let wrapped = props.classes?.search('wrapped')

	let circleMark = props.classes?.includes('circle-mark') ? true : false
	
	// If the current tab is marked as blank, skip to next one (default loading)
	useEffect(() => {
		if(tabz && tabz[currentTab]?.tabHandleClasses) {
			let blank = tabz[currentTab].tabHandleClasses?.search('blank')
			if (blank !== (-1)) {
				setCurrentTab(old => (old + 1))
			}
		}
	},[currentTab, tabz])

	// Monitor hash/parameters
	useEffect(() => {
		const href = location ? location.hash.substr(1) : ''
		const hash = href.split('?')[0]
		const params = href.split('?')[1]

		// Split params
		if (params && params !== '') {
			var search = params.split('&').reduce(function (res, item) {
				var parts = item.split('=')
				res[parts[0]] = parts[1]
				return res;
			}, {})

			// Set Tab
			if (hash === props.selectorAnchor) {
				setCurrentTab(parseInt(search.tab) > tabz?.length ? tabz?.length - 1 : parseInt(search.tab) < 0 ? 0 : parseInt(search.tab))
			}
		}
	},[props.selectorAnchor,location])

	// Run Scroll
	function run(hash) {
		if (typeof window !== 'undefined') {
			const mimic = document.getElementById('master-header-mimic')
			const anchor = document.querySelectorAll('#' + hash)[0]
			const anchorHeight = Math.round(anchor?.offsetHeight)
			const viewPortHeight = window.innerHeight
			const pageY = window.pageYOffset
			const offsetH = mimic?.offsetHeight
			const hashTop = Math.round(document.querySelector('#' + hash)?.getBoundingClientRect().top)
			const scrollToPosition = viewPortHeight < anchorHeight + (offsetH/2) ? pageY - offsetH + hashTop : pageY + hashTop - (viewPortHeight - anchorHeight)/2 - offsetH/2
			//console.log(hash,anchor,scrollToPosition)
			window.scrollTo({
				top: scrollToPosition,
				behavior: 'smooth'
			})
		}
	}

	// Tabs
	const TabPack = tabz?.map((node, i) => {

		const blocks = node.tabHandle?.map((subnode, i) => {
			return <BlocksRouter key={`blockx-${i}`} blocks={[{...subnode}]} />
		})

		// Look if the classes specify if the block is supposed to be blank
		let blank = node.tabHandleClasses ? node.tabHandleClasses?.search('blank') : (-1)

		return (
			<div className={`tab-handle ${node.tabHandleClasses} ${i === currentTab ? 'current' : ''} ${circleMark ? 'circle-mark' : ''}`}  key={i} onClick={() => onTabChange(i,blank)} onKeyDown={() => onTabChange(i,blank)} role="button" aria-label="Tab handle, day" tabIndex={0}>
				<div className={`${circleMark ? 'relative-container' : 'flex-12'}`}>
					{blocks}
					{circleMark ? 
						<Intro visible={i === currentTab && ioInView} in={{ fade: 100, draw: 800 }} delayIn={300} stay={false} className="with-svg mark x">
							<svg viewBox="0 0 594.18245 65.737857">
								<path					
									d="m 174.316,64.179 q 80.271,2.587 232.3,-3.635 c 82.134,-4.353 180.369,-18.639 186.268,-31.3 18.819,-40.4 -501,-34.2 -587.1,-9.978 -6.45,1.815 -5.763,5.641 -5.763,8.355 0,7.561 34.217,14.111 47.872,16.178 141.337,21.392 437.767,29.286 541.076,1.64"
									fill="none"
									stroke="#ffffff"
									strokeWidth="1.2"
								/>
							</svg>
						</Intro>
					: 
						null
					}
				</div>
			</div>
		)
	})

	// Tabs Content
	const TabContentPack = tabz ? tabz?.map((node, i) => {
		
		if (i === currentTab) {
			const blocks = node.tabContent?.map((subnode, i) => {
				return <BlocksRouter key={i} blocks={[{...subnode}]} />
			})

			// Look if the classes specify if the block is supposed to be blank
			let blank = node.tabHandleClasses ? node.tabHandleClasses?.search('blank') : (-1)

			return blank === (-1) ? (
				<div key={i} className={`tab-content ${node.tabContentClasses}`}>
					<div id={`tab-${i}-${id}`} className="anchor"></div>
					{blocks}
					{props.next && tabz?.length - 1 > i ?
						<div className="tab-control-next-positioner hover-trigger" onClick={() => onTabChange(i + 1,blank)} onKeyDown={() => onTabChange(i + 1,blank)} role="button" aria-label="Next" tabIndex={0}><span className="text">Next</span><span className="icon"></span></div>
					: null }
				</div>
			) : false
		}
		return false
	}) : <h6>No Content</h6>

	// On Parent Tab Change
	function onTabChange(i,blank) {
		if(blank === (-1)) {
			setCurrentTab(i)
      setTimeout(() => {
				run(`tab-${i}-${id}`)
      }, 300)
		} else {
			return false
		}
	}

  return tabz ? (
		<>
			{wrapped === (-1) ?
				<>
					<div ref={io} className={`tab-handles flex-12`}>
						{TabPack}
					</div>
					<Intro switch={currentTab || ioInView} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
						{TabContentPack}
					</Intro>
				</>
			:
				<div ref={io} className={`block-tabs ${props.classes}`}>
					<div className={`tab-handles flex-12`}>
						{TabPack}
					</div>
					<Intro switch={currentTab || ioInView} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents">
						{TabContentPack}
					</Intro>
				</div>
			}
		</>
  ) : false
}

export default Tabs