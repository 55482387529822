import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'

import Wysiwyg from '../../../Wysiwyg'
import Button from '../../../Button'
import { GatsbyImage } from 'gatsby-plugin-image'

let formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0,
})

function EventGroup(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const { eventDay, i, defaultImage } = props


	let eventsWithin = []

	for (let key in eventDay) {
		if(eventDay.hasOwnProperty(key)) {

			let day = new Date(parseInt(key)).toMyString()
		
			// Populate array of events
			eventsWithin.push(eventDay[key]?.map((nodenode,ii) => {

				const links = nodenode?.stakes?.data?.length > 0 ? nodenode.stakes.data?.map((link, i) => {
					
					return link.key ? (
						<div key={i} className="link-wrap">
						<div className="entry">
							<span>{`${link.key}, ${link.value ? link.value : null}, ${link.value2 ? link.value2 : null} - ${link.value3 ? formatter.format(link.value3) : null}`}</span>
						</div>
					</div>
					) : (
						null
					)
				}) : null

				const buttons = nodenode.buttons?.length > 0 ? nodenode.buttons?.map((button, i) => {
					return <Button key={i} link={button.value} label={button.key} classes="" />
				}) : null

				const raceDetails = nodenode.sys.contentType.sys.id === 'raceEvent' ?
					<Button link={`/racing-information/#race?day=${day}`} label={'Race Details'} classes="learn-more" />
				: null
				
				const promoButton = nodenode.sys.contentType.sys.id === 'promotion' ?
				<Button link={`/promotion/${nodenode.slug}/`} label={'learn-more'} classes="learn-more" />
				: null

				const eventButton = nodenode.sys.contentType.sys.id === 'event' ?
				<Button link={`/event/entertainment/${nodenode.slug}/`} label={'learn-more'} classes="learn-more" />
				: null

				return (
					<div key={`sub-${ii}`} style={{order: nodenode?.priority ?  (nodenode?.priority * -1) : 0}}  className={`event-block`}>

						<div className="image-content-box">

            {nodenode?.featuredImage?
									<div className="image-box">
										<div className='aspect-ratio'>
                      <GatsbyImage
                        image={nodenode.featuredImage.gatsbyImageData}
                        imgStyle={{objectFit: 'cover'}}
                        objectPosition='50% 50%'
                        title={nodenode.featuredImage.title}
                        alt={nodenode.featuredImage.description}
                        className="bg img-cover"
                      />	
										</div>						
									</div>
								: 
								<div className="image-box">
									<div className='aspect-ratio'>
                    <GatsbyImage
                      image={defaultImage.gatsbyImageData}
                      imgStyle={{objectFit: 'cover'}}
                      objectPosition='50% 50%'
                      title={defaultImage.title}                    
                      alt={defaultImage.description}
                      className="bg img-cover"
                    />
									</div>	
								</div>
								}

							<div className="content-box">

								{nodenode.type ?
									<h6 className="type">{nodenode.type}</h6>
								: null}

								<div className='copy'>
									<h2>{nodenode?.name} </h2>
								</div>

							
									<div className="button-box">
										<div className="button-box-inner">
											{buttons ? buttons : null}
											{raceDetails ? raceDetails : null}
											{promoButton ? promoButton : null}
											{eventButton ? eventButton : null}
										</div>
									</div>
						
	
							</div>

						</div>

						{links ?
							<div className="links-box">
								{links}
							</div>
						: null}

						{/* <small>
							starts at: {eventTime.toLocaleTimeString(['en-US'], { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit'})}
							<span className="extrasmall">
								({eventTime.toLocaleTimeString(['en-US'], { hour: '2-digit', minute: '2-digit'})} your time)
							</span>
						</small> */}
					
					</div>
				)

			}))

		}
	}

  return (
		<Intro
			key={`eventgroup-${i}`}
			visible={ioInView} 
			in={{ fadeBottom: 400 }} 
			delayIn={0} 
			mounted={true}
			stay={true} 
			className={`events-group`}
		>
			<div ref={io} className={`events-wrap c0`} >

				<div className="group">
					{eventsWithin}
				</div>

			</div>
		</Intro>
  )
}

export default EventGroup