import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../animations/Intro'

function ToSection(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<div ref={io} className={`block-tosection ${props.classes}`}>
			<Intro visible={ioInView} in={{ blink: 500 }} delayIn={800} mounted={true} stay={true} className="c3 t">
				<a href={`#${props.toSection}`} className="decorative-arrow down dark">
					{props.label ? <div className="label">{props.label}</div> : null}
				</a>
			</Intro>
		</div>
  )
}

export default ToSection