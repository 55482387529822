import React, { useState, useEffect } from 'react'
import './style.scss'
//import NaviContext from '../../../context/NaviContext'
import { GatsbyImage } from 'gatsby-plugin-image'
import Intro from '../../../animations/Intro'
import { useStaticQuery, graphql, Link } from "gatsby"
import validatePdf from '../../../utils/validatePdf'

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

function BarCarousel(props) {
  //const naviContext = useContext(NaviContext)

  const data = useStaticQuery(graphql`
    query NaviSlider {
      contentfulOptions {
        banner {
          name
          link
          image {
            gatsbyImageData
            description
            title
          }
          top
          left
          right
          type
        }
      }
    }
  `)

  const naviSliderData = data?.contentfulOptions?.banner

  // Delay Synchronization	
  const [baseDelay, setBaseDelay] = useState(1500)

  // Slider settings
  const [slide, setSlide] = useState(0)
  const [uiHover, setUiHover] = useState(false)

  const slideRate = 5500

  const content = naviSliderData?.map((item, i) => {
    if (item.type === 'Banner' && slide === i) {
      return (
        <div key={`slide-${i}`} className="slide banner">
          <div className="main">
            <div className="row">
              {item.link && validURL(item.link) ?
                <a href={item.link ? validatePdf(item.link) : null} className="nostrike" target="_blank" rel='noreferrer noopener' aria-label="Carousel link">
                  <Intro visible={true} in={{ border: 5, bg: 400, fade: 500 }} delayIn={baseDelay} className="pic c5 border-c5 t">
                    <div className="bg p-0">
                      <GatsbyImage
                        image={item?.image?.gatsbyImageData}
                        imgStyle={{ objectFit: 'cover' }}
                        objectPosition='50% 50%'
                        title={item?.image?.title}
                        alt={item?.image?.description}
                      />
                    </div>
                  </Intro>
                </a>
                : item.link ?
                  <Link to={item.link ? item.link : null} className="nostrike" aria-label="Carousel link">
                    <Intro visible={true} in={{ border: 5, bg: 400, fade: 500 }} delayIn={baseDelay} className="pic c5 border-c5 t">
                      <div className="bg p-0">
                        <GatsbyImage
                          image={item?.image?.gatsbyImageData}
                          imgStyle={{ objectFit: 'cover' }}
                          objectPosition='50% 50%'
                          title={item?.image?.title}
                          alt={item?.image?.description}
                        />
                      </div>
                    </Intro>
                  </Link>
                  :
                  <Intro visible={true} in={{ border: 5, bg: 400, fade: 500 }} delayIn={baseDelay} className="pic c5 border-c5 t">
                    <div className="bg p-0">
                      <GatsbyImage
                        image={item?.image?.gatsbyImageData}
                        imgStyle={{ objectFit: 'cover' }}
                        objectPosition='50% 50%'
                        title={item?.image?.title}
                        alt={item?.image?.description}
                      />
                    </div>
                  </Intro>
              }
            </div>
          </div>
        </div>
      )
    }
    else if (item.type === 'Slide' && slide === i) {
      return (
        <div key={`slide-${i}`} className="slide">
          {item.link && validURL(item.link) ?
            <a href={validatePdf(item.link)} className="slide nostrike" target="_blank" rel='noreferrer noopener'>
              <div className="aside">
                <Intro visible={true} in={{ border: 5, bg: 0, fade: 0 }} delayIn={baseDelay} className="decor c3 t"></Intro>
              </div>
              <div className="main">
                <div className="row">
                  <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay} className="box c0 t">
                    <h2>{item.top ? item.top : null}</h2>
                  </Intro>
                </div>
                <div className="row">
                  <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay} className="box c5 t border-c5">
                    <h6>{item.left ? item.left : null}</h6>
                  </Intro>
                  <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay + 400} className="box c3 t">
                    <h3>{item.right ? item.right : null}</h3>
                  </Intro>
                </div>
              </div>
            </a>
            : item.link ?
              <Link to={item.link} className="slide nostrike">
                <div className="aside">
                  <Intro visible={true} in={{ border: 5, bg: 0, fade: 0 }} delayIn={baseDelay} className="decor c3 t"></Intro>
                </div>
                <div className="main">
                  <div className="row">
                    <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay} className="box c0 t">
                      <h2>{item.top ? item.top : null}</h2>
                    </Intro>
                  </div>
                  <div className="row">
                    <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay} className="box c5 t border-c5">
                      <h6>{item.left ? item.left : null}</h6>
                    </Intro>
                    <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay + 400} className="box c3 t">
                      <h3>{item.right ? item.right : null}</h3>
                    </Intro>
                  </div>
                </div>
              </Link>
              :
              <>
                <div className="aside">
                  <Intro visible={true} in={{ border: 5, bg: 0, fade: 0 }} delayIn={baseDelay} className="decor c3 t"></Intro>
                </div>
                <div className="main">
                  <div className="row">
                    <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay} className="box c0 t">
                      <h2>{item.top ? item.top : null}</h2>
                    </Intro>
                  </div>
                  <div className="row">
                    <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay} className="box c5 t border-c5">
                      <h6>{item.left ? item.left : null}</h6>
                    </Intro>
                    <Intro visible={true} in={{ border: 5, bg: 0, fade: 500 }} delayIn={baseDelay + 400} className="box c3 t">
                      <h3>{item.right ? item.right : null}</h3>
                    </Intro>
                  </div>
                </div>
              </>
          }
        </div>
      )
    }
  })

  let max = content?.length

  let timer = null

  // Slider animation
  useEffect(() => {

    let i = slide

    // Hover pause
    if (uiHover === true) {
      clearTimeout(timer)
    } else {
      timer = setTimeout(() => {
        i++
        if (max === i) {
          i = 0
        }
        setSlide(function () {
          setBaseDelay(0)
          return i
        })
        setSlide(i)
      }, slideRate)
    }

    // Clear timeout on useEffect kill
    return () => {
      clearTimeout(timer)
    }

  }, [slide, uiHover, timer])

  // Clicking the dots
  function dotClicked(i) {
    setBaseDelay(0)
    setSlide(i)
  }

  useEffect(() => {
    if(typeof document !== 'undefined') {
      const heroCarouselSwipeElement = document.getElementById(`master-carousel`)
      let touchstartX = 0
      let touchendX = 0
          
      function checkDirection() {
        if (touchendX < touchstartX && touchstartX - touchendX > 100 ) {setSlide((nr) => {setBaseDelay(0); return nr + 1 < max ? nr + 1 : 0}); return true}
        if (touchendX > touchstartX && touchendX - touchstartX > 100) {setSlide((nr) => {setBaseDelay(0); return nr - 1 >= 0 ? nr - 1 : max - 1}); return true}
        else return false
      }

      heroCarouselSwipeElement.addEventListener('touchstart', e => {
        touchstartX = e.changedTouches[0].screenX
      })

      heroCarouselSwipeElement.addEventListener('touchend', e => {
        const currentSliderPic = heroCarouselSwipeElement.querySelector(`.animation-intro-wrap .slide`)
        touchendX = e.changedTouches[0].screenX
        const touchDistance = touchendX - touchstartX;
        const moveDistance = Math.abs(touchDistance) > 10 ? 10 : Math.abs(touchDistance)
        const direction = touchDistance > 0 ? 1 : -1
        const translateDistance = direction * moveDistance
        currentSliderPic.style.transition = 'transform 0.3s ease, opacity 0.3s ease'

        const slideChanged = checkDirection()

        if(slideChanged) {
          currentSliderPic.style.transform = `translateX(${direction * 100}%)`
          currentSliderPic.style.opacity = 0
        }
        else {
          currentSliderPic.style.opacity = 1
          currentSliderPic.style.transform = `translateX(${translateDistance}px)`
          currentSliderPic.style.transform = 'none'
        }

      })

      heroCarouselSwipeElement.addEventListener('touchmove', e => {
        const currentSliderPic = heroCarouselSwipeElement.querySelector(`.animation-intro-wrap .slide`)
        const touchmoveX = e.changedTouches[0].screenX
        const touchDistance = touchmoveX - touchstartX
        // const moveDistance = Math.abs(touchDistance) > 300 ? 300 : Math.abs(touchDistance)
        const moveDistance = Math.abs(touchDistance)
        const direction = touchDistance > 0 ? 1 : -1
        const translateDistance = direction * moveDistance
        currentSliderPic.style.transform = `translateX(${translateDistance}px)`
        currentSliderPic.style.opacity = Math.max(Math.exp(-0.01 * moveDistance), 0.1)
    })
    }
  },[max])

  // UI Dots
  const dots = content?.map((node, i) => {
    return (
      <Intro key={`dots-${i}`} visible={true} in={{ fade: 500 }} delayIn={baseDelay + 1500} className="c3 t"><div className={`${i === slide ? 'current' : ''} li`} onClick={() => dotClicked(i)}></div></Intro>
    )
  })

  return (
    <div id="bar-carousel">
      <div id="master-carousel" className="" onMouseEnter={() => setUiHover(true)} onMouseLeave={() => setUiHover(false)} role="button" aria-label="Pause slider" tabIndex={0}>
        <Intro visible={true} in={{ border: 500, bg: 200, appear: 0 }} delayIn={800} className="c0 border-right-off">
          <div className="master-carousel-inner">
            <Intro switch={slide} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="">
              {content}
            </Intro>
          </div>
        </Intro>
        <div className="dots">
          <div>
            {dots}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BarCarousel