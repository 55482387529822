import React, {useState} from 'react'
import './style.scss'
import BlocksRouter from '../../Sections/BlocksRouter'

function Timeline(props) {
   
   const [currentSlide, setCurrentSlide] = useState(0)

    const timeline = []
    const milestones = []

    for (let i = 0 ; i < props.blocks?.length ; i++) {
        
        let title = props.blocks[i].name
        // let wysiwyg = props.blocks[i].content
    
        timeline.push (   
            <div key={i} onClick={() => setCurrentSlide(i)} style={{transform: `translateX(-${(currentSlide * 100) + 50}%)`}} className={`timeline-slide single-item ${currentSlide === i ? 'current' : ''}`} role="button" aria-label="Timeline select" tabIndex={0}>
                
                <div className="blur-group">
                    <h4 className="title">{title}</h4>
                    {props.blocks[i].content ? 
                        <BlocksRouter blocks={[{...props.blocks[i], __typename: 'ContentfulBlockWysiwyg'}]} />
                    : null}
                    {props.blocks[i].image ? 
                        <BlocksRouter blocks={[{...props.blocks[i], classes: 'w-100', __typename: 'ContentfulBlockImage'}]} />
                    : null}
                    {props.blocks[i].imgSrcSet ? 
                        <BlocksRouter blocks={[{...props.blocks[i], classes: 'w-100', __typename: 'ContentfulBlockImage'}]} />
                    : null}
                </div>
                
            </div>
        )
        
        milestones.push (
            <div key={i} onClick={() => setCurrentSlide(i)} onKeyDown={() => setCurrentSlide(i)} className={ `milestone ${currentSlide === i ? 'current' : ''}`} style={{transform: `translateX(-${(currentSlide * 100) + 50}%)`}} role="button" aria-label="Milestone select" tabIndex={0}>
                 <div className="text">{title}</div>
            </div>
        )
    } 
       

	return (
        <div className="timeline">
            <div className="timeline-wrap">
                <div className='timeline-slider'>
                    {timeline}
                </div>
                <div className="milestones">
                    {milestones}
                </div>
            </div>
        </div>
	)
}

export default Timeline