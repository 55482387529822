import React, { useEffect, useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import _ from 'lodash'

import Intro from '../../../../../animations/Intro'
import use1stAPI from '../../../../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'
import RaceDescriptionBox from '../RaceDescriptionBox'
import RaceControl from '../RaceControl'

function isEmptyObject(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

function Custom(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const { selectedDate, data, switchFlag } = props


  // Setting up dates
  const selectedCDate = new Date(selectedDate)
  const selectedCYear = selectedCDate.getFullYear()
  const selectedCMonth = selectedCDate.getMonth() + 1
  const selectedCMonthLeadingZero = selectedCMonth < 10 ? '0' + selectedCMonth : '' + selectedCMonth
  const selectedCDay = selectedCDate.getDate()
  const selectedCDayLeadingZero = selectedCDay < 10 ? '0' + selectedCDay : '' + selectedCDay
  const selectedCRaceDate = `${selectedCYear}-${selectedCMonthLeadingZero}-${selectedCDayLeadingZero}`

  const [currentRace, setCurrentRace] = useState({
    race_date: selectedCRaceDate,
    race_number: null,
    highest_race: 1,
    track_code: 'GP',
    event_code: 'GPM',
    data: data
  })

  // const manualChanges = props?.data?.manualChanges

  // const manualChangesMap = manualChanges?.map((item, i) => {

  //   const changes = item?.value?.split(';')?.map((item, j) => {
  //     return (
  //       <Intro key={j} visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={100 * j} className="scratch c0 border-c5 t border-top-off border-right-off border-left-off">
  //         {item}
  //       </Intro>
  //     )
  //   })

  //   return (
  //     <Intro key={i} visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100 * i} className="scratch-group c0 border-c5 t border-top-off border-right-off">
  //       <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100 * i} className="scratch-group-race c5 border-c0 t border-top-off border-right-c0">
  //         <h4>{item.key}</h4>
  //       </Intro>
  //       {changes}
  //     </Intro>
  //   )
  // })

  //console.log(data)

  const { data: changes, loading: changesLoading } = use1stAPI('changes', { ...currentRace })
  const { data: track, loading: trackLoading } = use1stAPI('track', { ...currentRace })

  const trackConditions = track?.track?.track_conditions?.map((node, i) => {
    if (!isEmptyObject(node)) {
      if (i === 0)
        return node.course + ': ' + node.condition
      return ', ' + node.course + ': ' + node.condition
    }
    else
      return false
  }).filter(obj => obj)


  let batchOfRaces = []
  _.each(changes?.changes, function (value, i) {
    // Runners for the rece selected
    const runners = data.runners?.[i - 1]
    // Prepare scratches array
    let batchOfScratches = []
    let batchOfScratchesSorted = []
    // Get changes 
    const changes = value?.changes
    //console.log('changes',changes)
    _.each(changes, function (value, j) {
      const racer = runners?.filter(({ post_position }) => parseInt(post_position) === parseInt(j))
      const changed = value
      //console.log('racer',racer,changed)
      _.each(racer, function (value, k) {
        //console.log('ch',changed)
        if (changed.scratched || changed.jockey || changed.equipment || changed.current_weight) {
          const bipn = parseInt(racer?.[k]?.betting_interest_program_number)
          const bin = racer?.[k]?.betting_interest_name
          //const pp = parseInt(racer?.[k]?.post_position)
          batchOfScratches.push([
            <Intro key={`${bipn}${k}`} visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={100 * parseInt(bipn)} className="scratch c0 border-c5 t border-top-off border-right-off border-left-off">
              {changed.scratched || changed.jockey || changed.equipment || changed.current_weight ?
                <>
                  #{bipn} {bin}
                </>
                : null}
              {changed.scratched ? ` - Scratched` : null}
              {changed.jockey ? ` - Jockey: ${changed.jockey}` : null}
              {changed.equipment ? ` - ${changed.equipment}` : null}
              {changed.current_weight ? ` - Current Weight: ${changed.current_weight}` : null}
            </Intro>,
            bipn
          ]
          )
        }
      })
    })

    if (batchOfScratches.length > 0) {
      // Sort the array of scratches (according now to the bipn numbers)
      batchOfScratches.sort(function (a, b) {
        return a[1] - b[1]
      })
      // Get just JSX
      const batchOfScratchesJSXOnly = batchOfScratches.map((node) => {
        return node[0]
      })
      // Push to race groups
      batchOfRaces.push(
        <Intro key={i} visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100 * i} className="scratch-group c0 border-c5 t border-top-off border-right-off">
          <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={100 * i} className="scratch-group-race c5 border-c0 t border-top-off border-right-c0">
            <h4>Race {i}</h4>
          </Intro>
          {batchOfScratchesJSXOnly}
        </Intro>
      )
    }
  })

  // Track loading status changes
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!changesLoading && !trackLoading) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [changesLoading, trackLoading])

  // Date changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_date: selectedCRaceDate } })
  }, [selectedCRaceDate])

  // Data changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, data: data } })
  }, [data])


  return (
    <>
      {/* <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="padd-half padd-top-off padd-bottom-off" style={{ paddingBottom: '1px' }}>
        <RaceControl selectedDate={selectedDate} />
      </Intro> */}
      {!loading ?
        <div ref={io} className="scratches-changes-component">

          <div className="span-12 flex-12">

            <Intro
              switch={switchFlag}
              in={{ fade: 300 }}
              out={{ fade: 300 }}
              delayIn={0}
              delayOut={0}
              mounted={true}
              stay={true}
              className="span-12 span-12-tablet padd-half"
            >
              <div className="heading-intro-with-tabs">

                {/* {updated ?
                  <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={200} className="changes-updated c5 border-c0 t border-top-off border-right-c5">
                    <h5>Changes Updated: {updated}</h5>
                  </Intro>
                  : null} */}


                <div className="changes-wrap">
                  {/* {manualChangesMap?.length > 0 ?
                    <>
                      {manualChangesMap?.length > 0 ? manualChangesMap : <h5>No changes or scratches reported yet.</h5>}
                    </>
                    : */}
                  {trackConditions && trackConditions?.length ? 
                    <RaceDescriptionBox
                        trackConditions={trackConditions}
                    />
                    :
                    null
                  }
                  {batchOfRaces.length > 0 ? 
                      batchOfRaces
                    : 
                    <h5>No changes or scratches reported yet</h5>
                  }                
                  {/* } */}
                </div>
                {/* <h5>You can also visit the <a href="https://twitter.com/santaanitapark" target="_blank">Santa Anita Twitter</a> for Scratches &amp; Changes</h5> */}


                {/* <pre>
									<code>{JSON.stringify(z, null, 2)}</code>
								</pre> */}

              </div>
            </Intro>

          </div>

        </div>
        :
        <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
      }
    </>
  )
}

export default Custom