import React, { useContext } from 'react'
import NaviContext from '../../../../context/NaviContext'
import './style.scss'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import DayBlock from '../../../DateBlock'

import useEventsDayCollection from '../../../../utils/useQueryEventsDayCollection'


function Custom(props) {
  const { images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map, repeaters: repeater, videos: video } = props
  const [io, ioInView] = useInView({ triggerOnce: true })

  const naviContext = useContext(NaviContext)

  const data = useEventsDayCollection()


  // Setting up 2 months ahead and 1 back
  const todayDate = new Date()
  const todayYear = todayDate.getFullYear()
  const todayMonth = todayDate.getMonth()
  const todayDay = todayDate.getDate()
  const todayStripped = new Date(todayYear, todayMonth, todayDay)


  const currentView = data.allContentfulEventDay.nodes.filter(day => {
    return (new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')) >= todayStripped)
  })

  currentView.sort(function (a, b) {
    return new Date(a.date) - new Date(b.date)
  })

  // Prep events so that we got em rounded to full days for comparisons
  const eventsGroupedByDay = []

  const eventsViewedBunch = []

  // Merge Race Events with Events
  currentView.forEach(day => {
    if (!day.events)
      day.events = []
    if (!day.raceEvents)
      day.raceEvents = []

    let newArr = day.raceEvents?.concat(day.events)

    if (newArr.length) {
      const new_obj = { ...day, eventsCollection: { items: newArr } }
      eventsViewedBunch.push(new_obj)
    }
  })


  eventsViewedBunch?.forEach((day, i) => {

    // Show only 10 event days
    if (i > 9)
      return

    const eventsFiltred = day.eventsCollection?.items?.filter(day => day)

    const events = eventsFiltred?.map((nodenode, i) => {
      let flag = true
      let d = new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0, 0, 0, 0)

      // If there is already this event day in array, add the event to that day
      eventsGroupedByDay?.forEach(element => {
        if (element[d]) {
          element[d].push(nodenode)
          flag = false
        }
      })

      // Otherwise just add a new day
      if (flag) {
        eventsGroupedByDay.push(
          { [d]: [nodenode] }
        )
      }
    })
    return events
  })


  // Organize Event Blocks
  const eventsMap = eventsGroupedByDay?.map((eventDay, i) => {

    let day = null
    for (let key in eventDay) {
      if (eventDay.hasOwnProperty(key)) {
        // Parse the simplified day of the event
        day = new Date(parseInt(key))
        let theDay = <DayBlock day={day} inheritedDelay={i * 25} ii={i} />

        const result = eventDay[key].map((item, i) => {
          return item
        })

        return (
          <div key={key + i} className={`single-event flex `}>
            <Link onClick={() => naviContext.setActive(false)} className="nostrike" to={`/events/#select-date?day=${day.toMyString()}`}>
              <div className="event-date-container ">
                <div className="date flex">
                  {theDay}
                </div>
                <div className="event-name flex ai-flex-end">
                  <p>{eventDay[key][0].name}</p>
                </div>
              </div>
            </Link>
          </div>
        )
      }
    }
    return null
  })


  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const twoColumns = repeater[0]?.classes?.includes('2-columns') ? true : false

  const links = repeater[0]?.repeater.map((node, i) => {
    return (
      <div onClick={() => naviContext.setActive(false)} className={`link ${i === 0 ? '' : 'fs-85'} ${twoColumns && i !== 0 ? 'span-6' : 'span-12'}`} key={i}>
        {node.value === 'blank' ?
          <h6 className="padd-top-half colored">{node.key}</h6>
          :
          validURL(node.value) ?
            i === 0 ?
              <a target="_blank" rel='noreferrer noopener' href={node.value} className="title-link nostrike"><h2 className="colored title">{node.key}</h2></a>
              :
              <a target="_blank" rel='noreferrer noopener' className="navi-link nostrike hover-trigger" href={node.value}>{node.key}<div className="icon icon-arrow fs-85"></div></a>
            :
            i === 0 ?
              <Link to={node.value} className="title-link nostrike"><h2 className="colored title">{node.key}</h2></Link>
              :
              <Link className="navi-link nostrike hover-trigger" to={node.value}>{node.key}<div className="icon icon-arrow fs-85"></div></Link>
        }
      </div>
    )
  })

  return (
    <div ref={io} className={`block-custom ${props.classes}`}>
      {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
      <div className="box c5">
        <div className="content flex-12">
          {links}
          <div className="flex main">
            {eventsMap}
            <div className="see-more">
              <div className="line"></div>
              <Link to="/events/" onClick={() => naviContext.setActive(false)} className="nostrike">View Full Calendar</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Custom