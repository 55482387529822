import React, { useEffect, useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { createPortal } from 'react-dom'

import Intro from '../../../../../animations/Intro'
import RaceDescriptionBox from '../RaceDescriptionBox'
import RaceStandingsBox from '../RaceStandingsBox'
import use1stAPI from '../../../../../Api'
import RaceControl from '../RaceControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'
import validatePdf from '../../../../../utils/validatePdf'

function isEmptyObject(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

function Custom(props) {
  // const [io, ioInView] = useInView({ triggerOnce: true })
  const { currentTab, setCurrentTab, switchFlag, selectedDate, data } = props


  // Setting up dates
  const selectedCDate = new Date(selectedDate)
  const selectedCYear = selectedCDate.getFullYear()
  const selectedCMonth = selectedCDate.getMonth() + 1
  const selectedCMonthLeadingZero = selectedCMonth < 10 ? '0' + selectedCMonth : '' + selectedCMonth
  const selectedCDay = selectedCDate.getDate()
  const selectedCDayLeadingZero = selectedCDay < 10 ? '0' + selectedCDay : '' + selectedCDay
  const selectedCRaceDate = `${selectedCYear}-${selectedCMonthLeadingZero}-${selectedCDayLeadingZero}`

  const [currentRace, setCurrentRace] = useState({
    race_date: selectedCRaceDate,
    race_number: null,
    highest_race: 1,
    track_code: 'GP',
    event_code: 'GPM',
    data: data
  })

  const { data: runners, loading: runnersLoading } = use1stAPI('runners', { ...currentRace })

  const { data: races, loading: racesLoading } = use1stAPI('races', { ...currentRace })
  const racesSorted = races?.races?.slice().sort((a, b) => (a.race_number > b.race_number) ? 1 : -1)

  const { data: track, loading: trackLoading } = use1stAPI('track', { ...currentRace })

  const { data: changes, loading: changesLoading } = use1stAPI('changes', { ...currentRace })

  const trackConditions = track?.track?.track_conditions?.map((node, i) => {
    if (!isEmptyObject(node)) {
      if (i === 0)
        return node.course + ': ' + node.condition
      return ', ' + node.course + ': ' + node.condition
    }
    else
      return false
  }).filter(obj => obj)

  const racesSurface = data?.surface ? data.surface : null
  const files = data?.files ? data.files : null

  const [runnersSwitched, setRunnersSwitched] = useState(false)

  // Track loading status changes
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!runnersLoading && !racesLoading && !trackLoading && !changesLoading) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [runnersLoading, racesLoading, trackLoading, changesLoading])

  // Track tab changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_number: currentTab + 1 } })
    setRunnersSwitched(false)
  }, [currentTab])

  // Date changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, race_date: selectedCRaceDate } })
  }, [selectedCRaceDate])

  // Data changes
  useEffect(() => {
    setCurrentRace((old) => { return { ...old, data: data } })
  }, [data])

  // Monitor runners change so it can switch to them without 
  useEffect(() => {
    setRunnersSwitched(true)
  }, [runners])


  const contentEntries = racesSorted?.map((node, i) => {
    const d = new Date(node.post) // removed .replace(/-/g, "/")

    const raceNumber = parseInt(node.race_number)

    if (raceNumber - 1 === currentTab) {

      return (
        <div key={`current-tab-${raceNumber}`} className="flex-12 box-wrap">
          <RaceDescriptionBox {...node}
            details={node.description}
            distance={node.distance}
            raceType={node.pb_class}
            postTime={node.published_post_time}
            purseInt={node.purse}
            raceBreed={node.race_breed}
            raceNumber={raceNumber}
            trackConditions={trackConditions}
            // surface={racesSurface?.[i+1]}
            ii={raceNumber}
            d={d}
          />
          <RaceStandingsBox {...node}
            standing={runners?.runners}
            runnersSwitched={runnersSwitched}
            changes={changes?.changes?.[raceNumber]}
            type="entries"
          />
        </div>
      )
    } else {
      return false
    }
  })

  const filesPortal =
    <>
      {files ?
        <Intro switch={true} in={{ border: 500, bg: 500, fade: 500 }} out={{ fade: 300 }} delayIn={500} className={`download-wrap c0 t border-c5 ${files?.[1] ? 'border-bottom-off' : ''}`}>
          <div className="downloads">
            <a href={validatePdf(files.url)} className="icon-pdf" target="_blank" rel="noreferrer noopener">Overnight</a>
          </div>
        </Intro>
        : null}
    </>

  const portal = typeof document !== "undefined" ? document.getElementById('entries-pdf-portal') : null
  const portalling = portal ? createPortal(
    <div className=''>
      {filesPortal}
    </div>
    ,
    portal
  ) : null




  return (
    <>
      {!loading ?
        <>
          <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="padd-half padd-top-off padd-bottom-off" style={{ paddingBottom: '1px' }}>
            <RaceControl toControl={races?.races} currentTab={currentTab} setCurrentTab={setCurrentTab} program={null} programAbbreviated={null} selectedDate={selectedDate} files={files} />
          </Intro>
          <Intro switch={switchFlag} in={{ fade: 500 }} out={{ fade: 300 }} delayIn={200} className="c0">
            <div className="entries-component">

              <div className="span-12 padd-half padd-top-off flex-12">

                <Intro
                  switch={currentTab && contentEntries}
                  in={{ fade: 300 }}
                  out={{ fade: 300 }}
                  delayIn={0}
                  delayOut={0}
                  mounted={true}
                  stay={true}
                  className={`span-${props.replays ? '6' : '12'} span-12-tablet`}
                >
                  {contentEntries}
                </Intro>

                {!contentEntries ?
                  <h6 className='padd-bottom-half'>No racing information is available for the selected date, please come back at a later time.</h6>
                  : null}

              </div>

            </div>
          </Intro>
          {portalling}
        </>
        :
        <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
      }
    </>
  )
}

export default Custom