import React, {useContext} from 'react'
import './style.scss'

import BlocksRouter from '../../Sections/BlocksRouter'

import FSLightBoxContext from '../../../context/FSLightBoxContext'

function Lightbox(props) {
	const lightBoxContext = useContext(FSLightBoxContext)

	function thumbClicked(lightboxSlidesArray,lightboxThumbsArray,lightboxThumbsIconsArray) {
		lightBoxContext.setSources(() => lightboxSlidesArray)
		lightBoxContext.setThumbs(() => lightboxThumbsArray)
		lightBoxContext.setThumbsIcons(() => lightboxThumbsIconsArray)
		setTimeout(() => {
			lightBoxContext.setToggler((toggle) => !toggle)
		}, 50);
	}

	const thumb = props.handle?.map((node, i) => {
		if (node.__typename === 'ContentfulBlockImage') {
			return <BlocksRouter key={i} blocks={[{...node, _in: { border: 500, bg: 500, fade: 500 }}]} />
		} else {
			return <BlocksRouter key={i} blocks={[node]} />
		}
	})

	const thumbs = props.blocks?.map((node, i) => {
		if(node.__typename === 'ContentfulBlockImage') {
			return node.image?.gatsbyImageData.images.fallback.src
		} else if (node.__typename === 'ContentfulBlockVideo') {
			const image = node?.image?.gatsbyImageData?.images?.fallback?.src
			return image ? image : 'https://via.placeholder.com/150/1c1c1c/ffffff?text=%20'
		}
		return 'https://via.placeholder.com/150/1c1c1c/ffffff?text=%20'
	})

	const icons = props.blocks?.map((node, i) => {
		if(node.__typename === 'ContentfulBlockVideo') {
			return <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play-circle" className="svg-inline--fa fa-play-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"></path></svg>
		}
		return null
	})

	const sources = props.blocks?.map((node, i) => {
		if(node.type === 'image') {
			return node.imgSrcSet
		} else {
			return <div className="lightbox-inner-wrap"><div className="wtf"><BlocksRouter key={i} blocks={[node]} /></div></div>
		}
	})

	//console.log(sources)

  return (
		<div className={`lightbox-trigger ${props.classes}`} onClick={() => thumbClicked(sources,thumbs,icons)} aria-label="Lightbox trigger" onKeyDown={() => thumbClicked(sources,thumbs,icons)} role="button" tabIndex={0}>
			{thumb}
		</div>
  )
}

export default Lightbox