import React, {useState} from 'react'
import './style.scss'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'
import EventGroup from './EventGroup'
import DayBlock from '../../../DateBlock'

import BlocksRouter from '../../../Sections/BlocksRouter'

import useEventsDayCollection from '../../../../utils/useQueryEventsDayCollection'
import validatePdf from '../../../../utils/validatePdf'

function validURL(str) {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
}

// The fun starts here
function Custom(props) {
	const {images: img, repeaters: repeater} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

	const [currentTab, setCurrentTab] = useState(0)

  const data = useEventsDayCollection()


	function clickHandle(day) {
		setCurrentTab(day)
	}
  const itemsMapped = repeater?.[0]?.repeater?.map((item,i) => {
    return (
      <div key={i} className="link-box">
        {
          validURL(item?.value) ?
          <a className="link nostrike" href={validatePdf(item?.value)} target="_blank" rel='noreferrer noopener' aria-label="Event">
            <BlocksRouter key={`img-0`} blocks={[{ ...img?.[i], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 200 + 125 * i }]} />
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={325 + 125 * i} className="c0 t border-top-off">
              <BlocksRouter key={`btn-0`} blocks={[{ label: item?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200 }]} />
            </Intro>
          </a>
          :
          <Link className="link nostrike" to={item?.value} aria-label="Event">
            <BlocksRouter key={`img-0`} blocks={[{ ...img?.[i], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 200 + 125 * i }]} />
            <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={325 + 125 * i} className="c0 t border-top-off">
              <BlocksRouter key={`btn-0`} blocks={[{ label: item?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200 }]} />
            </Intro>
          </Link>
        }
      </div>
    )
  })

	// Setting up 2 months ahead and 1 back
	const todayDate = new Date()
	const todayYear = todayDate.getFullYear()
	const todayMonth = todayDate.getMonth()
	const todayDay = todayDate.getDate()
	const todayStripped = new Date(todayYear,todayMonth,todayDay)

	const currentView = data.allContentfulEventDay.nodes.filter(day => {
    return (new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')) >= todayStripped) 
  })
	
	currentView.sort(function(a,b){
		return new Date(a.date) - new Date(b.date)
	})

	const defaultImage = data?.contentfulOptions?.defaultRaceEventImage
 

	// Prep events so that we got em rounded to full days for comparisons
	const eventsGroupedByDay = []
	
	// Only Racing Days
	const eventsViewedBunch = []

	  // Merge Race Events with Events
		currentView.forEach(day => {
			if(!day.events)
				day.events = []
			if(!day.raceEvents)
				day.raceEvents = [] 
			if(!day.promotions)
				day.promotions = [] 
	
			let newArr = [].concat(day.events, day.raceEvents, day.promotions)
	
			const new_obj = { ...day, eventsCollection: { items: newArr} }
			eventsViewedBunch.push(new_obj)
		})
	

		
	eventsViewedBunch?.forEach((day, i) => {
    
		// Show only seven event days
		if(i > 6)
			return
	
		// Racing Events Only
		const eventsFiltred = day.eventsCollection.items
		
		const events = eventsFiltred?.map((nodenode,i) => {
			let flag = true
			let d = new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, '')).setHours(0,0,0,0)
			
			// If there is already this event day in array, add the event to that day
			eventsGroupedByDay?.forEach(element => {
				if(element[d]) {
					element[d].push(nodenode)
					flag = false
				}
			})
			
			// Otherwise just add a new day
			if(flag) {
				eventsGroupedByDay.push(
					{[d]: [nodenode]}
				)
			}
		})
		return events
	})

	// Organize Date Tab Handles
	const eventsTabHandlesMap = eventsGroupedByDay?.map((eventDay,i) => {

		let day = null
		for (let key in eventDay) {
			if(eventDay.hasOwnProperty(key)) {
				// Parse the simplified day of the event
				day = new Date(parseInt(key))
			}
		}

		return (
			<div key={i} onClick={() => clickHandle(day)} className={!currentTab && i === 0 || day.toString() === currentTab?.toString() ? 'current single-event' : 'single-event'}>
				<DayBlock day={day} selected={false} passed={false} ii={i} type={'full'} />
			</div>
		)
	})

	// Organize Event Blocks
	const eventsMap = eventsGroupedByDay?.map((eventDay,i) => {
		
		let day = null
		for (let key in eventDay) {
			if(eventDay.hasOwnProperty(key)) {
				// Parse the simplified day of the event
				day = new Date(parseInt(key))
			}
		}

		if (!currentTab && i === 0) {
			return <EventGroup key={i} defaultImage={defaultImage} eventDay={eventDay} todayDate={todayDate} i={i} />
		} else if (day.toString() === currentTab?.toString()) {
			return <EventGroup key={i} defaultImage={defaultImage} eventDay={eventDay} todayDate={todayDate} i={i} />
		}
		
		return false

	})


  return (
		<div ref={io} className={`block-custom events-upcoming-calendar ${props.classes}`}>

			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

			<div className="wrap">

				<div className="tab-handles">
					{eventsTabHandlesMap}
				</div>
				
						<>
							<div id="events-group-calendar" className={`events-container`}>
								{eventsMap.length > 0 ?
									<>
										<div className="tab-contents">
											<div className="tab-events">
												<Intro switch={currentTab} in={{ appear: 0 }} delayIn={10} out={{ fade: 400 }} className="">
													{eventsMap}
												</Intro>
											</div>
											<div className="tab-featured">
                        {itemsMapped}
												{/* <div className="link-box">
													{validURL(repeater[0]?.repeater[0]?.value) ?
													<a className="link nostrike" href={repeater[0]?.repeater[0]?.value} target="_blank" rel='noreferrer noopener' aria-label="Event">
														<BlocksRouter key={`img-0`} blocks={[{...img[0], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 200}]} />
														<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={325} className="c0 t border-top-off">
															<BlocksRouter key={`btn-0`} blocks={[{label: repeater[0]?.repeater[0]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
														</Intro>
													</a>
													 :
													<Link className="link nostrike" to={repeater[0]?.repeater[0]?.value} aria-label="Event">
														<BlocksRouter key={`img-0`} blocks={[{...img[0], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 200}]} />
														<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={325} className="c0 t border-top-off">
															<BlocksRouter key={`btn-0`} blocks={[{label: repeater[0]?.repeater[0]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
														</Intro>
													</Link>
													 }
												</div>
												<div className="link-box">
													{validURL(repeater[0]?.repeater[1]?.value) ?
													<a className="link nostrike" href={repeater[0]?.repeater[1]?.value} target="_blank" rel='noreferrer noopener' aria-label="Event">
														<BlocksRouter key={`img-1`} blocks={[{...img[1], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 325}]} />
														<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={450} className="c0 t border-top-off">
															<BlocksRouter key={`btn-1`} blocks={[{label: repeater[0]?.repeater[1]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
														</Intro>
													</a>
													 :
													<Link className="link nostrike" to={repeater[0]?.repeater[1]?.value} aria-label="Event">
													 <BlocksRouter key={`img-1`} blocks={[{...img[1], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 325}]} />
													 <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={450} className="c0 t border-top-off">
														 <BlocksRouter key={`btn-1`} blocks={[{label: repeater[0]?.repeater[1]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
													 </Intro>
												 </Link>
													}
												</div>
												<div className="link-box">
													{validURL(repeater[0]?.repeater[2]?.value) ?
													<a className="link nostrike" href={repeater[0]?.repeater[2]?.value} target="_blank" rel='noreferrer noopener' aria-label="Event">
														<BlocksRouter key={`img-2`} blocks={[{...img[2], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 450}]} />
														<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={575} className="c0 t border-top-off">
															<BlocksRouter key={`btn-2`} blocks={[{label: repeater[0]?.repeater[2]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
														</Intro>
													</a>
													:
													<Link className="link nostrike" to={repeater[0]?.repeater[2]?.value} aria-label="Event">
														<BlocksRouter key={`img-2`} blocks={[{...img[2], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 450}]} />
														<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={575} className="c0 t border-top-off">
															<BlocksRouter key={`btn-2`} blocks={[{label: repeater[0]?.repeater[2]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
														</Intro>
													</Link>
													}
												</div>
												<div className="link-box">
													{validURL(repeater[0]?.repeater[3]?.value) ?
													<a className="link nostrike" href={repeater[0]?.repeater[3]?.value} target="_blank" rel='noreferrer noopener' aria-label="Event">
														<BlocksRouter key={`img-3`} blocks={[{...img[3], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 575}]} />
														<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={700} className="c0 t border-top-off">
															<BlocksRouter key={`btn-3`} blocks={[{label: repeater[0]?.repeater[3]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
														</Intro>
													</a>
													:
													 <Link className="link nostrike" to={repeater[0]?.repeater[3]?.value} aria-label="Event">
														<BlocksRouter key={`img-3`} blocks={[{...img[3], __typename: 'ContentfulBlockImage', classes: 'c0 aspect-ratio', delay: 575}]} />
														<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={700} className="c0 t border-top-off">
															<BlocksRouter key={`btn-3`} blocks={[{label: repeater[0]?.repeater[3]?.key, __typename: 'ContentfulBlockButton', classes: 'colored', delay: 200}]} />
														</Intro>
												 	</Link>
														}
												</div> */}
											</div>
										</div>
									</>
								: <h2 className="text-center padd-top-half">Sorry, no upcoming events :(</h2>}
								<div className="see-more">
									<div className="line"></div>
									<Link to="/events/" className="nostrike">View Full Calendar</Link>
								</div>
							</div>
						</>
				


			</div>
			
			<Intro visible={ioInView} in={{ fadeRight: 500 }} delayIn={200} className="upcoming-container">
				<div className="upcoming"><div className="turn"><div className="pivot">Upcoming</div></div></div>
			</Intro>

		</div>
  )
}

export default Custom