import React, {useEffect} from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'

function Workouts(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	// const {workouts, program, currentTab, setCurrentTab, distance, setDistance} = props
	const {workouts, currentTab, setDistance} = props


	let distanceUpdate = null
	const contentWorkouts = workouts?.map((node,i) => {

		if (i === currentTab) {

			distanceUpdate = node.distance
		
			return (
				<div key={`current-tab-${i}`} className="flex-12 box-wrap">		
					<div className="span-6 span-12-tablet padd-half padd-top-off flex-12">
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c5">thumb/lightbox tab - {currentTab + 1}</div>
						</div>
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c3">thumb/lightbox</div>
						</div>
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c3">thumb/lightbox</div>
						</div>
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c5">thumb/lightbox</div>
						</div>
					</div>

					<div className="span-6 span-12-tablet padd-half padd-top-off flex-12">
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c5">thumb/lightbox</div>
						</div>
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c3">thumb/lightbox</div>
						</div>
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c3">thumb/lightbox</div>
						</div>
						<div className="span-6 span-12-tablet">
							<div className="aspect-ratio c5">thumb/lightbox</div>
						</div>
					</div>
				</div>
			)
		} else {
			return false
		}
	})

	useEffect(() => {
		setDistance(distanceUpdate)
	},[distanceUpdate])


  return (
		<div ref={io} className={`block-workouts flex-12`}>

			<Intro
				switch={currentTab && contentWorkouts && ioInView}
				in={{ fade: 300 }}
				out={{ fade: 300 }}
				delayIn={0}
				delayOut={0}
				mounted={true}
				stay={true}
				className={`span-12`}
			>
				{contentWorkouts}
			</Intro>

		</div>
  )
}

export default Workouts