import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

// import Block from '../../../Sections/BlocksRouter'
import Intro from '../../../../animations/Intro'

function FormGetNotes(props) {
	// const formImage = props.img
	const { register, handleSubmit, formState: { errors, reset } } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const delayIn = 500
	const [io, ioInView] = useInView({ triggerOnce: true })

	const onSubmit = (form, e) => {
		e.preventDefault()
		console.log("form",form)
		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
		data.append('your-lname', form.lname)
		data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-event-type', form.street)
		data.append('your-date', form.state)
		data.append('your-source', form.zip)
		data.append('your-message', form.phone)
		console.log(form)
		const url = `https://design-sentry.com/test/wp-json/contact-form-7/v1/contact-forms/239/feedback`
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<form
			name="contact"
			method="post"
			action="/thanks/"
			className="free-notes"
			onSubmit={handleSubmit(onSubmit)}
			ref={io}
		>
			<div className="flex-12 ">
				<label className="span-6">
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="first name" name="fname" {...register("fname",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your first name is required.</p>}
				</label>
				<label className="span-6">
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 150 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="last name" name="lname" {...register("lname",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.lname && <p className="small margin-off">your last name is required.</p>}
				</label>
				<label className="span-12">
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 250 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="email" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
					</Intro>
					{errors.email && <p className="small margin-off">a correct email is required.</p>}
				</label>
				<label className="span-12">
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 350 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Street Address" name="street" {...register("street",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.street && <p className="small margin-off">your street address is required.</p>}
				</label>
        <label className="span-6">
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 450 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="State" name="state" {...register("state",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.state && <p className="small margin-off">your state name is required.</p>}
				</label>
				<label className="span-6">
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 550 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Zip Code" name="zip" {...register("zip",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.zip && <p className="small margin-off">your zip code is required.</p>}
				</label>
        <label className="span-12">
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 650 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Phone Number" name="phone" {...register("phone",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.phone && <p className="small margin-off">your phone is required.</p>}
				</label>
				<div>
					<Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 750 } >	
						<p className="checkbox-desc">By clicking GET FREE TRIP NOTES, you agree to receive marketing text messages from Gulfstream Park at the number provided, including messages sent by autodialer. Consent is not a condition of any purchase. Message and data rates may apply. Message frequency varies. Reply HELP for help or STOP to cancel. View our Privacy Policy and Terms of Service. Terms of Service and Privacy Policy.</p>		
						<div className="checkbox-container">
							<label className="inline">
								<input type="checkbox"  value="agreement"  {...register("agreement",{required: true, maxLength: 80, message: "error message"})}/>
								<span className="checkmark">
									<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
										<svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
											<g transform="translate(-819.921 -242.525)">
												<path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
												<path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											</g>
										</svg>
									</Intro>
								</span>
								<p>I agree to receive marketing emails from Trip Note Pros &amp; Santa Anita Park (unsubscribe anytime).
									{errors.agreement && <p className="small message margin-off">This field is required</p>}
								</p>

							</label>
						</div>
					</Intro>
				</div>
				<Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 850 } >		
					<button className="submit" type="submit">GET FREE TRIP NOTES {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
				</Intro>
				<p className="message small">{message}</p>
				<p>HAVING ISSUES FILLING OUT THE FORM? CONTACT US AT <a href="mailto:CONTACT@SANTAANITA.COM">CONTACT@SANTAANITA.COM</a></p>
			</div>
		</form>
	)
}

export default FormGetNotes