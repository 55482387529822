import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../animations/Intro'
import Map from '../../../Map/GMap'

function Custom(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

  const lat = props.custom?.data?.lat ? props.custom?.data?.lat : 25.978136
  const lng = props.custom?.data?.lng ? props.custom?.data?.lng : -80.139579
  const zoom = props.custom?.data?.zoom ? props.custom?.data?.zoom : 15
  // console.log(lat)
  return (
		<div ref={io} className={`block-custom custom-map w-100  ${props.classes}`}>
      <Intro visible={ioInView} in={{ border: 300, bg: 700, fade: 500 }} delayIn={100} mounted={true} stay={true} className="c5 t border-c5">
        <div className="aspect-ratio">
          <div className="map">
            <Map lat={lat} lng={lng} zoom={zoom} />
          </div>
        </div>
      </Intro>
    </div>
  )
}

export default Custom