import React, {useState, useEffect} from 'react'
import './style.scss'

function Custom() {

  // useEffect(() => {
	// 	const script = document.createElement('script');
	
	// 	script.src = "https://ten-palms.popmenu.com/s/embeds/ftdwbpml.js"
	// 	script.async = true
  //   const target = document.querySelector('#append')
	// 	target.appendChild(script)

	// 	return () => {
	// 		target.removeChild(script)
	// 	}
	// }, []);

  return (
    <>
		<h1>POP MENU TEST</h1>
     
    </>
  )
}

export default Custom