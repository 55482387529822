import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Workouts(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [virtualStableLink, setVirtualStableLink] = useState([])
  const {
    standing: standing,
    runners: runners,
    type: type,
    changes: changes,
    runnersSwitched: runnersSwitched,
  } = props

  function handleAddVirtualStable(e) {
    const checkboxes = typeof document !== "undefined" ? [...document.querySelectorAll('.virtual-stable input:checked')] : null
    const selections = checkboxes?.map((node, i) => {
      return node.value
    })
    const virtualStableLinkInsert = 'http://www.equibase.com/virtualstable/horse.cfm?SID=1&hl=' + encodeURIComponent(selections.join(',')) + '&add=Y'
    setVirtualStableLink(virtualStableLinkInsert)
    return false
  }

  const list = standing?.map((node, i) => {
    //console.log(runners)
    const {
      post_position: startingGrid,
      post_position: pp,
      betting_interest_name: horse,
      jockey: jockey,
      jockeyLink: jockeyLink,
      weight_carried: jockeyweight,
      trainer: trainer,
      trainerLink: trainerLink,
      virtualStable: virtualStableID,
      color: color,
      gender: gender,
      horse_year_of_birth: yob,
      medication: med,
      claiming_price: claim,
      mlodd: ml,
      sire: sire,
      dam: dam,
      damsire: damsire,
      runner: runner,
      payout: payout,
      base_amount: baseAmount,
    } = node

    const r = parseInt(runner)

    return (
      <Intro key={`content-results-${i}`} switch={ioInView && runnersSwitched} in={{ fadeTop: 500 }} delayIn={i * 100} className="row">
        <div className={`no no-${runners?.[r - 1]?.tote_number} color-${runners?.[r - 1]?.color} runner saddle-color-${runners?.[r - 1]?.saddle_cloth_color} scratched-${changes?.changes?.[parseInt(pp)]?.scratched ? 'true' : 'false'}`}>
          <h4>{runners?.[r - 1]?.tote_number}</h4>
        </div>
        <div className={`pp`}>
          <h4>{runners?.[r - 1]?.post_position}</h4>
        </div>
        <div className="horse-jockey">
          <h5>{runners?.[r - 1]?.betting_interest_name ? runners?.[r - 1]?.betting_interest_name : 'N/A'}</h5>
          <p>{
            runners?.[r - 1]?.jockey && changes?.changes?.[r]?.jockey ?
              <><span className="jockey-scratched">{runners?.[r - 1]?.jockey}</span> <span>{changes?.changes?.[r]?.jockey}</span></>
              : `J: ${runners?.[r - 1]?.jockey}` ?
                `J: ${runners?.[r - 1]?.jockey}`
                : 'N/A'}
            <br />
            {
              runners?.[r - 1]?.trainer ?
                `T: ${runners?.[r - 1]?.trainer}`
                : 'N/A'}
          </p>
        </div>
        <div className="gender">
          <p>{runners?.[r - 1]?.gender ? runners?.[r - 1]?.gender : 'N/A'}</p>
        </div>
        <div className="yob">
          <p>{runners?.[r - 1]?.horse_year_of_birth ? runners?.[r - 1]?.horse_year_of_birth : 'N/A'}</p>
        </div>
        <div className="med">
          <p>{runners?.[r - 1]?.medication ? runners?.[r - 1]?.medication : 'N/A'}</p>
        </div>
        {/* <div className="claim">
					<p>{runners?.[r-1]?.claiming_price ? runners?.[r-1]?.claiming_price : 'N/A'}</p>
				</div> */}
        <div className="ml">
          <p>{runners?.[r - 1]?.mlodd ? runners?.[r - 1]?.mlodd : 'N/A'}</p>
        </div>
        {/* <div className="base">
					<p>{baseAmount ? baseAmount : 'N/A'}</p>
				</div> */}
        <div className="win-prize">
          <h6>{payout && payout?.[0] !== 0 ? '$' + payout?.[0]?.toFixed(2) : '-'}</h6>
        </div>
        <div className="place-prize">
          <h6>{payout && payout?.[1] !== 0 ? '$' + payout?.[1]?.toFixed(2) : '-'}</h6>
        </div>
        <div className="show-prize">
          <h6>{payout && payout?.[2] !== 0 ? '$' + payout?.[2]?.toFixed(2) : '-'}</h6>
        </div>
      </Intro>
    )
  })

  return (
    <div ref={io} className={`results-standings-box flex-12`}>

      <div className="results-tab">
        <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={0} className="row row-head">
          <div className="no">
            <p>PG</p>
          </div>
          <div className="pp">
            <p>PP</p>
          </div>
          <div className="horse-jockey">
            <p>Horse / Jockey</p>
          </div>
          {/* <div className="virtual-stable">
						<p>Virtual Stable</p>
					</div> */}
          <div className="gender">
            <p>Gender</p>
          </div>
          <div className="yob">
            <p>YoB</p>
          </div>
          <div className="med">
            <p>MED</p>
          </div>
          {/* <div className="claim">
						<p>Claim&nbsp;$</p>
					</div> */}
          <div className="ml">
            <p>M/L</p>
          </div>
          {/* <div className="base">
						<p>Base</p>
					</div> */}
          {type === 'results' ?
            <>
              <div className="win-prize">
                <p>Win</p>
              </div>
              <div className="place-prize">
                <p>Place</p>
              </div>
              <div className="show-prize">
                <p>Show</p>
              </div>
            </>
            :
            null
          }
        </Intro>
        {list}
        {!runnersSwitched ?
          <div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
          :
          null
        }
        <Intro visible={ioInView} in={{ fadeTop: 500 }} delayIn={500} className="row row-foot">
          <div className="no">
            <p>PG</p>
          </div>
          <div className="pp">
            <p>PP</p>
          </div>
          <div className="horse-jockey">
            <p>Horse / Jockey</p>
          </div>
          {/* <div className="virtual-stable">
						<p><a className="btn" target="_blank" href={virtualStableLink}><span>Add</span></a></p>
					</div> */}
          <div className="gender">
            <p>Gender</p>
          </div>
          <div className="yob">
            <p>YoB</p>
          </div>
          <div className="med">
            <p>MED</p>
          </div>
          {/* <div className="claim">
						<p>Claim&nbsp;$</p>
					</div> */}
          <div className="ml">
            <p>M/L</p>
          </div>
          {/* <div className="base">
						<p>Base</p>
					</div> */}
          {type === 'results' ?
            <>
              <div className="win-prize">
                <p>Win</p>
              </div>
              <div className="place-prize">
                <p>Place</p>
              </div>
              <div className="show-prize">
                <p>Show</p>
              </div>
            </>
            :
            null
          }
        </Intro>
      </div>
      &nbsp;
    </div>
  )
}

export default Workouts
