import React, { useState, useEffect } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'
import validatePdf from '../../../../../utils/validatePdf'

function RaceControl(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const { toControl, currentTab, setCurrentTab, text, program, programType, programAbbreviated, selectedDate } = props

  let handlesSorted = toControl?.slice().sort((a, b) => {
    return a.race_number - b.race_number
  })

  const handles = handlesSorted?.map((node, i) => {
    const ix = parseInt(node.race_number) - 1
    const isSimulcast = node?.description?.toLowerCase()?.includes("simulcast")

    return (
      <div key={`handle-${ix}`} className={`handle has-intro ${ix === currentTab ? 'current' : ''}`} onClick={() => setCurrentTab(ix)} onKeyDown={() => setCurrentTab(ix)} role="button" aria-label="Race Tab Handle" tabIndex={0}>
        <Intro switch={toControl} in={{ border: 500, bg: 500, fade: 500 }} className={`border-left-off ${ix === currentTab ? 'c3 t border-c5' : isSimulcast ? 'c-grey t border-c5 t border-right-c0' : 'c5 border-c5 t border-right-c0'}`} delayIn={i * 100}>
          <h4>{node.race_number ? node.race_number : i + 1}</h4>
        </Intro>
      </div>
    )
  })

  return (
    <div ref={io} className={`racing-handles`}>
      <div className="row-1">
        {toControl ?
          <div className="intro has-intro">
            <Intro switch={toControl} in={{ border: 500, bg: 500, fade: 500 }} out={{ fade: 300 }} className={`c5 t border-c5 border-right-c0`}>
              <h4>{text ? text : 'Race:'}</h4>
            </Intro>
          </div>
          : null}
        <div className="handles-wrap">
          {handles}
        </div>
        <Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={400} className="selected-date c5 t border-c5 border-top-off">
          <h6>{selectedDate?.toLocaleDateString(['en-US'], { weekday: 'long', month: 'long', day: 'numeric' })}</h6>
        </Intro>
        {program ?
          <Intro switch={toControl} in={{ border: 500, bg: 500, fade: 500 }} out={{ fade: 300 }} delayIn={500} className={`download-wrap c0 t border-c5 ${programAbbreviated ? 'border-right-off' : ''}`}>
            <div className="downloads">
              <a href={validatePdf(props.program)} className="icon-pdf" target="_blank" rel="noreferrer noopener">Download<br />{programType ? programType : 'Program'}</a>
            </div>
          </Intro>
          : null}
        {programAbbreviated ?
          <Intro switch={toControl} in={{ border: 500, bg: 500, fade: 500 }} out={{ fade: 300 }} delayIn={600} className={`download-wrap c0 t border-c5`}>
            <div className="downloads">
              <a href={validatePdf(props.programAbbreviated)} className="icon-pdf" target="_blank" rel="noreferrer noopener">Download<br />Abbreviated Program</a>
            </div>
          </Intro>
          : null}
      </div>
    </div>
  )
}

export default RaceControl
