import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import ContactUs from '../../../Form/Axios/ContactUs'

function Custom(props) {
	//const {images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map} = props
	const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<div ref={io} className={`block-custom ${props.classes}`}>
			{props.anchor ? <div id={props.anchor} className="anchor"></div> : null}
			<ContactUs/>
		</div>
  )
}

export default Custom